import React from 'react'
import { useState } from "react";
import { motion, AnimateSharedLayout, AnimatePresence } from "framer-motion";

// import { Button } from '../Button'

// import { Link } from 'react-router-dom'
import { withTranslation } from 'react-i18next';
import { Typography, makeStyles, Button } from '@material-ui/core';
import { Link } from 'react-router-dom'
import { useHistory } from 'react-router-dom';

import './ProcessSection.css'
import dotIcon from "../../images/home/dotIcon.png"

import dotLine from "../../images/home/dotLine.png"
import onlineTradeIcon1 from "../../images/home/onlineTradeIcon1.png"
import onlineTradeIcon2 from "../../images/home/onlineTradeIcon2.png"
import onlineTradeIcon3 from "../../images/home/onlineTradeIcon3.png"
import onlineTradeIcon4 from "../../images/home/onlineTradeIcon4.png"
import cryptocurrency from "../../images/home/Cryptocurrency.svg"
import payment from "../../images/home/Payment.svg"


function ProcessSellSection({ t, phone }) {
    const useStyles = makeStyles({
        fontStyle: {
            fontFamily: "Avenir", fontWeight: '500'
        }
    })
    const history = useHistory();

    const items = [
        { title: t('home_step1Title'), content: "aaa", imgIcon: onlineTradeIcon1, id: "1" },
        { title: t('home_step2Title'), content: "bbb", imgIcon: onlineTradeIcon2, id: "2" },
        { title: t('home_step3Title'), content: "ccc", imgIcon: onlineTradeIcon3, id: "3" },
        { title: t('home_step4Title'), content: "ddd", imgIcon: onlineTradeIcon4, id: "4" },

    ]
    const classes = useStyles();



    const cell = (title, image, subtitle) => {
        return (
            <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', width: '100%' }}>
                <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', width: '100%' }}>
                    <div style={{ height: 52, width: 52, borderRadius: 12, backgroundColor: '#F3F3F3' }}>
                        <img src={image}></img>
                    </div>
                    <div style={{ display: 'flex', flexDirection: 'column', marginLeft: 16, width: '80%', height: 108, justifyContent: 'center' }}>
                        <Typography className={classes.fontStyle} style={{ fontSize: 16 }}>{title}</Typography>
                        <Typography className={classes.fontStyle} style={{ fontSize: 14, color: '#8B96A7' }}>{subtitle}</Typography>

                    </div>
                </div>
                <div style={{ height: 1, backgroundColor: '#EAEAEA', width: '100%' }}></div>
            </div>
        )
    }

    const cellPhone = (title, image, subtitle, step) => {
        return (
            <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', width: '91%', marginTop: 16, backgroundColor: '#F9F9F9', paddingTop: 12, paddingBottom: 12, borderRadius: 12, }}>
                <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', width: '91%', justifyContent: 'space-between' }}>
                    <div style={{ height: 40, width: 40, borderRadius: 12, backgroundColor: '#F3F3F3' }}>
                        <img src={image}></img>
                    </div>
                    <div style={{ display: 'flex', flexDirection: 'column', marginLeft: 16, width: '84%', justifyContent: 'center' }}>
                        <Typography className={classes.fontStyle} style={{ fontSize: 14 }}>{step}. {title}</Typography>
                        <Typography className={classes.fontStyle} style={{ fontSize: 12, color: '#8B96A7' }}>{subtitle}</Typography>

                    </div>
                </div>
            </div>
        )
    }



    return phone ?
        (
            <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', flexDirection: 'column', marginTop: 64 }}>

                <div style={{ height: 1, width: '91%', backgroundColor: '#F2F2F2', marginBottom: 40 }}></div>

                <div style={{ width: '95%', display: 'flex', alignItems: 'center', flexDirection: 'column' }}>
                    <Typography className={classes.fontStyle} style={{ fontSize: phone ? 20 : 30, fontWeight: '500' }}>{t('home_howToTrade')}</Typography>
                    <Typography className={classes.fontStyle} style={{ fontSize: phone ? 12 : 16, fontWeight: '500', color: '#8B96A7', marginTop: 0 }}>{t('home_howToTradeSub')}</Typography>
                </div>
                <div style={{height:100, overflow: 'hidden', alignItems: 'center', display: 'flex', flexDirection: 'row', marginTop: 32, width: '91%', marginTop: 16, backgroundColor: '#F9F9F9', paddingTop: 12, paddingBottom: 12, borderRadius: 12, justifyContent: 'space-between' }}>
                    <div style={{ width: '60%', marginLeft: '5%' }}>
                        <Typography className={classes.fontStyle} style={{ fontSize: phone ? 14 : 30, fontWeight: '500' }}>{t('home_onlineTitle')}</Typography>
                        <Typography className={classes.fontStyle} style={{ fontSize: phone ? 12 : 16, fontWeight: '500', color: '#8B96A7', marginTop: 0 }}>{t('home_onlineSub')}</Typography>
                    </div>
                    <div style={{ width: '25%' }}>
                        <img src={cryptocurrency} style={{ height: '120%' }}></img>
                    </div>
                </div>
                {cellPhone(t('home_step1Title'), onlineTradeIcon1, t('home_step1Sub'), "1")}
                {cellPhone(t('home_step2Title'), onlineTradeIcon2, t('home_step2Sub'), "2")}
                {cellPhone(t('home_step3Title'), onlineTradeIcon3, t('home_step3Sub'), "3")}
                {cellPhone(t('home_step4Title'), onlineTradeIcon4, t('home_step4Sub'), "4")}
                {/* <Link to='/login' style={{ textDecoration: 'none' }} > */}

                <Button onClick={() => history.push('/register')} style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', width: '91%', height: 60, backgroundColor: '#3254FA', borderRadius: 16, marginTop: 32, textTransform: 'none' }}>

                    <Typography style={{ color: 'white', fontSize: 16 }} className={classes.fontStyle}> {t('home_onlineButton')}</Typography>
                </Button>
                {/* </Link> */}
            </div>


        )
        :
        (
            <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', flexDirection: 'column', marginTop: 64 }}>

                <div style={{ height: 1, width: '81%', backgroundColor: '#F2F2F2', marginBottom: 40 }}></div>

                <div style={{ width: '81%', display: 'flex', alignItems: 'center', flexDirection: 'column' }}>
                    <Typography className={classes.fontStyle} style={{ fontSize: phone ? 20 : 30, fontWeight: '500' }}>{t('home_howToTrade')}</Typography>
                    <Typography className={classes.fontStyle} style={{ fontSize: phone ? 12 : 16, fontWeight: '500', color: '#8B96A7', marginTop: 0 }}>{t('home_howToTradeSub')}</Typography>
                </div>

                <div style={{ width: '81%', display: 'flex', alignItems: 'center', flexDirection: 'row', marginTop: 64 }}>
                    <div style={{ display: 'flex', alignItems: 'flex-start', flexDirection: 'column', width: '60%' }}>
                        <div style={{ display: 'flex', alignItems: 'flex-start', flexDirection: 'column' }}>
                            <Typography className={classes.fontStyle} style={{ fontSize: 24, fontWeight: '500' }}>{t('home_onlineTitle')}</Typography>
                            <Typography className={classes.fontStyle} style={{ fontSize: 16, fontWeight: '500', color: '#8B96A7', marginTop: 0 }}>{t('home_onlineSub')}</Typography>
                        </div>
                        <div style={{ display: 'flex', flexDirection: "row" }}>
                            <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                                <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', width: 30, marginTop: 60 }}>
                                    <img style={{ height: 28 }} src={dotIcon}></img>

                                    <img style={{ height: 80, width: 1 }} src={dotLine}></img>
                                    <img style={{ height: 28 }} src={dotIcon}></img>
                                    <img style={{ height: 80, width: 1 }} src={dotLine}></img>
                                    <img style={{ height: 28 }} src={dotIcon}></img>
                                    <img style={{ height: 80, width: 1 }} src={dotLine}></img>
                                    <img style={{ height: 28 }} src={dotIcon}></img>


                                </div>
                                <div style={{ display: 'flex', flexDirection: 'column', width: '90%', marginTop: 18 }}>
                                    {cell(t('home_step1Title'), onlineTradeIcon1, t('home_step1Sub'))}
                                    {cell(t('home_step2Title'), onlineTradeIcon2, t('home_step2Sub'))}
                                    {cell(t('home_step3Title'), onlineTradeIcon3, t('home_step3Sub'))}
                                    {cell(t('home_step4Title'), onlineTradeIcon4, t('home_step4Sub'))}
                                    <Link to='/register' style={{ textDecoration: 'none' }} >
                                        <Button style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', width: '50%', height: 60, backgroundColor: '#3254FA', borderRadius: 20, marginTop: 32, textTransform: 'none' }}>

                                            <Typography style={{ color: 'white', fontSize: 16 }} className={classes.fontStyle}> {t('home_onlineButton')}</Typography>
                                        </Button>
                                    </Link>
                                </div>
                            </div>


                        </div>
                    </div>

                    <img style={{ width: '34%', position: 'absolute', right: 0, }} src={cryptocurrency}></img>

                </div>


            </div >
        )
}

export default withTranslation()(ProcessSellSection)
