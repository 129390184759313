import { withTranslation } from "react-i18next";
import { Button, InputAdornment, InputBase, makeStyles, MenuItem, Modal, Select, Typography } from "@material-ui/core";
import React, { useEffect, useState } from "react";
import secureIcon from "../../../images/secure.png";
import { useDispatch, useSelector } from "react-redux";
import { kycActions } from "../../../redux/actions/kycActions";
import { authActions } from "../../../redux/actions/authActions";
import x from "../../../images/closePopIcon.png";
import enterArrow from "../../../images/enterArrow.png";
import { motion } from "framer-motion"


const PhoneVerificationModal = (props) => {
    const {
        onCancel: handleClose,
        modalVisible,
        setPhoneVerificationModalVisible,
        t,
        phone,
        phoneNumberMsg,
        setPhoneNumberMsg,
        phoneNumberStatus,
        setPhoneNumberStatus,
        verificationCode,
        setVerificationCode,
        phoneCodeMsg,
        setPhoneCodeMsg,
        phoneCodeStatus,
        setPhoneCodeStatus,
        clearVerifyModal,
        phoneNumber,
        setPhoneNumber
    } = props;
    const dispatch = useDispatch();
    const [phonePrefix, setPhonePrefix] = useState('CAD')
    const { token, userInfo } = useSelector(state => state.auth)
    const { phoneUpdated, updateVerificationPhoneMsg, getVerificationCodeMsg, phoneCodeSent, failedReason } = useSelector(state => state.kyc)

    const [counter, setCounter] = useState(30);
    const [startCounting, setStartCounting] = useState(false)




    const useStyles = makeStyles((theme) => ({
        modal: {
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            textAlign: 'center',
        },
        font: {
            fontFamily: "Avenir",
            fontWeight: 500,
            letterSpacing: 0,
            textAlign: 'left'
        },
        inputLabel: {
            color: '#9097A1', marginBottom: 6, textAlign: 'initial', fontFamily: 'Avenir', fontSize: 16, fontWeight: 500, letterSpacing: 0, lineHeight: '22px'
        },
        inputLabelPhone: {
            color: '#9097A1', marginBottom: 5, textAlign: 'initial', fontFamily: 'Avenir', fontSize: 10, fontWeight: 500, letterSpacing: 0, lineHeight: '14px'
        },
        input: {
            borderRadius: 16, backgroundColor: 'white', width: '100%', height: '55px', fontSize: 16, padding: 20, border: "1px solid #EDEDED"
        },
        inputPhone: {
            borderRadius: 12, backgroundColor: 'white', width: '100%', height: '38.5px', fontSize: 10, padding: 14, border: "1px solid #EDEDED"
        },
        button: {

            height: '65px',
            borderRadius: 20,
            color: 'white !important',
            backgroundColor: '#0173F9',
            fontFamily: 'Avenir',
            fontSize: 18,
            fontWeight: 500,
            letterSpacing: 0,
            lineHeight: '25px',
            textTransform: 'none',
            "&:hover": {
                backgroundColor: '#081647',
                // Reset on touch devices, it doesn't add specificity
                "@media (hover: none)": {
                    backgroundColor: '#081647',
                    "&:active": {
                        backgroundColor: '#081647'
                    }
                },
                '&$disabled': {
                    backgroundColor: '#081647',
                },
            },
        },
        buttonPhone: {
            width: '100%',
            height: '48.3px',
            borderRadius: 12,
            color: 'white !important',
            backgroundColor: '#081647',
            fontFamily: 'Avenir',
            fontSize: 12,
            fontWeight: 500,
            letterSpacing: 0,
            lineHeight: '16px',
            textTransform: 'none',
            "&:hover": {
                backgroundColor: '#081647',
                // Reset on touch devices, it doesn't add specificity
                "@media (hover: none)": {
                    backgroundColor: '#081647',
                    "&:active": {
                        backgroundColor: '#081647'
                    }
                },
                '&$disabled': {
                    backgroundColor: '#081647',
                },
            },
        }
    }));
    const classes = useStyles();


    const sendVerificationCode = () => {
        if (userInfo.verificationPhone === '') {
            if (phoneNumber === '') {
                setPhoneNumberStatus(2)
                setPhoneNumberMsg(t('invalidPhone'))
            } else {
                setPhoneNumberStatus(1)
                setPhoneNumberMsg('')
                dispatch(kycActions.updateVerificationPhone(token, { newPhone: phoneNumber }))
            }
        }
        else {
            if (phoneNumber === userInfo.verificationPhone) {
                setPhoneNumberStatus(1)
                setPhoneNumberMsg('')
                dispatch(kycActions.getVerificationCode(token))
                setStartCounting(true)
            } else {
                dispatch(kycActions.updateVerificationPhone(token, { newPhone: phoneNumber }))
            }
        }
    }

    const handleVerificationCodeChange = (e) => {
        setVerificationCode(e.target.value)
        if (e.target.value.length !== 6) {
            if (e.target.value === '') {
                setPhoneCodeMsg(t(''))
            } else {
                setPhoneCodeMsg(t('invalidPhoneCode'))
            }
            setPhoneCodeStatus(2)
        }
        if (e.target.value.length === 6) {
            setPhoneCodeStatus(1)
            setPhoneCodeMsg('')
        }
    }

    const handlePhoneNumberChange = (e) => {
        setPhoneNumber(e.target.value)
    }

    const verifyPhone = () => {
        dispatch(kycActions.validateVerificationCode(token, { code: verificationCode }))
        dispatch(authActions.getUserInfo(token))
        setPhoneVerificationModalVisible(false)
        clearVerifyModal()
    }

    const setPhone = () => {
        if (userInfo.verificationPhone === undefined || userInfo.verificationPhone === null) {
            setPhoneNumber(userInfo.phone)
        } else {
            setPhoneNumber(userInfo.verificationPhone)
            if (userInfo.verificationPhone.length === 10) {
                setPhoneNumberStatus(1)
            }
        }
    }

    useEffect(() => {
        if (startCounting) {
            counter > 0 && setTimeout(() => setCounter(counter - 1), 1000);
        }

        if (counter === 0) {
            setStartCounting(false)
            setCounter(30)
        }
    }, [counter, startCounting]);

    useEffect(() => {
        setPhone()
    }, [])



    useEffect(() => {
        setPhone()
    }, [userInfo])

    useEffect(() => {
        if (phoneNumber === null || phoneNumber === undefined || phoneNumber === '') {
            setPhoneNumberStatus(0)
            setPhoneNumberMsg('')
        } else {
            if (phoneNumber.length !== 10) {
                setPhoneNumberStatus(2)
                setPhoneNumberMsg(t('invalidPhone'))
            } else {
                if (phoneNumber === '') {
                    setPhoneNumberStatus(0)
                    setPhoneNumberMsg('')
                } else {
                    if (phoneCodeSent) {
                        setPhoneNumberStatus(1)
                        setPhoneNumberMsg('')
                    } else {
                        setPhoneNumberStatus(0)
                        setPhoneNumberMsg(t('clickSend'))
                    }
                }
            }
        }
    }, [phoneNumber, modalVisible])

    useEffect(() => {
        console.log('update phone / get code result: ', phoneUpdated, phoneCodeSent, updateVerificationPhoneMsg, getVerificationCodeMsg)
        if ((!phoneUpdated && updateVerificationPhoneMsg !== '') || (!phoneCodeSent && getVerificationCodeMsg !== '')) {
            if (failedReason === 'Send sms validation code too frequently.') {
                setPhoneNumberMsg(t('tooFrequent'))
            } else {
                setPhoneNumberMsg(t('invalidPhone'))
            }
            setPhoneNumberStatus(2)
        } else if ((phoneUpdated && updateVerificationPhoneMsg === 'Verification phone number updated.') && !phoneCodeSent) {
            console.log('sending code')
            setPhoneNumberStatus(1)
            setPhoneNumberMsg('')
            dispatch(kycActions.getVerificationCode(token))
            setStartCounting(true)
        }
    }, [phoneUpdated, updateVerificationPhoneMsg, getVerificationCodeMsg, phoneCodeSent])


    useEffect(() => {
        console.log('verify phone status: ', phoneNumberStatus, phoneCodeStatus, phoneCodeSent)
    }, [phoneNumberStatus, phoneCodeStatus, phoneCodeSent])


    const renderPhoneInput = () => {
        return (
            <InputBase
                id="filled-adornment-phone"
                // type={values[showPassword] ? 'text' : 'password'}
                style={
                    phone ? { borderRadius: 12, backgroundColor: 'white', width: '100%', height: '50px', fontSize: 14, padding: 14, fontWeight: 500, border: "1px solid #EDEDED" }
                        : { borderRadius: 16, backgroundColor: 'white', width: '100%', height: '55px', fontSize: 16, padding: 20, fontWeight: 500, border: "1px solid #EDEDED" }
                }
                placeholder={t("placeHolderPhone")}
                value={phoneNumber}
                onChange={(e) => { handlePhoneNumberChange(e) }}
                startAdornment={
                    <InputAdornment position="start">
                        <Select
                            id="outlined-select"
                            value={phonePrefix}
                            disabled={true}
                            disableUnderline
                            className={classes.font}
                        // SelectProps={{
                        //     native: true,
                        // }}
                        >
                            <MenuItem value={'CAD'}>+1</MenuItem>
                        </Select>
                    </InputAdornment>
                }
                endAdornment={
                    <InputAdornment position="end">
                        <Button
                            className={classes.font}
                            style={{ color: "#0173F9", fontSize: phone ? 14 : 16, textTransform: "none", minWidth: 20 }}
                            onClick={sendVerificationCode}
                            disabled={startCounting}
                        >
                            {startCounting ? counter : t('sendCode')}
                        </Button>
                    </InputAdornment>
                }
            />
        )
    }

    const KycModalBody = (
        <motion.div
            initial={{ opacity: 0, scale: 0 }}
            animate={{ opacity: 1, scale: 1 }}
        >
            <div style={{
                backgroundColor: 'white', borderRadius: 20, width: phone ? " 89vw" : "606px", overflow: 'auto', outline: 'none'
            }}>
                <div style={phone ? { margin: 'min(10%,70px)' } : { marginLeft: 40, marginRight: 40, marginTop: 60, marginBottom: 60 }}>


                    <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                        <Typography className={classes.font} style={{ fontSize: phone ? 16 : 22, fontWeight: 300, lineHeight: phone ? '10px' : '30px', color: "black", }}>
                            {t('phoneVerification')}

                        </Typography>

                        <Button
                            style={{
                                padding: 0,
                                minHeight: 0,
                                minWidth: 0,
                            }}
                            onClick={handleClose}
                        >
                            <img alt='' src={x} style={phone ? { height: 24, width: 24 } : { height: 32, width: 32 }} />
                        </Button>

                    </div>
                    <div style={{ marginTop: phone ? 15 : 15, boxSizing: 'border-box', height: 1, width: '100%', border: '1px solid #DDDDDD' }} />

                    <div style={{ marginTop: phone ? 20 : 20, display: 'flex' }}>
                        {/* <div style={{ marginRight: phone ? 4 : 8, display: 'flex' }}>
                        <img alt="" src={secureIcon} style={{ height: phone ? 16 : 16 }} />
                    </div> */}
                        <div>
                            <Typography
                                className={classes.font}
                                style={{ color: '#858E9D', fontSize: phone ? 12 : 14 }}
                            >
                                {t('phoneVerificationNotice1')}
                            </Typography>
                            <Typography
                                className={classes.font}
                                style={{ marginTop: phone ? 8 : 10, color: '#858E9D', fontSize: phone ? 12 : 14 }}
                            >
                                {t('phoneVerificationNotice2')}
                            </Typography>
                        </div>
                    </div>
                    <div style={{ marginTop: phone ? 20 : 30 }}>
                        <Typography className={phone ? classes.inputLabelPhone : classes.inputLabel}>
                            {t("phoneNumber")}
                        </Typography>
                        {renderPhoneInput()}
                        {
                            phoneNumberStatus === 1 ? null :
                                <Typography style={{ marginTop: phone ? 8 : 8, fontFamily: "Avenir", color: "#DC3055", fontSize: phone ? 12 : 14, fontWeight: 500, lineHeight: phone ? "11px" : '16px', textAlign: "left" }}>
                                    {t(phoneNumberMsg)}
                                </Typography>
                        }
                    </div>

                    <div style={{ marginTop: phone ? 20 : 30 }}>
                        <Typography className={phone ? classes.inputLabelPhone : classes.inputLabel}>
                            {t("mobileVerificationCode")}
                        </Typography>
                        <InputBase
                            placeholder={t("placeHolderVerificationCode")}
                            id="filled-adornment-verification-code"
                            style={
                                phone ? { borderRadius: 12, backgroundColor: 'white', width: '100%', height: '50px', fontSize: 14, padding: 14, fontWeight: 500, border: "1px solid #EDEDED" }
                                    : { borderRadius: 16, backgroundColor: 'white', width: '100%', height: '55px', fontSize: 16, padding: 20, fontWeight: 500, border: "1px solid #EDEDED" }
                            }
                            value={verificationCode}
                            onChange={(e) => { handleVerificationCodeChange(e) }}
                        />
                        {
                            phoneCodeStatus === 0 ? null :
                                <Typography style={{ marginTop: phone ? 4 : 8, fontFamily: "Avenir", color: "#DC3055", fontSize: phone ? 12 : 14, fontWeight: 500, lineHeight: phone ? "11px" : '16px', textAlign: "left" }}>
                                    {t(phoneCodeMsg)}
                                </Typography>
                        }
                    </div>

                    {/* <Button
                    className={phone ? classes.buttonPhone : classes.button}
                    style={{ marginTop: phone ? 40 : 60, opacity: phoneNumberStatus !== 1 || phoneCodeStatus !== 1 || !phoneCodeSent ? 0.5 : 1 }}
                    onClick={verifyPhone}
                    disabled={phoneNumberStatus !== 1 || phoneCodeStatus !== 1 || !phoneCodeSent}
                >
                    {t('verifyPhone')}
                </Button> */}

                    <div style={{ display: 'flex', justifyContent: 'end', }}>
                        <Button
                            className={phone ? classes.buttonPhone : classes.button}
                            style={{ marginTop: phone ? 40 : 60, opacity: phoneNumberStatus !== 1 || phoneCodeStatus !== 1 || !phoneCodeSent ? 0.5 : 1 }}
                            onClick={verifyPhone}
                            disabled={phoneNumberStatus !== 1 || phoneCodeStatus !== 1 || !phoneCodeSent}
                        >
                            <Typography className={classes.font} style={{ fontSize: phone ? 14 : 18, color: 'white', fontWeight: 500, marginLeft: 12, marginRight: 12, lineHeight: phone ? '11px' : '25px' }}>{t('verifyPhone')}</Typography>
                            <img style={{ height: phone ? 16 : 20 }} src={enterArrow}></img>
                        </Button>
                    </div>


                </div>
            </div >
        </motion.div>
    );

    return (
        <Modal
            open={modalVisible}
            onClose={() => {
                handleClose()
            }}
            className={classes.modal}
            aria-labelledby="simple-modal-title"
            aria-describedby="simple-modal-description"
            BackdropProps={{
                className:
                    'modalBackground'
            }}
        >
            {KycModalBody}
        </Modal>
    )
}


export default withTranslation()(PhoneVerificationModal);
