import {
    CHECK_EXIST,
    CHECK_FAILED,
    CHECK_SUCCEED,

    LOGIN,
    LOGIN_SUCCEED,
    LOGIN_FAILED,
    CLEAR_LOGIN_STATE,
    LOGOUT,

    SIGNUP,
    SIGNUP_SUCCEED,
    SIGNUP_FAILED,
    CLEAR_SIGNUP_STATE,

    GET_USER_INFO_SUCCEED,
    GET_USER_INFO_FAILED,

    UPDATE_USER_INFO,
    UPDATE_USER_INFO_SUCCEED,
    UPDATE_USER_INFO_FAILED,
    CLEAR_UPDATE_INFO_STATE,

    CHECK_USER,
    CHECK_USER_FAILED,
    CHECK_USER_SUCCEED,
    CLEAR_CHECK_USER_STATE,

    RESET_PASSWORD,
    RESET_PASSWORD_FAILED,
    RESET_PASSWORD_SUCCEED,
    CLEAR_RESET_PASSWORD_STATE,

    UPDATE_LOGIN_PASSWORD,
    UPDATE_LOGIN_PASSWORD_SUCCEED,
    UPDATE_LOGIN_PASSWORD_FAILED,
    CLEAR_UPDATE_LOGIN_PASSWORD,

    SEND_EMAIL_CODE,
    SEND_EMAIL_CODE_FAILED,
    SEND_EMAIL_CODE_SUCCEED,


    SEND_EMAIL_SUBSCRIBE,
    SEND_EMAIL_SUBSCRIBE_SUCCEED,
    SEND_EMAIL_SUBSCRIBE_FAILED,

    GET_LATEST_ARTICLE,
    GET_LATEST_ARTICLE_FAILED,
    GET_LATEST_ARTICLE_SUCCEED,

    GET_OAUTH_TOKEN,
    GET_OAUTH_TOKEN_FAILED,
    GET_OAUTH_TOKEN_SUCCEED,
} from '../constants';

export function auth(state = {
    loggedIn: false,
    loggingIn: false,
    loading: false,
    token: '',
    userInfo: { phoneValid: false, allowUsdt: true },
    emailCodeSent: false,
    emailCodeMsg: '',
    articleList: [{
        title: '',
        content: '',
        html_url: '',
        updated_at: ''
    }],
    getArticleMsg: '',
    zendeskToken: '',
    zendeskTokenMsg: '',
    updateUserInfoMsg: ''
}, action) {
    switch (action.type) {
        case CHECK_EXIST:
            return {
                ...state,
                token: action.token,
                checkLoading: true
            }
        case CHECK_SUCCEED:
            return {
                ...state,
                loggedIn: true,
                userInfo: action.data,
                checkLoading: false
            }
        case CHECK_FAILED:
            return {
                ...state,
                loggedIn: false,
                userInfo: {},
                checkLoading: false
            }

        case SIGNUP:
            return {
                ...state,
                signingUp: true,
                registerSuccess: false,
                registerFailed: false
            }
        case SIGNUP_SUCCEED:
            return {
                ...state,
                registerSuccess: true,
                signingUp: false
            }
        case SIGNUP_FAILED:
            return {
                ...state,
                registerFailed: true,
                signingUp: false,
                signUpMsg: action.message
            }
        case CLEAR_SIGNUP_STATE:
            return {
                ...state,
                registerFailed: false,
                registerSuccess: false
            }

        case LOGIN:
            return {
                ...state,
                loggingIn: true,
                loginMsg: '',
                loginFailed: false,
                token: ''
            };
        case LOGIN_SUCCEED:
            return {
                ...state,
                loggedIn: true,
                loggingIn: false,
                loginSuccess: true,
                token: action.data.data,
            };
        case LOGIN_FAILED:
            return {
                ...state,
                loggedIn: false,
                loggingIn: false,
                loginFailed: true,
                loginMsg: action.message,
                token: ''
            };
        case CLEAR_LOGIN_STATE:
            return {
                ...state,
                loginSuccess: false,
                loginFailed: false
            }
        case LOGOUT:
            return {
                ...state,
                loggedIn: false,
                token: '',
                userInfo: {}
            }

        case GET_USER_INFO_SUCCEED:
            return {
                ...state,
                userInfo: action.data
            }
        case GET_USER_INFO_FAILED:
            if (action.message.message === "Access Denied") {
                return {
                    ...state,
                    loggedIn: false,
                    userInfo: {}
                }
            } else {
                return {
                    ...state,
                }
            }

        case UPDATE_USER_INFO:
            return {
                ...state,
                updateInfoSuccess: false,
                updateInfoFailed: false
            }
        case UPDATE_USER_INFO_SUCCEED:
            return {
                ...state,
                updateInfoSuccess: true
            }
        case UPDATE_USER_INFO_FAILED:
            return {
                ...state,
                updateInfoFailed: true,
                updateUserInfoMsg: action.message
            }
        case CLEAR_UPDATE_INFO_STATE:
            return {
                ...state,
                updateInfoSuccess: false,
                updateInfoFailed: false,
                updateUserInfoMsg: ''
            }


        case CHECK_USER:
            return {
                ...state,
                checkingUser: true,
                checkUserSuccess: false,
                checkUserFailed: false,
                checkUserMsg: ''
            }
        case CHECK_USER_SUCCEED:
            return {
                ...state,
                checkingUser: false,
                checkUserSuccess: true,
            }
        case CHECK_USER_FAILED:
            return {
                ...state,
                checkingUser: false,
                checkUserFailed: true,
                checkUserMsg: action.message
            }
        case CLEAR_CHECK_USER_STATE:
            return {
                ...state,
                checkUserSuccess: false,
                checkUserFailed: false,
            }


        case RESET_PASSWORD:
            return {
                ...state,
                resettingPassword: true,
                resetPasswordSuccess: false,
                resetPasswordFailed: false,
                resetPasswordMsg: ''
            }
        case RESET_PASSWORD_SUCCEED:
            return {
                ...state,
                resettingPassword: false,
                resetPasswordSuccess: true
            }
        case RESET_PASSWORD_FAILED:
            return {
                ...state,
                resettingPassword: false,
                resetPasswordFailed: true,
                resetPasswordMsg: action.message
            }
        case CLEAR_RESET_PASSWORD_STATE:
            return {
                ...state,
                resetPasswordFailed: false,
                resetPasswordSuccess: false
            }

        case UPDATE_LOGIN_PASSWORD:
            return {
                ...state,
                updatingPassword: true,
                updatingPasswordSuccess: false,
                updatingPasswordFailed: false,
                updatingPasswordMsg: ''
            }
        case UPDATE_LOGIN_PASSWORD_SUCCEED:
            return {
                ...state,
                updatingPassword: false,
                updatingPasswordSuccess: true
            }
        case UPDATE_LOGIN_PASSWORD_FAILED:
            return {
                ...state,
                updatingPassword: false,
                updatingPasswordFailed: true,
                updatingPasswordMsg: action.message
            }
        case CLEAR_UPDATE_LOGIN_PASSWORD:
            return {
                ...state,
                updatingPasswordFailed: false,
                updatingPasswordSuccess: false
            }
        case SEND_EMAIL_CODE:
            return {
                ...state,
                emailCodeSent: false,
                emailCodeMsg: ''
            }
        case SEND_EMAIL_CODE_SUCCEED:
            return {
                ...state,
                emailCodeSent: true
            }

        case SEND_EMAIL_CODE_FAILED:
            return {
                ...state,
                emailCodeMsg: action.message
            }

        case SEND_EMAIL_SUBSCRIBE:
            return {
                ...state,
                emailSubscribe: true,
                emailSubscribeSuccess: false,
                emailSubscribeFailed: false,
                emailSubscribeMsg: ''
            }

        case SEND_EMAIL_SUBSCRIBE_SUCCEED:
            return {
                ...state,
                emailSubscribe: false,
                emailSubscribeSuccess: true
            }

        case SEND_EMAIL_SUBSCRIBE_FAILED:
            return {
                ...state,
                emailSubscribe: false,
                emailSubscribeSuccess: true,
                emailSubscribeMsg: action.message
            }

        case GET_LATEST_ARTICLE:
            return {
                ...state,
            }

        case GET_LATEST_ARTICLE_SUCCEED:
            return {
                ...state,
                articleList: action.data.articles
            }

        case GET_LATEST_ARTICLE_FAILED:
            return {
                ...state,
                getArticleMsg: action
            }
        case GET_OAUTH_TOKEN:
            return {
                ...state,
            }

        case GET_OAUTH_TOKEN_SUCCEED:
            return {
                ...state,
                zendeskToken: action.data.access_token
            }

        case GET_OAUTH_TOKEN_FAILED:
            return {
                ...state,
                zendeskTokenMsg: action.message.error_description
            }
        default:
            return state;
    }
};
