import {withTranslation} from "react-i18next";
import {
    Avatar,
    Button,
    InputAdornment,
    InputBase,
    makeStyles,
    MenuItem,
    Modal,
    Select,
    Typography
} from "@material-ui/core";
import React, {useEffect, useState} from "react";
import close from "../../../images/nft/close.png";
import nft1 from '../../../images/nft/nft1.png'
import nft2 from '../../../images/nft/nft2.png'
import {nftActions} from "../../../redux/actions/nftActions";
import {useDispatch, useSelector} from "react-redux";
import ClaimNFTResultModal from "./ClaimNFTResultModal";
const ClaimNFTModal = (props) => {
    const {
        onCancel: handleClose,
        modalVisible,
        t,
        phone,
        width,
        nft,
        setClaimNftVisible,
        refresh,
        scrollToVouchers
    } = props;
    const dispatch = useDispatch();
    const { token } = useSelector(state => state.auth)
    const { claimNft, claimNftLoading, claimNftFailed, claimNftSuccess, claimNftMsg } = useSelector(state => state.nft)
    const [resultVisible, setResultVisible] = useState(false);
    const [resultSuccess, setResultSuccess] = useState(false)
    const useStyles = makeStyles((theme) => ({
        modal: {
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            overflow:'scroll',
        },
        font: {
            color: '#1C2237',
            fontFamily: 'Avenir',
            fontSize: phone?14:16,
            letterSpacing: -0.31
        },
    }));
    const classes = useStyles();

    const onClaim = () => {
        dispatch(nftActions.claimNft(nft === 'nft1'? 'Regular' :nft === 'nft2'? 'Rare' :null,token))
    }

    useEffect(() => {
        if (claimNftLoading === false) {
            if (claimNftFailed) {
                handleClose()
                setResultSuccess(false)
                setResultVisible(true)
                dispatch(nftActions.clearClaimNft())
            } else if (claimNftSuccess) {
                refresh();
                handleClose()
                setResultSuccess(true);
                setResultVisible(true);
                dispatch(nftActions.clearClaimNft())
            }
        }
        return () => {
        }
    }, [claimNftLoading])

    const ClaimNFTModalBody = (
        <div style={{position:"relative", width: phone? 350 : 400, borderRadius: 24, backgroundColor:"white", outline:0}}>
            <img src={close} style={{position:"absolute", width:phone?40:33, height:phone?40:33, right:phone?0:-33, top:phone?-50:-33, cursor: "pointer"}} onClick={()=>{handleClose()}}/>
            <div style={{margin:30}}>
                <div style={{width:'100%',display:"flex", alignItems: 'center', justifyContent: 'center' }}>
                    <img src={nft === "nft1" ?nft1:nft2} style={{width:150, height:150}}/>
                </div>
                <Typography className={classes.font} style={{display:"flex", alignItems: nft === 'nft1'? "baseline":"center", fontSize:phone?20:24, fontWeight:900, marginTop:20}}>
                    {t('nftVirtualHead')}
                    {
                        nft === "nft1" ?
                            <Typography style={{marginLeft:8, fontSize:12, fontWeight:900}}>
                                {t('nftRegular')}
                            </Typography>
                            :
                            <div style={{display:"flex", marginLeft:8,height:21, width:44, borderRadius:6, background: "linear-gradient(90deg, #FC4362 0%, #D00ABD 100%)", alignItems:"center"}}>
                                <Typography style={{marginLeft:8, fontSize:12, fontWeight:900, color:"white"}}>
                                    {t('nftRare')}
                                </Typography>
                            </div>
                    }
                </Typography>
                <Typography className={classes.font}>
                    {nft === "nft1" ? t('claimNftSubtitle') : t('claimRareNftSubtitle')}
                </Typography>
                <Button
                    className={classes.font} style={{height:55, width:'100%', borderRadius:20, backgroundColor:"#003FFF", marginTop:20, color:'white', textTransform:"none", fontSize:phone?18:20}}
                    onClick={onClaim}
                >
                    {t('claim')}
                </Button>
            </div>
        </div>
    );


    return (
        <>
            <Modal
                open={modalVisible}
                onClose={handleClose}
                className={classes.modal}
                aria-labelledby="simple-modal-title"
                aria-describedby="simple-modal-description"
                BackdropProps={{
                    className:
                        'modalBackground'
                }}
                disableAutoFocus={true}
            >
                {ClaimNFTModalBody}
            </Modal>

            <ClaimNFTResultModal
                onCancel={() => {
                    setResultVisible(false);
                }}
                startAnimation={true}
                modalVisible={resultVisible}
                t
                phone={phone}
                width={width}
                success={resultSuccess}
                setClaimNftVisible={setClaimNftVisible}
                scrollToVouchers={scrollToVouchers}
            />
        </>
    )
}


export default withTranslation()(ClaimNFTModal);
