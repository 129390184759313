import {withTranslation} from "react-i18next";
import {
    Avatar,
    Button,
    InputAdornment,
    InputBase,
    makeStyles,
    MenuItem,
    Modal,
    Select,
    Typography
} from "@material-ui/core";
import React, {useEffect, useState} from "react";
import {motion} from "framer-motion"
import close from "../../../images/nft/close.png";
import successIcon from "../../../images/nft/success.png";
import failedIcon from "../../../images/nft/failed.png";
import {useHistory} from "react-router-dom";
import i18n from "../../../i18n";
const VoucherTransferResultModal = (props) => {
    const {
        onCancel: handleClose,
        modalVisible,
        t,
        phone,
        width,
        success,
        resultInfo,
        setVoucherTransferVisible
    } = props;
    const history = useHistory();
    const useStyles = makeStyles((theme) => ({
        modal: {
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            overflow:'scroll',
        },
        font: {
            color: '#1C2237',
            fontFamily: 'Avenir',
            fontSize: phone?14:16,
            letterSpacing: -0.31
        },
    }));
    const classes = useStyles();
    const onFailedClose = () =>{
        setVoucherTransferVisible(true)
        handleClose()
    }

    const VoucherTransferResultBody = (
        <div style={{position:"relative", height: phone?null :420, width: phone? 350 : 400, borderRadius: 32, backgroundColor:"white", outline:0}}>
            <img src={close} style={{position:"absolute", width:phone?40:33, height:phone?40:33, right:phone?0:-20, top:phone?-50:-30, cursor: "pointer"}} onClick={handleClose}/>
            <div style={{margin:phone?30:40}}>
                <div style={{height:phone?265:285}}>
                    <div style={{width:'100%',display:"flex", alignItems: 'center', justifyContent: 'center' }}>
                        <img src={success ? successIcon : failedIcon} style={{width:271, height:117}}/>
                    </div>
                    <Typography className={classes.font} style={{fontSize:phone?20:24, fontWeight:900, marginTop:30}}>
                        {success ? t('voucherTransferSucceed') : t('voucherTransferFailed')}
                    </Typography>
                    {
                        i18n.language === 'en' ?
                            <Typography className={classes.font}>
                                {success ? t('voucherTransferSucceedMsg1') + resultInfo.amount: resultInfo.msg}
                                {success ? t('voucherTransferSucceedMsg2') : null}
                                {success ? <Typography className={classes.font} style={{display:"inline", color:"#003FFF"}}> {resultInfo.email} </Typography> : null}
                                {success ? t('voucherTransferSucceedMsg3') : null}
                            </Typography>
                            :
                            <Typography className={classes.font}>
                                {success ? t('voucherTransferSucceedMsg1') : resultInfo.msg}
                                {success ? <Typography className={classes.font} style={{display:"inline", color:"#003FFF"}}> {resultInfo.email} </Typography> : null}
                                {success ? t('voucherTransferSucceedMsg2')+ resultInfo.amount : null} {success ? t('voucherTransferSucceedMsg3') : null}
                            </Typography>
                    }
                </div>

                <Button
                    className={classes.font} style={{height:55, width:'100%', borderRadius:20, backgroundColor:success?"#003FFF":"#DC3055", color:'white', textTransform:"none"}}
                    onClick={()=>{
                        if (success){
                            handleClose();
                        }else{
                            handleClose();
                            onFailedClose();
                        }
                    }}
                >
                    {success ? t('voucherReceiveGotIt') : t('voucherTransferTryAgain')}
                </Button>
            </div>
        </div>
    );


    return (
        <Modal
            open={modalVisible}
            onClose={success ? handleClose : onFailedClose}
            className={classes.modal}
            aria-labelledby="simple-modal-title"
            aria-describedby="simple-modal-description"
            BackdropProps={{
                className:
                    'modalBackground'
            }}
            disableAutoFocus={true}
        >
            {VoucherTransferResultBody}
        </Modal>
    )
}


export default withTranslation()(VoucherTransferResultModal);
