import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import './i18n';
import App from './App';
import * as serviceWorker from './serviceWorker';
import { Provider } from 'react-redux'
import { store } from './redux/store'
import GA4React, { useGA4React } from "ga-4-react";
import * as Url from './config/Url'
import { BrowserRouter } from "react-router-dom";

const ga4react = new GA4React(Url.GA_TRACKING_ID);


// ReactDOM.render(
//     <Provider store={store}>
//         <App />
//     </Provider>,
//     document.getElementById('root')
// );

(async _ => {

    await ga4react.initialize()
        .then(res => console.log("Analytics Success."))
        .catch(err => console.log("Analytics Failure."))
        .finally(() => {
            ReactDOM.render(
                <Provider store={store}>
                    <BrowserRouter>
                        <App />
                    </BrowserRouter>
                </Provider>,
                document.getElementById('root')
            );
        });
})();

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
