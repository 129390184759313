import React, { useEffect, useState } from 'react'
import { withTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { authActions } from "../../redux/actions/authActions";
import { kycActions } from "../../redux/actions/kycActions";
import { Button, makeStyles, Typography } from '@material-ui/core';
import twoFAIcon from "../../images/setting/2fa.png";
import emailIcon from "../../images/setting/email.png";
import phoneIcon from "../../images/setting/phone.png";
// import checkIcon from "../../images/setting/check.png";
// import xIcon from "../../images/setting/x.png";
import phoneX from "../../images/setting/phoneX.png"
import securityIcon from "../../images/setting/security.png"
import lockIcon from "../../images/setting/lock.png"
import ChangeLoginPasswordModal from "./components/ChangeLoginPassword";
import PhoneVerificationModal from "./components/PhoneVerification";
import ResultModal from "../ResultModal/ResultModal";
import SuccessModal from "../ResultModal/ResultModal";


import FailedStatusIcon from "../../images/FailedStatusIcon.png";

import PassedStatusIcon from "../../images/PassedStatusIcon.png";
import { Helmet } from "react-helmet";


function Setting({ t, width }) {
    const dispatch = useDispatch();
    const { token, userInfo } = useSelector(state => state.auth)
    const { phoneValidated, validatePhoneMsg } = useSelector(state => state.kyc)
    const [phone, setPhone] = useState(width <= 745);
    const [changePasswordModalVisible, setChangePasswordModalVisible] = useState(false);
    const [phoneVerificationModalVisible, setPhoneVerificationModalVisible] = useState(false);
    const [resultModalVisible, setResultModalVisible] = useState(false)


    const [phoneNumber, setPhoneNumber] = useState('')
    const [phoneNumberStatus, setPhoneNumberStatus] = useState(0)
    const [phoneNumberMsg, setPhoneNumberMsg] = useState('')

    const [phoneCodeStatus, setPhoneCodeStatus] = useState(0)
    const [phoneCodeMsg, setPhoneCodeMsg] = useState('')

    const [verificationCode, setVerificationCode] = useState('')

    const useStyles = makeStyles({
        font: {
            color: "#1C2237",
            fontFamily: "Avenir",
            fontWeight: 500
        },
        button: {
            height: phone ? 20 : 25,
            width: phone ? 52 : 65,
            borderRadius: 6,
            backgroundColor: '#0173F9',
            minWidth: 52

        },
        buttonLabel: {
            fontSize: phone ? 12 : 14,
            fontWeight: 500,
            color: 'white',
            textTransform: 'none'
        },
        settingContainer: {
            marginTop: 30,
            borderRadius: phone ? 8 : 20,
            backgroundColor: "#F9F9F9",
            padding: phone ? "16px 14px" : "30px 22px"
        }
    });
    const classes = useStyles();

    const clearVerifyModal = () => {
        setPhoneNumberStatus(0)
        setPhoneNumberMsg('')
        setPhoneCodeStatus(0)
        setPhoneCodeMsg('')
        setVerificationCode('')
        setPhoneNumber(userInfo.verificationPhone)
    }

    useEffect(() => {
        setPhone(width <= 745)
        return () => {
        }
    }, [width]);

    useEffect(() => {
        if (validatePhoneMsg !== '') {
            setResultModalVisible(true)
            dispatch(authActions.getUserInfo(token))
        }
    }, [phoneValidated, validatePhoneMsg])

    useEffect(() => {
        dispatch(authActions.getUserInfo(token))
        return () => {
        }
    }, []);

    const render2FA = () => {
        return (
            <div className={classes.settingContainer} style={{ marginTop: phone ? 0 : 30 }}>

                <div style={{ display: "flex", justifyContent: "space-between", alignItems: 'center' }}>
                    <Typography align={'left'} className={classes.font} style={{ color: '#858E9D', fontSize: phone ? 12 : 22, fontWeight: 500 }}>{t('2FA')}</Typography>
                    <img src={twoFAIcon} style={{ width: phone ? 28 : 46, marginRight: phone ? 6 : 12 }} />
                </div>

                <div style={{ marginTop: phone ? 11 : 6, boxSizing: 'border-box', height: 1, border: '1px solid #DDDDDD' }} />

                {
                    phone ?
                        <div style={{ marginTop: 30, display: "flex", alignItems: 'center', justifyContent: "space-between" }}>
                            <div >
                                <Typography align={'left'} className={classes.font} style={{ color: '#858E9D', fontSize: 12, fontWeight: 500 }}>{t('emailVerification')}</Typography>
                                <Typography align={'left'} className={classes.font} style={{ fontSize: 14, fontWeight: 500 }}>{userInfo.username}</Typography>
                            </div>
                            <div style={{ display: "flex", alignItems: 'center' }}>
                                <img src={PassedStatusIcon} style={{ width: 18, marginLeft: 8 }} />
                            </div>
                        </div>
                        : <>
                            <div style={{ marginTop: 24, display: "flex", alignItems: 'center' }}>
                                <img src={emailIcon} style={{ width: 19, height: 14.25, marginLeft: 1, marginRight: 12 }} />
                                <Typography className={classes.font} style={{ color: '#858E9D', fontSize: 18, fontWeight: 500 }}>{t('emailVerification')}</Typography>
                            </div>
                            <div style={{ display: "flex", alignItems: 'center' }}>
                                <Typography align={'left'} className={classes.font} style={{ marginLeft: 32, fontSize: 16, fontWeight: 500 }}>{userInfo.username}</Typography>
                                <img src={PassedStatusIcon} style={{ width: 18, height: 18, marginLeft: 8 }} />
                            </div>
                        </>
                }


                <div style={{ marginTop: phone ? 30 : 24, display: "flex", justifyContent: "space-between", alignItems: "center" }}>
                    <div>
                        <div style={{ display: "flex", alignItems: 'center' }}>
                            {
                                phone ? null : <img src={phoneIcon} style={{ width: 14, marginLeft: 4, marginRight: 14 }} />
                            }
                            <Typography className={classes.font} style={{ color: '#858E9D', fontSize: phone ? 12 : 18, fontWeight: 500 }}>{t('phoneVerification')}</Typography>
                        </div>
                        <div style={{ display: "flex", alignItems: 'center' }}>
                            <Typography align={'left'} className={classes.font} style={{ marginLeft: phone ? 0 : 32, fontSize: phone ? 14 : 16, fontWeight: 500 }}>{userInfo.verificationPhone}</Typography>
                            {
                                phone ? null : userInfo.phoneValid ? <img src={PassedStatusIcon} style={{ width: 18, height: 18, marginLeft: 8 }} />
                                    : <img src={FailedStatusIcon} style={{ width: 18, marginLeft: 8 }} />
                            }
                        </div>
                    </div>
                    {
                        userInfo.phoneValid ? (phone ? <img src={PassedStatusIcon} style={{ width: 18, marginLeft: 8 }} /> : null) :
                            <div>
                                <Button className={classes.button} style={{ backgroundColor: phone ? '#DC3055' : '#0173F9', width: phone ? 65 : null }} onClick={() => { setPhoneVerificationModalVisible(true) }}>
                                    <div style={{
                                        display: 'flex',
                                        flexDirection: 'row',
                                        alignItems: 'center',
                                        justifyContent: phone ? 'space-around' : 'center',
                                        width: '100%'
                                    }}>
                                        {phone ? <img src={phoneX} style={{ width: 9 }} /> : null}
                                        <Typography className={`${classes.font} ${classes.buttonLabel}`}>{t('verify')}</Typography>
                                    </div>
                                </Button>
                            </div>
                    }
                </div>

            </div>
        )
    }

    const renderSecurity = () => {
        return (
            <div className={classes.settingContainer}>

                <div style={{ display: "flex", justifyContent: "space-between", alignItems: 'center' }}>
                    <Typography align={'left'} className={classes.font} style={{ color: '#858E9D', fontSize: phone ? 12 : 22, fontWeight: 500 }}>{t('securities')}</Typography>
                    <img src={securityIcon} style={{ width: phone ? 28 : 46, marginRight: phone ? 6 : 12 }} />
                </div>

                <div style={{ marginTop: phone ? 11 : 6, boxSizing: 'border-box', height: 1, border: '1px solid #DDDDDD' }} />

                <div style={{ marginTop: phone ? 30 : 24, display: "flex", justifyContent: "space-between", alignItems: "center" }}>
                    <div style={{ display: "flex", alignItems: 'center' }}>
                        {
                            phone ? null : <img src={lockIcon} style={{ width: 14, marginLeft: 4, marginRight: 14 }} />
                        }
                        <Typography className={classes.font} style={{ color: '#858E9D', fontSize: phone ? 14 : 18, fontWeight: 500 }}>{t('loginPassword')}</Typography>
                    </div>
                    <Button className={classes.button} style={{ backgroundColor: '#0173F9' }} onClick={() => { setChangePasswordModalVisible(true) }}>
                        <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'center' }}>
                            <Typography className={`${classes.font} ${classes.buttonLabel}`}>{t('modify')}</Typography>
                        </div>
                    </Button>
                </div>

            </div>
        )
    }


    return (
  
        <div style={{ width: '100%', display: 'flex', alignItems: 'center', flexDirection: 'column' }}>
              <Helmet>
              <title>{t("titleSetting")}</title>

                <meta name="keywords" content="Toronto, OTC, bitcoin, exchange, cryptocurrency, 多伦多, 场外交易平台, 比特币, 以太坊, 加元, 换汇，加密货币, 人民币,多倫多, 場外交易平台, 比特幣, 以太坊, 加元, 換匯, 人民幣, Yesbit, Fiat, CAD, BTC, ETH, USD,security setting" />
            </Helmet>
            <div style={{ width: '91%', alignItems: 'center', textAlign: 'center', maxWidth: 543, minWidth: 317, margin: "24px 0px 100px" }}>


                <Typography className={classes.font} style={phone ? { marginTop: 24,marginBottom:24, color: "#1C2237", fontFamily: "Avenir", fontSize: 16, fontWeight: 500, letterSpacing: 0, lineHeight: '22px', textAlign: 'initial' } : { fontWeight: 500, fontSize: 24, textAlign: 'initial',fontFamily: "Avenir", }}>{t('setting')}</Typography>

                {render2FA()}
                {renderSecurity()}
            </div>

            <PhoneVerificationModal
                onCancel={() => {
                    clearVerifyModal()
                    setPhoneVerificationModalVisible(false);
                    dispatch(authActions.getUserInfo(token))
                }}
                modalVisible={phoneVerificationModalVisible}

                setPhoneVerificationModalVisible={setPhoneVerificationModalVisible}
                t
                phone={phone}
                width={width}
                phoneNumberStatus={phoneNumberStatus}
                setPhoneNumberStatus={setPhoneNumberStatus}
                phoneNumberMsg={phoneNumberMsg}
                setPhoneNumberMsg={setPhoneNumberMsg}
                verificationCode={verificationCode}
                setVerificationCode={setVerificationCode}
                phoneNumber={phoneNumber}
                setPhoneNumber={setPhoneNumber}

                phoneCodeStatus={phoneCodeStatus}
                setPhoneCodeStatus={setPhoneCodeStatus}
                phoneCodeMsg={phoneCodeMsg}
                setPhoneCodeMsg={setPhoneCodeMsg}
                clearVerifyModal={clearVerifyModal}
            />

            <ChangeLoginPasswordModal
                onCancel={() => {
                    setChangePasswordModalVisible(false);
                }}
                modalVisible={changePasswordModalVisible}
                t
                phone={phone}
                width={width}
            />
            <ResultModal
                onCancel={() => {
                    setResultModalVisible(false)
                    dispatch(kycActions.clearPhoneVerify())
                }}
                modalVisible={resultModalVisible}

                t
                width={width}
                phone={phone}
                title={phoneValidated ? t('phoneVerifiedTitle') : t('phoneNotVerified')}
                content={phoneValidated ? t('phoneVerifiedContent') : validatePhoneMsg === 'Failed to validate sms code.' ? t('wrongCode') : t('phoneNotVerifiedContent')}
                resultSuccess={phoneValidated}
            />


        </div>
    )
}
export default withTranslation()(Setting)
