import { kycService } from '../services/kycServices';
import {
    INIT_KYC_LINK, INIT_KYC_LINK_FAILED, INIT_KYC_LINK_SUCCEED, GET_KYC_DETAILS, GET_KYC_DETAILS_FAILED, GET_KYC_DETAILS_SUCCEED,
    SYNC_KYC_STATUS, SYNC_KYC_STATUS_FAILED, SYNC_KYC_STATUS_SUCCEED, UPDATE_KYC_STATUS, UPDATE_KYC_STATUS_FAILED, UPDATE_KYC_STATUS_SUCCEED,
    UPDATE_VERIFICATION_PHONE, UPDATE_VERIFICATION_PHONE_SUCCEED, UPDATE_VERIFICATION_PHONE_FAILED,
    VALIDATE_VERIFICATION_CODE, VALIDATE_VERIFICATION_CODE_FAILED, VALIDATE_VERIFICATION_CODE_SUCCEED,
    GET_VERIFICATION_CODE, GET_VERIFICATION_CODE_FAILED, GET_VERIFICATION_CODE_SUCCEED, CLEAR_PHONE_VERIFY,
    INIT_ADDRESS_VERIFICATION, INIT_ADDRESS_VERIFICATION_SUCCEED, INIT_ADDRESS_VERIFICATION_FAILED,
    GET_DOCUMENT_DETAILS, GET_DOCUMENT_DETAILS_FAILED, GET_DOCUMENT_DETAILS_SUCCEED,
    SYNC_ADDRESS_STATUS, SYNC_ADDRESS_STATUS_FAILED, SYNC_ADDRESS_STATUS_SUCCEED
} from '../constants';


export const kycActions = {
    initKycLink,
    getKycDetails,
    syncKycStatus,
    updateKycStatus,
    updateVerificationPhone,
    getVerificationCode,
    validateVerificationCode,
    clearPhoneVerify,
    initAddressVerificationLink,
    getDocumentDetails,
    syncAddressStatus
}

function clearPhoneVerify() {
    return dispatch => {
        dispatch(request());
    };
    function request() { return { type: CLEAR_PHONE_VERIFY } }
}

function initKycLink(token) {
    return dispatch => {
        dispatch(request());
        kycService.initKycLink(token)
            .then(
                res => {
                    dispatch(success(res.data));
                },
                error => {
                    dispatch(failure(error.data));
                }
            );
    };

    function request() { return { type: INIT_KYC_LINK } }
    function success(data) { return { type: INIT_KYC_LINK_SUCCEED, data } }
    function failure(message) { return { type: INIT_KYC_LINK_FAILED, message } }
}

function getKycDetails(token) {
    return dispatch => {
        dispatch(request());
        kycService.getKycDetails(token)
            .then(
                res => {
                    dispatch(success(res.data));
                },
                error => {
                    console.log('get kyc Details failed: ', error)
                    dispatch(failure(error.data));
                }
            );
    };

    function request() { return { type: GET_KYC_DETAILS } }
    function success(data) { return { type: GET_KYC_DETAILS_SUCCEED, data } }
    function failure(message) { return { type: GET_KYC_DETAILS_FAILED, message } }
}

function syncKycStatus(token) {
    return dispatch => {
        dispatch(request());
        kycService.syncKycStatus(token)
            .then(
                res => {
                    dispatch(success(res.data));
                },
                error => {
                    dispatch(failure(error.data));
                }
            );
    };

    function request() { return { type: SYNC_KYC_STATUS } }
    function success(data) { return { type: SYNC_KYC_STATUS_SUCCEED, data } }
    function failure(message) { return { type: SYNC_KYC_STATUS_FAILED, message } }
}

function updateKycStatus(token, payload) {
    return dispatch => {
        dispatch(request());
        kycService.updateKycStatus(token, payload)
            .then(
                res => {
                    dispatch(success(res.data));
                },
                error => {
                    dispatch(failure(error.data));
                }
            );
    };

    function request() { return { type: UPDATE_KYC_STATUS } }
    function success(data) { return { type: UPDATE_KYC_STATUS_SUCCEED, data } }
    function failure(message) { return { type: UPDATE_KYC_STATUS_FAILED, message } }
}

function updateVerificationPhone(token, payload) {
    return dispatch => {
        dispatch(request());
        kycService.updateVerificationPhone(token, payload)
            .then(
                res => {
                    dispatch(success(res.message));
                },
                error => {
                    console.log('update phone error: ', error)
                    dispatch(failure(error.message));
                }
            );
    };

    function request() { return { type: UPDATE_VERIFICATION_PHONE } }
    function success(data) { return { type: UPDATE_VERIFICATION_PHONE_SUCCEED, data } }
    function failure(message) { return { type: UPDATE_VERIFICATION_PHONE_FAILED, message } }
}

function getVerificationCode(token) {
    console.log('getting code')
    return dispatch => {
        dispatch(request());
        kycService.getVerificationCode(token)
            .then(
                res => {
                    console.log('getting phone code action res: ', res)
                    dispatch(success(res));
                },
                error => {
                    console.log('get phone code error: ', error)
                    dispatch(failure(error));
                }
            );
    };

    function request() { return { type: GET_VERIFICATION_CODE } }
    function success(data) { return { type: GET_VERIFICATION_CODE_SUCCEED, data } }
    function failure(message) { return { type: GET_VERIFICATION_CODE_FAILED, message } }
}

function validateVerificationCode(token, payload) {
    return dispatch => {
        dispatch(request());
        kycService.validateVerificationCode(token, payload)
            .then(
                res => {
                    dispatch(success(res));
                },
                error => {
                    console.log('validate code error: ', error)
                    dispatch(failure(error.message));
                }
            );
    };

    function request() { return { type: VALIDATE_VERIFICATION_CODE } }
    function success(data) { return { type: VALIDATE_VERIFICATION_CODE_SUCCEED, data } }
    function failure(message) { return { type: VALIDATE_VERIFICATION_CODE_FAILED, message } }
}

function initAddressVerificationLink(token, payload) {
    return dispatch => {
        dispatch(request());
        kycService.initAddressVerificationLink(token, payload)
            .then(
                res => {
                    dispatch(success(res));
                },
                error => {
                    console.log('init address verfication failed: ', error)
                    dispatch(failure(error.message));
                }
            );
    };

    function request() { return { type: INIT_ADDRESS_VERIFICATION } }
    function success(data) { return { type: INIT_ADDRESS_VERIFICATION_SUCCEED, data } }
    function failure(message) { return { type: INIT_ADDRESS_VERIFICATION_FAILED, message } }
}

function getDocumentDetails(token, payload) {
    return dispatch => {
        dispatch(request());
        kycService.getDocumentDetails(token, payload)
            .then(
                res => {
                    dispatch(success(res.data));
                },
                error => {
                    console.log('get document details failed: ', error)
                    dispatch(failure(error.message));
                }
            );
    };

    function request() { return { type: GET_DOCUMENT_DETAILS } }
    function success(data) { return { type: GET_DOCUMENT_DETAILS_SUCCEED, data } }
    function failure(message) { return { type: GET_DOCUMENT_DETAILS_FAILED, message } }
}

function syncAddressStatus(token, payload) {
    return dispatch => {
        dispatch(request());
        kycService.syncAddressStatus(token, payload)
            .then(
                res => {
                    dispatch(success(res));
                },
                error => {
                    console.log('fetch address status failed: ', error)
                    dispatch(failure(error.message));
                }
            );
    };

    function request() { return { type: SYNC_ADDRESS_STATUS } }
    function success(data) { return { type: SYNC_ADDRESS_STATUS_SUCCEED, data } }
    function failure(message) { return { type: SYNC_ADDRESS_STATUS_FAILED, message } }
}
