import { nftService } from '../services/nftServices';
import {
    CLEAR_TRANSFER_VOUCHER,
    TRANSFER_VOUCHER,
    TRANSFER_VOUCHER_FAILED,
    TRANSFER_VOUCHER_SUCCEED,
    GET_COMMISSION_AND_NFT,
    GET_COMMISSION_AND_NFT_FAILED,
    GET_COMMISSION_AND_NFT_SUCCEED,
    CLAIM_NFT,
    CLAIM_NFT_FAILED,
    CLAIM_NFT_SUCCEED,
    GET_NFT_CLAIMED,
    GET_NFT_CLAIMED_FAILED,
    GET_NFT_CLAIMED_SUCCEED,
    GET_NFT_ASSET,
    GET_NFT_ASSET_SUCCEED,
    GET_NFT_ASSET_FAILED,
    CLEAR_CLAIM_NFT,
    GET_COUNTDOWN,
    GET_COUNTDOWN_FAILED,
    GET_COUNTDOWN_SUCCEED,
    CLEAR_COUNTDOWN
} from '../constants';


export const nftActions = {
    transferVoucher,
    clearTransferVoucherState,
    getCommissionAndNft,
    getNftClaimed,
    claimNft,
    getNftAsset,
    clearClaimNft,
    getCountdown,
    clearCountdown
}

function transferVoucher(payload,token) {
    return dispatch => {
        dispatch(request());
        nftService.transferVoucher(payload, token)
            .then(
                res => {
                    dispatch(success(res.data));
                },
                error => {
                    let toast = ''
                    if (error.data === "Invalid username") {
                        toast = "voucherTransferInvalidEmail"
                    } else if (error.data === "Wrong email code") {
                        toast = "voucherTransferInvalidEmailCode"
                    } else if (error.data === "Wrong sms code") {
                        toast = "voucherTransferInvalidPhoneCode"
                    } else {
                        toast = 'voucherTransferFailedMsg'
                    }
                    dispatch(failure(toast));
                }
            );
    };

    function request() { return { type: TRANSFER_VOUCHER } }
    function success(data) { return { type: TRANSFER_VOUCHER_SUCCEED, data } }
    function failure(message) { return { type: TRANSFER_VOUCHER_FAILED, message } }
}

function clearTransferVoucherState() {
    return dispatch => {
        dispatch(request());
    };
    function request() { return { type: CLEAR_TRANSFER_VOUCHER } }
}

function getCommissionAndNft(token) {
    return dispatch => {
        dispatch(request());
        nftService.getCommissionAndNft( token)
            .then(
                res => {
                    dispatch(success(res.data));
                },
                error => {
                    dispatch(failure(error.data));
                }
            );
    };

    function request() { return { type: GET_COMMISSION_AND_NFT } }
    function success(data) { return { type: GET_COMMISSION_AND_NFT_SUCCEED, data } }
    function failure(message) { return { type: GET_COMMISSION_AND_NFT_FAILED, message } }
}

function getNftClaimed(token) {
    return dispatch => {
        dispatch(request());
        nftService.getNftClaimed( token)
            .then(
                res => {
                    dispatch(success(res.data));
                },
                error => {
                    dispatch(failure(error.data));
                }
            );
    };

    function request() { return { type: GET_NFT_CLAIMED } }
    function success(data) { return { type: GET_NFT_CLAIMED_SUCCEED, data } }
    function failure(message) { return { type: GET_NFT_CLAIMED_FAILED, message } }
}

function getNftAsset(token) {
    return dispatch => {
        dispatch(request());
        nftService.getNftAsset( token)
            .then(
                res => {
                    dispatch(success(res.data));
                },
                error => {
                    dispatch(failure(error.data));
                }
            );
    };

    function request() { return { type: GET_NFT_ASSET } }
    function success(data) { return { type: GET_NFT_ASSET_SUCCEED, data } }
    function failure(message) { return { type: GET_NFT_ASSET_FAILED, message } }
}

function claimNft (payload,token) {
    return dispatch => {
        dispatch(request());
        nftService.claimNft(payload, token)
            .then(
                res => {
                    dispatch(success(res.data));
                },
                error => {
                    let toast = ''
                    if (error.data === "Insufficient voucher.") {
                        toast = "claimNftInsufficientMsg"
                    } else {
                        toast = 'claimNftFailedMsg'
                    }
                    dispatch(failure(toast));
                }
            );
    };

    function request() { return { type: CLAIM_NFT } }
    function success(data) { return { type: CLAIM_NFT_SUCCEED, data } }
    function failure(message) { return { type: CLAIM_NFT_FAILED, message } }
}

function clearClaimNft() {
    return dispatch => {
        dispatch(request());
    };
    function request() { return { type: CLEAR_CLAIM_NFT } }
}

function getCountdown(token) {
    return dispatch => {
        dispatch(request());
        nftService.getCountdown( token)
            .then(
                res => {
                    dispatch(success(res.data));
                },
                error => {
                    dispatch(failure(error.data));
                }
            );
    };

    function request() { return { type: GET_COUNTDOWN } }
    function success(data) { return { type: GET_COUNTDOWN_SUCCEED, data } }
    function failure(message) { return { type: GET_COUNTDOWN_FAILED, message } }
}

function clearCountdown(){
    return dispatch =>{
        dispatch(request());
    }
    function request(){return {type:CLEAR_COUNTDOWN}}
}