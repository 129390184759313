import { withTranslation } from "react-i18next";
import { Avatar, Button, Fade, IconButton, makeStyles, Modal, Snackbar, Typography, Dialog } from "@material-ui/core";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import logout from "../../../images/nav/contactUs.png";
import x from "../../../images/closePopIcon.png";
import whatsapp from "../../../images/nav/whatsapp.png";
import email from "../../../images/nav/email.png";
import wechat from "../../../images/nav/wechat.png";
import { authActions } from "../../../redux/actions/authActions";
import './LogoutModal.css'
import { motion } from "framer-motion"


const ContactUsModal = (props) => {
    const {
        onCancel: handleClose,
        modalVisible,
        t,
        phone,
        width,
    } = props;
    const useStyles = makeStyles((theme) => ({
        modal: {
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            textAlign: 'center',
        },
        font: {
            color: '#1C2237',
            fontFamily: 'Avenir',
            fontSize: phone ? 10 : 14,
            fontWeight: 900,
            letterSpacing: 0,
            textAlign: 'center',
        },
        contactDiv: {
            height: phone ? 40 : 66,
            width: '80%',
            maxWidth: 425,
            borderRadius: 12,
            backgroundColor: 'white',
            margin: "21px auto 0px",
            position: 'relative',
            border: "1px solid #EDEDED"
        }
    }));
    const classes = useStyles();
    const dispatch = useDispatch();
    const { userInfo } = useSelector(state => state.auth)

    const ContactUsBody = (
        <motion.div
            initial={{ opacity: 0, scale: 0 }}
            animate={{ opacity: 1, scale: 1 }}
        >
            <div style={{
                backgroundColor: 'white', borderRadius: 20, width: phone ? " 89vw" : "606px", overflow: 'hidden', outline: 0, display: 'flex', alignItems: 'center', flexDirection: 'column'
            }}>
                <div style={{ width: "100%", }}>
                    <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center',marginTop: 40, marginLeft:32,marginRight:32}}>
                        <Typography style={{ fontSize: phone ? 16 : 24, fontWeight: "500", color: '#1C2237', fontFamily: 'Avenir', }}>
                        {t('contactUs')}
                        </Typography>

                        <Button
                            style={{
                                padding: 0,
                                minHeight: 0,
                                minWidth: 0,
                            }}
                            onClick={handleClose}
                        >
                            <img alt='' src={x} style={phone ? { height: 24, width: 24 } : { height: 32, width: 32 }} />
                        </Button>

                    </div>
                    <div style={{ marginTop: phone ? 15 : 15,marginBottom:15, boxSizing: 'border-box', height: 1, border: '1px solid #DDDDDD',marginLeft:32,marginRight:32 }} />

                    <img alt='' src={logout} style={phone ? { width: 32, height: 32 } : { width: 60, height: 60 }}></img>
           
                    <div className={classes.contactDiv}>
                        <div style={{ display: 'flex', transform: 'translateY(-50%)', position: 'absolute', top: "50%" }}>
                            <img alt='' src={whatsapp} style={phone ? { height: 15, width: 18, marginRight: 10, marginLeft: 12 } : { height: 25, width: 29, marginRight: 16, marginLeft: 20 }} />
                            <Typography className={classes.font} style={{ margin: 'auto 0px' }}>
                                {t('whatsapp')}
                                <div style={{display:"inline-grid"}}><div>{"+1 (647) 947-6258"}</div></div>
                            </Typography>
                        </div>

                    </div>
                    <div className={classes.contactDiv} style={{marginBottom:40}}>
                        <div style={{ display: 'flex', transform: 'translateY(-50%)', position: 'absolute', top: "50%" }}>
                            <img alt='' src={email} style={phone ? { height: 10, width: 18, marginRight: 10, marginLeft: 12 } : { height: 20, width: 29, marginRight: 16, marginLeft: 20 }} />
                            <Typography className={classes.font} style={{ margin: 'auto 0px' }}>
                                {t('otcEmail')}
                            </Typography>
                        </div>
                    </div>

                
                </div>
            </div >
        </motion.div>
    );

    return (
        <Modal
            open={modalVisible}
 
            onClose={() => {
                handleClose()
            }}
            className={classes.modal}
            aria-labelledby="simple-modal-title"
            aria-describedby="simple-modal-description"
            BackdropProps={{
                className:
                    'modalBackground'
            }}
        >
            {ContactUsBody}
        </Modal>
    )
}


export default withTranslation()(ContactUsModal);