import { withTranslation } from "react-i18next";
import {
    Avatar,
    Button,
    InputAdornment,
    InputBase,
    makeStyles,
    MenuItem,
    Modal,
    Select,
    Typography
} from "@material-ui/core";
import React, { useEffect, useState } from "react";
import { motion } from "framer-motion"
import close from "../../../images/nft/close.png";
import insufficientFailed from "../../../images/nft/insufficientFailed.png";
import successAnimation from "../../../images/nft/successAnimation.json";
import successAnimation2 from "../../../images/nft/successAnimation2.json";
import successAnimation4 from "../../../images/nft/successAnimation4.json";
import successAnimation3 from "../../../images/nft/successAnimation3.json";

import failedIcon from "../../../images/nft/failed.png";
import { useHistory } from "react-router-dom";
import { useSelector } from "react-redux";
import Lottie from 'lottie-react-web'
const ClaimNFTResultModal = (props) => {
    const {
        onCancel: handleClose,
        modalVisible,
        t,
        phone,
        width,
        success,
        setClaimNftVisible,
        scrollToVouchers,

    } = props;
    const history = useHistory();
    const { claimNft, claimNftMsg } = useSelector(state => state.nft)





    const useStyles = makeStyles((theme) => ({
        modal: {
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            overflow: 'scroll',
        },
        font: {
            color: '#1C2237',
            fontFamily: 'Avenir',
            fontSize: phone ? 14 : 16,
            letterSpacing: -0.31
        },
    }));
    const classes = useStyles();
    async function onFailedClose() {
        if (claimNftMsg === 'claimNftInsufficientMsg') {
            await handleClose()
            scrollToVouchers()
        } else {
            setClaimNftVisible(true)
            handleClose()
        }
    }


    const ClaimNFTResultBody = (
        <div style={{ position: "relative", height: phone ? null : 400, width: phone ? 350 : 400, borderRadius: 32, backgroundColor: "white", outline: 0 }}>

            <div style={{ height: "100%", width: "100%", position: 'absolute', opacity: 0.8 }}>
                <Lottie

                    direction={success ? 1 : -1}
                    options={{
                        animationData: successAnimation2,
                        loop: false,
                    }}
                />
            </div>
            <img src={close} style={{ position: "absolute", width: phone ? 40 : 33, height: phone ? 40 : 33, right: phone ? 0 : -20, top: phone ? -50 : -30, cursor: "pointer" }} onClick={() => {
                if (success) {
                    handleClose();


                    // clearTimeout()
                } else {
                    onFailedClose();

                    // clearTimeout()

                }
            }} />
            <div style={{ margin: phone ? 30 : 40 }}>
                <div style={{ height: phone ? 265 : 275 }}>
                    {
                        success ?
                            <div style={{ overflow: 'hidden' }}>

                                <div style={{ width: '100%', display: "flex", alignItems: 'center', justifyContent: 'center' }}>
                                    <img src={claimNft.imageUrl} style={{ width: 150, height: 150, borderRadius: 24 }} />

                                </div>
                                <div style={{ marginTop: 10, display: "flex", justifyContent: "center" }}>
                                    <Typography className={classes.font} style={{ display: "flex", alignItems: claimNft.grade === 'Regular' ? "baseline" : "center", fontSize: phone ? 20 : 24, fontWeight: 900 }}>
                                        {t('nftVirtualHead')}
                                        {
                                            claimNft.grade === "Regular" ?
                                                <Typography style={{ marginLeft: 8, fontSize: 12, fontWeight: 900 }}>
                                                    {t('nftRegular')}
                                                </Typography>
                                                :
                                                <div style={{ display: "flex", marginLeft: 8, height: 21, width: 44, borderRadius: 6, background: "linear-gradient(90deg, #FC4362 0%, #D00ABD 100%)", alignItems: "center" }}>
                                                    <Typography style={{ marginLeft: 8, fontSize: 12, fontWeight: 900, color: "white" }}>
                                                        {t('nftRare')}
                                                    </Typography>
                                                </div>
                                        }
                                    </Typography>
                                </div>
                                <Typography className={classes.font} style={{ textAlign: 'center', marginTop: phone ? 7 : 10 }}>
                                    {t('claimNftSucceedMsg1') + '#' + claimNft.id} {claimNft.grade === "Regular" ? t('nftRegular') : t('nftRare')}{t('claimNftSucceedMsg2')}
                                </Typography>
                            </div>
                            :
                            <div>
                                <div style={{ width: '100%', display: "flex", alignItems: 'center', justifyContent: 'center' }}>
                                    <img src={claimNftMsg === 'claimNftInsufficientMsg' ? insufficientFailed : failedIcon} style={{ width: 271, height: 117 }} />
                                </div>
                                <Typography className={classes.font} style={{ fontSize: phone ? 20 : 24, fontWeight: 900, marginTop: 30 }}>
                                    {t('claimNftFailed')}
                                </Typography>
                                <Typography className={classes.font} style={{ marginTop: phone ? 7 : 10 }}>
                                    {t(claimNftMsg)}
                                </Typography>
                            </div>
                    }
                </div>

                <Button
                    className={classes.font} style={{ height: 55, width: '100%', borderRadius: 20, backgroundColor: success || claimNftMsg === 'claimNftInsufficientMsg' ? "#003FFF" : "#DC3055", color: 'white', textTransform: "none", fontSize: phone ? 18 : 20 }}
                    onClick={() => {
                        if (success) {
                            handleClose();
                            // clearTimeout()

                        } else {
                            onFailedClose();
                            // clearTimeout()

                        }
                    }}
                >
                    {success ? t('voucherReceiveGotIt') : claimNftMsg === 'claimNftInsufficientMsg' ? t('nftEarnMoreVouchers') : t('voucherTransferTryAgain')}
                    {success ?
                        <div style={{ width: 32, height: 32, opacity: 1, alignItems: 'flex-end', justifyContent: 'flex-end', display: 'flex',position:'absolute',right:24 }}>
                            <Lottie
                                style={{ height: '100%' }}
                                direction={1}
                                options={{
                                    animationData: successAnimation4,
                                    loop: false,
                                }}
                            />
                        </div> : null
                    }
                </Button>
            </div>
        </div>
    );


    return (
        <Modal
            open={modalVisible}
            onClose={success ? handleClose : onFailedClose}
            className={classes.modal}
            aria-labelledby="simple-modal-title"
            aria-describedby="simple-modal-description"
            BackdropProps={{
                className:
                    'modalBackground'
            }}
            disableAutoFocus={true}
        >
            {ClaimNFTResultBody}
        </Modal>
    )
}


export default withTranslation()(ClaimNFTResultModal);
