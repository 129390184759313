import { withTranslation } from "react-i18next";
import { Avatar, Button, Fade, IconButton, makeStyles, Modal, Snackbar, Typography, Dialog } from "@material-ui/core";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import success from "../../images/success.png";
import fail from '../../images/note.png'
import x from "../../images/closePopIcon.png";

import { motion } from "framer-motion"

const ResultModal = (props) => {
    const {
        onCancel: handleClose,
        modalVisible,
        t,
        phone,
        width,
        title,
        content,
        resultSuccess
    } = props;
    const useStyles = makeStyles((theme) => ({
        modal: {
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            textAlign: 'center',
        }
    }));
    const classes = useStyles();

    const updateSuccessBody = (
        <motion.div
            initial={{ opacity: 0, scale: 0 }}
            animate={{ opacity: 1, scale: 1 }}
        >
            <div style={{
                backgroundColor: 'white', borderRadius: 20, width: phone ? " 89vw" : "606px", overflow: 'auto', outline: 'none'
            }}>
                <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', marginTop: 40, marginLeft: 32, marginRight: 32 }}>
                    <Typography style={{ fontSize: phone ? 16 : 24, fontWeight: "500", color: '#1C2237', fontFamily: 'Avenir', }}>
                        {title}
                    </Typography>

                    <Button
                        style={{
                            padding: 0,
                            minHeight: 0,
                            minWidth: 0,
                        }}
                        onClick={handleClose}
                    >
                        <img alt='' src={x} style={phone ? { height: 24, width: 24 } : { height: 32, width: 32 }} />
                    </Button>

                </div>
                <div style={{ marginTop: phone ? 15 : 15,marginBottom:15, boxSizing: 'border-box', height: 1, border: '1px solid #DDDDDD',marginLeft:32,marginRight:32 }} />

                <div style={{ margin: 'min(10%,30px)' }}>

                    <img alt='' src={resultSuccess ? success : fail} style={phone ? { width: 30 } : { width: 60 }}></img>
           
                    <Typography style={{ marginTop: phone ? 8 : 24, fontFamily: 'Avenir', color: "#9097A1", fontSize: phone ? 12 : 16, fontWeight: 500, letterSpacing: 0, lineHeight: phone ? '11px' : '19px' }}>
                        {content}
                    </Typography>

                </div>
            </div >
        </motion.div>
    );

    return (
        <Modal
            open={modalVisible}
           

            onClose={() => {
                handleClose()
            }}
            className={classes.modal}
            aria-labelledby="simple-modal-title"
            aria-describedby="simple-modal-description"
            BackdropProps={{
                class:
                    'modalBackground'
            }}
        >
            {updateSuccessBody}
        </Modal>
    )
}


export default withTranslation()(ResultModal);
