import React, {useEffect, useRef, useState} from 'react';
import {Link, NavLink, useHistory, useLocation} from 'react-router-dom';
import headIcon from '../../images/headIcon.png';
import settingIcon from '../../images/settingIcon.png';
import personIcon from '../../images/personIcon.png';
import historyIcon from '../../images/historyIconBlack.png';


import connectIcon from '../../images/connectIcon.png';
import tutorialIcon from '../../images/tutorialIcon.png';
import LungIcon from '../../images/LungIcon.png';
import logoutIcon from '../../images/logoutIcon.png';
import otcNewIcon from '../../images/otcNewIcon.png';
import referralIcon from '../../images/invIcon.png';
import referralIconUnread from '../../images/invIcon1.png';
import ChinaIcon from '../../images/home/ChinaIcon.png';
import USIcon from '../../images/home/USIcon.png';
import dropdownIcon from '../../images/dropdownIcon.png'
import announcementIcon from '../../images/AnnouncementIcon.png';
import { checkRoutes } from "../../utils/CheckRoutes";

import {withTranslation} from 'react-i18next';
import {
    Button,
    ClickAwayListener,
    Grow,
    IconButton,
    ListItem,
    makeStyles,
    MenuItem,
    MenuList,
    Paper,
    Popper,
    Typography
} from '@material-ui/core';
import MenuIcon from '@material-ui/icons/Menu';
import CloseIcon from '@material-ui/icons/Close';
import {useDispatch, useSelector} from "react-redux";
import LogoutModal from "./components/LogoutModal";
import AnnocementButton from "./components/AnnocementButton";

import i18n from "../../i18n";
import ContactUsModal from "./components/ContactUsModal";
import {kycActions} from "../../redux/actions/kycActions";
import AnnocementModal from './components/AnnocementModal';
import {authActions} from "../../redux/actions/authActions";


function PlatformFooter({ t, width }) {
    const [phone, setPhone] = useState(width <= 745);
    const [logoutVisible, setLogoutVisible] = useState(false);
    const [contactUsVisible, setContactUsVisible] = useState(false);
    const { token, userInfo } = useSelector(state => state.auth)
    const { kycDetails, documentDetails } = useSelector(state => state.kyc)
    const [open, setOpen] = useState(false);
    const [phoneAnnouncementOpen, setPhoneAnnouncementOpen] = useState(false);

    const anchorRef = useRef(null);
    const prevOpen = React.useRef(open);
    const dispatch = useDispatch();
    let history = useHistory();
    let location = useLocation()
    const useStyles = makeStyles({
        font: {
            color: '#1C2237',
            fontFamily: 'Avenir',
            fontSize: 16,
            fontWeight: 500,
            letterSpacing: 0,
            textAlign: 'center',
        },
        selected: {
            color: '#3254FA !important',
            borderBottom: "2px solid #3254FA"
        },

        popTitle: {
            fontSize: 14, fontWeight: '500', fontFamily: 'Avenir'
        },
        popSubTitle: {
            fontSize: 12, fontWeight: '500', color: "#8B96A7", fontFamily: 'Avenir'
        }
    })
    const classes = useStyles();

    const navLinks = [
        // { title: loggedIn ? t('wallet') : t('home'), path: loggedIn ? `/wallet` : `/` },
        { title: t('quickTrade'), path: `/trade` },
        { title: t('balance'), path: `/balance` },
        // { title: t('history'), path: `/history` },
        { title: t('nft'), path: `/nft` },
    ]

    useEffect(() => {
        console.log('here: ', window.location)
        checkRoutes()
    }, [history, location])

    useEffect(() => {
        setPhone(width <= 980)

    }, [width]);

    useEffect(() => {
        if (prevOpen.current === true && open === false) {
            anchorRef.current.focus();
        }

        prevOpen.current = open;
    }, [open]);

    useEffect(() => {
        dispatch(kycActions.getKycDetails(token))
        dispatch(kycActions.getDocumentDetails(token))
        dispatch(authActions.getLatestArticles("e352bd2e72bb1d58a6ae2dd0be75fdc4ba812786e515a8405a754f3c0988a45b"))
    }, [])

    const handleToggle = () => {
        setOpen((prevOpen) => !prevOpen);
    };

    const handleTogglePhoneAnnocement = () => {
        setPhoneAnnouncementOpen((phoneAnnouncementOpen) => !phoneAnnouncementOpen);
    };

    const handleClose = (event) => {
        if (anchorRef.current && anchorRef.current.contains(event.target)) {
            return;
        }

        setOpen(false);
    };

    function handleListKeyDown(event) {
        if (event.key === 'Tab') {
            event.preventDefault();
            setOpen(false);
        } else if (event.key === 'Escape') {
            setOpen(false);
        }
    }

    const changeLanguage = (e) => {
        let newLang = i18n.language === 'en' ? 'cn' : 'en'
        i18n.changeLanguage(newLang);
        localStorage.setItem('lng', newLang)
    }


    return (

        <div style={{ width: '100%', display: 'flex', alignItems: 'center', flexDirection: 'column', minWidth: 317 }}>
            {
                phone ?
                    //Phone UI
                    <div style={{ paddingTop: '24px', paddingBottom: '12px', width: '100%', display: 'flex', alignItems: 'center', flexDirection: 'row', flexWrap: 'wrap', justifyContent: 'space-between', backgroundColor: '#FBFBFB' }}>

                        <Link to='/'>
                            <img alt='' src={otcNewIcon} style={{ height: 32, width: 32, marginLeft: 16 }} />
                        </Link>
                        <div style={{ display: 'flex', flexDirection: 'row', height: '100%', alignItems: 'center', marginRight: 16 }}>

                            <div style={{ display: 'flex', alignItems: 'center' }}>
                                {navLinks.map(({ title, path }) => (
                                    <NavLink
                                        to={path}
                                        key={title}
                                        style={{
                                            height: '100%', textDecoration: `none`, color: "#858E9D"
                                        }}
                                        activeClassName={classes.selected}
                                        isActive={(match, location) => {
                                            if (location.pathname === path) {
                                                return true
                                            } else {
                                                return false
                                            }
                                        }}


                                    >
                                        <ListItem button style={{ height: '100%' }}>
                                            <Typography style={{ fontSize: 12, fontWeight: '500', textTransform: 'none', }}>
                                                {title}
                                                {title === t('nft') ? <span style={{ display: "inline-block", height: 6, width: 6, borderRadius: "50%", backgroundColor: "#00C9FF", marginBottom: 7, marginLeft: 2 }} /> : null}
                                            </Typography>
                                            {/* {renderNewIcon(path)} */}
                                        </ListItem>
                                    </NavLink>


                                ))
                                }

                                <>

                                    <IconButton
                                        size="medium"
                                        edge="end"
                                        color="inherit"
                                        aria-label="open drawer"
                                        onClick={handleToggle}
                                        ref={anchorRef}
                                    // sx={{ mr: 2 }}
                                    >
                                        {open ?
                                            <CloseIcon />
                                            :
                                            <MenuIcon />
                                        }
                                    </IconButton>
                                </>
                            </div>


                            <Popper
                                style={{ marginTop: 16, width: '100%', display: 'flex', justifyContent: 'center' }}
                                open={open}
                                anchorEl={anchorRef.current}
                                role={undefined}
                                placement="top-end"
                                // placement="bottom-end"
                                transition
                            >
                                {({ TransitionProps, placement }) => (
                                    <Grow
                                        {...TransitionProps}

                                        in={open}

                                    >
                                        <Paper elevation={8} style={{ backgroundColor: "white", width: '90%', borderRadius: 20, padding: 0, overflow: 'hidden' }}>
                                            <ClickAwayListener onClickAway={handleClose}>
                                                <MenuList
                                                    autoFocusItem={open}
                                                    id="composition-menu"
                                                    aria-labelledby="composition-button"
                                                    onKeyDown={handleListKeyDown}
                                                >
                                                    {/* <div style={{ display: "flex", alignItems: 'center', justifyContent: 'flex-start', marginTop: -8, padding: 24 }}>


                                                        <img alt='' src={headIcon} style={{ width: 32, height: 32, backgroundColor: '#F6F7FA', borderRadius: 8, marginRight: 8 }} />
                                                        <Typography style={{ fontFamily: 'Avenir', fontSize: 12, fontWeight: 500, marginRight: 4 }}>Hello, {userInfo.firstName}</Typography>
                                                        <div
                                                            style={
                                                                { backgroundColor: kycDetails.status === 'passed' ? "#0CB451" : "#DC3055", borderRadius: 4, marginLeft: 4 }

                                                            }
                                                        >
                                                            <Typography style={{ fontSize: 10, fontWeight: '500', color: "white", marginLeft: 6, marginRight: 6 }}>
                                                                {kycDetails.status === 'passed' ? t('accountVerified') : t('notVerified')}
                                                            </Typography>
                                                        </div>
                                                    </div> */}
                                                    <MenuItem style={{ justifyContent: 'flex-start', padding: '22px 0px', height: 72, marginTop: 24 }} onClick={() => { history.push('/history'); handleToggle() }}>
                                                        <img alt='' src={historyIcon} style={{ marginLeft: 24, width: 48, height: 48, backgroundColor: '#F6F7FA', borderRadius: 14, marginRight: 8 }} />

                                                        <div style={{ display: 'flex', flexDirection: 'column' }}>

                                                            <Typography className={classes.popTitle}>
                                                                {t('history')}
                                                            </Typography>
                                                            <Typography className={classes.popSubTitle}>
                                                                {t('historySubtitle')}
                                                            </Typography>
                                                        </div>
                                                    </MenuItem>

                                                    <MenuItem style={{ justifyContent: 'flex-start', padding: '22px 0px', height: 72 }} onClick={() => { history.push('/setting'); handleToggle() }}>
                                                        <img alt='' src={settingIcon} style={{ marginLeft: 24, width: 48, height: 48, backgroundColor: '#F6F7FA', borderRadius: 14, marginRight: 8 }} />
                                                        <div style={{ display: 'flex', flexDirection: 'column' }}>
                                                            <Typography className={classes.popTitle}>
                                                                {t('setting')}
                                                            </Typography>
                                                            <Typography className={classes.popSubTitle}>
                                                                {t('settingSubTitle')}
                                                            </Typography>
                                                        </div>
                                                    </MenuItem>





                                                    <MenuItem style={{ justifyContent: 'flex-start', padding: '22px 0px', height: 72 }} onClick={() => { history.push('/userProfile'); handleToggle() }}>
                                                        <img alt='' src={personIcon} style={{ marginLeft: 24, width: 48, height: 48, backgroundColor: '#F6F7FA', borderRadius: 14, marginRight: 8 }} />

                                                        <div style={{ display: 'flex', flexDirection: 'column' }}>

                                                            <Typography className={classes.popTitle}>
                                                                {t('profile')}
                                                            </Typography>
                                                            <Typography className={classes.popSubTitle}>
                                                                {t('profileSubtitle')}
                                                            </Typography>
                                                        </div>
                                                    </MenuItem>

                                                    <MenuItem style={{ justifyContent: 'flex-start', padding: '22px 0px', height: 72 }} onClick={() => {
                                                        window.open(i18n.language === 'en' ? "https://otcdocs.yesbit.io/v/english/" : "https://otcdocs.yesbit.io/");
                                                        handleToggle()
                                                    }}>

                                                        <img alt='' src={tutorialIcon} style={{ marginLeft: 24, width: 48, height: 48, backgroundColor: '#F6F7FA', borderRadius: 14, marginRight: 8 }} />

                                                        <div style={{ display: 'flex', flexDirection: 'column' }}>

                                                            <Typography className={classes.popTitle}>
                                                                {t('tutorials')}
                                                            </Typography>
                                                            <Typography className={classes.popSubTitle}>
                                                                {t('tutorialsSubtitle')}
                                                            </Typography>
                                                        </div>
                                                    </MenuItem>

                                                    <MenuItem style={{ justifyContent: 'flex-start', padding: '22px 0px', height: 72 }} onClick={() => { handleTogglePhoneAnnocement(); handleToggle() }}>
                                                        <div style={{ marginLeft: 24, width: 48, height: 48, backgroundColor: '#F6F7FA', borderRadius: 14, marginRight: 8, alignItems: 'center', justifyContent: 'center', display: 'flex' }} >
                                                            <img alt='' src={announcementIcon} style={{ width: 24, height: 24 }} />
                                                        </div>
                                                        <div style={{ display: 'flex', flexDirection: 'column' }}>

                                                            <Typography className={classes.popTitle}>
                                                                {t('announcement')}
                                                            </Typography>
                                                            <Typography className={classes.popSubTitle}>
                                                                {t('announcementDetails')}
                                                            </Typography>
                                                        </div>
                                                    </MenuItem>

                                                    <MenuItem style={{ justifyContent: 'flex-start', padding: '22px 0px', height: 72 }} onClick={() => { setContactUsVisible(true); handleToggle() }}>
                                                        <img alt='' src={connectIcon} style={{ marginLeft: 24, width: 48, height: 48, backgroundColor: '#F6F7FA', borderRadius: 14, marginRight: 8 }} />

                                                        <div style={{ display: 'flex', flexDirection: 'column' }}>

                                                            <Typography className={classes.popTitle}>
                                                                {t('contactUs')}
                                                            </Typography>
                                                            <Typography className={classes.popSubTitle}>
                                                                {t('contactUsSubTitle')}
                                                            </Typography>
                                                        </div>
                                                    </MenuItem>






                                                    <MenuItem style={{ justifyContent: 'flex-start', padding: '22px 0px', height: 72 }} onClick={() => { history.push('/referral');; handleToggle() }}>
                                                        <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', marginLeft: 24, width: 48, height: 48, backgroundColor: '#F6F7FA', borderRadius: 14, marginRight: 8 }} >
                                                            <img alt='' src={referralIcon} style={{ width: 32, height: 32 }} />
                                                        </div>
                                                        <div style={{ display: 'flex', flexDirection: 'column' }}>

                                                            <Typography className={classes.popTitle}>
                                                                {t('navReferral')}
                                                            </Typography>
                                                            <Typography className={classes.popSubTitle}>
                                                                {t('navReferralSubtitle')}
                                                            </Typography>
                                                        </div>
                                                    </MenuItem>


                                                    <MenuItem style={{ justifyContent: 'flex-start', padding: '22px 0px', height: 72 }} onClick={() => { changeLanguage(); handleToggle() }}>


                                                        <img alt='' src={LungIcon} style={{ marginLeft: 24, width: 48, height: 48, backgroundColor: '##F6F7FA', borderRadius: 14, marginRight: 8 }} />

                                                        <div style={{ display: 'flex', flexDirection: 'column' }}>

                                                            <Typography className={classes.popTitle}>
                                                                {t('lang')}
                                                            </Typography>
                                                            <Typography className={classes.popSubTitle}>
                                                                {t('langSubtitle')}
                                                            </Typography>
                                                        </div>
                                                    </MenuItem>



                                                    <MenuItem style={{ padding: '22px 0px', display: 'flex', justifyContent: 'center' }} onClick={() => { setLogoutVisible(true); handleToggle() }}>

                                                        <div style={{ width: '89%', backgroundColor: '#E63636', display: 'flex', alignItems: 'center', borderRadius: 12 }}>

                                                            <img alt='' src={logoutIcon} style={{ marginLeft: 4, width: 48, height: 48, marginRight: 8 }} />
                                                            <Typography style={{ fontSize: 14, fontWeight: '500', color: 'white' }}>
                                                                {t('logOut')}
                                                            </Typography>
                                                        </div>
                                                    </MenuItem>
                                                </MenuList>
                                            </ClickAwayListener>
                                        </Paper>
                                    </Grow>
                                )}
                            </Popper>
                        </div>
                    </div>
                    :

                    //Desk UI
                    <div style={{ padding: '16px', width: '100%', display: 'flex', alignItems: 'center', flexDirection: 'row', flexWrap: 'wrap', justifyContent: 'space-between', backgroundColor: '#FBFBFB' }}>
                        <div style={{ display: 'flex', flexDirection: 'row', height: '100%', alignItems: 'center' }}>
                            <Link to='/'>
                                <img alt='' src={otcNewIcon} style={{ height: width < 1300 ? 28 : 48, width: width < 1300 ? 28 : 48, marginRight: "1vw", marginLeft: '3vw' }} />
                            </Link>

                            {navLinks.map(({ title, path }) => (
                                <NavLink
                                    to={path}
                                    key={title}
                                    style={{
                                        textDecoration: `none`, color: "#8B96A7", marginLeft: "1vw", marginRight: "1vw", height: 54
                                    }}
                                    activeClassName={classes.selected}
                                    isActive={(match, location) => {
                                        if (location.pathname === path) {
                                            return true
                                        } else {
                                            return false
                                        }
                                    }}


                                >
                                    <ListItem button style={{ height: '100%' }}>
                                        <Typography style={{ fontSize: width < 1300 ? 14 : 16, fontWeight: '600', textTransform: 'none', fontFamily: 'Avenir', }}>
                                            {title}
                                            {title === t('nft') ? <span style={{ display: "inline-block", height: 6, width: 6, borderRadius: "50%", backgroundColor: "#00C9FF", marginBottom: 10, marginLeft: 3 }} /> : null}
                                        </Typography>
                                        {/* {renderNewIcon(path)} */}
                                    </ListItem>
                                </NavLink>
                            ))}

                            <Button style={{
                                borderBottom: window.location.pathname == "/referral" ? "2px solid #3254FA" : 'unset', borderRadius: 0, textDecoration: `none`, color: "#858E9D", minHeight: 0, minWidth: 0
                            }} onClick={() => { history.push('/referral'); localStorage.setItem(userInfo.userId + 'otcReferralClicked', 'true') }}
                            >

                                <ListItem button style={{ height: '100%' }}>

                                    <img alt='' src={localStorage.getItem(userInfo.userId + 'otcReferralClicked') ? referralIcon : referralIconUnread} style={{ width: width < 1300 ? 20 : 26, height: width < 1300 ? 20 : 26, backgroundColor: '##F6F7FA' }} />

                                    <Typography style={{ color: window.location.pathname == "/referral" ? "#3254FA" : "#8B96A7", fontWeight: "600", fontSize: width < 1300 ? 14 : 16, textTransform: 'none', marginLeft: 12 }}>
                                        {t("navBar_Commission")}
                                    </Typography>
                                    {/* {renderNewIcon(path)} */}
                                </ListItem>
                            </Button>

                        </div>
                        <div style={{ flexDirection: 'row', height: '100%', alignItems: 'center', justifyContent: 'flex-end', marginRight: "5%", display: 'flex' }}>


                            <>






                                <Button onClick={changeLanguage} style={{ minHeight: '100%', textTransform: 'none', marginRight: "1vw" }}>
                                    {/* <img alt='' src={LungIcon} style={{ width: 48, height: 48, backgroundColor: '##F6F7FA', borderRadius: 14 }} /> */}

                                    <img src={localStorage.getItem('lng') === 'en' ? ChinaIcon : USIcon} style={{ height: width < 1300 ? 20 : 24, width: width < 1300 ? 20 : 24, marginRight: 8 }}></img>
                                    <Typography style={{ fontSize: width < 1300 ? 12 : 14, fontWeight: '500', fontFamily: 'Avenir' }}>
                                        {localStorage.getItem('lng') === 'en' ? "中文" : "English"}

                                    </Typography>
                                </Button>
                                {/*<Button style={{ minHeight: '100%' }}>*/}
                                {/*    <img src={announcementIcon} style={{ height: 24, width: 24 }}></img>*/}

                                {/*</Button> */}
                                <AnnocementButton />

                                <Button ref={anchorRef} style={{ boxShadow: "0 0px 6px 0 rgba(200,200,200,0.5)", marginLeft:24,borderRadius: 12, minHeight: '100%', textTransform: 'none', backgroundColor: "white" }} onClick={handleToggle}>
                                    <div style={{ display: "flex", alignItems: 'center' }}>
                                        <img alt='' src={headIcon} style={{ width: width < 1300 ? 24 : 32, height: width < 1300 ? 24 : 32, marginLeft: 4, marginRight: "0.5vw" }} />

                                        <Typography style={{ fontFamily: 'Avenir', fontSize: width < 1300 ? 14 : 16, fontWeight: 500, color: 'black' }}>Hello, {userInfo.firstName}</Typography>
                                        <div
                                            style={
                                                { backgroundColor: kycDetails.status === 'passed' ? "#0CB451" : "#DC3055", borderRadius: 4, marginLeft: 8, marginRight: 8 }

                                            }
                                        >
                                            <Typography style={{ fontSize: 10, fontWeight: '500', color: "white", marginLeft: 6, marginRight: 6 }}>
                                                {kycDetails.status === 'passed' ? t('accountVerified') : t('notVerified')}
                                            </Typography>
                                        </div>
                                        <img alt='' src={dropdownIcon} style={{ width: 14, marginLeft: 4, marginRight: 8 }} />



                                    </div>
                                </Button>

                            </>

                            <Popper
                                style={{ marginTop: 16, width: phone ? '100%' : null }}
                                open={open}
                                anchorEl={anchorRef.current}
                                role={undefined}
                                placement="top-end"
                                // placement="bottom-end"
                                transition
                            >
                                {({ TransitionProps, placement }) => (
                                    <Grow
                                        {...TransitionProps}

                                        in={open}

                                    >
                                        <Paper elevation={8} style={{ backgroundColor: 'white', borderRadius: 12, width: 300 }}>
                                            <ClickAwayListener onClickAway={handleClose}>
                                                <MenuList
                                                    autoFocusItem={open}
                                                    id="composition-menu"
                                                    aria-labelledby="composition-button"
                                                    onKeyDown={handleListKeyDown}
                                                >

                                                    <MenuItem style={{ justifyContent: 'flex-start', padding: '22px 0px' }} onClick={() => { history.push('/history'); handleToggle() }}>
                                                        <img alt='' src={historyIcon} style={{ marginLeft: 24, width: 48, height: 48, backgroundColor: '#F6F7FA', borderRadius: 14, marginRight: 8 }} />

                                                        <div style={{ display: 'flex', flexDirection: 'column' }}>

                                                            <Typography className={classes.popTitle}>
                                                                {t('history')}
                                                            </Typography>
                                                            <Typography className={classes.popSubTitle}>
                                                                {t('historySubtitle')}
                                                            </Typography>
                                                        </div>
                                                    </MenuItem>

                                                    <MenuItem style={{ justifyContent: 'flex-start', padding: '22px 0px' }} onClick={() => { history.push('/setting'); handleToggle() }}>
                                                        <img alt='' src={settingIcon} style={{ marginLeft: 24, width: 48, height: 48, backgroundColor: '#F6F7FA', borderRadius: 14, marginRight: 8 }} />
                                                        <div style={{ display: 'flex', flexDirection: 'column' }}>
                                                            <Typography className={classes.popTitle}>
                                                                {t('setting')}
                                                            </Typography>
                                                            <Typography className={classes.popSubTitle}>
                                                                {t('settingSubTitle')}
                                                            </Typography>
                                                        </div>
                                                    </MenuItem>

                                                    <MenuItem style={{ justifyContent: 'flex-start', padding: '22px 0px' }} onClick={() => { history.push('/userProfile'); handleToggle() }}>
                                                        <img alt='' src={personIcon} style={{ marginLeft: 24, width: 48, height: 48, backgroundColor: '#F6F7FA', borderRadius: 14, marginRight: 8 }} />

                                                        <div style={{ display: 'flex', flexDirection: 'column' }}>

                                                            <Typography className={classes.popTitle}>
                                                                {t('profile')}
                                                            </Typography>
                                                            <Typography className={classes.popSubTitle}>
                                                                {t('profileSubtitle')}
                                                            </Typography>
                                                        </div>
                                                    </MenuItem>


                                                    <MenuItem style={{ justifyContent: 'flex-start', padding: '22px 0px' }}
                                                        onClick={() => {
                                                            window.open(i18n.language === 'en' ? "https://otcdocs.yesbit.io/v/english/" : "https://otcdocs.yesbit.io/");
                                                            handleToggle()
                                                        }}>

                                                        <img alt='' src={tutorialIcon} style={{ marginLeft: 24, width: 48, height: 48, backgroundColor: '#F6F7FA', borderRadius: 14, marginRight: 8 }} />

                                                        <div style={{ display: 'flex', flexDirection: 'column' }}>

                                                            <Typography className={classes.popTitle}>
                                                                {t('tutorials')}
                                                            </Typography>
                                                            <Typography className={classes.popSubTitle}>
                                                                {t('tutorialsSubtitle')}
                                                            </Typography>
                                                        </div>
                                                    </MenuItem>

                                                    <MenuItem style={{ justifyContent: 'flex-start', padding: '22px 0px' }} onClick={() => { setContactUsVisible(true); handleToggle() }}>
                                                        <img alt='' src={connectIcon} style={{ marginLeft: 24, width: 48, height: 48, backgroundColor: '#F6F7FA', borderRadius: 14, marginRight: 8 }} />

                                                        <div style={{ display: 'flex', flexDirection: 'column' }}>

                                                            <Typography className={classes.popTitle}>
                                                                {t('contactUs')}
                                                            </Typography>
                                                            <Typography className={classes.popSubTitle}>
                                                                {t('contactUsSubTitle')}
                                                            </Typography>
                                                        </div>
                                                    </MenuItem>





                                                    <MenuItem style={{ justifyContent: 'flex-start', padding: '22px 0px' }} onClick={() => { changeLanguage(); handleToggle() }}>


                                                        <img alt='' src={LungIcon} style={{ marginLeft: 24, width: 48, height: 48, backgroundColor: '#F6F7FA', borderRadius: 14, marginRight: 8 }} />

                                                        <div style={{ display: 'flex', flexDirection: 'column' }}>

                                                            <Typography className={classes.popTitle}>
                                                                {t('lang')}
                                                            </Typography>
                                                            <Typography className={classes.popSubTitle}>
                                                                {t('langSubtitle')}
                                                            </Typography>
                                                        </div>
                                                    </MenuItem>



                                                    <MenuItem style={{ padding: '22px 0px', display: 'flex', justifyContent: 'center' }} onClick={() => { setLogoutVisible(true); handleToggle() }}>

                                                        <div style={{ width: '89%', backgroundColor: '#E63636', display: 'flex', alignItems: 'center', borderRadius: 12 }}>

                                                            <img alt='' src={logoutIcon} style={{ marginLeft: 4, width: 48, height: 48, marginRight: 8 }} />
                                                            <Typography style={{ fontSize: 14, fontWeight: '500', color: 'white' }}>
                                                                {t('logOut')}
                                                            </Typography>
                                                        </div>
                                                    </MenuItem>
                                                </MenuList>
                                            </ClickAwayListener>
                                        </Paper>
                                    </Grow>
                                )}
                            </Popper>
                        </div>
                    </div >


            }

            <LogoutModal
                onCancel={() => {
                    setLogoutVisible(false);
                }}
                modalVisible={logoutVisible}
                t
                phone={phone}
                width={width}
            />
            <ContactUsModal
                onCancel={() => {
                    setContactUsVisible(false);
                }}
                modalVisible={contactUsVisible}
                t
                phone={phone}
                width={width}
            />

            <AnnocementModal
                onCancel={() => {
                    setPhoneAnnouncementOpen(false);
                }}
                modalVisible={phoneAnnouncementOpen}

                t
                phone={phone}
                width={width}
            />
        </div >
    );
}

export default withTranslation()(PlatformFooter);
