import React from 'react'
import { withTranslation } from 'react-i18next';

import './ProcessSection.css'

import largePic from '../../images/home/processLargePic.svg';
import { Typography, makeStyles } from '@material-ui/core';
import vipIcon from "../../images/vipIcon.png"
import priceIcon from "../../images/priceIcon.png"
import securityIcon from "../../images/SecurityIcon.png"


function ProcessSection({ t, phone }) {
    const useStyles = makeStyles({
        fontStyle: {
            fontFamily: "Avenir", fontWeight: '500'
        }
    })
    const classes = useStyles();

    return phone ? (<div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', flexDirection: 'column', marginTop: 64 }}>
        <div style={{ height: 1, width: '95%', backgroundColor: '#F2F2F2', marginBottom: 40 }}></div>

        <div style={{ width: "95%", display: 'flex', alignItems: 'center', flexDirection: 'column',overflow:'hidden' }}>
            <Typography style={{ fontSize: phone ? 20 : 30, marginBottom: 16 }} className={classes.fontStyle}>{t('home_HPBTitle')}</Typography>

            <div style={{ backgroundColor: '#F9F9F9' ,borderRadius:24}}>
                <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', marginBottom: 40, marginLeft: 24, marginRight: 24, marginTop: 32 }}>
                    <div>
                        <Typography style={{ fontSize: 16 }} className={classes.fontStyle}>{t('home_guaranteedTitle')}</Typography>
                        <Typography style={{ fontSize: 12, color: '#8B96A7', fontWeight: '500' }} className={classes.fontStyle}>{t('home_guaranteedSub')}</Typography>

                    </div>
                    <img src={securityIcon} style={{ height: 38, marginLeft: 8 }}></img>
                </div>

                <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', marginBottom: 40, marginLeft: 24, marginRight: 24 }}>
                    <div>
                        <Typography style={{ fontSize: 16, }} className={classes.fontStyle}>{t('home_PriceTitle')}</Typography>
                        <Typography style={{ fontSize: 12, color: '#8B96A7', fontWeight: '500' }} className={classes.fontStyle}>{t('home_PriceSub')}</Typography>

                    </div>
                    <img src={priceIcon} style={{ height: 38, marginLeft: 8 }}></img>
                </div>

                <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', marginBottom: 40, marginLeft: 24, marginRight: 24, marginBottom: 32 }}>
                    <div>
                        <Typography style={{ fontSize: 16, }} className={classes.fontStyle}>{t('home_VIPTitle')}</Typography>
                        <Typography style={{ fontSize: 12, color: '#8B96A7', fontWeight: '500' }} className={classes.fontStyle}>{t('home_VIPSub')}</Typography>

                    </div>
                    <img src={vipIcon} style={{ height: 38, marginLeft: 8 }}></img>
                </div>


            </div>
        </div>
    </div>) : (
        <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', flexDirection: 'column', marginTop: 64 }}>
            <div style={{ height: 1, width: '91%', backgroundColor: '#F2F2F2', marginBottom: 40 }}></div>

            <div style={{ width: '81%', }}>
                <Typography style={{ fontSize: 30, marginBottom: 12 }} className={classes.fontStyle}>{t('home_HPBTitle')}</Typography>

                <div style={{ backgroundColor: '#F9F9F9', padding: 40, borderRadius: 20 }}>
                    <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', marginBottom: 40 }}>
                        <div>
                            <Typography style={{ fontSize: 20 }} className={classes.fontStyle}>{t('home_guaranteedTitle')}</Typography>
                            <Typography style={{ fontSize: 16, color: '#8B96A7', fontWeight: '500' }} className={classes.fontStyle}>{t('home_guaranteedSub')}</Typography>

                        </div>
                        <img src={securityIcon} style={{ height: 49, marginRight: 40 }}></img>
                    </div>

                    <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', marginBottom: 40 }}>
                        <div>
                            <Typography style={{ fontSize: 20, }} className={classes.fontStyle}>{t('home_PriceTitle')}</Typography>
                            <Typography style={{ fontSize: 16, color: '#8B96A7', fontWeight: '500' }} className={classes.fontStyle}>{t('home_PriceSub')}</Typography>

                        </div>
                        <img src={priceIcon} style={{ height: 49, marginRight: 40 }}></img>
                    </div>

                    <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                        <div>
                            <Typography style={{ fontSize: 20, }} className={classes.fontStyle}>{t('home_VIPTitle')}</Typography>
                            <Typography style={{ fontSize: 16, color: '#8B96A7', fontWeight: '500' }} className={classes.fontStyle}>{t('home_VIPSub')}</Typography>

                        </div>
                        <img src={vipIcon} style={{ height: 49, marginRight: 40 }}></img>
                    </div>


                </div>
            </div>
        </div>
    )
}

export default withTranslation()(ProcessSection)
