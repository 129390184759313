import { withTranslation } from "react-i18next";
import { Button, makeStyles, Modal, Typography } from "@material-ui/core";
import React, { useEffect, useState } from "react";
import otcLogo from "../../../images/otcLogo.png";
import './Agreement.css'
import x from "../../../images/closePopIcon.png";
import EnAgreement from "./EnAgreement";
import { motion } from "framer-motion"
import HBPLinkIcon from "../../../images/HBPLinkIcon.png";

const Agreement = (props) => {
    const {
        onCancel: handleClose,
        modalVisible,
        t,
        phone,
        width
    } = props;
    const useStyles = makeStyles((theme) => ({
        modal: {
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            textAlign: 'center',
        }
    }));
    const classes = useStyles();

    const agreementBody = (
        <motion.div
            initial={{ opacity: 0, scale: 0 }}
            animate={{ opacity: 1, scale: 1 }}
        >


            <div style={{
                backgroundColor: 'white', borderRadius: 20, width: '80%', maxWidth: '820px', maxHeight: 'min(80%,600px)', overflow: 'auto', outline: 'none'
            }}>

                <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', marginLeft: phone ? "10%" : 40, marginRight: phone ? "10%" : 40, marginTop: 40, marginBottom: 16 }}>
                    {/* <Typography className={classes.font} style={{ fontSize: phone ? 16 : 22, fontWeight: 300, lineHeight: phone ? '10px' : '30px', color: "black", }}>
                        {t('serviceAgreementTitle')}
                    </Typography> */}
                    <img style={{ width: 160 }} src={HBPLinkIcon}></img>
                    <Button
                        style={{
                            padding: 0,
                            minHeight: 0,
                            minWidth: 0,
                        }}
                        onClick={handleClose}
                    >
                        <img alt='' src={x} style={phone ? { height: 24, width: 24 } : { height: 32, width: 32 }} />
                    </Button>

                </div>
                <div style={{ marginTop: phone ? 15 : 15, boxSizing: 'border-box', height: 1, border: '1px solid #DDDDDD', marginLeft: phone ? "10%" : 40, marginRight: phone ? "10%" : 40 }} />

                <div style={{ marginLeft: phone ? "10%" : 40, marginRight: phone ? "10%" : 40, marginTop: 16 }}>
                    <Typography style={{ textAlign: 'left', marginTop: 24, marginBottom: 24, fontFamily: 'Avenir', fontSize: phone ? 14 : 24, fontWeight: 500, letterSpacing: 0, lineHeight: phone ? '11px' : '35px' }}>
                        {t('serviceAgreementTitle')}
                    </Typography>
                    <div className={'agreementContent'} style={{ marginBottom: 40, backgroundColor: '#F1F0F0', borderRadius: 16, maxHeight: phone ? 266 : 346, overflow: 'auto' }}>
                        <EnAgreement status={1} />
                    </div>


                </div>
            </div >
        </motion.div>
    );

    return (
        <Modal
            open={modalVisible}
            onClose={() => {
                handleClose()
            }}
            className={classes.modal}
            aria-labelledby="simple-modal-title"
            aria-describedby="simple-modal-description"
            BackdropProps={{
                class:
                    'modalBackground'
            }}
        >
            {agreementBody}
        </Modal>
    )
}


export default withTranslation()(Agreement);