import React, {useEffect} from 'react';
import './Jump.css';
import { useHistory } from 'react-router-dom';
import {Typography} from '@material-ui/core';
import {withTranslation} from 'react-i18next';
import {kycActions} from "../../redux/actions/kycActions";
import {useDispatch, useSelector} from "react-redux";
import { useQuery } from "../../utils/UseQuery";
import {authActions} from "../../redux/actions/authActions";


function Jump({t}) {
    const dispatch = useDispatch();
    const { loggedIn, userInfo, loading, token } = useSelector(state => state.auth)
    const { kycDetails, fetched, documentDetails } = useSelector(state => state.kyc)
    const history = useHistory();
    const query = useQuery();

    useEffect(() => {
        console.log('token: ', token)
        dispatch(kycActions.getKycDetails(token))
        dispatch(kycActions.getDocumentDetails(token))
        dispatch(authActions.checkUser())
        return () => {
        }
    }, []);

    useEffect(() => {
        let transactionReference = query.get('transactionReference') === null ? '' : query.get('transactionReference')
        let customerInternalReference = query.get('customerInternalReference') === null ? '' : query.get('customerInternalReference')
        if (loggedIn && userInfo.id !== undefined) {
            console.log('checking user id', loggedIn, kycDetails, customerInternalReference, userInfo.id)
            if (userInfo.id === Number(customerInternalReference)) {
                dispatch(kycActions.syncKycStatus(token))
                dispatch(kycActions.syncAddressStatus(token))
                if (kycDetails.status === 'inited') {
                    let payload = {
                        customerInternalReference,
                        transactionReference,
                        status: 'inReview'
                    }
                    dispatch(kycActions.updateKycStatus(token, payload))
                }
            } else {
                dispatch(authActions.logOut())
                history.push('/trade')
            }
        }

        return () => {
        }
    }, [loggedIn, kycDetails, userInfo]);

    useEffect(() => {
        dispatch(kycActions.getKycDetails(token))
    }, [fetched, loggedIn])

    useEffect(() => {
        console.log('login status: ', loading, loggedIn)
        if (!loading && fetched === true && (kycDetails.status !== 'inited')) {
            console.log('not finding : ', kycDetails.status)
            history.push('/trade')
        }
        if (loading === false && loggedIn === false && kycDetails.status !== 'idPassed') {
            history.push('/login?redirect=true')
        }
    }, [kycDetails, loading, fetched, loggedIn]);

    return (
        <div className='redirect-container'>
            <Typography style={{  }}>{t('redirecting')}</Typography>
        </div>
    );
}

export default withTranslation()(Jump);
