import React from 'react'

import './ContactUs.css'
import Checkbox from '@material-ui/core/Checkbox';
import FormGroup from '@material-ui/core/FormGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import TextField from '@material-ui/core/TextField';
import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import emailjs from 'emailjs-com';
import { withTranslation } from 'react-i18next';

import { toast } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'

toast.configure()
function ContactUs({ t }) {


    const [isValidEmailAddress, setIsValidEmailAddress] = React.useState(true)
    const handleSetIsValidEmailAddress = (isValid) => {
        setIsValidEmailAddress(isValid)
    }

    const [firstNameState, setFirstNameState] = React.useState('')

    const handleFirstNameInput = (event) => {
        setFirstNameState(event.target.value)
    }


    const [lastNameState, setLastNameState] = React.useState('')

    const handleLastNameInput = (event) => {
        setLastNameState(event.target.value)
    }

    const [emailState, setEmailState] = React.useState('')

    const handleEmailInput = (event) => {
        checkIsValidEmailAddress(event.target.value)
        setEmailState(event.target.value)
    }

    function checkIsValidEmailAddress(address) {
        if (address !== ''){
            if (address.match(/.+@.+/)) {
                handleSetIsValidEmailAddress(true)
            } else {
                handleSetIsValidEmailAddress(false)
            }
        } else {
            handleSetIsValidEmailAddress(true)
        }

    }

    const [phoneState, setPhoneState] = React.useState('')

    const handlePhoneInput = (event) => {
        setPhoneState(event.target.value)
    }


    const handleSendEmail = (event) => {

        event.preventDefault();

        const templateParams = {
            firstName: firstNameState,
            lastName: lastNameState,
            email: emailState,
            telephone: phoneState,
            transaction: transaction,
            BTC: coinState.BTC,
            ETH: coinState.ETH,
            USDT: coinState.USDT,
            Others: coinState.Others,
        }
        resetAllInput()


        emailjs.send('OTC_service', 'OTC_Template', templateParams, 'user_5SNLv4jbZDYixNY2cyL5I')
            .then((result) => {
                toast.success(t('toast_Success'))
            }, (error) => {
                toast.error(t('toast_Error'))

                console.log(error.text);
            });

    }



    function resetAllInput() {
        toast.info(t('toast_Sending'))

        setCoinState({
            BTC: false,
            ETH: false,
            USDT: false,
            Others: false
        })

        setFirstNameState('')
        setLastNameState('')
        setEmailState('')
        setPhoneState('')
        setTransaction('')
    }




    const [transaction, setTransaction] = React.useState('');
    const handleSelectChange = (event) => {
        setTransaction(event.target.value);
    };


    const [coinState, setCoinState] = React.useState({
        BTC: false,
        ETH: false,
        USDT: false,
        Others: false
    });

    const handleCheckBoxChange = (event) => {
        setCoinState({ ...coinState, [event.target.name]: event.target.checked });
    };
    return (
        <>
            <div className="contact__row"
                style={{ display: 'flex', flexDirection: 'column' }}
            >
                <div className="contact__header">

                    <div className="contact__textWrapper">
                        <h1 className={'contact__heading'}>
                            {t("quote_Title")}
                        </h1>

                        {/* <p className={'contact__subtitle'}>
                            {t("quote_Subtitle")}
                        </p> */}
                    </div>


                </div>

                <div className="contact__fillInfoContainer" >

                    <div  >
                        <div className='contact__textInputAreaWrapper'>
                            <p className="contact__SectionTitle">{t('quote_ContactInfo')}</p>
                            <TextField value={firstNameState} id="outlined-basic" label={t('quote_firstName')} variant="outlined" type="text" name="firstName" margin='normal' fullWidth onChange={handleFirstNameInput} />
                            <TextField value={lastNameState} id="outlined-basic" label={t('quote_lastName')} variant="outlined" type="text" name="lastName" margin='normal' fullWidth onChange={handleLastNameInput} />
                            <TextField error={!isValidEmailAddress} helperText={isValidEmailAddress ? null :t('email_error')} value={emailState} id="outlined-basic" label={t('quote_email')} variant="outlined" type="email" name="email" margin='normal' fullWidth onChange={handleEmailInput} />
                            <TextField value={phoneState} id="outlined-basic" label={t('quote_telephone')} variant="outlined" type="text" name="telephone" margin='normal' fullWidth onChange={handlePhoneInput} />


                        </div>


                        <p className="contact__SectionTitle">{t('quote_detail')}</p>


                        <div>
                            <FormControl variant="outlined" className="contact__pickerWrapper" margin='normal'>
                                <InputLabel >{t('quote_transaction')}</InputLabel>

                                <Select
                                    labelId="transaction"
                                    value={transaction}
                                    onChange={handleSelectChange}
                                    label={t('quote_transaction')}
                                    name="transaction"
                                >
                                    <MenuItem value="None">
                                        <em>{t('quote_transaction_notSure')}</em>
                                    </MenuItem>
                                    <MenuItem value={"10k-100k"}> {t('quote_transaction_10k')}</MenuItem>
                                    <MenuItem value={"100k-500k"}>{t('quote_transaction_100k')}</MenuItem>
                                    <MenuItem value={"over 500k"}>{t('quote_transaction_500k')}</MenuItem>
                                </Select>
                            </FormControl>

                        </div>


                        <div className="contact__selectCoinContainer">
                            <p className="contact__subtitle-dark">{t('quote_coinType')}</p>
                            <FormGroup row className="contact__SelectionRow" m="12rem">

                                <FormControlLabel
                                    control={
                                        <Checkbox
                                            checked={coinState.BTC}
                                            onChange={handleCheckBoxChange}
                                            name="BTC"
                                            value={coinState.BTC}

                                        />
                                    }
                                    label="BTC"
                                />

                                <FormControlLabel
                                    control={
                                        <Checkbox
                                            checked={coinState.ETH}
                                            onChange={handleCheckBoxChange}
                                            name="ETH"
                                            value={coinState.ETH}

                                        // color="primary"
                                        />
                                    }
                                    label="ETH"
                                />

                                <FormControlLabel
                                    control={
                                        <Checkbox
                                            checked={coinState.USDT}
                                            onChange={handleCheckBoxChange}
                                            name="USDT"
                                            value={coinState.USDT}
                                        />
                                    }
                                    label="USDT"
                                />


                                <FormControlLabel
                                    control={
                                        <Checkbox
                                            checked={coinState.Others}
                                            onChange={handleCheckBoxChange}
                                            name="Others"
                                            value={coinState.Others}
                                        />
                                    }
                                    label="Others"
                                />



                            </FormGroup>
                        </div>

                        <button onClick={handleSendEmail} disabled={firstNameState !== "" && lastNameState !== "" && phoneState !== "" && emailState !== "" && isValidEmailAddress ? false : true} className={firstNameState !== "" && lastNameState !== "" && phoneState !== "" && emailState !== "" && isValidEmailAddress ? 'contact__getQuoteButton' : 'contact__getQuoteButton-disabled'}>
                            {t("quote_confirm")}
                        </button>



                    </div>
                </div>

            </div>


        </>


    )
}

export default withTranslation()(ContactUs)
