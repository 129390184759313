import React from 'react'

// import { Button } from '../Button'

// import { Link } from 'react-router-dom'
import { withTranslation } from 'react-i18next';
import { Typography, makeStyles, Button } from '@material-ui/core';

import './ProcessSection.css'
import dotIcon from "../../images/home/dotIcon.png"

import dotLine from "../../images/home/dotLine.png"
import offlineTradeIcon1 from "../../images/home/offlineTradeIcon1.png"
import offlineTradeIcon2 from "../../images/home/offlineTradeIcon2.png"
import offlineTradeIcon3 from "../../images/home/offlineTradeIcon3.png"
import offlineTradeIcon4 from "../../images/home/offlineTradeIcon4.png"
import cryptocurrency from "../../images/home/Cryptocurrency.svg"
import payment from "../../images/home/Payment.svg"



function ProcessSellSection2({ t, phone }) {
    const useStyles = makeStyles({
        fontStyle: {
            fontFamily: "Avenir", fontWeight: '500'
        }
    })
    const classes = useStyles();
    const cell = (title, image, subtitle) => {
        return (
            <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', width: '100%' }}>
                <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', width: '100%' }}>
                    <div style={{ height: 52, width: 52, borderRadius: 12, backgroundColor: '#F3F3F3' }}>
                        <img src={image}></img>
                    </div>
                    <div style={{ display: 'flex', flexDirection: 'column', marginLeft: 16, width: '80%', height: 108, justifyContent: 'center' }}>
                        <Typography className={classes.fontStyle} style={{ fontSize: 20 }}>{title}</Typography>
                        <Typography className={classes.fontStyle} style={{ fontSize: 16, color: '#8B96A7' }}>{subtitle}</Typography>

                    </div>
                </div>
                <div style={{ height: 1, backgroundColor: '#EAEAEA', width: '100%' }}></div>
            </div>
        )
    }

    const cellPhone = (title, image, subtitle, step) => {
        return (
            <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', width: '91%', marginTop: 16, backgroundColor: '#F9F9F9', paddingTop: 12, paddingBottom: 12, borderRadius: 12, }}>
                <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', width: '91%', justifyContent: 'space-between' }}>
                    <div style={{ height: 40, width: 40, borderRadius: 12, backgroundColor: '#F3F3F3' }}>
                        <img src={image}></img>
                    </div>
                    <div style={{ display: 'flex', flexDirection: 'column', marginLeft: 16, width: '84%', justifyContent: 'center' }}>
                        <Typography className={classes.fontStyle} style={{ fontSize: 14 }}>{step}. {title}</Typography>
                        <Typography className={classes.fontStyle} style={{ fontSize: 12, color: '#8B96A7' }}>{subtitle}</Typography>

                    </div>
                </div>
            </div>
        )
    }
    return phone ?
        (
            <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', flexDirection: 'column', marginTop: 64 }}>

                <div style={{ height: 1, width: '91%', backgroundColor: '#F2F2F2', marginBottom: 40 }}></div>


                <div style={{ height:100,overflow: 'hidden', alignItems: 'center', display: 'flex', flexDirection: 'row', marginTop: 32, width: '91%', marginTop: 16, backgroundColor: '#F9F9F9', paddingTop: 12, paddingBottom: 12, borderRadius: 12, justifyContent: 'space-between' }}>
                    <div style={{ width: '60%', marginLeft: '5%' }}>
                        <Typography className={classes.fontStyle} style={{ fontSize: phone ? 14 : 30, fontWeight: '500' }}>{t('home_offlineTitle')}</Typography>
                        <Typography className={classes.fontStyle} style={{ fontSize: phone ? 12 : 16, fontWeight: '500', color: '#8B96A7', marginTop: 0 }}>{t('home_offlineSub')}</Typography>
                    </div>
                    <div style={{ width: '25%' }}>
                        <img src={payment} style={{ height: '120%' }}></img>
                    </div>
                </div>
                {cellPhone(t('home_offstep1Title'), offlineTradeIcon1, t('home_offstep1Sub'), "1")}
                {cellPhone(t('home_offstep2Title'), offlineTradeIcon2, t('home_offstep2Sub'), "2")}
                {cellPhone(t('home_offstep3Title'), offlineTradeIcon3, t('home_offstep3Sub'), "3")}
                {cellPhone(t('home_offstep4Title'), offlineTradeIcon4, t('home_offstep4Sub'), "4")}
                <Button
                    onClick={() => {
                        window.scrollTo({
                            top: document.documentElement.scrollHeight,
                            behavior: 'smooth'
                        });
                    }} style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', width: '91%', height: 60, backgroundColor: '#3254FA', borderRadius: 16, marginTop: 32, textTransform: 'none' }}>

                    <Typography style={{ color: 'white', fontSize: 16 }} className={classes.fontStyle}> {t('home_offonlineButton')}</Typography>
                </Button>

            </div>


        )
        : (
            <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', flexDirection: 'column', marginTop: "6%" }}>



                <div style={{ width: '81%', display: 'flex', alignItems: 'center', justifyContent: 'flex-end', flexDirection: 'row', marginTop: 64 }}>
                    <div style={{ display: 'flex', alignItems: 'flex-start', flexDirection: 'column', width: '60%', marginLeft: 120 }}>
                        <div style={{ display: 'flex', alignItems: 'flex-start', flexDirection: 'column', marginLeft: "16%" }}>
                            <Typography className={classes.fontStyle} style={{ fontSize: 30, fontWeight: '500' }}>{t('home_offlineTitle')} </Typography>
                            <Typography className={classes.fontStyle} style={{ fontSize: 16, fontWeight: '500', color: '#8B96A7', marginTop: 0 }}>{t('home_offlineSub')}</Typography>
                        </div>
                        <div style={{ display: 'flex', flexDirection: "row", marginLeft: "16%" }}>
                            <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                                <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', width: 30, marginTop: 60 }}>
                                    <img style={{ height: 28 }} src={dotIcon}></img>

                                    <img style={{ height: 80, width: 1 }} src={dotLine}></img>
                                    <img style={{ height: 28 }} src={dotIcon}></img>
                                    <img style={{ height: 80, width: 1 }} src={dotLine}></img>
                                    <img style={{ height: 28 }} src={dotIcon}></img>
                                    <img style={{ height: 80, width: 1 }} src={dotLine}></img>
                                    <img style={{ height: 28 }} src={dotIcon}></img>


                                </div>
                                <div style={{ display: 'flex', flexDirection: 'column', width: 600, marginLeft: 40, marginTop: 18 }}>
                                    {cell(t('home_offstep1Title'), offlineTradeIcon1, t('home_offstep1Sub'))}
                                    {cell(t('home_offstep2Title'), offlineTradeIcon2, t('home_offstep2Sub'))}
                                    {cell(t('home_offstep3Title'), offlineTradeIcon3, t('home_offstep3Sub'))}
                                    {cell(t('home_offstep4Title'), offlineTradeIcon4, t('home_offstep4Sub'))}
                                    <Button style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', width: '50%', height: 60, backgroundColor: '#3254FA', borderRadius: 20, marginTop: 32, textTransform: 'none' }}
                                        onClick={() => {
                                            window.scrollTo({
                                                top: document.documentElement.scrollHeight,
                                                behavior: 'smooth'
                                            });
                                        }}
                                    >

                                        <Typography style={{ color: 'white', fontSize: 16 }} className={classes.fontStyle}> {t('home_offonlineButton')}</Typography>
                                    </Button>
                                </div>
                            </div>


                        </div>
                    </div>

                    <img style={{ width: '46%', position: 'absolute', left: -120, }} src={payment}></img>

                </div>


            </div >
        )
}

export default withTranslation()(ProcessSellSection2)
