import * as Url from '../../config/Url'
import { authHeader } from '../../utils/AuthHeader';
import { handleResponse } from '../../utils/HandleResponse'

export const tradeService = {
    tradePriceQuote,
    trade,
    getTradeTransactionByCoinType,
    tradeLivePrice,
    getAquaBalance,
    expireAuaQuote,
    getTicker,
    getPriceChange,
    getBannerInfo
};

async function tradePriceQuote( payload, token) {
    const requestOptions = {
        method: 'POST',
        headers: authHeader(token),
        body: JSON.stringify(payload)
    };

    return fetch(Url.TRADE_PRICE_QUOTE, requestOptions).then(handleResponse);
}

async function tradeLivePrice( payload) {
    const requestOptions = {
        method: 'POST',
        headers: authHeader(),
        body: JSON.stringify(payload)
    };

    return fetch(Url.TRADE_LIVE_PRICE, requestOptions).then(handleResponse);
}

async function trade( payload, token) {
    const requestOptions = {
        method: 'POST',
        headers: authHeader(token),
        body: JSON.stringify(payload)
    };

    return fetch(Url.TRADE, requestOptions).then(handleResponse);
}

async function getTradeTransactionByCoinType(token, payload) {
    const requestOptions = {
        method: 'POST',
        headers: authHeader(token),
        body: JSON.stringify(payload)
    };

    // console.log('token: ', token, payload)
    return fetch(Url.GET_TRANSACTION_BY_TYPE, requestOptions).then(handleResponse);
}

async function getAquaBalance(token, payload) {
    const requestOptions = {
        method: 'GET',
        headers: authHeader(token),
    };

    // console.log('token: ', token, payload)
    return fetch(Url.GET_AQUA_BALANCE + payload.coin, requestOptions).then(handleResponse);
}

async function expireAuaQuote(token, payload) {
    const requestOptions = {
        method: 'POST',
        headers: authHeader(token),
    };

    return fetch(Url.EXPIRE_AQUA_QUOTE+payload, requestOptions).then(handleResponse);
}

async function getTicker(payload, token) {
    const requestOptions = {
        method: 'GET',
        headers: authHeader(token),
    };
    return fetch(Url.GET_TICKER+`token1=${payload.token1}&token2=${payload.token2}`, requestOptions).then(handleResponse);
}

async function getPriceChange(payload, token) {
    const requestOptions = {
        method: 'GET',
        headers: authHeader(token),
    };
    return fetch(Url.GET_TICKER+`token1=${payload.token1}&token2=${payload.token2}`, requestOptions).then(handleResponse);
}

async function getBannerInfo(token) {
    const requestOptions = {
        method: 'GET',
        headers: authHeader(token),
    };
    return fetch(Url.GET_BANNER_INFO, requestOptions).then(handleResponse);
}
