import React, {useEffect, useState} from 'react'
import {withTranslation} from "react-i18next";
import {useDispatch, useSelector} from "react-redux";
import {authActions} from "../../redux/actions/authActions";
import {Button, makeStyles, Typography} from '@material-ui/core';
import passed from '../../images/passed.png';
import KycVerified from "../../images/kycVerified.png";
import KycExpired from '../../images/kycExpired.png'
import "react-datepicker/dist/react-datepicker.css";
import "./UserProfile.css";
import KycModal from "../Kyc/KycModal";
import {useHistory} from "react-router-dom";
import {isEnglishKeyboard} from "../../utils/Checker";
import {kycActions} from "../../redux/actions/kycActions";
import TextKycStatus from '../KycStatus/TextKycStatus'
import NameIcon from "../../images/NameIcon.png"
import DoBIcon from "../../images/DoBIcon.png"
import idIcon from "../../images/idIcon.png"
import personalInfoIcon from "../../images/personalInfoIcon.png"
import addressInfoIcon from "../../images/addressInfoIcon.png"
import {Helmet} from "react-helmet";

import {convertDateString} from "../../utils/Common";

function UserProfile({ t, width }) {
    const dispatch = useDispatch();
    const { token, userInfo, updateInfoSuccess } = useSelector(state => state.auth)
    const [phone, setPhone] = useState(width <= 745);
    const [loaded, setLoaded] = useState(false)
    const [updateInfoVisible, setUpdateInfoVisible] = useState(false);
    const [updateSuccessVisible, setUpdateSuccessVisible] = useState(false);
    const [kycVisible, setKycVisible] = useState(false);
    const [initialing, setInitialing] = useState(true);
    const [personalInfo, setPersonalInfo] = useState({
        firstName: '',
        lastName: '',
        dateOfBirth: '',
        occupation: '',
        employerName: '',
    });
    const [contactInfo, setContactInfo] = useState({
        phone: '',
        address: '',
        country: '',
        province: '',
        city: '',
        postalCode: '',
    });
    const [focuses, setFocuses] = useState({
        firstName: false,
        lastName: false,
        dateOfBirth: false,
        occupation: false,
        employerName: false,
        phone: false,
        address: false,
        country: false,
        province: false,
        city: false,
        postalCode: false,
    })

    const [countryRegionColor, setCountryRegionColor] = useState({ country: '#9097A1', province: "#9097A1" })

    const updateDisable = () => {
        let disable = true
        for (let input in personalInfo) {
            if (personalInfo[input]) {
                disable = false
            }
        }
        for (let input in contactInfo) {
            if (input != 'province' && input != 'country') {
                if (contactInfo[input]) {
                    disable = false
                }
            }
        }
        if (contactInfo.province != userInfo.province && contactInfo.province != '') {
            disable = false
        }
        return disable
    }


    const useStyles = makeStyles({

        inputLabel: {
            marginBottom: 6, textAlign: 'initial', fontFamily: 'Avenir', fontSize: 16, fontWeight: 500, letterSpacing: 0, lineHeight: '22px'
        },
        inputLabelPhone: {
            marginBottom: 5, textAlign: 'initial', fontFamily: 'Avenir', fontSize: 10, fontWeight: 500, letterSpacing: 0, lineHeight: '14px'
        },
        input: {
            borderRadius: 16, backgroundColor: 'white', width: '100%', height: '55px', fontSize: 16, padding: 20, fontWeight: 500, border: "1px solid #EDEDED", fontFamily: 'Avenir',
        },
        inputPhone: {
            borderRadius: 12, backgroundColor: 'white', width: '100%', height: '38.5px', fontSize: 10, padding: 14, fontWeight: 500, border: "1px solid #EDEDED", fontFamily: 'Avenir',
        },
        datePicker: {
            borderRadius: 16, backgroundColor: '#F1F0F0', width: '-webkit-fill-available', height: '55px', fontSize: 16, padding: '0px 20px', border: 0, fontWeight: 500, fontFamily: 'Avenir',
        },
        datePickerPhone: {
            borderRadius: 12, backgroundColor: '#F1F0F0', width: '-webkit-fill-available', height: '38.5px', fontSize: 10, padding: '0px 14px', border: 0, fontWeight: 500, fontFamily: 'Avenir',
        },
        button: {
            width: '100%',
            height: '58px',
            borderRadius: 16,
            color: 'white !important',
            backgroundColor: '#081647',
            fontFamily: 'Avenir',
            fontSize: 18,
            fontWeight: 500,
            letterSpacing: 0,
            lineHeight: '25px',
            textTransform: 'none',
            "&:hover": {
                backgroundColor: '#081647',
                // Reset on touch devices, it doesn't add specificity
                "@media (hover: none)": {
                    backgroundColor: '#081647',
                    "&:active": {
                        backgroundColor: '#081647'
                    }
                },
                '&$disabled': {
                    backgroundColor: '#081647',
                },
            }
        },
        buttonPhone: {
            width: '100%',
            height: '48px',
            borderRadius: 10,
            color: 'white !important',
            backgroundColor: '#081647',
            fontFamily: 'Avenir',
            fontSize: 12,
            fontWeight: 500,
            letterSpacing: 0,
            lineHeight: '16px',
            textTransform: 'none',
            "&:hover": {
                backgroundColor: '#081647',
                // Reset on touch devices, it doesn't add specificity
                "@media (hover: none)": {
                    backgroundColor: '#081647',
                    "&:active": {
                        backgroundColor: '#081647'
                    }
                },
                '&$disabled': {
                    backgroundColor: '#081647',
                },
            }
        }
    });
    const classes = useStyles();
    const history = useHistory();

    const { kycDetails, fetched, documentDetails } = useSelector(state => state.kyc)

    const handlePersonalInfoChange = (prop) => (event) => {
        if (isEnglishKeyboard(event.target.value)) {
            setPersonalInfo({ ...personalInfo, [prop]: event.target.value });
        }
    };

    const handleContactInfoChange = (prop) => (event) => {
        if (isEnglishKeyboard(event.target.value)) {
            setContactInfo({ ...contactInfo, [prop]: event.target.value });
        }
    };
    const handleCountryRegionChange = (prop, e) => {
        if (prop === 'country') {
            setContactInfo({ ...contactInfo, ['country']: e, ['province']: '' });
            if (userInfo.country === e) {
                setCountryRegionColor({ ...countryRegionColor, ['country']: '#9097A1' })
            } else {
                setCountryRegionColor({ country: 'black', province: 'black' });
            }

        }
        if (prop === 'province') {
            setContactInfo({ ...contactInfo, ['province']: e });
            if (userInfo.province === e) {
                setCountryRegionColor({ ...countryRegionColor, ['province']: '#9097A1' })
            } else {
                setCountryRegionColor({ ...countryRegionColor, ['province']: 'black' })
            }
        }
    }

    useEffect(() => {
        setPhone(width <= 745)
        return () => {
        }
    }, [width]);

    useEffect(() => {
        dispatch(authActions.getUserInfo(token))
        dispatch(kycActions.syncKycStatus(token))
        setLoaded(true)
        return () => {
        }
    }, []);

    useEffect(() => {
        if (fetched) {
            dispatch(kycActions.getKycDetails(token))
        }
    }, [fetched])

    useEffect(() => {
        if (updateInfoSuccess) {
            dispatch(authActions.getUserInfo(token))
            setUpdateSuccessVisible(true)
            setPersonalInfo({ firstName: '', lastName: '', dateOfBirth: '', occupation: '', employerName: '' })
            setContactInfo({ phone: '', address: '', country: '', province: '', city: '', postalCode: '' })
            dispatch(authActions.clearUpdateUserInfoState())
        }
        return () => {
        }
    }, [updateInfoSuccess])

    useEffect(() => {
        if (initialing) {
            setInitialing(false)
        } else {
            setContactInfo({ ...contactInfo, ['country']: userInfo.country, ['province']: userInfo.province })
        }
        return () => {
        }
    }, [userInfo])

    const renderPersonalInfo = () => {
        let noEdit = kycDetails.status === 'passed' || kycDetails.status === 'inReview' || documentDetails.status === 'inReview'
        return (
            <div style={{ backgroundColor: noEdit ? '#F9F9F9' : "#FFF8F9", display: 'flex', flexDirection: 'column', padding: '24px', borderRadius: "24px", marginTop: 24 }}>

                <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>
                    <Typography style={{ color: '#858E9D', fontSize: phone ? 12 : 22, fontWeight: 500, fontFamily: 'Avenir', }}>{t("Identity")}</Typography>
                    {loaded ? <TextKycStatus phone={phone} setKycVisible={setKycVisible} mode={1} /> : null}
                </div>

                <div style={{ backgroundColor: noEdit ? '#DDDDDD' : "#DC3055", height: '1px', width: '100%', marginTop: 16 }} />

                <div style={{ display: 'flex', flexDirection: 'row', marginTop: "24px", alignItems: 'center' }}>

                    <img alt='' src={NameIcon} style={phone ? { height: 13, width: 13 } : { height: 32, width: 32 }} />
                    <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-start', marginLeft: 20 }}>
                        <Typography style={{ color: '#858E9D', fontSize: phone ? "12px" : "16px", fontWeight: '500', fontFamily: 'Avenir', }}>{t("fullName")}</Typography>
                        <Typography style={{ color: '#1C2237', fontSize: phone ? "12px" : "14px", fontWeight: '500', fontFamily: 'Avenir', }}>{userInfo.firstName} {userInfo.lastName}</Typography>
                    </div>

                </div>

                <div style={{ display: 'flex', flexDirection: 'row', marginTop: "24px", alignItems: 'center' }}>

                    <img alt='' src={DoBIcon} style={phone ? { height: 13, width: 13 } : { height: 32, width: 32 }} />
                    <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-start', marginLeft: 20 }}>
                        <Typography style={{ color: '#858E9D', fontSize: phone ? "12px" : "16px", fontWeight: '500', fontFamily: 'Avenir', }}>{t("dob")}</Typography>
                        <Typography style={{ color: '#1C2237', fontSize: phone ? "12px" : "14px", fontWeight: '500', fontFamily: 'Avenir', }}>{convertDateString(userInfo.dateOfBirth)}</Typography>
                    </div>

                </div>

                <div style={{ display: 'flex', flexDirection: 'row', marginTop: "24px", alignItems: 'center' }}>
                    <img alt='' src={idIcon} style={phone ? { height: 13, width: 13 } : { height: 32, width: 32 }} />

                    <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-start', marginLeft: 20 }}>
                        <Typography style={{ color: '#858E9D', fontSize: phone ? "12px" : "16px", fontWeight: '500', fontFamily: 'Avenir', }}>{t("id")}</Typography>
                        <Typography style={{ color: '#1C2237', fontSize: phone ? "12px" : "14px", fontWeight: '500', fontFamily: 'Avenir', }}>{kycDetails.idNumber ? kycDetails.idNumber : "N/A"}</Typography>
                    </div>
                </div>

                {loaded ? <TextKycStatus phone={phone} setKycVisible={setKycVisible} /> : null}
            </div>
        )
    }

    const renderAddressInfo = () => {
        return (
            <div style={{ backgroundColor: '#F9F9F9', display: 'flex', flexDirection: 'column', padding: '24px', borderRadius: "24px", marginTop: 32, marginBottom: 60 }}>



                <div style={{ display: "flex", justifyContent: "space-between", alignItems: 'center' }}>
                    <Typography align={'left'} className={classes.font} style={{ color: '#858E9D', fontSize: phone ? 12 : 22, fontWeight: 500, fontFamily: 'Avenir', }}>{t('addressInfo')}</Typography>
                    <img src={addressInfoIcon} style={{ width: phone ? 28 : 46, marginRight: phone ? 6 : 12 }} />
                </div>
                <div style={{ backgroundColor: '#DDDDDD', height: '1px', width: '100%', marginTop: 16 }} />


                <div style={{ display: 'flex', flexDirection: 'row', marginTop: "24px" }}>
                    <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-start' }}>
                        <Typography style={{ color: '#858E9D', fontSize: phone ? "12px" : "16px", fontWeight: '500', fontFamily: 'Avenir', }}>{t('homeAddress')}</Typography>
                        <Typography style={{ color: '#1C2237', fontSize: phone ? "12px" : "14px", fontWeight: '500', textAlign: 'start', fontFamily: 'Avenir', }}>{userInfo.address}, {userInfo.city}, {userInfo.province}, {userInfo.country}, {userInfo.postalCode}</Typography>

                    </div>
                </div>

                <Button
                    className={phone ? classes.buttonPhone : classes.button}
                    style={{ marginTop: phone ? 32 : 60 }}

                    onClick={() => {
                        history.push({
                            pathname: '/user',
                            state: { viewMode: 1 }
                        })
                    }}
                >
                    {t('updateInfo')}
                </Button>

            </div>
        )
    }

    const renderContactInfo = () => {
        return (
            <div style={{ backgroundColor: '#F9F9F9', display: 'flex', flexDirection: 'column', padding: '24px', borderRadius: "24px", marginTop: 32, marginBottom: 60 }}>



                <div style={{ display: "flex", justifyContent: "space-between", alignItems: 'center' }}>
                    <Typography align={'left'} className={classes.font} style={{ color: '#858E9D', fontSize: phone ? 12 : 22, fontWeight: 500, fontFamily: 'Avenir', }}>{t('personalInfo')}</Typography>
                    <img src={personalInfoIcon} style={{ width: phone ? 28 : 46, marginRight: phone ? 6 : 12 }} />
                </div>
                <div style={{ backgroundColor: '#DDDDDD', height: '1px', width: '100%', marginTop: 16 }} />

                <div style={{ display: 'flex', flexDirection: 'row', marginTop: "24px" }}>
                    <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-start' }}>
                        <Typography style={{ color: '#858E9D', fontSize: phone ? "12px" : "16px", fontWeight: '500', fontFamily: 'Avenir', }}>{t("occupation")}</Typography>
                        <Typography style={{ color: '#1C2237', fontSize: phone ? "12px" : "14px", fontWeight: '500', fontFamily: 'Avenir', }}>{userInfo.occupation}</Typography>

                    </div>
                </div>

                <div style={{ display: 'flex', flexDirection: 'row', marginTop: "24px" }}>
                    <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-start' }}>
                        <Typography style={{ color: '#858E9D', fontSize: phone ? "12px" : "16px", fontWeight: '500', fontFamily: 'Avenir', }}>{t("employerNameText")}</Typography>
                        <Typography style={{ color: '#1C2237', fontSize: phone ? "12px" : "14px", fontWeight: '500', fontFamily: 'Avenir', }}>{userInfo.employerName == "" ? "N/A" : userInfo.employerName}</Typography>
                    </div>

                </div>



                <Button
                    className={phone ? classes.buttonPhone : classes.button}
                    style={{ marginTop: phone ? 32 : 60 }}

                    onClick={() => {
                        history.push({
                            pathname: '/user',
                            state: { viewMode: 2 }
                        })
                    }}

                >
                    {t('updateInfo')}
                </Button>

            </div>
        )
    }



    const renderKyc = () => {
        return (
            <div>
                <Typography
                    style={
                        phone ? { marginTop: 30, color: "#BAC3CE", fontFamily: "Avenir", fontSize: 16, fontWeight: 500, letterSpacing: 0, lineHeight: '22px', textAlign: 'initial' }
                            : { marginTop: 53, color: "#BAC3CE", fontFamily: "Avenir", fontSize: 24, fontWeight: 500, letterSpacing: 0, lineHeight: '35px', textAlign: 'initial' }
                    }
                >
                    {t("identity")}
                </Typography>
                <div style={{ marginTop: phone ? 18 : 22 }}>
                    <Typography className={phone ? classes.inputLabelPhone : classes.inputLabel}>
                        {t("identityForKyc")}
                    </Typography>
                    <Button
                        style={
                            phone ? {
                                borderRadius: 12,
                                backgroundColor: '#F1F0F0',
                                width: '100%',
                                height: '38.5px',
                                fontSize: 10,
                                padding: 14,
                                justifyContent: "space-between",
                                textTransform: "none"
                            }
                                : {
                                    borderRadius: 16,
                                    backgroundColor: '#F1F0F0',
                                    width: '100%',
                                    height: '55px',
                                    fontSize: 16,
                                    padding: 20,
                                    justifyContent: "space-between",
                                    textTransform: "none"
                                }
                        }
                        disabled={true}
                    >
                        <Typography
                            style={
                                phone ? { fontFamily: "Avenir", color: "#9097A1", fontSize: 10, fontWeight: '500' }
                                    : { fontFamily: "Avenir", color: "#9097A1", fontSize: 16, fontWeight: '500' }
                            }
                        >
                            {kycDetails.idNumber === null ? null : `${kycDetails.idCountry === null ? null : kycDetails.idCountry} ${kycDetails.idType === null ? null : kycDetails.idType} ${kycDetails.idNumber}`}
                        </Typography>
                        <div style={{ display: "flex" }}>
                            <Typography
                                style={
                                    phone ? { fontFamily: "Avenir", color: kycDetails.status === 'passed' ? "#4D8C7B" : '#DC3055', fontSize: 10, fontWeight: 500, margin: "auto" }
                                        : { fontFamily: "Avenir", color: kycDetails.status === 'passed' ? "#4D8C7B" : '#DC3055', fontSize: 16, fontWeight: 500 }
                                }
                            >
                                {kycDetails.status === 'passed' ? t("verified") : t('expired')}
                            </Typography>
                            <img
                                alt=''
                                src={kycDetails.status === 'passed' ? KycVerified : KycExpired}
                                style={
                                    phone ? { width: 18, height: 18, margin: "auto 0px auto 6.3px" }
                                        : { width: 22.4, height: 22.4, margin: "auto 0px auto 9px" }
                                }
                            />
                        </div>
                    </Button>
                </div>
            </div>
        )
    }

    return (

        <div style={{ width: '100%', display: 'flex', alignItems: 'center', flexDirection: 'column' }}>
            <Helmet>
                <title>{t('titleUser')}</title>

                <meta name="keywords" content="Toronto, OTC, bitcoin, exchange, cryptocurrency, 多伦多, 场外交易平台, 比特币, 以太坊, 加元, 换汇，加密货币, 人民币,多倫多, 場外交易平台, 比特幣, 以太坊, 加元, 換匯, 人民幣, Yesbit, Fiat, CAD, BTC, ETH, USD, balance, user profile" />
            </Helmet>
            <div style={{ width: '91%', alignItems: 'center', textAlign: 'center', maxWidth: 543, minWidth: 317 }}>
                {/* {loaded ? <KycStatus phone={phone} setKycVisible={setKycVisible} /> : null} */}
                <Typography
                    style={
                        phone ? { marginTop: 40, color: "#1C2237", fontSize: 16, fontWeight: 500, letterSpacing: 0, lineHeight: '22px', textAlign: 'initial', fontFamily: 'Avenir', }
                            : { marginTop: 60, color: "#1C2237", fontSize: 24, fontWeight: 500, letterSpacing: 0, lineHeight: '35px', textAlign: 'initial', fontFamily: 'Avenir', }
                    }
                >
                    {t("profile")}
                </Typography>

                {renderPersonalInfo()}
                {renderAddressInfo()}
                {renderContactInfo()}
                {/* {kycDetails.status === 'passed' || kycDetails.status === 'expired' ? renderKyc() : null} */}



            </div>
            {/*KYC弹窗*/}
            <KycModal
                onCancel={() => {
                    setKycVisible(false);
                }}
                modalVisible={kycVisible}
                t
                phone={phone}
                width={width}
                userInfo={userInfo}
            />
        </div>
    )
}
export default withTranslation()(UserProfile)
