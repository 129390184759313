import { authService } from '../services/authServices';
import {
    CHECK_EXIST,
    CHECK_FAILED,
    CHECK_SUCCEED,

    LOGIN,
    LOGIN_SUCCEED,
    LOGIN_FAILED,
    CLEAR_LOGIN_STATE,
    LOGOUT,

    SIGNUP,
    SIGNUP_SUCCEED,
    SIGNUP_FAILED,
    CLEAR_SIGNUP_STATE,

    GET_USER_INFO,
    GET_USER_INFO_SUCCEED,
    GET_USER_INFO_FAILED,

    UPDATE_USER_INFO,
    UPDATE_USER_INFO_FAILED,
    UPDATE_USER_INFO_SUCCEED,
    CLEAR_UPDATE_INFO_STATE,

    CHECK_USER,
    CHECK_USER_FAILED,
    CHECK_USER_SUCCEED,
    CLEAR_CHECK_USER_STATE,

    RESET_PASSWORD,
    RESET_PASSWORD_FAILED,
    RESET_PASSWORD_SUCCEED,
    CLEAR_RESET_PASSWORD_STATE,

    UPDATE_LOGIN_PASSWORD,
    UPDATE_LOGIN_PASSWORD_FAILED,
    UPDATE_LOGIN_PASSWORD_SUCCEED,
    CLEAR_UPDATE_LOGIN_PASSWORD,

    SEND_EMAIL_CODE,
    SEND_EMAIL_CODE_SUCCEED,
    SEND_EMAIL_CODE_FAILED,

    GET_LATEST_ARTICLE,
    GET_LATEST_ARTICLE_SUCCEED,
    GET_LATEST_ARTICLE_FAILED, GET_USER_CAPITALS_SUCCEED,
    GET_OAUTH_TOKEN,
    GET_OAUTH_TOKEN_SUCCEED,
    GET_OAUTH_TOKEN_FAILED, CLEAR_DEPOSIT
} from '../constants';


export const authActions = {
    checkUser,
    signUp,
    clearSignUpState,
    logIn,
    clearLoginState,
    logOut,
    getUserInfo,
    updateUserInfo,
    clearUpdateUserInfoState,
    sendVerificationCode,
    checkEmail,
    clearCheckEmailState,
    resetPassword,
    clearResetPasswordState,
    updateLoginPassword,
    clearUpdateLoginPassword,
    getLatestArticles,
    getOAUTHToken
}

function checkUser() {
    return dispatch => {
        const token = localStorage.getItem("otcuser")
        if(token && token != ""){
            dispatch(request(token));
            authService.getUserInfo(token)
                .then(
                    res => {
                        if (res) {
                            dispatch(success(res.data));
                        }
                    },
                    error => {
                        dispatch(failure());
                    }
                );
        }else{
            dispatch(failure());
        }
    };

    function request(token) { return { type: CHECK_EXIST, token } }
    function success(data) { return { type: CHECK_SUCCEED, data } }
    function failure() { return { type: CHECK_FAILED } }
}

function signUp(payload) {
    return dispatch => {
        dispatch(request());
        authService.signUp(payload)
            .then(
                res => {
                    if (res) {
                        dispatch(success());
                        dispatch(logIn(payload))
                    }
                },
                error => {
                    if (error.message === 'Sign up failed, email has been used.') {
                        dispatch(failure('emailUsed'));
                    } else if( error.message === 'Sign up failed, wrong email code.'){
                        dispatch(failure('wrongVerificationCode'))
                    } else if( error.message === 'Invalid password.'){
                        dispatch(failure('invalidPassword'))
                    } else if( error.message === 'Invalid username.') {
                        dispatch(failure('invalidUsername'))
                    } else if( error.message === "Invalid referral code"){
                        dispatch(failure('invalidReferralCode'))
                    } else {
                        dispatch(failure('serverError'));
                    }
                }
            );
    };

    function request() { return { type: SIGNUP } }
    function success() { return { type: SIGNUP_SUCCEED } }
    function failure(message) { return { type: SIGNUP_FAILED, message } }

}
function clearSignUpState() {
    return dispatch =>{
        dispatch(request());
    };
    function request(){return {type: CLEAR_SIGNUP_STATE}}
}

function logIn(payload) {
    return dispatch => {
        dispatch(request());
        authService.logIn(payload)
            .then(
                res => {
                    dispatch(success(res));
                    localStorage.setItem("otcuser", res.data)
                },
                error => {
                    if (error.message === 'Wrong net work') {
                        dispatch(failure('serverError'));
                    } else if(error.message === 'Bad credentials'){
                        dispatch(failure('wrongEmailOrPassword'));
                    } else if(error.message === 'User does not exist.'){
                        dispatch(failure('wrongEmailOrPassword'));
                    } else {
                        dispatch(failure('serverError'));
                    }
                }
            );
    };

    function request() { return { type: LOGIN } }
    function success(data) { return { type: LOGIN_SUCCEED, data } }
    function failure(message) { return { type: LOGIN_FAILED, message } }
}
function clearLoginState() {
    return dispatch =>{
        dispatch(request());
    };
    function request(){return {type: CLEAR_LOGIN_STATE}}
}

function logOut() {
    return dispatch => {
        dispatch(request());
        dispatch(clear(false))
        authService.logOut();
    };
    function request() { return { type: LOGOUT } }
    function clear(token) { return { type: CLEAR_DEPOSIT, token } }
}

function getUserInfo(token) {
    return dispatch => {
        dispatch(request());
        authService.getUserInfo(token)
            .then(
                res => {
                    dispatch(success(res.data));
                },
                error => {
                    dispatch(failure(error));
                }
            );
    };

    function request() { return { type: GET_USER_INFO } }
    function success(data) { return { type: GET_USER_INFO_SUCCEED, data } }
    function failure(message) { return { type: GET_USER_INFO_FAILED, message } }
}

function updateUserInfo(payload,token) {
    return dispatch => {
        dispatch(request());
        authService.updateUserInfo(payload, token)
            .then(
                res => {
                    dispatch(success());
                },
                error => {
                    console.log('update failed: ', error)
                    dispatch(failure(error.message));
                }
            );
    };

    function request() { return { type: UPDATE_USER_INFO } }
    function success() { return { type: UPDATE_USER_INFO_SUCCEED } }
    function failure(message) { return { type: UPDATE_USER_INFO_FAILED, message } }
}

function clearUpdateUserInfoState() {
    return dispatch =>{
        dispatch(request());
    };
    function request(){return {type: CLEAR_UPDATE_INFO_STATE}}
}

function sendVerificationCode(payload){
    return dispatch =>{
        dispatch(request());
        authService.sendVerificationCode(payload)
            .then(
                res=>{
                    dispatch(success())
                },
                error=>{
                    dispatch(failure('emailNotRegistered'))
                }
            )
    }
    function request() { return { type: SEND_EMAIL_CODE } }
    function success() { return { type: SEND_EMAIL_CODE_SUCCEED } }
    function failure(message) { return { type: SEND_EMAIL_CODE_FAILED, message } }
}

function checkEmail(payload){
    return dispatch =>{
        dispatch(request());
        authService.checkUser(payload)
            .then(
                res=>{
                    dispatch(success())
                },
                error=>{
                    if (error.message === 'User does not exist.'){
                        dispatch(failure('emailNotRegistered'))
                    }else{
                        dispatch(failure('serverError'))
                    }
                }
            )
    }
    function request() { return { type: CHECK_USER } }
    function success() { return { type: CHECK_USER_SUCCEED } }
    function failure(message) { return { type: CHECK_USER_FAILED, message } }
}
function clearCheckEmailState() {
    return dispatch =>{
        dispatch(request());
    };
    function request(){return {type: CLEAR_CHECK_USER_STATE}}
}

function resetPassword(payload){
    return dispatch =>{
        dispatch(request());
        authService.resetPassword(payload)
            .then(
                res=>{
                    dispatch(success())
                },
                error=>{
                    if (error.message === 'Update password failed, wrong email code.') {
                        dispatch(failure('wrongVerificationCode'));
                    } else {
                        dispatch(failure('serverError'));
                    }
                }
            )
    }
    function request() { return { type: RESET_PASSWORD } }
    function success() { return { type: RESET_PASSWORD_SUCCEED } }
    function failure(message) { return { type: RESET_PASSWORD_FAILED, message} }
}

function clearResetPasswordState() {
    return dispatch =>{
        dispatch(request());
    };
    function request(){return {type: CLEAR_RESET_PASSWORD_STATE}}
}

function updateLoginPassword(payload,token){
    return dispatch =>{
        dispatch(request());
        authService.updateLoginPassword(payload,token)
            .then(
                res=>{
                    dispatch(success())
                },
                error=>{
                    dispatch(failure(error.message));
                }
            )
    }
    function request() { return { type: UPDATE_LOGIN_PASSWORD } }
    function success() { return { type: UPDATE_LOGIN_PASSWORD_SUCCEED } }
    function failure(message) { return { type: UPDATE_LOGIN_PASSWORD_FAILED, message} }
}

function clearUpdateLoginPassword() {
    return dispatch =>{
        dispatch(request());
    };
    function request(){return {type: CLEAR_UPDATE_LOGIN_PASSWORD}}
}

function getLatestArticles(token) {
    return dispatch =>{
        dispatch(request());
        authService.getLatestArticles(token)
            .then(
                res=>{
                    dispatch(success(res))
                },
                error=>{
                    console.log('get article err: ', error)
                    dispatch(failure(error.message));
                }
            )
    }
    function request(token) { return { type: GET_LATEST_ARTICLE, token } }
    function success(data) { return { type: GET_LATEST_ARTICLE_SUCCEED, data } }
    function failure(message) { return { type: GET_LATEST_ARTICLE_FAILED, message} }
}

function getOAUTHToken() {
    return dispatch =>{
        dispatch(request());
        authService.getOAUTHToken()
            .then(
                res=>{
                    console.log('get oauth token res: ', res)
                    dispatch(success(res))
                },
                error=>{
                    console.log('get oauth token err: ', error)
                    dispatch(failure(error));
                }
            )
    }
    function request(token) { return { type: GET_OAUTH_TOKEN, token } }
    function success(data) { return { type: GET_OAUTH_TOKEN_SUCCEED, data } }
    function failure(message) { return { type: GET_OAUTH_TOKEN_FAILED, message} }
}
