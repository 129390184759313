import { combineReducers } from 'redux';
import { auth } from './auth'
import { kyc } from "./kyc";
import { funds } from "./funds";
import { trade } from "./trade";
import {referral} from "./referral"
import {nft} from "./nft"

const rootReducer = combineReducers({
    auth, kyc, funds, trade, referral, nft
});

export default rootReducer;
