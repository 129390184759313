import {
    GET_REFERRALS,
    GET_REFERRALS_SUCCEED,
    GET_REFERRALS_FAILED,
    GET_USERNAME_BY_REFERRAL_CODE,
    GET_USERNAME_BY_REFERRAL_CODE_SUCCEED,
    GET_USERNAME_BY_REFERRAL_CODE_FAILED,
    CLEAR_REFERRER_USERNAME
} from '../constants';

export function referral (state = {
    referrals:{
        list:[],
        page:0,
        size:10,
        isEnd:true,
        total:0
    },
    referrer:""
}, action) {
    switch (action.type) {
        case GET_REFERRALS:
            return {
                ...state,
                getReferralsLoading: true
            }
        case GET_REFERRALS_SUCCEED:
            return {
                ...state,
                referrals: action.data,
                getReferralsLoading: false
            }
        case GET_REFERRALS_FAILED:
            return {
                ...state,
                getReferralsLoading: false
            }

        case GET_USERNAME_BY_REFERRAL_CODE:
            return {
                ...state,
                getUsernameByReferralCodeLoading: true
            }
        case GET_USERNAME_BY_REFERRAL_CODE_SUCCEED:
            return {
                ...state,
                referrer: action.data+' ',
                getUsernameByReferralCodeLoading: false
            }
        case GET_USERNAME_BY_REFERRAL_CODE_FAILED:
            return {
                ...state,
                referrer: "",
                getUsernameByReferralCodeLoading: false
            }
        case CLEAR_REFERRER_USERNAME:
            return{
                ...state,
                referrer:""
            }

        default:
            return state;
    }
};
