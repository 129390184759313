import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import navLogo from '../../images/nav/NavLogo.svg';
import { withTranslation } from 'react-i18next';
import { Button, Typography } from '@material-ui/core';
import i18n from '../../i18n';
import phoneOtcLogo from "../../images/nav/phoneOtcLogo.png";
import otcNewIcon from '../../images/otcNewIcon.png';
import LungIcon from '../../images/LungIcon.png';

import yesbitLogo from '../../images/yesbitLogo.png';

function PlatformFooter({ t, width }) {
    const [phone, setPhone] = useState(width <= 745);
    const changeLanguage = (e) => {
        let newLang = i18n.language === 'en' ? 'cn' : 'en'
        i18n.changeLanguage(newLang);
        localStorage.setItem('lng', newLang)
    }

    useEffect(() => {
        setPhone(width <= 745)
    }, [width]);

    return phone ?
        (
            <div style={{ width: '100%', display: 'flex', alignItems: 'center', flexDirection: 'column', minWidth: 317, backgroundColor: '#fbfbfb' }}>
                <div style={{ padding: phone ? '5px 0px 27px 0px' : '24px 0', width: '95%', display: 'flex', alignItems: 'center', flexDirection: 'row', flexWrap: 'wrap', marginTop: 16 }}>
                    <div style={{ marginRight: 0, display: 'flex', alignItems: 'center', }}>
                        <Link to='/'>
                            <img alt='' src={otcNewIcon} style={{ width: 32 }} />
                        </Link>

                    </div>

                    <Button onClick={changeLanguage} style={{
                        display: 'inline-block',
                        padding: 0,
                        minHeight: 0,
                        minWidth: 0, borderRadius: 0, height: '100%',
                        marginLeft: 16, marginRight: 16
                    }}>

                        <Typography style={{ fontSize: 12, fontWeight: 500, color: '#1C2237' }}>{t('lang')}</Typography>

                    </Button>
                    <Button onClick={() => { window.open("https://otcdocs.yesbit.io/v/english/faq/cryptocurrencies") }} style={{
                        display: 'inline-block',
                        padding: 0,
                        minHeight: 0,
                        minWidth: 0, marginRight: 0, borderRadius: 0, height: '100%', textTransform: 'none'
                    }}>

                        <Typography style={{ fontSize: 12, fontWeight: 500, color: '#1C2237' }}>{t('cryptoCurrencies')}</Typography>

                    </Button>

                </div>
                <div style={{ height: 1, backgroundColor: '#E6E6E6', width: "95%" }}></div>
                <div style={{ padding: phone ? '5px 0px 27px 0px' : '24px 0', width: '95%', display: 'flex', alignItems: 'center', flexDirection: 'column', flexWrap: 'wrap', justifyContent: 'space-between', marginTop: 16 }}>
                    <div style={{ display: 'flex', alignItems: 'center', marginBottom:16}}>

                        <span style={{ fontFamily: "Avenir", fontSize: phone ? 12 : 14, fontWeight: 500, color: '#9097A1', marginLeft: 8 }}>

                            <span style={{ color: 'black' }}> FINTRAC MSB # M22187471</span>
                        </span>
                        <div style={{ paddingLeft: 8, paddingRight: 8, paddingTop: 4, paddingBottom: 4, backgroundColor: 'white', marginLeft: 8, borderRadius: 6 }}>
                            <img style={{ width: 40 }} src={yesbitLogo}></img>
                        </div>
                    </div>
                    <div style={{ marginRight: 24, display: 'flex', alignItems: 'center', }}>

                        <Typography style={{ fontSize: phone ? 12 : 14, fontWeight: 500, color: '#9097A1', marginLeft: 8 }}>{`© ${new Date().getFullYear()} Yesbit. All rights reserved.`}</Typography>
                    </div>

                </div>
            </div>
        )
        :
        (
            <div style={{ width: '100%', display: 'flex', alignItems: 'center', flexDirection: 'column', minWidth: 317, backgroundColor: '#fbfbfb' }}>
                <div style={{ padding: phone ? '5px 0px 27px 0px' : '24px 0', width: '95%', display: 'flex', alignItems: 'center', flexDirection: 'row', flexWrap: 'wrap', }}>
                    <div style={{ marginRight: 24, display: 'flex', alignItems: 'center', }}>
                        <Link to='/'>
                            <img alt='' src={otcNewIcon} style={{ width: phone ? 24 : 40 }} />
                        </Link>
                        <Typography style={{ fontSize: phone ? 12 : 14, fontWeight: 500, color: '#9097A1', marginLeft: 8 }}>{t('signUp_subtitle')}</Typography>
                    </div>

                    <Button onClick={changeLanguage} style={{ borderRadius: 0, height: '100%', marginLeft: 16, marginRight: 16, }}>
                        <img alt='' src={LungIcon} style={{ width: 48, height: 48, backgroundColor: '##F6F7FA', borderRadius: 14 }} />

                        <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'center', textTransform: 'none' }}>
                            <Typography style={{ fontSize: phone ? 12 : 14, fontWeight: 500, color: '#1C2237' }}>{t('lang')}</Typography>
                        </div>
                    </Button>
                    <Button onClick={() => { window.open("https://otcdocs.yesbit.io/v/english/faq/cryptocurrencies") }} style={{ marginRight: 0, borderRadius: 0, height: '100%', textTransform: 'none' }}>

                        <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'center', marginLeft: 16, marginRight: 16 }}>
                            <Typography style={{ fontSize: phone ? 12 : 14, fontWeight: 500, color: '#1C2237' }}>{t('cryptoCurrencies')}</Typography>
                        </div>
                    </Button>

                </div>
                <div style={{ height: 1, backgroundColor: '#E6E6E6', width: "95%" }}></div>
                <div style={{ padding: phone ? '5px 0px 27px 0px' : '24px 0', width: '95%', display: 'flex', alignItems: 'center', flexDirection: 'row', flexWrap: 'wrap', justifyContent: 'space-between' }}>
                    <div style={{ marginRight: 24, display: 'flex', alignItems: 'center', }}>

                        <Typography style={{ fontSize: phone ? 12 : 14, fontWeight: 500, color: '#9097A1', marginLeft: 8 }}>{`© ${new Date().getFullYear()} Yesbit. All rights reserved.`}</Typography>
                    </div>

                    <div style={{ display: 'flex', alignItems: 'center', }}>

                        <span style={{ fontFamily: "Avenir", fontSize: phone ? 12 : 14, fontWeight: 500, color: '#9097A1', marginLeft: 8 }}>

                            <span style={{ color: 'black' }}> FINTRAC MSB # M22187471</span>
                        </span>
                        <div style={{ paddingLeft: 8, paddingRight: 8, paddingTop: 4, paddingBottom: 4, backgroundColor: 'white', marginLeft: 8, borderRadius: 6 }}>
                            <img style={{ width: 40 }} src={yesbitLogo}></img>
                        </div>
                    </div>

                </div>
            </div>
        )
}

export default withTranslation()(PlatformFooter);
