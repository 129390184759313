import { withTranslation } from "react-i18next";
import { Avatar, Button, Fade, IconButton, makeStyles, Modal, Snackbar, Typography, Dialog } from "@material-ui/core";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import success from "../../../images/success.png";
import x from "../../../images/closePopIcon.png";
import { motion } from "framer-motion"
import enterArrow from "../../../images/enterArrow.png";


const UpdateSuccess = (props) => {
    const {
        onCancel: handleClose,
        modalVisible,
        t,
        phone,
        width
    } = props;
    const useStyles = makeStyles((theme) => ({
        modal: {
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            textAlign: 'center',
        },
        button: {
            alignSelf: 'center',

            height: '65px',
            borderRadius: 20,
            color: 'white',
            backgroundColor: '#0173F9',
            fontFamily: 'Avenir',
            fontSize: 18,
            fontWeight: 500,
            letterSpacing: 0,
            lineHeight: '25px',
            textTransform: 'none',
            "&:hover": {
                backgroundColor: '#081647',
                // Reset on touch devices, it doesn't add specificity
                "@media (hover: none)": {
                    backgroundColor: '#081647',
                    "&:active": {
                        backgroundColor: '#162E83'
                    }
                },
                '&$disabled': {
                    backgroundColor: '#162E83',
                },
            }
        },
        buttonPhone: {
            width: '100%',
            height: '50px',
            borderRadius: 14,
            color: 'white',
            backgroundColor: '#162E83',
            fontFamily: 'Avenir',
            fontSize: 14,
            fontWeight: 500,
            letterSpacing: 0,
            lineHeight: '16px',
            textTransform: 'none',
            "&:hover": {
                backgroundColor: '#081647',
                // Reset on touch devices, it doesn't add specificity
                "@media (hover: none)": {
                    backgroundColor: '#081647',
                    "&:active": {
                        backgroundColor: '#081647'
                    }
                },
                '&$disabled': {
                    backgroundColor: '#162E83',
                },
            }
        },
    }));
    const classes = useStyles();

    const updateSuccessBody = (
        <motion.div
            initial={{ opacity: 0, scale: 0 }}
            animate={{ opacity: 1, scale: 1 }}
        >
            <div style={{
                backgroundColor: 'white', borderRadius: 20, width: phone ? " 89vw" : "606px", overflow: 'auto', outline: 'none'
            }}>

                <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', marginTop: 40, marginLeft: 32, marginRight: 32 }}>
                    <Typography style={{ fontSize: phone ? 16 : 24, fontWeight: 500, color: '#1C2237', fontFamily: 'Avenir', }}>
                        {t('updateInfo')}
                    </Typography>

                    <Button
                        style={{
                            padding: 0,
                            minHeight: 0,
                            minWidth: 0,
                        }}
                        onClick={handleClose}
                    >
                        <img alt='' src={x} style={phone ? { height: 24, width: 24 } : { height: 32, width: 32 }} />
                    </Button>

                </div>
                <div style={{ marginTop: phone ? 15 : 15, marginBottom: 15, boxSizing: 'border-box', height: 1, border: '1px solid #DDDDDD', marginLeft: 32, marginRight: 32 }} />

                <div style={{ margin: 'min(10%,30px)' }}>
                    <img alt='' src={success} style={phone ? { width: 30, height: 30 } : { width: 60, height: 60 }}></img>
                    {/* <Typography style={{ marginTop: phone ? 14 : 19, fontFamily: 'Avenir', fontSize: phone ? 14 : 24, fontWeight: 500, letterSpacing: 0, lineHeight: phone ? '11px' : '35px' }}>
                        {t('updatedSuccessfully')}
                    </Typography> */}
                    <Typography style={{ marginTop: phone ? 8 : 24, fontFamily: 'Avenir', color: "#9097A1", fontSize: phone ? 12 : 16, fontWeight: 500, letterSpacing: 0, lineHeight: phone ? '11px' : '19px' }}>
                        {t('updateSuccessSubtitle')}
                    </Typography>

                    {/* <Button
                    style={{marginTop:phone?60:45, fontFamily:'Avenir', fontSize: phone?12:18, fontWeight:900, letterSpacing:0, lineHeight:phone?'11px':'25px'}}
                    onClick={handleClose}
                >
                    <img alt='' src={x} style={phone ? {height:7.36, width:7.36, marginRight:7.25}:{height:12.27, width:12.27, marginRight:12}}/>
                    {t("close")}
                </Button> */}

                    <div style={{ display: 'flex', justifyContent: 'end', }}>
                        <Button
                            className={phone ? classes.buttonPhone : classes.button}
                            style={{ marginTop: phone ? 40 : 60 }}
                            onClick={handleClose}

                        >
                            <Typography className={classes.font} style={{ fontSize: phone ? 14 : 18, color: 'white', fontWeight: 500, marginLeft: 12, marginRight: 12, lineHeight: phone ? '11px' : '25px' }}>{t("close")}</Typography>
                            <img style={{ height: phone ? 16 : 20 }} src={enterArrow}></img>
                        </Button>
                    </div>
                </div>
            </div >
        </motion.div>
    );

    return (
        <Modal
            open={modalVisible}
            // open={true}


            onClose={() => {
                handleClose()
            }}
            className={classes.modal}
            aria-labelledby="simple-modal-title"
            aria-describedby="simple-modal-description"
            BackdropProps={{
                class:
                    'modalBackground'
            }}
        >
            {updateSuccessBody}
        </Modal>
    )
}


export default withTranslation()(UpdateSuccess);