import {withTranslation} from "react-i18next";
import {
    Avatar,
    Button,
    InputAdornment,
    InputBase,
    makeStyles,
    MenuItem,
    Modal,
    Select,
    Typography
} from "@material-ui/core";
import React, {useEffect, useState} from "react";
import {useDispatch, useSelector} from "react-redux";
import {kycActions} from "../../../redux/actions/kycActions";
import {checkEmail, isEnglishKeyboard} from "../../../utils/Checker";
import ReactDatePicker from "react-datepicker";
import backIcon from "../../../images/backIcon.png"
import {authActions} from "../../../redux/actions/authActions";
import {convertDateString, getIcons} from "../../../utils/Common";
import CAD from '../../../images/funds/cad.png'
import USA from '../../../images/funds/usa.png'

import secureIcon from '../../../images/secure.png'
import {motion} from "framer-motion"
import x from "../../../images/closePopIcon.png";
import vouchersBlack from '../../../images/nft/voucherBlack.png'
import enterArrow from "../../../images/enterArrow.png";
import {nftActions} from "../../../redux/actions/nftActions";
import VoucherTransferResultModal from "./VoucherTransferResultModal";
import TwoFARequired from "./TwoFARequired";

const VoucherTransferModal = (props) => {
    const {
        onCancel: handleClose,
        modalVisible,
        t,
        phone,
        width,
        setVoucherTransferVisible
    } = props;
    const [step, setStep] = useState(1)
    const [transferInfo, setTransferInfo] = useState({
        email: '',
        amount: '',
        emailCode: '',
        phoneCode: '',
    });
    const { token, userInfo, emailCodeSent, emailCodeMsg } = useSelector(state => state.auth)
    const { transferVoucherLoading, transferVoucherSuccess, transferVoucherFailed, transferVoucherMsg } = useSelector(state => state.nft)
    const { phoneCodeSent } = useSelector(state => state.kyc)
    const [startCounting, setStartCounting] = useState(false)
    const [counter, setCounter] = useState(30);
    const [phoneCodeStatus, setPhoneCodeStatus] = useState(0)
    const [phoneCodeMsg, setPhoneCodeMsg] = useState('')

    const [startEmailCounting, setStartEmailCounting] = useState(false)
    const [emailCounter, setEmailCounter] = useState(30);
    const [emailCodeStatus, setEmailCodeStatus] = useState(0)
    const [localEmailCodeMsg, setLocalEmailCodeMsg] = useState('')
    const [nextDisabled, setNextDisabled] = useState(true)
    const [transferDisabled, setTransferDisabled] = useState(true)

    const [resultVisible, setResultVisible] = useState(false);
    const [resultSuccess, setResultSuccess] = useState(false)
    const [resultInfo, setResultInfo] = useState({})


    const useStyles = makeStyles((theme) => ({
        indCircalActive: {
            height: phone ? 24 : 40, width: phone ? 24 : 40, backgroundColor: 'black', borderRadius: 20, display: 'flex', alignItems: 'center', justifyContent: 'center'
        },
        indCircalTextActive: {
            fontSize: phone ? 12 : 16, fontWeight: "500", color: 'white', fontFamily: 'Avenir'
        },
        indCircalInactive: {
            height: phone ? 24 : 40, width: phone ? 24 : 40, backgroundColor: '#D8D8D8', borderRadius: 20, display: 'flex', alignItems: 'center', justifyContent: 'center'
        },
        indCircalTextInactive: {
            fontSize: phone ? 12 : 16, fontWeight: "500", color: 'white', fontFamily: 'Avenir'
        },
        modal: {
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            textAlign: 'center',
        },
        font: {
            fontFamily: "Avenir",
            fontWeight: 500,
            letterSpacing: 0,
            textAlign: 'left'
        },
        inputLabel: {
            color: '#9097A1', marginBottom: 6, textAlign: 'initial', fontFamily: 'Avenir', fontSize: 16, fontWeight: 500, letterSpacing: 0, lineHeight: '22px'
        },
        inputLabelPhone: {
            color: '#9097A1', marginBottom: 5, textAlign: 'initial', fontFamily: 'Avenir', fontSize: 14, fontWeight: 500, letterSpacing: 0, lineHeight: '14px'
        },
        input: {
            borderRadius: 16, backgroundColor: 'white', width: '100%', height: '55px', fontSize: 16, padding: 20, border: "1px solid #EDEDED"
        },
        inputPhone: {
            borderRadius: 12, backgroundColor: 'white', width: '100%', height: '45px', fontSize: 14, padding: 14, border: "1px solid #EDEDED"
        },
        button: {
            // width: '100%',
            height: '65px',
            borderRadius: 20,
            color: 'white !important',
            backgroundColor: '#0173F9',
            fontFamily: 'Avenir',
            fontSize: 18,
            fontWeight: 900,
            letterSpacing: 0,
            lineHeight: '25px',
            textTransform: 'none',
            "&:hover": {
                backgroundColor: '#0173F9',
                // Reset on touch devices, it doesn't add specificity
                "@media (hover: none)": {
                    backgroundColor: '#0173F9',
                    "&:active": {
                        backgroundColor: '#0173F9'
                    }
                },
                '&$disabled': {
                    backgroundColor: '#0173F9',
                },
            },
        },
        buttonPhone: {
            height: '48.3px',
            borderRadius: 12,
            color: 'white !important',
            backgroundColor: '#0173F9',
            fontFamily: 'Avenir',
            fontSize: 12,
            fontWeight: 900,
            letterSpacing: 0,
            lineHeight: '16px',
            textTransform: 'none',
            "&:hover": {
                backgroundColor: '#0173F9',
                // Reset on touch devices, it doesn't add specificity
                "@media (hover: none)": {
                    backgroundColor: '#0173F9',
                    "&:active": {
                        backgroundColor: '#0173F9'
                    }
                },
                '&$disabled': {
                    backgroundColor: '#0173F9',
                },
            },
        },
    }));
    const classes = useStyles();
    const dispatch = useDispatch();

    useEffect(() => {
        if (transferVoucherLoading === false) {
            if (transferVoucherFailed) {
                setStep(1)
                setTransferInfo({...transferInfo,
                    emailCode: '',
                    phoneCode: '',
                })
                handleClose()
                setResultInfo({msg:t(transferVoucherMsg)})
                setResultSuccess(false)
                setResultVisible(true)
                dispatch(nftActions.clearTransferVoucherState())
            } else if (transferVoucherSuccess) {
                close()
                setResultInfo({email:transferInfo.email, amount:transferInfo.amount});
                setResultSuccess(true);
                setResultVisible(true);
                dispatch(nftActions.clearTransferVoucherState())
            }
        }
        return () => {
        }
    }, [transferVoucherLoading])

    useEffect(() => {
        if (phoneCodeStatus === 1 && emailCodeStatus === 1 && transferInfo.emailCode != '' && transferInfo.phoneCode != ''){
            setTransferDisabled(false)
        }
        else{
            setTransferDisabled(true)
        }
    }, [transferInfo.emailCode, transferInfo.phoneCode,phoneCodeStatus,emailCodeStatus]);

    useEffect(() => {
        if (!checkEmail(transferInfo.email) || transferInfo.amount === '' || transferInfo.amount > userInfo.voucher){
            setNextDisabled(true)
        }
        else{
            setNextDisabled(false)
        }
    }, [transferInfo.email, transferInfo.amount]);

    useEffect(() => {
        if (startCounting) {
            counter > 0 && setTimeout(() => setCounter(counter - 1), 1000);
        }

        if (counter === 0) {
            setStartCounting(false)
            setCounter(30)
        }
    }, [counter, startCounting]);

    useEffect(() => {
        if (startEmailCounting) {
            emailCounter > 0 && setTimeout(() => setEmailCounter(emailCounter - 1), 1000);
        }

        if (emailCounter === 0) {
            setStartEmailCounting(false)
            setEmailCounter(30)
        }
    }, [emailCounter, startEmailCounting]);

    useEffect(() => {
        if (transferInfo.phoneCode === '') {
            setPhoneCodeMsg(t(''))
            setPhoneCodeStatus(0)
        } else {
            if (!phoneCodeSent) {
                setPhoneCodeMsg(t('clickSend'))
                setPhoneCodeStatus(2)
            } else {
                if (transferInfo.phoneCode.length !== 6) {
                    setPhoneCodeMsg(t('invalidPhoneCode'))
                    setPhoneCodeStatus(2)
                } else {
                    setPhoneCodeStatus(1)
                    setPhoneCodeMsg('')
                }
            }
        }
    }, [transferInfo.phoneCode, phoneCodeSent])

    useEffect(() => {
        if (transferInfo.emailCode === '') {
            setLocalEmailCodeMsg(t(''))
            setEmailCodeStatus(0)
        } else {
            if (!emailCodeSent) {
                setLocalEmailCodeMsg(t('clickSend'))
                setEmailCodeStatus(2)
            } else {
                if (emailCodeMsg !== '') {
                    setLocalEmailCodeMsg(emailCodeMsg)
                }
                if (transferInfo.emailCode.length !== 6) {
                    setLocalEmailCodeMsg(t('invalidEmailCode'))
                    setEmailCodeStatus(2)
                } else {
                    setEmailCodeStatus(1)
                    setLocalEmailCodeMsg('')
                }
            }
        }
    }, [transferInfo.emailCode, emailCodeSent, emailCodeMsg])

    const sendVerificationCode = () => {
        dispatch(kycActions.getVerificationCode(token))
        setStartCounting(true)
    }

    const sendEmailCode = () => {
        dispatch(authActions.sendVerificationCode(userInfo.username));
        setStartEmailCounting(true);
        setEmailCodeStatus(1)
    };

    const stepIndicatorView = (step) => {
        return (
            <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                <div className={classes.indCircalActive}>
                    <Typography className={classes.indCircalTextActive}>
                        1
                    </Typography>
                </div>
                <div style={{ height: 1, width: 40, backgroundColor: step == 1 || step == 2 ? 'black' : "#D8D8D8" }} />

                <div className={step == 2 || step == 3 ? classes.indCircalActive : classes.indCircalInactive}>
                    <Typography className={step == 2 || step == 3 ? classes.indCircalTextActive : classes.indCircalTextInactive}>
                        2
                    </Typography>
                </div>

            </div>
        )
    }

    const close = () =>{
        setStep(1)
        setTransferInfo({
            email: '',
            amount: '',
            emailCode: '',
            phoneCode: '',
        })
        handleClose()
    }

    const transfer = () =>{
        dispatch(nftActions.transferVoucher(transferInfo,token));
    }

    const KycModalBody1 = (
        <div style={{
            backgroundColor: 'white', borderRadius: 20, width: phone ? " 89vw" : "606px", overflow: 'hidden', outline: 0, display: 'flex', alignItems: 'center', flexDirection: 'column', height: phone ? 450 : 600
        }}>
            <div style={{ width: "90%", }}>
                <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', marginTop: phone ? 24 : 40}}>

                    {stepIndicatorView(1)}
                    <Button
                        style={{
                            padding: 0,
                            minHeight: 0,
                            minWidth: 0,
                        }}
                        onClick={close}
                    >
                        <img alt='' src={x} style={phone ? { height: 24, width: 24 } : { height: 32, width: 32 }} />
                    </Button>
                </div>
                <div style={{ marginTop: 15, marginBottom: 15, boxSizing: 'border-box', height: 1, border: '1px solid #DDDDDD', width: '100%' }} />
                <div style={{height:phone?300:360}}>
                    <Typography className={classes.font} style={{fontSize:phone?20:24, fontWeight:900}}>
                        {t('voucherTransferTitle1')}
                    </Typography>
                    <Typography className={classes.font} style={{fontSize:phone?12:14, color:"#9097A1", fontWeight:900}}>
                        {t('voucherTransferSubtitle1')}
                    </Typography>
                    <Typography className={phone?classes.inputLabelPhone:classes.inputLabel} style={{marginTop:30}}>
                        {t('voucherTransferEmail')}
                    </Typography>
                    <InputBase
                        className={phone ? classes.inputPhone : classes.input}
                        placeholder={t('voucherTransferEmailPlaceHolder')}
                        value={transferInfo.email}
                        style={{ color: 'black' }}
                        onChange={(e)=>{setTransferInfo({...transferInfo, email:e.target.value})}}
                    />
                    {
                        (!checkEmail(transferInfo.email) && transferInfo.email != '' )?
                            <Typography style={{ marginTop: phone ? 4 : 8, fontFamily: "Avenir", color: "#DC3055", fontSize: phone ? 12 : 14, fontWeight: 500, lineHeight: phone ? "11px" : '16px', textAlign: "left" }}>
                                {t('email_error')}
                            </Typography>
                            :null
                    }
                    <div style={{display:"flex", justifyContent:"space-between", marginTop:30}}>
                        <Typography className={phone?classes.inputLabelPhone:classes.inputLabel}>
                            {t('voucherTransferAmount')}
                        </Typography>
                        <Typography className={phone?classes.inputLabelPhone:classes.inputLabel}>
                            {t('voucherTransferAvailable')}: {userInfo.voucher} {t('nftVouchers')}
                        </Typography>
                    </div>

                    <InputBase
                        className={!phone ? classes.input : classes.inputPhone}
                        placeholder={t('voucherTransferAmountPlaceHolder')}
                        value={transferInfo.amount}
                        onChange={(e)=>{
                            let input = e.target.value ;
                            if( !input || ( input[input.length-1].match('[0-9]') && input[0].match('[1-9]')) )
                                setTransferInfo({...transferInfo, amount:e.target.value})}
                        }
                        endAdornment={
                            <InputAdornment position="end">
                                <Button
                                    style={{ display: "flex", justifyContent: 'center', alignItems: 'center' }}
                                    onClick={()=>{setTransferInfo({...transferInfo, amount:userInfo.voucher})}}
                                >
                                    <Typography style={{ fontSize: phone ? 14 : 16, fontWeight: '900', color: '#0173F9', textTransform:'none' }}>
                                        {t('max')}
                                    </Typography>
                                </Button>
                            </InputAdornment>
                        }
                        startAdornment={
                            phone?null:
                            <InputAdornment position="start">
                                <img src={vouchersBlack} style={{height:20, width:26}}/>
                            </InputAdornment>
                        }
                    />
                    {
                        transferInfo.amount > userInfo.voucher ?
                            <Typography style={{ marginTop: phone ? 4 : 8, fontFamily: "Avenir", color: "#DC3055", fontSize: phone ? 12 : 14, fontWeight: 500, lineHeight: phone ? "11px" : '16px', textAlign: "left" }}>
                                {t('insufficient_fund')}
                            </Typography>
                            :null
                    }
                </div>

                <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'flex-end', width: '100%', }}>
                    <Button
                        className={phone ? classes.buttonPhone : classes.button}
                        style={{ opacity:nextDisabled? 0.4:1}}
                        disabled={nextDisabled}
                        onClick={() => { setStep(2) }}
                    >
                        <Typography className={classes.font} style={{ fontSize: phone ? 14 : 18, color: 'white', fontWeight: 500, marginLeft: 12, marginRight: 12, lineHeight: phone ? '11px' : '25px' }}>{t('nextStep')}</Typography>
                        <img style={{ height: phone ? 16 : 20 }} src={enterArrow}></img>
                    </Button>
                </div>
            </div>

        </div >
    );

    const KycModalBody2 = (
        <div style={{
            backgroundColor: 'white', borderRadius: 20, width: phone ? " 89vw" : "606px", overflow: 'hidden', outline: 0, display: 'flex', alignItems: 'center', flexDirection: 'column', height: phone ? 450 : 600
        }}>
            <div style={{ width: "90%", }}>
                <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', marginTop: phone ? 24 : 40}}>

                    {stepIndicatorView(2)}
                    <Button
                        style={{
                            padding: 0,
                            minHeight: 0,
                            minWidth: 0,
                        }}
                        onClick={close}
                    >
                        <img alt='' src={x} style={phone ? { height: 24, width: 24 } : { height: 32, width: 32 }} />
                    </Button>
                </div>
                <div style={{ marginTop: 15, marginBottom: 15, boxSizing: 'border-box', height: 1, border: '1px solid #DDDDDD', width: '100%'}} />

                <div style={{height:phone?300:360}}>
                    <Typography className={classes.font} style={{fontSize:phone?20:24, fontWeight:900}}>
                        {t('voucherTransferTitle2')}
                    </Typography>
                    <Typography className={classes.font} style={{fontSize:phone?12:14, color:"#9097A1", fontWeight:900}}>
                        {t('voucherTransferSubtitle2')}
                    </Typography>
                    <div style={{display:"flex", justifyContent:"space-between", marginTop:30}}>
                        <Typography className={phone?classes.inputLabelPhone:classes.inputLabel}>
                            {t("emailCode")}
                        </Typography>
                        <Typography className={phone?classes.inputLabelPhone:classes.inputLabel} style={{fontSize:phone?12:14}}>
                            {`${userInfo.username.substring(0, 4)}****${userInfo.username.split('@')[1]}`}
                        </Typography>
                    </div>

                    <InputBase
                        className={!phone ? classes.input : classes.inputPhone}
                        placeholder={t('placeHolderVerificationCode')}
                        value={transferInfo.emailCode}
                        onChange={(e)=>{
                            setTransferInfo({...transferInfo, emailCode:e.target.value})
                        }}
                        endAdornment={
                            <InputAdornment position="end">
                                <Button
                                    style={{ display: "flex", justifyContent: 'center', alignItems: 'center' }}
                                    onClick={sendEmailCode}
                                    disabled={startEmailCounting}
                                >
                                    <Typography style={{ fontSize: phone ? 14 : 16, fontWeight: '900', color: '#0173F9', textTransform:'none' }}>
                                        {startEmailCounting ? emailCounter : t('sendCode')}
                                    </Typography>
                                </Button>
                            </InputAdornment>
                        }
                    />
                    {
                        emailCodeStatus === 0 ? null :
                            <Typography style={{ marginTop: phone ? 4 : 8, fontFamily: "Avenir", color: "#DC3055", fontSize: phone ? 12 : 14, fontWeight: 500, lineHeight: phone ? "11px" : '16px', textAlign: "left" }}>
                                {t(localEmailCodeMsg)}
                            </Typography>
                    }
                    <div style={{display:"flex", justifyContent:"space-between", marginTop:30}}>
                        <Typography className={phone?classes.inputLabelPhone:classes.inputLabel}>
                            {t('mobileCode')}
                        </Typography>
                        <Typography className={phone?classes.inputLabelPhone:classes.inputLabel} style={{fontSize:phone?12:14}}>
                            {userInfo.verificationPhone ? `${userInfo.verificationPhone.substring(0, 3)}****${userInfo.verificationPhone.substring(userInfo.verificationPhone.length - 3, userInfo.verificationPhone.length)}` :null}
                        </Typography>
                    </div>

                    <InputBase
                        className={!phone ? classes.input : classes.inputPhone}
                        placeholder={t('placeHolderVerificationCode')}
                        value={transferInfo.phoneCode}
                        onChange={(e)=>{
                            setTransferInfo({...transferInfo, phoneCode:e.target.value})
                        }}
                        endAdornment={
                            <InputAdornment position="end">
                                <Button
                                    style={{ display: "flex", justifyContent: 'center', alignItems: 'center' }}
                                    onClick={sendVerificationCode}
                                    disabled={startCounting}
                                >
                                    <Typography style={{ fontSize: phone ? 14 : 16, fontWeight: '900', color: '#0173F9', textTransform:'none' }}>
                                        {startCounting ? counter : t('sendCode')}
                                    </Typography>
                                </Button>
                            </InputAdornment>
                        }
                    />
                    {
                        phoneCodeStatus === 0 ? null :
                            <Typography style={{ marginTop: phone ? 4 : 8, fontFamily: "Avenir", color: "#DC3055", fontSize: phone ? 12 : 14, fontWeight: 500, lineHeight: phone ? "11px" : '16px', textAlign: "left" }}>
                                {t(phoneCodeMsg)}
                            </Typography>
                    }
                </div>


                <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', alignItems: 'center' }}>
                    <Button
                        onClick={() => {
                            setStep(1)
                        }}
                    >
                        <img src={backIcon} style={phone ? { height: 24, width: 24 } : { height: 40, width: 40 }} />
                    </Button>

                    <Button
                        className={phone ? classes.buttonPhone : classes.button}
                        style={{opacity:transferDisabled?0.4:1}}
                        disabled={transferDisabled}
                        onClick={transfer}
                    >
                        <Typography className={classes.font} style={{ fontSize: phone ? 14 : 18, color: 'white', fontWeight: 500, marginLeft: 12, marginRight: 12, lineHeight: phone ? '11px' : '25px' }}> {t('confirm')}</Typography>
                    </Button>
                </div>
            </div>

        </div >
    );

    return (
        <>
            <Modal
                open={modalVisible}
                // open={true}
                onClose={close}
                className={classes.modal}
                aria-labelledby="simple-modal-title"
                aria-describedby="simple-modal-description"
                BackdropProps={{
                    className:
                        'modalBackground'
                }}
            >
                <motion.div
                    initial={{ opacity: 0, scale: 0 }}
                    animate={{ opacity: 1, scale: 1 }}
                >
                    {
                        step === 1 ?
                            KycModalBody1
                            :
                            KycModalBody2
                    }
                </motion.div>
            </Modal>
            <VoucherTransferResultModal
                onCancel={() => {
                    setResultVisible(false);
                }}
                modalVisible={resultVisible}
                t
                phone={phone}
                width={width}
                success = {resultSuccess}
                resultInfo = {resultInfo}
                setVoucherTransferVisible = {(visible)=>{
                    setVoucherTransferVisible(visible)
                }}
            />
        </>
    )
}


export default withTranslation()(VoucherTransferModal);
