import React from 'react';
import {withTranslation} from 'react-i18next';
import notFound from '../../images/404.png'
import './NotFound.css';
import {useHistory} from "react-router-dom";


function NotFound({t}) {
    const history = useHistory();

    const goToHome = () => {
        history.push('/')
    }

    return (
        <div className='body'>
            <img className='bg'  src={notFound} />
            <h3 className='content'>{t('notFound')}</h3>
            <button onClick={goToHome} className='return'>
                <p className='buttonContent'>{t('home')}</p>
            </button>
        </div>
    );
}

export default withTranslation()(NotFound);
