import { withTranslation } from "react-i18next";
import { makeStyles, Typography } from "@material-ui/core";
import React from "react";

const EnAgreement = ({ status }) => {
    const useStyles = makeStyles((theme) => ({
        title: {
            fontSize: 14,
            fontFamily: 'Avenir',
            fontWeight: 900,
            color: '#1C2237',
            textAlign: 'left',
            whiteSpace: 'pre-line'
        },
        content: {
            fontSize: 14,
            fontFamily: 'Avenir',
            fontWeight: 500,
            color: '#1C2237',
            textAlign: 'left',
            whiteSpace: 'pre-line'
        }
    }));
    const classes = useStyles();
    return status === 1 ? (
        <div style={{ margin: 'min(33px,5%)', whiteSpace: 'pre-line' }}>
            <Typography className={classes.title}>
                Parties
            </Typography>
            <Typography className={classes.content}>
                The parties to this agreement are HPB LINK LTD. (referred to as "HPB" in this
                agreement), an Ontario corporation that is registered as a Virtual Currency Dealer with
                FINTRAC (registration #M22187471), and the person identified in the section of this
                agreement titled "Signature of Customer" (referred to as "Customer" in this agreement).
            </Typography>

            {"\n"}
            <Typography className={classes.title}>
                Customer Eligibility
            </Typography>
            <Typography className={classes.content}>

                Customer represents that they meet all of the following qualifications:
                {"\n"}1. not a "Politically Exposed Person" as defined in Canada's anti-money laundering legislation; and,
                {"\n"}2. not a representative, agent, employee, or in any way associated with any entity or person that has been sanctioned by the Government of Canada.

            </Typography>

            {"\n"}
            <Typography className={classes.title}>
                Onboarding And Anti-Money Laundering
            </Typography>
            <Typography className={classes.content}>
                As part of HPB's legal obligation to deter money laundering, Customer shall provide
                additional documentation and proof of identity according to HPB's current anti-money
                laundering policies and procedures. Furthermore, Customer shall provide notice to HPB
                if their eligibility status changes or if the information provided during the onboarding
                process changes. HPB reserves the right to make inquiries of third parties regarding
                Customer, including credit bureaus and other databases so as to ascertain Customer's
                identity and validate any information provided.
            </Typography>

            {"\n"}
            <Typography className={classes.title}>
                Purchase and Sale of Digital Currency
            </Typography>
            <Typography className={classes.content}>
                Customer shall have the option of tendering virtual currency (e.g. Bitcoin or ether)
                ("Digital Currency") to Yesbit for purchase or sale, at prices agreed to between the
                parties (each amount tendered shall be considered an "Order").
            </Typography>

            {"\n"}
            <Typography className={classes.title}>
                Means of Placing Orders
            </Typography>
            <Typography className={classes.content}>
                Customer may place Order(s) by email, text, phone, or any other means used by Yesbit,
                and any Order so received may be acted upon by Yesbit without additional formality.
            </Typography>

            {"\n"}
            <Typography className={classes.title}>
                No Obligation To Accept Orders
            </Typography>
            <Typography className={classes.content}>
                Yesbit shall not be required to accept any Order from a Customer, and any delay in
                accepting or rejecting an Order shall be at Customer's risk without any liability to Yesbit.
                In the event that Yesbit accepts an Order, it shall promptly inform Customer.
                {'\n'}
                {'\n'}
                Orders that are valued in excess of $10000 may be subject to additional rules and
                procedures, including, for example, a requirement to send a test amount before the full
                amount of the Order. Yesbit shall communicate any additional rules or procedures to
                Customer prior to accepting the applicable Order.
            </Typography>

            {"\n"}
            <Typography className={classes.title}>
                No Third Party Sales
            </Typography>
            <Typography className={classes.content}>
                Customer may only place orders on their own behalf and not for any third party.
            </Typography>

            {"\n"}
            <Typography className={classes.title}>
                Offered Prices Valid For Short Time
            </Typography>
            <Typography className={classes.content}>
                If Customer places an Order with Yesbit and Yesbit provides a price at which it is willing
                to accept the Order, that price shall only be valid for a period of up to 5 minutes, due to
                fluctuations in the prices of Digital Currencies.
            </Typography>

            {"\n"}
            <Typography className={classes.title}>
                You Buy It, You Own It
            </Typography>
            <Typography className={classes.content}>
                Legal title to your Digital Currency shall pass upon payment of the purchase price (in
                the case of purchases from Yesbit), or upon receiving the purchase price from Yesbit (in
                the case of sales to Yesbit).
                {'\n'}
                {'\n'}
                Due to the nature of Digital Currency, Yesbit is unable to offer refunds. Similarly, Yesbit
                does not permit exchanges or returns.

            </Typography>

            {"\n"}
            <Typography className={classes.title}>
                Transferring To Your Wallet
            </Typography>
            <Typography className={classes.content}>
                Yesbit is a registered virtual currency dealer and not a place suitable for long-term
                storage of Customer's Digital Currency. Customer shall promptly provide to Yesbit one
                or more blockchain addresses to which Customer's purchased Digital Currency shall be
                transferred to.
            </Typography>

            {"\n"}
            <Typography className={classes.title}>
                Payment Methods
            </Typography>
            <Typography className={classes.content}>
                Customer shall not be permitted to pay for Orders using physical currency.
            </Typography>

            {"\n"}
            <Typography className={classes.title}>
                Non-Reliance
            </Typography>
            <Typography className={classes.content}>
                Yesbit is not in the business of providing advice to its clients and Customer explicitly
                acknowledges that it shall not rely on any advice received from Yesbit regarding
                characteristics of Digital Currencies, technical security steps that they may wish to take
                with their personal devices, or any other types of information that may be incidentally
                provided as part of Yesbit's provision of services to Customer.
                {"\n"}
                {"\n"}
                Customer acknowledges that they have sufficient knowledge of Digital Currencies to
                make their own decisions regarding Digital Currencies and other types of intangible
                property, without relying on the expertise of Yesbit or its personnel.
            </Typography>

            {"\n"}
            <Typography className={classes.title}>
                No Claims for Delay
            </Typography>
            <Typography className={classes.content}>
                Yesbit shall not be responsible for any damages, claims or losses due to delay in
                accepting or carrying out an Order on behalf of Customer.
            </Typography>

            {"\n"}
            <Typography className={classes.title}>
                Limitation of Liability
            </Typography>
            <Typography className={classes.content}>
                Notwithstanding any term of this agreement, Yesbit's liability under any theory of law or
                cause of action shall be limited to the value of the sum of all Digital Currency Orders
                accepted but not yet moved to Customer’s private key(s).
            </Typography>

            {"\n"}
            <Typography className={classes.title}>
                Governing Law & Choice of Forum
            </Typography>
            <Typography className={classes.content}>
                Disputes between Customer and Yesbit shall be governed by the laws of Ontario,
                Canada, and the forum for any such disputes shall be the City of Toronto, Ontario,
                Canada. The parties acknowledge that the place for performance of the services
                rendered pursuant to this agreement shall be Ontario.
            </Typography>

            {"\n"}
            <Typography className={classes.title}>
                Entire Agreement
            </Typography>
            <Typography className={classes.content}>
                This is the entire agreement between the parties and supersedes any other
                understandings or representations. Furthermore, the parties acknowledge that they
                have not relied on any representations made prior to executing this agreement.
            </Typography>
        </div>

    ) :

        (<div style={{ margin: 'min(33px,5%)', whiteSpace: 'pre-line' }}>
            <Typography className={classes.title}>
                The HPB Risk Statement (The Risk Statement) is offered to you by HPB LINK LTD. (HPB) for the security of your funds and personal information. You shall make reference to this statement when you buy and sell any crypto-assets on HPB’s platform. The Risk Statement is incorporated by reference into the online User Agreement that you will accept at the time of account opening. Capitalized terms used and not defined in this Risk Statement have the meanings given to them in the User Agreement.
            </Typography>

            <Typography className={classes.title} style={{ marginTop: 24 }}>
                No securities regulatory authority has expressed any opinion about the crypto assets made available on the HPB’s Platform. By using HPB Platform or any other services related thereto (collectively, the Services), you shall understand that there are substantial risks associated with the purchase, sale and use of crypto assets through us, and you are agreeing to familiarize yourself and assume any and all such risks, including:
            </Typography>


            {"\n"}
            <Typography className={classes.title}>
                1.Exposure to frauds
            </Typography>
            <Typography className={classes.content}>
                You should avoid any third-party frauds, such as ransomware, gift card purchases, ponzi schemes, and etc. When you deposit or withdraw any fiat currencies or crypto-assets, either directly or indirectly, into HPB's platform and our associated service providers, HPB is not liable for any losses incurred as a result of third-party frauds. If you have a problem with any goods or services purchased from or sold to a third party using crypto-assets transferred through our services, or if you have a dispute with such a third party, you should resolve the dispute with that third party directly.
            </Typography>

            {"\n"}
            <Typography className={classes.title}>
                2. Lack of Investor protection insurance
            </Typography>
            <Typography className={classes.content}>
                When you purchase crypto-assets on HPB, the fiat currency and crypto assets are not insured in any way by us. HPB is not a member of the Canadian Investor Protection Fund (CIPF) or the crypto assets held by HPB (directly or indirectly through third parties) do not qualify for CIPF protection. Also, The crypto-assets are not protected by the Canadian Investor Protection Fund, the Canadian Deposit Insurance Corporation or any other investor protection insurance scheme.

            </Typography>

            {"\n"}
            <Typography className={classes.title}>
                3.Volatiliity Risk
            </Typography>
            <Typography className={classes.content}>
                Cryptoassets are high risk, with higher volatility than traditional markets. The demand and supply of crypto-assets can change quickly, and some factors, such as (a) the global financial market; (b) monetary and fiscal policies; (c) interest rate; (d) liquidity of the crypto-assets; (e) reputation of the crypto-assets; (f) whether the crypto-assets are involved in any legal issues in specific jurisdictions, etc., may affect the price volatility of the crypto-assets. ​​Some whale addresses may hold a significant amount of the crypto-assets in circulation. If they trade these crypto-assets in the market, it will have a significant impact on price volatility.
            </Typography>

            {"\n"}
            <Typography className={classes.title}>
                4.Wallet Risk
            </Typography>
            <Typography className={classes.content}>
                Crypto-assets wallet files containing private keys can be accidentally deleted, lost, or stolen.  Users are responsible for managing their own private keys, if the private keys or passwords are forgotten, or the electronic devices that store crypto-assets are permanently broken, damaged or stolen, users will be unable to access the BTC wallets, resulting in a permanent loss of the crypto-assets, given that they do not back up their private keys. When a user loses his wallet, his bitcoins are taken out of circulation.

            </Typography>

            {"\n"}
            <Typography className={classes.title}>
                5.Blockchain network risks
            </Typography>
            <Typography className={classes.content}>
                Although the Bitcoin and Ether blockchains have a high reputation among all crypto-assets, some other blockchain networks may be hacked or stopped if they face critical problems; if the vast majority of validators are no longer willing to work, that blockchain network may cease to maintain daily activities or transactions.
            </Typography>

            {"\n"}
            <Typography className={classes.title}>
                6.Cyber Security Risk
            </Typography>
            <Typography className={classes.content}>
                The nature of crypto assets may lead to an increased risk of fraud or cyber-attack. A breach in cyber security refers to both intentional and unintentional events that may cause HPB to lose proprietary information or other information subject to privacy laws, suﬀer data corruption, or lose operational capacity. This in turn could cause HPB to incur regulatory penalties, reputational damage, additional compliance costs associated with corrective measures and/or ﬁnancial loss. Cyber security breaches may involve unauthorized access to HPB’s digital information systems (e.g. through “hacking” or malicious software coding), but may also result from outside attacks such as denial-of-service attacks (i.e. eﬀorts to make network services unavailable to intended users). In addition, cyber security breaches of HPBs third-party service providers can also give rise to many of the same risks associated with direct cyber security breaches. As with operational risk in general, HPB has established risk management systems designed to reduce the risks associated with cyber security.

            </Typography>

            {"\n"}
            <Typography className={classes.title}>
                7.Regulatory Risk
            </Typography>
            <Typography className={classes.content}>
                Changes in applicable law may have a negative impact on the use, transfer, exchange, or value of your crypto assets, and such changes may be sudden and unexpected. If a competent regulator or court determines that a crypto asset listed on the HPB Platform is a security or derivative, the crypto asset will be delisted.
            </Typography>

            {"\n"}
            <Typography className={classes.title}>
                8.Technology Risk
            </Typography>
            <Typography className={classes.content}>
                Flaws in crypto asset source code have previously been exposed and exploited, including flaws that disabled some functionality for users, exposed users' personal information, and/or resulted in the theft of users' digital assets.Cryptography of some crypto-assets can be proven to be flawed or ineffective over time. For example, advances in mathematics and/or technology, such as advances in digital computing, algebraic geometry, and quantum computing, could render the blockchain network's cryptography vulnerable to attack. In general, any decrease in public trust in the security or source code of a core blockchain network could have a negative impact on the broader sector, and thus on the value of crypto assets traded on the HPB Platform.
            </Typography>


        </div>

        )

}
export default withTranslation()(EnAgreement);