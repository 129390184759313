import failed from "../../images/failed.png";
import passed from "../../images/passed.png";
import {Button, Typography} from "@material-ui/core";
import React from "react";
import {withTranslation} from "react-i18next";
import {useSelector} from "react-redux";
import {useHistory} from "react-router-dom";
import FailedStatusIcon from "../../images/FailedStatusIcon.png";
import pendingStatusIcon from "../../images/pendingStatusIcon.png";
import WarningStatusIcon from "../../images/WarningStatusIcon.png";
import PassedStatusIcon from "../../images/PassedStatusIcon.png";
import required from "../../images/required.png";


function TextKycStatus({ t, phone, setKycVisible, mode }) {

    const { kycDetails, documentDetails } = useSelector(state => state.kyc)
    let linkExpired = kycDetails.remark === 'link expired.' && kycDetails.status === 'failed'
    const history = useHistory();
    let color = kycDetails.status === "passed" ? "#C7C7C7" : "#DC3055"
    let icon = null
    let textColor = kycDetails.status === undefined || (kycDetails.status === 'inited' || linkExpired) || kycDetails.status === 'inReview' ? '#DC3055' : '#858E9D'
    let title = ''
    if (kycDetails.status === undefined || kycDetails.status === 'inited') {
        title = t('initedTitle')
        icon = WarningStatusIcon
    } else if (kycDetails.status === 'inReview') {
        title = t('inReviewTitle')
        icon = pendingStatusIcon
    } else if (kycDetails.status === 'failed') {
        title = t('failedTitle')
        icon = FailedStatusIcon
    } else if (kycDetails.status === 'expired') {
        title = t('expiredTitle')
        icon = FailedStatusIcon
    } else if (kycDetails.status === 'passed') {
        title = t('passedTitle')
        icon = PassedStatusIcon
    } else if (kycDetails.status === 'idPassed') {
        if (documentDetails.status === undefined || documentDetails.status === 'inited') {
            title = t('addressVerificationRequired')
            icon = WarningStatusIcon
        } else if (documentDetails.status === 'inReivew') {
            title = t('addressVerificationInReview')
            icon = pendingStatusIcon
        } else if (documentDetails.status === 'failed' || documentDetails.status === 'expired') {
            title = t('addressVerificationFailed')
            icon = FailedStatusIcon
        }
    } else {
        title = t('uncaughtStatus')
        icon = required
    }

    let buttonTitle =
        kycDetails.status === undefined || (kycDetails.status === 'inited' || linkExpired) || kycDetails.status === 'idPassed' ? t('VerifyNow') :
            kycDetails.status === 'inReview' ? t('inReviewTextTitle') :
                (kycDetails.status === 'failed' && !linkExpired) ? t('VerifyAgain') :
                    kycDetails.status === 'passed' ? t('passedTextTitle') :
                        kycDetails.status === 'expired' ? t('VerifyAgain') : t('VerifyAgain')

    let opacity = kycDetails.status === 'inReview' || documentDetails.status === 'inReview' ? 0.4 : 1
    let disableIntoKYCPage = kycDetails.status === "inReview" || kycDetails.status === "passed" || documentDetails.status === 'inReview'


    console.log('title: ', title, kycDetails.status, documentDetails.status)
    if (mode === 1) {
        return (
            <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'center' }}>
                <div style={{marginRight:8}}>
                    <Typography
                        style={
                            phone ? { fontFamily: "Avenir", color: textColor, fontSize: 12, fontWeight: 500,marginLeft:12, letterSpacing: 0, lineHeight: "16px", textAlign: 'initial' }
                                : { fontFamily: "Avenir", color: textColor, fontSize: 14, fontWeight: 500, letterSpacing: 0, textAlign: 'initial' }
                        }
                    >
                        {title}
                    </Typography>
                </div>
                <img alt='' src={icon} style={phone ? { width: 12, height: 12, marginRight: 4 } : { width: 16, height: 16, marginRight: 8 }} />

            </div>

        )

    }
    else {
        if (kycDetails.status === 'passed') {
            return null
        } else {
            return (
                <Button
                    style={
                        phone ? { opacity: opacity, marginTop: 24, width: "100%", height: "48px", backgroundColor: color, borderRadius: 10, padding: "17.8px 11px", textTransform: "none", justifyContent: "center" }
                            : { opacity: opacity, marginTop: 80, width: "100%", height: "58px", backgroundColor: color, borderRadius: 16, textTransform: "none", justifyContent: 'center' }
                    }
                    onClick={() => {
                        if (!disableIntoKYCPage) {
                            setKycVisible(true)
                        }
                    }}
                >
                    <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'center' }}>
                        {/* <img alt='' src={icon} style={phone ? {width:27,height:27}: {width:44,height:44}}/> */}
                        <div style={phone ? { margin: "0px 9.52px" } : { margin: "0px 17px" }}>
                            <Typography
                                style={
                                    phone ? { fontFamily: "Avenir", color: "white", fontSize: 12, fontWeight: 500, letterSpacing: 0, lineHeight: "16px", textAlign: 'initial' }
                                        : { fontFamily: "Avenir", color: "white", fontSize: 20, fontWeight: 500, letterSpacing: 0, lineHeight: "30px", textAlign: 'initial' }
                                }
                            >
                                {buttonTitle}
                            </Typography>
                        </div>
                    </div>

                </Button>
            )
        }

    }


}

export default withTranslation()(TextKycStatus);
