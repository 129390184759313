import failed from "../../images/failed.png";
import passed from "../../images/passed.png";
import {withStyles, Button, makeStyles, Tooltip, Typography} from "@material-ui/core";
import React, {useState} from "react";
import {withTranslation} from "react-i18next";
import {useDispatch, useSelector} from "react-redux";
import {useHistory} from "react-router-dom";
import arrowWhite from "../../images/arrowWhite.png";
import tickIcon from "../../images/tick.png";
import registerIcon from '../../images/trade/register.png'
import verifyIcon from '../../images/trade/verify.png'
import fundIcon from '../../images/trade/fund.png'
import KycModal from "../Kyc/KycModal";
import {authActions} from "../../redux/actions/authActions";


function EliteKycStatus({ t, phone, responsive, width }) {
    const { userInfo } = useSelector(state => state.auth);
    const { kycDetails, documentDetails } = useSelector(state => state.kyc)
    const { userCapitals } = useSelector(state => state.funds)
    const [kycVisible, setKycVisible] = useState(false);
    const dispatch = useDispatch();

    const LightTooltip = withStyles((theme) => ({
        tooltip: {
            backgroundColor: theme.palette.common.white,
            color: '#000000',
            boxShadow: theme.shadows[1],
            fontSize: 14,
            fontWeight: 500
        },
    }))(Tooltip);

    const useStyles = makeStyles((theme) => ({
        indCircalActive: {
            height: phone ? 25 : 40, width: phone ? 25 : 40, borderRadius: 24, display: 'flex', alignItems: 'center', justifyContent: 'center'
        },
        indCircalTextActive: {
            fontSize: phone ? 12 : 16, fontWeight: "500", fontFamily: 'Avenir'
        },
        indCircalInactive: {
            height: phone ? 24 : 38, width: phone ? 24 : 38, borderRadius: 20, display: 'flex', alignItems: 'center', justifyContent: 'center'
        },
        indCircalTextInactive: {
            fontSize: phone ? 12 : 16, fontWeight: "500", fontFamily: 'Avenir'
        },
    }))

    const classes = useStyles();

    const history = useHistory();


    let originalColor = '#8B96A7'
    let successColor = '#003FFF'
    let inProgressColor = '#E1E9FF'
    let failedColor = '#E63636'
    let title = ''
    let content = ''
    let showBtn = true
    let btnText = t('verify')

    let step = userInfo.firstName !== undefined && userInfo.firstName !== null ? 1 : 0

    if (kycDetails.status === undefined || kycDetails.status === 'inited') {
        title = t('verifyIDRequiredTitle')
        content = t('verifyIDRequiredContent')
        step = 2
    } else if (kycDetails.status === 'inReview') {
        title = t('reviewingTitle')
        content = t('reviewingContent')
        showBtn = false
        step = 2
    } else if (kycDetails.status === 'failed') {
        title = t('reviewFailedTitle')
        content = t('reviewFailedContent')
        step = 2
    } else if (kycDetails.status === 'expired') {
        title = t('idExpiredTitle')
        content = t('idExpiredContent')
        step = 2
    } else if (kycDetails.status === 'passed') {
        console.log('kycDetails: ', new Date(kycDetails.idExpiry).getTime(), new Date().getTime() )
        if (userCapitals.length === 0) {
            title = t('depositRequiredTitle')
            content = t('depositRequiredContent')
            btnText = t('fundingTitle')
            step = 3
        }
        else if (new Date(kycDetails.idExpiry).getTime() < new Date().getTime()) {
            title = t('idExpiredTitle')
            content = t('idExpiredContent')
            step = 2
        }
        else {
            title = t('passedTitle')
            content = ''
            showBtn = false
            step = 4
        }

    } else if (kycDetails.status === 'idPassed') {
        if (documentDetails.status === undefined || documentDetails.status === 'inited') {
            title = t('addressVerificationRequiredTitle')
            content = t('addressVerificationRequiredContent')
            step = 2
        } else if (documentDetails.status === 'inReivew') {
            title = t('reviewingTitle')
            content = t('reviewingContent')
            showBtn = false
            step = 2
        } else if (documentDetails.status === 'failed' || documentDetails.status === 'expired') {
            title = t('addressVerificationFailedTitle')
            content = t('addressVerificationFailedContent')
            step = 2
        }
    } else {
        title = t('unknownTitle')
        content = t('unknownContent')
        step = 2
    }

    let opacity = kycDetails.status === 'inReview' || documentDetails.status === 'inReview' ? 0.6 : 1
    let disableIntoKYCPage = kycDetails.status === "inReview" || kycDetails.status === "passed" || documentDetails.status === 'inReview'

    const stepIndicatorView = () => {
        return (
            <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'flex-start', width: '100%' }}>
                <LightTooltip title={t('register')} >
                    <div className={classes.indCircalActive} style={{ backgroundColor: step < 1 ? 'transparent' : successColor, border: `solid thin ${step < 1 ? 'transparent' : successColor}` }}>
                        <Typography className={classes.indCircalTextActive} style={{ color: step < 1 ? originalColor : 'white' }}>
                            1
                        </Typography>
                    </div>
                </LightTooltip>
                <div style={{
                    height: 1, width: phone ? 40 :  "25%", backgroundColor: step > 1 ? kycDetails.status === 'failed' || kycDetails.status === 'expired' || (kycDetails.status === 'idPassed' && (documentDetails.status === 'failed' || documentDetails.status === 'expired')) ?
                        failedColor : successColor : originalColor
                }} />

                <LightTooltip title={t('verify')} >
                    <div className={classes.indCircalActive} style={{
                        backgroundColor: step < 2 ? "#91ACFE" : step > 2 ? successColor :
                            step === 2 &&
                                (kycDetails.status === 'failed' || kycDetails.status === 'expired' || (kycDetails.status === 'idPassed' && (documentDetails.status === 'failed' || documentDetails.status === 'expired'))) ?
                                failedColor : inProgressColor,
                        border: step < 2 ||documentDetails.status ==='inited'  ? `1px solid ${successColor}` : kycDetails.status === 'inited' || kycDetails.status === undefined ? `solid thin ${successColor}` : 'transparent',
                    }}>
                        <Typography className={classes.indCircalTextActive}
                            style={{
                                color: kycDetails.status === undefined || kycDetails.status === 'inited' || kycDetails.status === 'inReview' || documentDetails.status === 'inReview'||documentDetails.status ==='inited' ?
                                    successColor : 'white'
                            }}>
                            2
                        </Typography>
                    </div>
                </LightTooltip>
                <div style={{ height: 1, width: phone ? 40 : "25%", backgroundColor: step === 3 ? successColor : originalColor }} />
                <LightTooltip title={t('fund')} >
                    <div className={classes.indCircalActive} style={{
                        backgroundColor: step < 3 ? 'transparent' : step > 3 ? successColor :
                            inProgressColor,
                        border: step < 3 ? `solid 1px ${originalColor}` : `solid 1px ${successColor}`,
                    }}>
                        <Typography className={classes.indCircalTextActive} style={{ color: step < 3 ? originalColor : step > 3 ? 'white' : successColor }}>
                            3
                        </Typography>
                    </div>
                </LightTooltip>


                <div style={{ height: 1, width: phone ? 40 : "25%", backgroundColor: originalColor }} />


                <LightTooltip title={t('indicatorPopFinish')} >
                    <div className={classes.indCircalActive} style={{ border: `solid 1px ${originalColor}` }}>
                        <img style={{ height: phone ? 10 : 12, alignSelf: 'center', color: originalColor, filter: 'grayscale(100%)', opacity: 0.7 }} src={tickIcon} />

                    </div>
                </LightTooltip>

            </div>
        )
    }


    return (
        <div
            style={
                { display: 'flex', flexDirection: 'column', justifyContent: 'flex-start', alignItems: 'center', padding: responsive ? 0 : 0 }
            }
            onClick={() => {

            }}
        >
            {/* <Typography style={{ marginTop: phone ? 12 : 20, marginBottom: phone ? 12 : 20, alignSelf: 'flex-start', fontSize: phone ? 20 : 32, fontWeight: 500, letterSpacing: 0, fontFamily: 'Avenir' }}>
                {`Hi ${userInfo.firstName}`}
            </Typography> */}
            {
                stepIndicatorView()
            }
            <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'flex-start', marginTop: phone ? 12 : 30,marginBottom:phone ?24:32, width: '100%' }}>
                <div style={phone ? { width: '100%', margin: 0 } : { margin: "unset", width: '100%'}}>

                    <Typography
                        style={
                            phone ? { fontFamily: "Avenir", color: (((kycDetails.status === 'failed' || documentDetails.status === 'expired' ||documentDetails.status === 'failed'  ))) ? failedColor : successColor, fontSize: 14, fontWeight: 500, letterSpacing: 0, lineHeight: "16px", textAlign: 'initial' }
                                : { fontFamily: "Avenir", color: (((kycDetails.status === 'failed' || documentDetails.status === 'expired'||documentDetails.status === 'failed'  ))) ? failedColor : successColor, fontSize: 20, fontWeight: 500, letterSpacing: 0, lineHeight: "30px", textAlign: 'initial' }
                        }
                    >
                        {title}
                    </Typography>
                    <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'space-between'}}>
                        {/* <Typography
                            style={
                                { fontFamily: "Avenir", color: "#8B96A7",  fontSize: phone ? 10 : 14, fontWeight: 500, letterSpacing: 0, marginTop: 4, marginRight: 8, textAlign: 'initial', maxWidth: 430 }
                            }
                        >
                            {content}
                        </Typography> */}
                            <Typography
                                style={
                                    { fontFamily: "Avenir", color: "#8B96A7", fontSize: phone ? 12 : 16, fontWeight: 500, letterSpacing: 0,  textAlign: 'initial', maxWidth: "70%" ,marginTop:8,marginRight:16}
                                }
                            >
                                {content}
                            </Typography>
                            {
                                showBtn ?

                           null
                                 :
                                  null
                            }

                              {
                                showBtn ?
                                    <Button
                                        onClick={() => {
                                            if (step === 3) {
                                                history.push('/fund')
                                            } else {
                                                if (!disableIntoKYCPage) {
                                                    setKycVisible(true)
                                                }
                                            }
                                        }

                                    }
                                    style={{ width: 89, height: 33, borderRadius: 14, backgroundColor: '#003FFF', textTransform: 'unset' }}>
                                    <Typography style={{ color: 'white', fontSize: 14, fontFamily: 'Avenir' }}>
                                        {btnText}
                                    </Typography>
                                    <img alt='' src={arrowWhite} style={{ marginLeft: 10, height: 15, width: 6 }} />
                                    </Button>
                                 :
                                  null
                        }
                    </div>
                    <div style={{ height: 1, width: '100%', backgroundColor: '#E5E5E5', marginBottom: 16 ,marginTop:24}} />


                </div>
            </div>
            {/*KYC弹窗*/}
            <KycModal
                onCancel={() => {
                    setKycVisible(false);
                    dispatch(authActions.clearUpdateUserInfoState())
                }}
                modalVisible={kycVisible}
                t
                phone={phone}
                width={width}
                userInfo={userInfo}
            />

        </div>
    )
}

export default withTranslation()(EliteKycStatus);
