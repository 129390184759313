import {withTranslation} from "react-i18next";
import {
    Avatar,
    Button,
    InputAdornment,
    InputBase,
    makeStyles,
    MenuItem,
    Modal,
    Select,
    Typography
} from "@material-ui/core";
import React, {useEffect, useState} from "react";
import close from "../../../images/nft/close.png";
import receive from "../../../images/nft/receive.png";
const VoucherReceiveModal = (props) => {
    const {
        onCancel: handleClose,
        modalVisible,
        t,
        phone,
        width,
    } = props;

    const useStyles = makeStyles((theme) => ({
        modal: {
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            overflow:'scroll',
        },
        font: {
            color: '#1C2237',
            fontFamily: 'Avenir',
            fontSize: phone?14:16,
            letterSpacing: -0.31
        },
    }));
    const classes = useStyles();

    const VoucherReceiveModalBody = (
        <div style={{position:"relative", height: phone?null :400, width: phone? 350 : 400, borderRadius: 32, backgroundColor:"white", outline:0}}>
            <img src={close} style={{position:"absolute", width:phone?40:33, height:phone?40:33, right:phone?0:-20, top:phone?-50:-30, cursor: "pointer"}} onClick={()=>{handleClose()}}/>
            <div style={{margin:phone?30:40}}>
                <div style={{width:'100%',display:"flex", alignItems: 'center', justifyContent: 'center' }}>
                    <img src={receive} style={{width:271, height:117}}/>
                </div>
                <Typography className={classes.font} style={{fontSize:phone?20:24, fontWeight:900, marginTop:30}}>
                    {t('voucherReceive')}
                </Typography>
                <Typography className={classes.font}>
                    {t('voucherReceiveSubtitle')}
                </Typography>
                <Button
                    className={classes.font} style={{height:55, width:'100%', borderRadius:20, backgroundColor:"#003FFF", marginTop:20, color:'white', textTransform:"none"}}
                    onClick={handleClose}
                >
                    {t('voucherReceiveGotIt')}
                </Button>
            </div>
        </div>
    );


    return (
        <Modal
            open={modalVisible}
            onClose={handleClose}
            className={classes.modal}
            aria-labelledby="simple-modal-title"
            aria-describedby="simple-modal-description"
            BackdropProps={{
                className:
                    'modalBackground'
            }}
            disableAutoFocus={true}
        >
            {VoucherReceiveModalBody}
        </Modal>
    )
}


export default withTranslation()(VoucherReceiveModal);
