import { withTranslation } from 'react-i18next';
import React, { useEffect, useState } from "react";
import referralWebBanner from '../../images/referral/referralWebBanner.png'
import referralPhoneBanner from '../../images/referral/referralPhoneBanner.png'
import check from '../../images/tick.png'
import openNewTab from '../../images/referral/openNewTab.png'
import {
    Button,
    Typography,
    TextField,
    makeStyles,
    MenuItem,
    Avatar,
    Snackbar,
    IconButton,
    Table,
    TableHead,
    TableRow,
    TableBody,
    TableFooter,
    TablePagination,
    TableContainer,
    InputAdornment,
    Input,
    withStyles,
    TableCell
} from '@material-ui/core';
import { convertTimeString, FormatNumber, roundingDown } from "../../utils/Common";
import { authActions } from "../../redux/actions/authActions";
import { referralActions } from "../../redux/actions/referralActions";
import { useDispatch, useSelector } from "react-redux";
import i18n from "../../i18n";
import empty from "../../images/empty.png";
import { Helmet } from "react-helmet";
import {nftActions} from "../../redux/actions/nftActions";

function Referral({ t, width, height }) {
    const { token, userInfo } = useSelector(state => state.auth)
    const { referrals } = useSelector(state => state.referral)
    const { commissionAndNft } = useSelector(state => state.nft)
    const [phone, setPhone] = useState(width <= 745);
    const [copied, setCopied] = useState(false)
    const [page, setPage] = React.useState(0);
    const [rowsPerPage, setRowsPerPage] = React.useState(10);

    const dispatch = useDispatch();
    const useStyles = makeStyles({
        table: {
            borderCollapse: 'separate',
            borderSpacing: phone ? "0" : '0px 15px'
        },
        font: {
            fontFamily: "Avenir",
            fontSize: 14,
            fontWeight: 500,
            letterSpacing: 0
        },
        paginationSelect: {
            margin: 'auto',
            fontSize: 10
        }
    });
    const classes = useStyles();

    const StyledTableCell = withStyles((theme) => ({
        root: {
            borderBottom: 'none',

            padding: 0,
            "&:first-child": {
                paddingLeft: 32,
                borderTopLeftRadius: "25px !important",
                borderBottomLeftRadius: "25px !important"
            },
            "&:last-child": {
                paddingRight: 32,
                borderTopRightRadius: "25px !important",
                borderBottomRightRadius: "25px !important"
            }
        },
        head: {
            // backgroundColor: 'red',
            color: '#9097A1',
            fontSize: 14,
            fontWeight: 400,
            paddingLeft: 80,

        },
        body: {
            color: '#1C2237',

            padding: '24px 0px',
            fontWeight: 900,
            paddingLeft: 58,
            backgroundColor: '#F9F9F9',
        }
    }))(TableCell);

    useEffect(() => {
        dispatch(authActions.getUserInfo(token))
        dispatch(nftActions.getCommissionAndNft(token))
        dispatch(referralActions.getReferrals({ currentPage: page, pageSize: rowsPerPage }, token))
    }, []);

    useEffect(() => {
        setPhone(width <= 745)
    }, [width]);

    const onCopy = (record) => {
        const oInput = document.createElement('input')
        oInput.value = record;
        document.body.appendChild(oInput)
        oInput.select() // 选择对象
        document.execCommand("Copy")
        oInput.remove()
    };

    const renderPhoneRow = (row) => {

        return (

            <div style={{ display: 'flex', width: '100%', backgroundColor: '#F8F7F7', marginBottom: 8, borderRadius: 12, flexDirection: 'column' }}>
                <div style={{ margin: '12px 16px' }}>
                    <div style={{ justifyContent: 'space-between', display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                        <Typography className={classes.font} style={{ fontSize: 12 }}>{row.email}</Typography>
                        <Typography className={classes.font} style={{ fontSize: 10, color: "#9097A1" }}>{t('referralTableCommission')} {roundingDown(commissionAndNft.referralBonusRate*100,2) == 20
                            ? 30
                            :roundingDown(commissionAndNft.referralBonusRate*100,2) == 33.33?
                                50
                                :null
                        }%</Typography>
                    </div>
                    <div style={{ justifyContent: 'space-between', display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                        <Typography className={classes.font} style={{ fontSize: 10, color: "#9097A1", fontWeight: 500 }}>{convertTimeString(row.createdAt)} (UTC) {t('registered')}</Typography>
                        <Typography className={classes.font} style={{ fontSize: 14, color: "#0173F9" }}>${roundingDown(row.commission, 2)} CAD</Typography>
                    </div>
                </div>
            </div>
        )

    }

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
        dispatch(referralActions.getReferrals({ currentPage: newPage, pageSize: rowsPerPage }, token))
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
        dispatch(referralActions.getReferrals({ currentPage: 0, pageSize: event.target.value }, token))
    };

    return (

        <div style={{ display: 'flex', justifyContent: 'center', paddingBottom: 80 }}>
            <Helmet>
                <title>{t('titleReferral')}</title>
                <meta name="keywords" content="Referral program, 返佣奖励, Toronto, OTC, bitcoin, exchange, cryptocurrency, 多伦多, 场外交易平台, 比特币, 以太坊, 加元, 换汇, 加密货币, 人民币,多倫多, 場外交易平台, 比特幣, 以太坊, 加元, 換匯, 人民幣, Yesbit, Fiat, CAD, BTC, ETH, USD" />
            </Helmet>
            <div style={{ width: '90%' }}>
                <div style={{ display: 'flex', alignItems: 'center', marginTop: 30, width: '100%', height: phone ? width * 0.5384 : width * 0.2219, backgroundImage: `url(${phone ? referralPhoneBanner : referralWebBanner})`, backgroundSize: '100% 100%' }}>
                    {phone ? null :
                        <div style={{ marginLeft: 28 }}>
                            <Typography className={classes.font} style={{ fontSize: 32, lineHeight: '27px' }}>
                                {t('referralTitle1')} <Typography className={classes.font} style={{
                                    fontSize: 40,
                                    display: 'inline'
                                }}>50%</Typography> {t('referralTitle2')}
                            </Typography>
                            <a className={classes.font} target="_blank" rel="noopener noreferrer" href={i18n.language === 'en' ? "https://otcdocs.yesbit.io/v/english/faq/referrals" : "https://otcdocs.yesbit.io/v/jian-ti-zhong-wen/faq/yao-qing-ji-zhi"}
                                style={{ fontSize: 20, color: "#0173F9" }}>{t('referralSubtitle')}</a>
                            <img src={openNewTab} style={{ marginLeft: 4, width: 20, height: 20 }} />
                        </div>
                    }
                </div>
                {phone ?
                    <div style={{ marginTop: 20 }}>
                        <Typography className={classes.font} style={{ fontSize: 20, lineHeight: '22px' }}>
                            {t('referralTitle1')} 45% {t('referralTitle2')}
                        </Typography>
                        <a className={classes.font} target="_blank" rel="noopener noreferrer" href={i18n.language === 'en' ? "https://otcdocs.yesbit.io/v/english/faq/referrals" : "https://otcdocs.yesbit.io/faq/yao-qing-ji-zhi"}
                            style={{ fontSize: 12, color: "#0173F9" }}>{t('referralSubtitle')}</a>
                        <img src={openNewTab} style={{ marginLeft: 4, width: 12, height: 12 }} />
                    </div>
                    : null
                }
                <div style={{ marginTop: phone ? 24 : 42, display: phone ? null : 'flex', justifyContent: phone ? null : "space-between" }}>
                    <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', height: phone ? 99 : 141, width: phone ? "100%" : '60%', backgroundColor: '#F2F8FF', borderRadius: phone ? 12 : 24 }}>
                        <div style={{ width: '90%' }}>
                            <Typography className={classes.font} style={phone ? { fontSize: 12 } : null}>
                                {t('referralLink')}
                            </Typography>
                            <div style={{ display: "flex", alignItems: 'center', marginTop: 8, backgroundColor: 'white', borderRadius: phone ? 8 : 16 }}>
                                <TextField
                                    className={classes.font}
                                    style={{ width: '100%', backgroundColor: 'white', borderRadius: 16, marginLeft: 20, color: "#9097A1" }}
                                    InputProps={{ disableUnderline: true, style: { minHeight: phone ? 29 : 55, fontSize: phone ? 10 : 14 } }}
                                    value={window.location.origin + '/register?referralCode=' + userInfo.referralCode}
                                    multiline
                                    disabled
                                />

                                <Button
                                    className={classes.font}
                                    style={{ color: "white", fontWeight: 500, marginRight: 20, marginTop: 8, marginBottom: 8, fontSize: phone ? 14 : 16, whiteSpace: 'nowrap', backgroundColor: '#0173F9', borderRadius: 8 }}
                                    edge="end"
                                    onClick={() => { onCopy(window.location.origin + '/register?referralCode=' + userInfo.referralCode); setCopied(true) }}
                                >
                                    {/* {copied?<img src={check} style={{width:19, height:13, marginRight:6}}/>:null} */}
                                    {copied ? t('copied') : t('copy')}

                                </Button>
                            </div>
                        </div>
                    </div>

                    <div style={{ marginTop: phone ? 24 : null, display: 'flex', justifyContent: "space-around", alignItems: 'center', height: phone ? 70 : 141, minWidth: phone ? "100%" : '35%', backgroundColor: '#F2F8FF', borderRadius: phone ? 12 : 24 }}>
                        <div style={{ display: 'grid', justifyItems: 'center' }}>
                            <Typography className={classes.font} style={{ fontSize: phone ? 16 : 28 }}>
                                {userInfo.totalReferrals}
                            </Typography>
                            <Typography className={classes.font} style={{ fontSize: phone ? 12 : 14, fontWeight: 500 }}>
                                {t('referrals')}
                            </Typography>
                        </div>
                        <div style={{ display: 'grid', justifyItems: 'center' }}>
                            <Typography className={classes.font} style={{ fontSize: phone ? 16 : 28 }}>
                                {userInfo.voucher}
                            </Typography>
                            <Typography className={classes.font} style={{ fontSize: phone ? 12 : 14, fontWeight: 500 }}>
                                {t('voucher')}
                            </Typography>
                        </div>
                        <div style={{ display: 'grid', justifyItems: 'center' }}>
                            <Typography className={classes.font} style={{ fontSize: phone ? 16 : 28 }}>
                                ${FormatNumber(roundingDown(userInfo.totalReferralCommission, 2))}
                            </Typography>
                            <Typography className={classes.font} style={{ fontSize: phone ? 12 : 14, fontWeight: 500 }}>
                                {t('referralCommissions')}
                            </Typography>
                        </div>
                    </div>
                </div>


                <TableContainer
                    style={{
                        background: 'transparent',
                        alignItems: 'center',
                        justifyContent: 'center',
                        marginTop: phone ? 24 : 60,
                        border: 'none',
                        boxShadow: 'none'
                    }}>
                    <Table className={classes.table} aria-label="customized table">
                        {
                            phone ?
                                [
                                    <Typography className={classes.font} style={{ fontSize: 12, marginBottom: 8 }}>{t('referralDetails')}</Typography>,
                                    referrals.list.map((row) => (renderPhoneRow(row)))
                                ]
                                :
                                <>
                                    <TableHead >
                                        <TableRow>
                                            <StyledTableCell align="left">{t('referralTableEmail')}</StyledTableCell>
                                            <StyledTableCell align="right">{t('referralTableCommission')}</StyledTableCell>
                                            <StyledTableCell align="right">{t('referralTableRegisterTime')}</StyledTableCell>
                                            <StyledTableCell align="right">{t('referralTableMyCommissions')}</StyledTableCell>
                                        </TableRow>
                                    </TableHead>

                                    <TableBody>
                                        {
                                            referrals.list.map((row, index) => (
                                                <TableRow style={{ marginTop: 10 }} key={index}>
                                                    <StyledTableCell align="left"><Typography style={{ fontWeight: 'bold', fontSize: 14 }}>{row.email}</Typography></StyledTableCell>
                                                    <StyledTableCell align="right"><Typography style={{ fontSize: 14, fontWeight: 'bold' }}>
                                                        {roundingDown(commissionAndNft.referralBonusRate*100,2) == 20
                                                            ? 30
                                                            :roundingDown(commissionAndNft.referralBonusRate*100,2) == 33.33?
                                                                50
                                                                :null
                                                        }%
                                                    </Typography></StyledTableCell>
                                                    <StyledTableCell align="right"><Typography style={{ fontSize: 14, fontWeight: 'bold' }}>{convertTimeString(row.createdAt)} (UTC) </Typography></StyledTableCell>
                                                    <StyledTableCell align="right"><Typography style={{ fontSize: 14, fontWeight: 'bold' }}>${roundingDown(row.commission, 2)} CAD</Typography></StyledTableCell>
                                                </TableRow>
                                            ))
                                        }
                                    </TableBody>
                                </>
                        }

                        {referrals.list.length === 0 ?
                            null
                            :
                            <TableFooter>
                                <TableRow >
                                    <TablePagination
                                        style={{ backgroundColor: '#f1f0f0' }}
                                        rowsPerPageOptions={[5, 10, 25]}
                                        count={referrals.total}
                                        rowsPerPage={rowsPerPage}
                                        page={page}
                                        SelectProps={{
                                            inputProps: { 'aria-label': 'rows per page' },
                                            native: true,
                                        }}
                                        labelRowsPerPage={t('rowsPerPage')}
                                        labelDisplayedRows={(arg) => {
                                            return `${arg.from}-${arg.to} ${t('paginationOf')} ${arg.count}`
                                        }}
                                        onPageChange={handleChangePage}
                                        onRowsPerPageChange={handleChangeRowsPerPage}
                                        classes={phone ? {
                                            toolbar: classes.pagination,
                                            actions: classes.pagination,
                                            caption: classes.paginationSelect
                                        } : null}
                                    />
                                </TableRow>
                            </TableFooter>
                        }
                    </Table>

                    {referrals.list.length === 0 ?
                        <>
                            <div style={{ display: 'flex', justifyContent: "center" }}>
                                <img alt="" src={empty} style={{ width: 190, height: 190 }} />
                            </div>
                            <Typography className={classes.font} style={{ marginTop: 25, fontSize: 14, color: "#333333", fontWeight: 900, textAlign: "center" }}>{t('referralsEmpty')}</Typography>
                        </>
                        : null}

                </TableContainer>
            </div>
        </div>
    )
}

export default withTranslation()(Referral);