import { withTranslation } from "react-i18next";
import { Button, IconButton, InputAdornment, InputBase, makeStyles, Modal, Typography } from "@material-ui/core";
import React, { useEffect, useState } from "react";
import secureIcon from "../../../images/secure.png";
import { useDispatch, useSelector } from "react-redux";
import { kycActions } from "../../../redux/actions/kycActions";
import { Visibility, VisibilityOff } from "@material-ui/icons";
import { checkEmail, isValidPassword } from "../../../utils/Checker";
import { tradeActions } from "../../../redux/actions/tradeActions";
import { authActions } from "../../../redux/actions/authActions";
import ResultModal from "../../ResultModal/ResultModal";
import x from "../../../images/closePopIcon.png";
import enterArrow from "../../../images/enterArrow.png";
import { motion } from "framer-motion"


const ChangeLoginPasswordModal = (props) => {
    const {
        onCancel: handleClose,
        modalVisible,
        t,
        phone,
        width
    } = props;
    const useStyles = makeStyles((theme) => ({
        modal: {
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            textAlign: 'center',
        },
        font: {
            fontFamily: "Avenir",
            fontWeight: 500,
            letterSpacing: 0,
            textAlign: 'left'
        },
        inputLabel: {
            color: '#9097A1', marginBottom: 6, textAlign: 'initial', fontFamily: 'Avenir', fontSize: 16, fontWeight: 500, letterSpacing: 0, lineHeight: '22px'
        },
        inputLabelPhone: {
            color: '#9097A1', marginBottom: 5, textAlign: 'initial', fontFamily: 'Avenir', fontSize: 12, fontWeight: 500, letterSpacing: 0, lineHeight: '14px'
        },
        input: {
            borderRadius: 16, backgroundColor: '#F1F0F0', width: '100%', height: '55px', fontSize: 16, padding: 20
        },
        inputPhone: {
            borderRadius: 12, backgroundColor: '#F1F0F0', width: '100%', height: '38.5px', fontSize: 10, padding: 14
        },
        button: {
            // width: '100%',
            height: '65px',
            borderRadius: 20,
            color: 'white !important',
            backgroundColor: '#0173F9',
            fontFamily: 'Avenir',
            fontSize: 18,
            fontWeight: 900,
            letterSpacing: 0,
            lineHeight: '25px',
            textTransform: 'none',
            "&:hover": {
                backgroundColor: '#0173F9',
                // Reset on touch devices, it doesn't add specificity
                "@media (hover: none)": {
                    backgroundColor: '#0173F9',
                    "&:active": {
                        backgroundColor: '#0173F9'
                    }
                },
                '&$disabled': {
                    backgroundColor: '#0173F9',
                },
            },
        },
        buttonPhone: {
            width: '100%',
            height: '48.3px',
            borderRadius: 12,
            color: 'white !important',
            backgroundColor: '#0173F9',
            fontFamily: 'Avenir',
            fontSize: 12,
            fontWeight: 900,
            letterSpacing: 0,
            lineHeight: '16px',
            textTransform: 'none',
            "&:hover": {
                backgroundColor: '#0173F9',
                // Reset on touch devices, it doesn't add specificity
                "@media (hover: none)": {
                    backgroundColor: '#0173F9',
                    "&:active": {
                        backgroundColor: '#0173F9'
                    }
                },
                '&$disabled': {
                    backgroundColor: '#0173F9',
                },
            },
        }
    }));
    const classes = useStyles();
    const dispatch = useDispatch();

    const { token, userInfo, updatingPassword, updatingPasswordSuccess, updatingPasswordFailed, updatingPasswordMsg } = useSelector(state => state.auth)
    const [validInputWarning, setValidInputWarning] = useState({ currentPassword: false, newPassword: false, confirmPassword: false });
    const [validInputWarningStr, setValidInputWarningStr] = useState({ currentPassword: '', newPassword: '', confirmPassword: '' })
    const [values, setValues] = useState({
        currentPassword: '',
        newPassword: '',
        confirmPassword: '',
        showCurrentPassword: false,
        showNewPassword: false,
        showConfirmPassword: false,
    });
    const [resultModalVisible, setResultModalVisible] = useState(false);
    const [updateResult, setUpdateResult] = useState(true)

    const handleChange = (prop) => (event) => {
        setValues({ ...values, [prop]: event.target.value });
        if (prop === 'currentPassword') {
            setValidInputWarning({ ...validInputWarning, ["newPassword"]: (!isValidPassword(values.newPassword) || values.newPassword === event.target.value) && values.newPassword != "" })
            if (event.target.value === values.newPassword) {
                setValidInputWarningStr({ ...validInputWarningStr, ["newPassword"]: t('newPasswordError') })
            } else if (!isValidPassword(values.newPassword) && values.newPassword != "") {
                setValidInputWarningStr({ ...validInputWarningStr, ["newPassword"]: t('passwordWarning') })
            }
        }
        else if (prop === 'newPassword') {
            setValidInputWarningStr({ ...validInputWarningStr, [prop]: t('passwordWarning') })
            setValidInputWarning({ ...validInputWarning, [prop]: !isValidPassword(event.target.value) || event.target.value === values.currentPassword, ["confirmPassword"]: !(event.target.value === values.confirmPassword || values.confirmPassword === "") })
            if (event.target.value === values.currentPassword) {
                setValidInputWarningStr({ ...validInputWarningStr, [prop]: t('newPasswordError') })
            }
        }
        else if (prop === 'confirmPassword') {
            setValidInputWarning({ ...validInputWarning, [prop]: !(event.target.value === values.newPassword) })
            setValidInputWarningStr({ ...validInputWarningStr, [prop]: t('confirmPasswordWarning') })
        }
    };

    const handleClickShowPassword = (prop) => {
        setValues({ ...values, [prop]: !values[prop] });
    };

    const handleMouseDownPassword = (event) => {
        event.preventDefault();
    };

    const confirmDisable = () => {
        return values.currentPassword === '' || !isValidPassword(values.newPassword) || values.newPassword != values.confirmPassword || values.currentPassword === values.newPassword
    };

    const updateLoginPassword = () => {
        setValidInputWarning({ currentPassword: false, newPassword: false, confirmPassword: false })
        dispatch(authActions.updateLoginPassword({
            "oldPassword": values.currentPassword,
            "newPassword": values.newPassword,
        }, token))
    }

    useEffect(() => {
        if (updatingPasswordSuccess) {
            dispatch(authActions.clearUpdateLoginPassword())
            setUpdateResult(true)
            setResultModalVisible(true)
            handleClose()
        }
        if (updatingPasswordFailed) {
            dispatch(authActions.clearUpdateLoginPassword())
            setUpdateResult(false)
            setResultModalVisible(true)
            handleClose()
            if (updatingPasswordMsg === "Bad credentials") {
                setValidInputWarningStr({ ...validInputWarningStr, ["currentPassword"]: t('currentPasswordError') })
                setValidInputWarning({ ...validInputWarning, ["currentPassword"]: true })
            } else {
                setValidInputWarningStr({ ...validInputWarningStr, ["currentPassword"]: t('serverError') })
                setValidInputWarning({ ...validInputWarning, ["currentPassword"]: true })
            }
        }
        setValues({
            currentPassword: '',
            newPassword: '',
            confirmPassword: '',
            showCurrentPassword: false,
            showNewPassword: false,
            showConfirmPassword: false,
        })
        setValidInputWarning({
            currentPassword: false,
            newPassword: false,
            confirmPassword: false
        })
    }, [updatingPassword])

    const renderPasswordInput = (value, change, showPassword,placeHolder) => {
        return (
            <InputBase
                id="filled-adornment-password"
                placeholder={placeHolder}
                type={values[showPassword] ? 'text' : 'password'}
                style={
                    phone ? { borderRadius: 12, backgroundColor: 'white', width: '100%', height: '50px', fontSize: 14, padding: 14, fontWeight: 500, border: "1px solid #EDEDED" }
                        : { borderRadius: 16, backgroundColor: 'white', width: '100%', height: '55px', fontSize: 16, padding: 20, fontWeight: 500, border: "1px solid #EDEDED" }
                }
                value={value}
                onChange={handleChange(change)}
                endAdornment={
                    <InputAdornment position="end">
                        <IconButton
                            aria-label="toggle password visibility"
                            onClick={() => handleClickShowPassword(showPassword)}
                            onMouseDown={handleMouseDownPassword}
                            edge="end"
                        >
                            {values[showPassword] ? <Visibility style={{ width: phone ? 16 : null }} /> : <VisibilityOff style={{ width: phone ? 16 : null }} />}
                        </IconButton>
                    </InputAdornment>
                }
            />
        )
    }

    const modalBody = (
        <motion.div
        initial={{ opacity: 0, scale: 0 }}
        animate={{ opacity: 1, scale: 1 }}
    >
        <div style={{
                backgroundColor: 'white', borderRadius: 20, width: phone ? " 89vw" : "606px", overflow: 'auto', outline: 'none'
            }}>
            <div style={phone ? { margin: 'min(10%,70px)' } : {marginLeft:40,marginRight:40,marginTop:60,marginBottom:60 }}>
                <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                    <Typography className={classes.font} style={{ fontSize: phone ? 16 : 22, fontWeight: 300, lineHeight: phone ? '10px' : '30px', color: "black", }}>
                        {t('loginPassword')}
                    </Typography>

                    <Button
                        style={{
                            padding: 0,
                            minHeight: 0,
                            minWidth: 0,
                        }}
                        onClick={handleClose}
                    >
                        <img alt='' src={x} style={phone ? { height: 24, width: 24 } : { height: 32, width: 32 }} />
                    </Button>

                </div>
                <div style={{ marginTop: phone ? 15 : 15, boxSizing: 'border-box', height: 1, width: '100%', border: '1px solid #DDDDDD' }} />

                <div style={{ marginTop: phone ? 20 : 30 }}>
                    <Typography className={phone ? classes.inputLabelPhone : classes.inputLabel}>
                        {t('currentPassword')}
                    </Typography>
                    {renderPasswordInput(values.currentPassword, "currentPassword", "showCurrentPassword",t("newPassword1"))}
                </div>


                <div style={{ marginTop: phone ? 20 : 30 }}>
                    <Typography className={phone ? classes.inputLabelPhone : classes.inputLabel}>
                        {t('newPassword')}
                    </Typography>
                    {renderPasswordInput(values.newPassword, "newPassword", "showNewPassword",t("placeHolderLoginCreatePassword"))}
                </div>
                {
                    validInputWarning.newPassword
                        ? <Typography style={{ marginTop: 8, fontFamily: "Avenir", color: "#DC3055", fontSize: phone ? 12 : 14, fontWeight: 500, lineHeight: phone ? "11px" : '16px', textAlign: "left" }}>
                            {validInputWarningStr.newPassword}
                        </Typography>
                        : null
                }

                <div style={{ marginTop: phone ? 20 : 30 }}>
                    <Typography className={phone ? classes.inputLabelPhone : classes.inputLabel}>
                        {t('confirmPassword')}
                    </Typography>
                    {renderPasswordInput(values.confirmPassword, "confirmPassword", "showConfirmPassword",t("placeHolderLoginRepeatPassword"))}
                </div>
                {
                    validInputWarning.confirmPassword
                        ? <Typography style={{ marginTop: 8, fontFamily: "Avenir", color: "#DC3055", fontSize: phone ? 12 : 14, fontWeight: 500, lineHeight: phone ? "11px" : '16px', textAlign: "left" }}>
                            {validInputWarningStr.confirmPassword}
                        </Typography>
                        : null
                }

              

                <div style={{ display: 'flex', justifyContent: 'end', }}>
                    <Button
                        className={phone ? classes.buttonPhone : classes.button}
                        style={{ marginTop: phone ? 40 : 60, opacity: confirmDisable() ? 0.4 : 1 }}
                        onClick={() => { updateLoginPassword() }}
                        disabled={confirmDisable()}
                    >
                        <Typography className={classes.font} style={{ fontSize: phone ? 14 : 18, color: 'white', fontWeight: 500, marginLeft: 12, marginRight: 12, lineHeight: phone ? '11px' : '25px' }}>{t("confirm")}</Typography>
                        <img style={{ height: phone ? 16 : 20 }} src={enterArrow}></img>
                    </Button>
                </div>

            </div>
        </div >
        </motion.div>
    );

    return [
        <Modal
            open={modalVisible}
            onClose={() => {
                handleClose()
                setValues({
                    currentPassword: '',
                    newPassword: '',
                    confirmPassword: '',
                    showCurrentPassword: false,
                    showNewPassword: false,
                    showConfirmPassword: false,
                })
                setValidInputWarning({
                    currentPassword: false,
                    newPassword: false,
                    confirmPassword: false
                })
            }}
            className={classes.modal}
            aria-labelledby="simple-modal-title"
            aria-describedby="simple-modal-description"
            BackdropProps={{
                className:
                    'modalBackground'
            }}
        >
            {modalBody}
        </Modal>,
        <ResultModal
            onCancel={() => {
                setResultModalVisible(false);
            }}
            modalVisible={resultModalVisible}
            
            t
            phone={phone}
            width={width}
            title={updateResult ? t('passwordUpdated') : t('passwordUpdateFailed')}
            content={updateResult ? t('passwordUpdatedContent') : validInputWarningStr.currentPassword}
            resultSuccess={updateResult}
        />
    ]
}


export default withTranslation()(ChangeLoginPasswordModal);
