import React, {useEffect, useState} from 'react'
import {withTranslation} from "react-i18next";
import {useDispatch, useSelector} from "react-redux";
import {authActions} from "../../redux/actions/authActions";
import {Button, InputAdornment, InputBase, makeStyles, Typography} from '@material-ui/core';
import passed from '../../images/passed.png';
import EditIcon from "../../images/editIcon.png"
import BlackEditIcon from "../../images/onFocusEditIcon.png"
import UpdateInfo from "./components/UpdateInfo";
import UpdateSuccess from "./components/UpdateSuccess";
import "react-datepicker/dist/react-datepicker.css";
import "./UserCenter.css";
import {fundsActions} from "../../redux/actions/fundsActions";
import backToIcon from '../../images/backToIcon.png'
import {useHistory, useLocation} from "react-router-dom";

// import KycModal from "../Kyc/KycModal";
import {CountryDropdown, RegionDropdown} from "react-country-region-selector";
import {isEnglishKeyboard} from "../../utils/Checker";
import {kycActions} from "../../redux/actions/kycActions";
import {Helmet} from "react-helmet";

function UserCenter({ t, width, viewMode }) {
    const dispatch = useDispatch();
    const { token, userInfo, updateInfoSuccess } = useSelector(state => state.auth)
    const [phone, setPhone] = useState(width <= 745);
    const [loaded, setLoaded] = useState(false)
    const [updateInfoVisible, setUpdateInfoVisible] = useState(false);
    const [updateSuccessVisible, setUpdateSuccessVisible] = useState(false);
    const [kycVisible, setKycVisible] = useState(false);
    const [initialing, setInitialing] = useState(true);
    const [personalInfo, setPersonalInfo] = useState({
        firstName: '',
        lastName: '',
        dateOfBirth: '',
        occupation: '',
        employerName: '',
    });
    const location = useLocation();

    const [contactInfo, setContactInfo] = useState({
        phone: '',
        address: '',
        country: '',
        province: '',
        city: '',
        postalCode: '',
    });
    const [focuses, setFocuses] = useState({
        firstName: false,
        lastName: false,
        dateOfBirth: false,
        occupation: false,
        employerName: false,
        phone: false,
        address: false,
        country: false,
        province: false,
        city: false,
        postalCode: false,
    })

    const [countryRegionColor, setCountryRegionColor] = useState({ country: '#9097A1', province: "#9097A1" })

    const updateDisable = () => {
        let disable = true
        for (let input in personalInfo) {
            if (personalInfo[input]) {
                disable = false
            }
        }
        for (let input in contactInfo) {
            if (input != 'province' && input != 'country') {
                if (contactInfo[input]) {
                    disable = false
                }
            }
        }
        if (contactInfo.province != userInfo.province && contactInfo.province != '') {
            disable = false
        }
        if(personalInfo.employerName === "" && userInfo.employerName === ""){
            disable = true
        }
        return disable
    }


    const useStyles = makeStyles({
        inputLabel: {
            marginBottom: 6, textAlign: 'initial', fontFamily: 'Avenir', fontSize: 16, fontWeight: 500, letterSpacing: 0, lineHeight: '22px'
        },
        inputLabelPhone: {
            marginBottom: 5, textAlign: 'initial', fontFamily: 'Avenir', fontSize: 10, fontWeight: 500, letterSpacing: 0, lineHeight: '14px'
        },
        input: {
            borderRadius: 16, backgroundColor: 'white', width: '100%', height: '55px', fontSize: 16, padding: 20, fontWeight: 500, border: "1px solid #EDEDED"
        },
        inputPhone: {
            borderRadius: 12, backgroundColor: '#F1F0F0', width: '100%', height: '38.5px', fontSize: 10, padding: 14, fontWeight: 500, border: "1px solid #EDEDED"
        },
        datePicker: {
            borderRadius: 16, backgroundColor: '#F1F0F0', width: '-webkit-fill-available', height: '55px', fontSize: 16, padding: '0px 20px', border: 0, fontWeight: 500
        },
        datePickerPhone: {
            borderRadius: 12, backgroundColor: '#F1F0F0', width: '-webkit-fill-available', height: '38.5px', fontSize: 10, padding: '0px 14px', border: 0, fontWeight: 500
        },
        button: {
            width: '100%',
            height: '65px',
            borderRadius: 20,
            color: 'white !important',
            backgroundColor: '#081647',
            fontFamily: 'Avenir',
            fontSize: 18,
            fontWeight: 500,
            letterSpacing: 0,
            lineHeight: '25px',
            textTransform: 'none',
            "&:hover": {
                backgroundColor: '#081647',
                // Reset on touch devices, it doesn't add specificity
                "@media (hover: none)": {
                    backgroundColor: '#081647',
                    "&:active": {
                        backgroundColor: '#081647'
                    }
                },
                '&$disabled': {
                    backgroundColor: '#081647',
                },
            }
        },
        buttonPhone: {
            width: '100%',
            height: '48.3px',
            borderRadius: 12,
            color: 'white !important',
            backgroundColor: '#081647',
            fontFamily: 'Avenir',
            fontSize: 12,
            fontWeight: 500,
            letterSpacing: 0,
            lineHeight: '16px',
            textTransform: 'none',
            "&:hover": {
                backgroundColor: '#081647',
                // Reset on touch devices, it doesn't add specificity
                "@media (hover: none)": {
                    backgroundColor: '#081647',
                    "&:active": {
                        backgroundColor: '#081647'
                    }
                },
                '&$disabled': {
                    backgroundColor: '#081647',
                },
            }
        }
    });
    const classes = useStyles();
    const history = useHistory();

    const { kycDetails, fetched } = useSelector(state => state.kyc)

    const handlePersonalInfoChange = (prop) => (event) => {
        if (isEnglishKeyboard(event.target.value)) {
            setPersonalInfo({ ...personalInfo, [prop]: event.target.value });
        }
    };

    const handleContactInfoChange = (prop) => (event) => {
        if (isEnglishKeyboard(event.target.value)) {
            setContactInfo({ ...contactInfo, [prop]: event.target.value });
        }
    };
    const handleCountryRegionChange = (prop, e) => {
        if (prop === 'country') {
            setContactInfo({ ...contactInfo, ['country']: e, ['province']: '' });
            if (userInfo.country === e) {
                setCountryRegionColor({ ...countryRegionColor, ['country']: '#9097A1' })
            } else {
                setCountryRegionColor({ country: 'black', province: 'black' });
            }

        }
        if (prop === 'province') {
            setContactInfo({ ...contactInfo, ['province']: e });
            if (userInfo.province === e) {
                setCountryRegionColor({ ...countryRegionColor, ['province']: '#9097A1' })
            } else {
                setCountryRegionColor({ ...countryRegionColor, ['province']: 'black' })
            }
        }
    }

    const goBack = () => {
        dispatch(fundsActions.clear())
        history.goBack()
    }

    useEffect(() => {
        setPhone(width <= 745)
        return () => {
        }
    }, [width]);

    useEffect(() => {
        dispatch(authActions.getUserInfo(token))
        dispatch(kycActions.syncKycStatus(token))
        dispatch(kycActions.syncAddressStatus(token))
        setLoaded(true)

        return () => {
        }
    }, []);

    useEffect(() => {
        if (fetched) {
            dispatch(kycActions.getKycDetails(token))
        }
    }, [fetched])

    useEffect(() => {
        if (updateInfoSuccess) {
            dispatch(authActions.getUserInfo(token))
            setUpdateSuccessVisible(true)
            setPersonalInfo({ firstName: '', lastName: '', dateOfBirth: '', occupation: '', employerName: '' })
            setContactInfo({ phone: '', address: '', country: '', province: '', city: '', postalCode: '' })
            dispatch(authActions.clearUpdateUserInfoState())
        }
        return () => {
        }
    }, [updateInfoSuccess])

    useEffect(() => {
        if (initialing) {
            setInitialing(false)
        } else {
            setContactInfo({ ...contactInfo, ['country']: userInfo.country, ['province']: userInfo.province })
        }
        return () => {
        }
    }, [userInfo])

    const renderPersonalInfo = () => {
        let noEdit = kycDetails.status === 'passed' || kycDetails.status === 'inReview'
        return (
            <>


                <div style={{ marginTop: phone ? 18 : 24, justifyContent: 'space-between', display: 'flex' }}>

                </div>



                <div style={{ marginTop: phone ? 20 : 32 }}>
                    <Typography className={phone ? classes.inputLabelPhone : classes.inputLabel}>
                        {t("occupation")}
                    </Typography>
                    <InputBase
                        className={phone ? classes.inputPhone : classes.input}
                        placeholder={userInfo.occupation}
                        value={personalInfo.occupation}
                        onChange={handlePersonalInfoChange('occupation')}
                        endAdornment={
                            <InputAdornment position="end">
                                <img alt='' src={focuses.occupation ? BlackEditIcon : EditIcon} style={phone ? { height: 13, width: 13 } : { height: 17, width: 17 }} />
                            </InputAdornment>
                        }
                        onFocus={() => { setFocuses({ ...focuses, ['occupation']: true }) }}
                        onBlur={() => { setFocuses({ ...focuses, ['occupation']: false }) }}
                    />
                </div>

                <div style={{ marginTop: phone ? 20 : 32 }}>
                    <Typography className={phone ? classes.inputLabelPhone : classes.inputLabel}>
                        {t("employerName")}
                    </Typography>
                    <InputBase
                        className={phone ? classes.inputPhone : classes.input}
                        placeholder={userInfo.employerName}
                        value={personalInfo.employerName}
                        onChange={handlePersonalInfoChange('employerName')}
                        endAdornment={
                            <InputAdornment position="end">
                                <img alt='' src={focuses.employerName ? BlackEditIcon : EditIcon} style={phone ? { height: 13, width: 13 } : { height: 17, width: 17 }} />
                            </InputAdornment>
                        }
                        onFocus={() => { setFocuses({ ...focuses, ['employerName']: true }) }}
                        onBlur={() => { setFocuses({ ...focuses, ['employerName']: false }) }}
                    />
                </div>
            </>
        )
    }

    const renderAddressInfo = () => {
        return (
            <>



                <div style={{ marginTop: phone ? 20 : 32 }}>
                    <Typography
                        className={phone ? classes.inputLabelPhone : classes.inputLabel}
                    >
                        {t('homeAddress')}
                    </Typography>

                    <InputBase
                        className={phone ? classes.inputPhone : classes.input}
                        placeholder={userInfo.address}
                        value={contactInfo.address}
                        onChange={handleContactInfoChange('address')}
                        endAdornment={
                            <InputAdornment position="end">
                                <img alt='' src={focuses.address ? BlackEditIcon : EditIcon} style={phone ? { height: 13, width: 13 } : { height: 17, width: 17 }} />
                            </InputAdornment>
                        }
                        onFocus={() => { setFocuses({ ...focuses, ['address']: true }) }}
                        onBlur={() => { setFocuses({ ...focuses, ['address']: false }) }}
                    />
                </div>

                <div style={{ marginTop: phone ? 20 : 32, justifyContent: 'space-between', display: 'flex' }}>
                    <div style={{ width: '45%' }}>
                        <Typography
                            className={phone ? classes.inputLabelPhone : classes.inputLabel}
                        >
                            {t('country')}
                        </Typography>

                        <CountryDropdown
                            className={phone ? classes.inputPhone : classes.input}
                            style={{
                                padding: "0px 30px 0px 20px",
                                color: countryRegionColor.country,
                                backgroundImage: `url(${focuses.country ? BlackEditIcon : EditIcon})`,
                                backgroundSize: phone ? '13px 13px' : '17px 17px',
                                backgroundPositionX: '91%',
                                outline: 'none',
                                border: "1px solid #EDEDED"
                            }}
                            showDefaultOption={false}
                            value={contactInfo.country}
                            onChange={(value) => handleCountryRegionChange('country', value)}
                            onFocus={() => { setFocuses({ ...focuses, ['country']: true }) }}
                            onBlur={() => { setFocuses({ ...focuses, ['country']: false }) }}
                        />
                    </div>

                    <div style={{ width: '45%' }}>
                        <Typography
                            className={phone ? classes.inputLabelPhone : classes.inputLabel}
                        >
                            {t('province')}
                        </Typography>

                        <RegionDropdown
                            className={phone ? classes.inputPhone : classes.input}
                            style={{
                                padding: "0px 30px 0px 20px",
                                color: countryRegionColor.province,
                                backgroundImage: `url(${focuses.province ? BlackEditIcon : EditIcon})`,
                                backgroundSize: phone ? '13px 13px' : '17px 17px',
                                backgroundPositionX: '91%',
                                outline: 'none',
                                border: "1px solid #EDEDED"
                            }}
                            country={contactInfo.country}
                            value={contactInfo.province}
                            onChange={(value) => handleCountryRegionChange('province', value)}
                            onFocus={() => { setFocuses({ ...focuses, ['province']: true }) }}
                            onBlur={() => { setFocuses({ ...focuses, ['province']: false }) }}
                        />
                    </div>
                </div>

                <div style={{ marginTop: phone ? 20 : 32, justifyContent: 'space-between', display: 'flex' }}>
                    <div style={{ width: '45%' }}>
                        <Typography
                            className={phone ? classes.inputLabelPhone : classes.inputLabel}
                        >
                            {t('city')}
                        </Typography>

                        <InputBase
                            className={phone ? classes.inputPhone : classes.input}
                            placeholder={userInfo.city}
                            value={contactInfo.city}
                            onChange={handleContactInfoChange('city')}
                            endAdornment={
                                <InputAdornment position="end">
                                    <img alt='' src={focuses.city ? BlackEditIcon : EditIcon} style={phone ? { height: 13, width: 13 } : { height: 17, width: 17 }} />
                                </InputAdornment>
                            }
                            onFocus={() => { setFocuses({ ...focuses, ['city']: true }) }}
                            onBlur={() => { setFocuses({ ...focuses, ['city']: false }) }}
                        />
                    </div>
                    <div style={{ width: '45%' }}>
                        <Typography
                            className={phone ? classes.inputLabelPhone : classes.inputLabel}
                        >
                            {t('postalCode')}
                        </Typography>

                        <InputBase
                            className={phone ? classes.inputPhone : classes.input}
                            placeholder={userInfo.postalCode}
                            value={contactInfo.postalCode}
                            onChange={handleContactInfoChange('postalCode')}
                            endAdornment={
                                <InputAdornment position="end">
                                    <img alt='' src={focuses.postalCode ? BlackEditIcon : EditIcon} style={phone ? { height: 13, width: 13 } : { height: 17, width: 17 }} />
                                </InputAdornment>
                            }
                            onFocus={() => { setFocuses({ ...focuses, ['postalCode']: true }) }}
                            onBlur={() => { setFocuses({ ...focuses, ['postalCode']: false }) }}
                        />
                    </div>
                </div>
            </>
        )
    }





    return (


        <div style={{ width: '100%', display: 'flex', alignItems: 'center', flexDirection: 'column' }}>
            <Helmet>
            <title>{t("titleUser")}</title>

                <meta name="keywords" content="Toronto, OTC, bitcoin, exchange, cryptocurrency, 多伦多, 场外交易平台, 比特币, 以太坊, 加元, 换汇，加密货币, 人民币,多倫多, 場外交易平台, 比特幣, 以太坊, 加元, 換匯, 人民幣, Yesbit, Fiat, CAD, BTC, ETH, USD, balance, user profile" />
            </Helmet>
            <div style={{ width: '60%', alignItems: 'center', textAlign: 'center', maxWidth: 543, minWidth: 317 }}>

                <div style={{ marginTop: phone ? 16 : 60, display: 'flex', width: '100%', justifyContent: 'space-between', alignItems: 'center' }}>

                    <Typography
                        style={
                            phone ? { color: "#1C2237", fontFamily: "Avenir", fontSize: 16, fontWeight: 500, letterSpacing: 0, lineHeight: '22px', textAlign: 'initial' }
                                : { color: "#1C2237", fontFamily: "Avenir", fontSize: 24, fontWeight: 500, letterSpacing: 0, lineHeight: '35px', textAlign: 'initial' }
                        }
                    >
                        { }
                        {
                            location.state === undefined ? t('addressInfo') :
                            location.state.viewMode === 1 ?
                                t('addressInfo')
                                :
                                t('personalInfo')

                        }
                    </Typography>



                    <Button

                        style={{ padding: 4 }}
                        onClick={() => { goBack() }}
                    >

                        <img src={backToIcon} style={{ width: phone ? 24 : 32, marginRight: 4 }} />


                    </Button>
                </div>
                <div style={{ height: 1, width: '100%', backgroundColor: '#EFEFEF', marginTop: 12, marginBottom: 36 }} />
                {
                    location.state === undefined ? renderAddressInfo() :
                        location.state.viewMode === 1 ?
                            renderAddressInfo()
                            :
                            renderPersonalInfo()


                }


                <Button
                    className={phone ? classes.buttonPhone : classes.button}
                    style={{ margin: phone ? '42px 0' : '53px 0', opacity: updateDisable() ? 0.3 : null }}
                    disabled={updateDisable()}
                    onClick={() => { setUpdateInfoVisible(true) }}
                >
                    {t('updateInfo')}
                </Button>

            </div>
            {/*更新用户信息弹框*/}
            <UpdateInfo
                onCancel={() => {
                    setUpdateInfoVisible(false);
                }}
                modalVisible={updateInfoVisible}
                t
                phone={phone}
                width={width}
                personalInfo={personalInfo}
                contactInfo={contactInfo}
            />
            {/*更新成功弹框*/}
            <UpdateSuccess
                onCancel={() => {
                    setUpdateSuccessVisible(false);
                }}
                modalVisible={updateSuccessVisible}
                t
                phone={phone}
                width={width}
            />

        </div>
    )
}
export default withTranslation()(UserCenter)
