import {
    CLEAR_TRANSFER_VOUCHER,
    GET_COMMISSION_AND_NFT,
    TRANSFER_VOUCHER,
    TRANSFER_VOUCHER_FAILED,
    TRANSFER_VOUCHER_SUCCEED,
    GET_COMMISSION_AND_NFT_SUCCEED,
    GET_COMMISSION_AND_NFT_FAILED,
    GET_NFT_CLAIMED,
    GET_NFT_CLAIMED_SUCCEED,
    GET_NFT_CLAIMED_FAILED,
    GET_NFT_ASSET,
    GET_NFT_ASSET_FAILED,
    GET_NFT_ASSET_SUCCEED,
    CLAIM_NFT,
    CLAIM_NFT_SUCCEED,
    CLAIM_NFT_FAILED,
    CLEAR_CLAIM_NFT,
    GET_COUNTDOWN,
    GET_COUNTDOWN_SUCCEED,
    GET_COUNTDOWN_FAILED,
    CLEAR_COUNTDOWN
} from '../constants';

export function nft (state = {
    commissionAndNft:{},
    nftClaimed:{},
    nftAsset:[],
    countdown:{}
}, action) {
    switch (action.type) {
        case TRANSFER_VOUCHER:
            return {
                ...state,
                transferVoucherSuccess: false,
                transferVoucherFailed: false,
                transferVoucherLoading: true,
                transferVoucherMsg: ''
            }
        case TRANSFER_VOUCHER_SUCCEED:
            return {
                ...state,
                transferVoucherMsg: '',
                transferVoucherSuccess: true,
                transferVoucherLoading: false,
            }
        case TRANSFER_VOUCHER_FAILED:
            return {
                ...state,
                transferVoucherMsg: action.message,
                transferVoucherFailed: true,
                transferVoucherLoading: false,
            }
        case CLEAR_TRANSFER_VOUCHER:
            return {
                ...state,
                transferVoucherFailed: false,
                transferVoucherSuccess: false,
            }

        case GET_COMMISSION_AND_NFT:
            return {
                ...state,
                commissionAndNftLoading: true,
                commissionAndNftMsg: ''
            }
        case GET_COMMISSION_AND_NFT_SUCCEED:
            return {
                ...state,
                commissionAndNft : action.data,
                commissionAndNftLoading: false,
            }
        case GET_COMMISSION_AND_NFT_FAILED:
            return {
                ...state,
                commissionAndNftMsg: action.message,
                commissionAndNftLoading: false,
            }

        case GET_NFT_CLAIMED:
            return {
                ...state,
                getNftClaimedLoading: true,
                getNftClaimedFailed: false,
                getNftClaimedSuccess: false,
                getNftClaimedMsg: ''
            }
        case GET_NFT_CLAIMED_SUCCEED:
            return {
                ...state,
                nftClaimed : action.data,
                getNftClaimedLoading: false,
                getNftClaimedSuccess: true,
            }
        case GET_NFT_CLAIMED_FAILED:
            return {
                ...state,
                getNftClaimedMsg: action.message,
                getNftClaimedLoading: false,
                getNftClaimedFailed: true,
            }


        case GET_NFT_ASSET:
            return {
                ...state,
                getNftAssetLoading: true,
                getNftAssetFailed: false,
                getNftAssetSuccess: false,
                getNftAssetMsg: ''
            }
        case GET_NFT_ASSET_SUCCEED:
            return {
                ...state,
                nftAsset : action.data,
                getNftAssetLoading: false,
                getNftAssetSuccess:true
            }
        case GET_NFT_ASSET_FAILED:
            return {
                ...state,
                nftAsset : [],
                getNftAssetMsg: action.message,
                getNftAssetLoading: false,
                getNftAssetFailed:true
            }

        case CLAIM_NFT:
            return {
                ...state,
                claimNftSuccess: false,
                claimNftFailed: false,
                claimNftLoading: true,
                claimNftMsg: ''
            }
        case CLAIM_NFT_SUCCEED:
            return {
                ...state,
                claimNft: action.data,
                claimNftSuccess: true,
                claimNftLoading: false,
            }
        case CLAIM_NFT_FAILED:
            return {
                ...state,
                claimNftMsg: action.message,
                claimNftFailed: true,
                claimNftLoading: false,
            }
        case CLEAR_CLAIM_NFT:
            return {
                ...state,
                claimNftFailed: false,
                claimNftSuccess: false,
            }


        case GET_COUNTDOWN:
            return {
                ...state,
                getCountdownLoading: true,
                getCountdownFailed: false,
                getCountdownSuccess: false,
                getCountdownMsg: ''
            }
        case GET_COUNTDOWN_SUCCEED:
            return {
                ...state,
                countdown : action.data,
                getCountdownLoading: false,
                getCountdownSuccess:true
            }
        case GET_COUNTDOWN_FAILED:
            return {
                ...state,
                getCountdownMsg: action.message,
                getCountdownLoading: false,
                getCountdownFailed:true
            }
        case CLEAR_COUNTDOWN:
            return{
                ...state,
                countdown : {}
            }
        default:
            return state;
    }
};
