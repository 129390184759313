export const LOGIN = 'LOGIN';
export const LOGIN_SUCCEED = 'LOGIN_SUCCEED';
export const LOGIN_FAILED = 'LOGIN_FAILED';
export const CLEAR_LOGIN_STATE = 'CLEAR_LOGIN_STATE';
export const LOGOUT = 'LOGOUT';

export const SIGNUP = 'SIGNUP';
export const SIGNUP_FAILED = 'SIGNUP_FAILED';
export const SIGNUP_SUCCEED = 'SIGNUP_SUCCEED';
export const CLEAR_SIGNUP_STATE = 'CLEAR_SIGNUP_STATE';

export const GET_USER_INFO = 'GET_USER_INFO';
export const GET_USER_INFO_SUCCEED = 'GET_USER_INFO_SUCCEED';
export const GET_USER_INFO_FAILED = 'GET_USER_INFO_FAILED';

export const UPDATE_USER_INFO = 'UPDATE_USER_INFO';
export const UPDATE_USER_INFO_SUCCEED = 'UPDATE_USER_INFO_SUCCEED';
export const UPDATE_USER_INFO_FAILED = 'UPDATE_USER_INFO_FAILED';
export const CLEAR_UPDATE_INFO_STATE = 'CLEAR_UPDATE_INFO_STATE';

export const CHECK_EXIST = 'CHECK_EXIST';
export const CHECK_FAILED = 'CHECK_FAILED';
export const CHECK_SUCCEED = 'CHECK_SUCCEED';

export const CHECK_USER = 'CHECK_USER';
export const CHECK_USER_FAILED = 'CHECK_USER_FAILED';
export const CHECK_USER_SUCCEED = 'CHECK_USER_SUCCEED';
export const CLEAR_CHECK_USER_STATE ='CLEAR_CHECK_USER_STATE';

export const RESET_PASSWORD = 'RESET_PASSWORD';
export const RESET_PASSWORD_FAILED = 'RESET_PASSWORD_FAILED';
export const RESET_PASSWORD_SUCCEED = 'RESET_PASSWORD_SUCCEED';

export const CLEAR_RESET_PASSWORD_STATE = 'CLEAR_RESET_PASSWORD_STATE'

export const INIT_KYC_LINK = 'INIT_KYC_LINK'
export const INIT_KYC_LINK_SUCCEED = 'INIT_KYC_LINK_SUCCEED'
export const INIT_KYC_LINK_FAILED = 'INIT_KYC_LINK_FAILED'

export const SYNC_KYC_STATUS = 'SYNC_KYC_STATUS'
export const SYNC_KYC_STATUS_SUCCEED = 'SYNC_KYC_STATUS_SUCCEED'
export const SYNC_KYC_STATUS_FAILED = 'SYNC_KYC_STATUS_FAILED'

export const GET_KYC_DETAILS = 'GET_KYC_DETAILS'
export const GET_KYC_DETAILS_SUCCEED = 'GET_KYC_DETAILS_SUCCEED'
export const GET_KYC_DETAILS_FAILED = 'GET_KYC_DETAILS_FAILED'

export const UPDATE_KYC_STATUS = 'UPDATE_KYC_STATUS'
export const UPDATE_KYC_STATUS_SUCCEED = 'UPDATE_KYC_STATUS_SUCCEED'
export const UPDATE_KYC_STATUS_FAILED = 'UPDATE_KYC_STATUS_FAILED'

export const GET_USER_CAPITALS = 'GET_USER_CAPITALS'
export const GET_USER_CAPITALS_SUCCEED = 'GET_USER_CAPITALS_SUCCEED'
export const GET_USER_CAPITALS_FAILED = 'GET_USER_CAPITALS_FAILED'

export const GET_FUNDS_TRANSACTIONS = 'GET_FUNDS_TRANSACTIONS'
export const GET_FUNDS_FIAT_TRANSACTIONS_SUCCEED = 'GET_FUNDS_FIAT_TRANSACTIONS_SUCCEED'
export const GET_FUNDS_CRYPTO_TRANSACTIONS_SUCCEED = 'GET_FUNDS_CRYPTO_TRANSACTIONS_SUCCEED'
export const GET_FUNDS_TRANSACTIONS_FAILED = 'GET_FUNDS_TRANSACTIONS_FAILED'

export const FIAT_DEPOSIT = 'FIAT_DEPOSIT'
export const FIAT_DEPOSIT_SUCCEED = 'FIAT_DEPOSIT_SUCCEED'
export const FIAT_DEPOSIT_FAILED = 'FIAT_DEPOSIT_FAILED'

export const PRICE_QUOTE = 'PRICE_QUOTE'
export const PRICE_QUOTE_SUCCEED = 'PRICE_QUOTE_SUCCEED'
export const PRICE_QUOTE_FAILED = 'PRICE_QUOTE_FAILED'

export const TRADE_PRICE_QUOTE = 'TRADE_PRICE_QUOTE'
export const TRADE_PRICE_QUOTE_SUCCEED = 'TRADE_PRICE_QUOTE_SUCCEED'
export const TRADE_PRICE_QUOTE_FAILED = 'TRADE_PRICE_QUOTE_FAILED'

export const GET_LIVE_PRICE = 'GET_LIVE_PRICE'
export const GET_LIVE_PRICE_SUCCEED = 'GET_LIVE_PRICE_SUCCEED'
export const GET_LIVE_PRICE_FAILED = 'GET_LIVE_PRICE_FAILED'

export const CLEAR_TRADE_QUOTE = 'CLEAR_TRADE_QUOTE'

export const TRADE = 'TRADE'
export const TRADE_SUCCEED = 'TRADE_SUCCEED'
export const TRADE_FAILED = 'TRADE_FAILED'
export const CLEAR_TRADE = 'CLEAR_TRADE'

export const GET_TRADE_TRANSACTIONS = 'GET_TRADE_TRANSACTIONS'
export const GET_TRADE_TRANSACTIONS_SUCCEED = 'GET_TRADE_TRANSACTIONS_SUCCEED'
export const GET_TRADE_TRANSACTIONS_FAILED = 'GET_TRADE_TRANSACTIONS_FAILED'

export const CLEAR_DEPOSIT = 'CLEAR_DEPOSIT'

export const UPDATE_LOGIN_PASSWORD = 'UPDATE_LOGIN_PASSWORD'
export const UPDATE_LOGIN_PASSWORD_SUCCEED = 'UPDATE_LOGIN_PASSWORD_SUCCEED'
export const UPDATE_LOGIN_PASSWORD_FAILED = 'UPDATE_LOGIN_PASSWORD_FAILED'
export const CLEAR_UPDATE_LOGIN_PASSWORD = 'CLEAR_UPDATE_LOGIN_PASSWORD'

export const FIAT_WITHDRAW = 'FIAT_WITHDRAW'
export const FIAT_WITHDRAW_SUCCEED = 'FIAT_WITHDRAW_SUCCEED'
export const FIAT_WITHDRAW_FAILED = 'FIAT_WITHDRAW_FAILED'
export const CLEAR_WITHDRAW = 'CLEAR_WITHDRAW'

export const UPDATE_VERIFICATION_PHONE = 'UPDATE_VERIFICATION_PHONE'
export const UPDATE_VERIFICATION_PHONE_SUCCEED = 'UPDATE_VERIFICATION_PHONE_SUCCEED'
export const UPDATE_VERIFICATION_PHONE_FAILED = 'UPDATE_VERIFICATION_PHONE_FAILED'

export const GET_VERIFICATION_CODE = 'GET_VERIFICATION_CODE'
export const GET_VERIFICATION_CODE_SUCCEED = 'GET_VERIFICATION_CODE_SUCCEED'
export const GET_VERIFICATION_CODE_FAILED = 'GET_VERIFICATION_CODE_FAILED'

export const VALIDATE_VERIFICATION_CODE = 'VALIDATE_VERIFICATION_CODE'
export const VALIDATE_VERIFICATION_CODE_SUCCEED = 'VALIDATE_VERIFICATION_CODE_SUCCEED'
export const VALIDATE_VERIFICATION_CODE_FAILED = 'VALIDATE_VERIFICATION_CODE_FAILED'

export const CLEAR_PHONE_VERIFY = 'CLEAR_PHONE_VERIFY'

export const CRYPTO_WITHDRAW = 'CRYPTO_WITHDRAW'
export const CRYPTO_WITHDRAW_SUCCEED = 'CRYPTO_WITHDRAW_SUCCEED'
export const CRYPTO_WITHDRAW_FAILED = 'CRYPTO_WITHDRAW_FAILED'

export const GET_TOKEN_INFO = 'GET_TOKEN_INFO'
export const GET_TOKEN_INFO_SUCCEED = 'GET_TOKEN_INFO_SUCCEED'
export const GET_TOKEN_INFO_FAILED = 'GET_TOKEN_INFO_FAILED'

export const GET_FEE = 'GET_FEE'
export const GET_FEE_SUCCEED = 'GET_FEE_SUCCEED'
export const GET_FEE_FAILED = 'GET_FEE_FAILED'

export const GET_ADDRESS = 'GET_ADDRESS'
export const GET_ADDRESS_SUCCEED = 'GET_ADDRESS_SUCCEED'
export const GET_ADDRESS_FAILED = 'GET_ADDRESS_FAILED'

export const GET_AQUA_BALANCE = 'GET_AQUA_BALANCE'
export const GET_AQUA_BALANCE_SUCCEED = 'GET_AQUA_BALANCE_SUCCEED'
export const GET_AQUA_BALANCE_FAILED = 'GET_AQUA_BALANCE_FAILED'

export const SEND_EMAIL_CODE = 'SEND_EMAIL_CODE'
export const SEND_EMAIL_CODE_SUCCEED = 'SEND_EMAIL_CODE_SUCCEED'
export const SEND_EMAIL_CODE_FAILED = 'SEND_EMAIL_CODE_FAILED'

export const SEND_EMAIL_SUBSCRIBE = 'SEND_EMAIL_CODE'
export const SEND_EMAIL_SUBSCRIBE_SUCCEED = 'SEND_EMAIL_CODE_SUCCEED'
export const SEND_EMAIL_SUBSCRIBE_FAILED = 'SEND_EMAIL_CODE_FAILED'

export const EXPIRE_AQUA_QUOTE = 'EXPIRE_AQUA_QUOTE'
export const EXPIRE_AQUA_QUOTE_SUCCEED = 'EXPIRE_AQUA_QUOTE_SUCCEED'
export const EXPIRE_AQUA_QUOTE_FAILED = 'EXPIRE_AQUA_QUOTE_FAILED'

export const GET_REFERRALS = 'GET_REFERRALS'
export const GET_REFERRALS_SUCCEED = 'GET_REFERRALS_SUCCEED'
export const GET_REFERRALS_FAILED = 'GET_REFERRALS_FAILED'

export const GET_USERNAME_BY_REFERRAL_CODE = 'GET_USERNAME_BY_REFERRAL_CODE'
export const GET_USERNAME_BY_REFERRAL_CODE_SUCCEED = 'GET_USERNAME_BY_REFERRAL_CODE_SUCCEED'
export const GET_USERNAME_BY_REFERRAL_CODE_FAILED = 'GET_USERNAME_BY_REFERRAL_CODE_FAILED'
export const CLEAR_REFERRER_USERNAME = 'CLEAR_REFERRER_USERNAME'

export const INIT_ADDRESS_VERIFICATION = 'INIT_ADDRESS_VERIFICATION'
export const INIT_ADDRESS_VERIFICATION_SUCCEED = 'INIT_ADDRESS_VERIFICATION_SUCCEED'
export const INIT_ADDRESS_VERIFICATION_FAILED = 'INIT_ADDRESS_VERIFICATION_FAILED'
export const GET_DOCUMENT_DETAILS = 'GET_DOCUMENT_DETAILS'
export const GET_DOCUMENT_DETAILS_SUCCEED = 'GET_DOCUMENT_DETAILS_SUCCEED'
export const GET_DOCUMENT_DETAILS_FAILED = 'GET_DOCUMENT_DETAILS_FAILED'
export const SYNC_ADDRESS_STATUS = 'SYNC_ADDRESS_STATUS'
export const SYNC_ADDRESS_STATUS_SUCCEED = 'SYNC_ADDRESS_STATUS_SUCCEED'
export const SYNC_ADDRESS_STATUS_FAILED = 'SYNC_ADDRESS_STATUS_FAILED'

export const GET_BTC_QUOTE = 'GET_BTC_QUOTE'
export const GET_BTC_QUOTE_SUCCEED = 'GET_BTC_QUOTE_SUCCEED'
export const GET_BTC_QUOTE_FAILED = 'GET_BTC_QUOTE_FAILED'
export const GET_ETH_QUOTE = 'GET_ETH_QUOTE'
export const GET_ETH_QUOTE_SUCCEED = 'GET_ETH_QUOTE_SUCCEED'
export const GET_ETH_QUOTE_FAILED = 'GET_ETH_QUOTE_FAILED'

export const GET_BTC_PRICE_CHANGE = 'GET_BTC_PRICE_CHANGE'
export const GET_BTC_PRICE_CHANGE_SUCCEED = 'GET_BTC_PRICE_CHANGE_SUCCEED'
export const GET_BTC_PRICE_CHANGE_FAILED = 'GET_BTC_PRICE_CHANGE_FAILED'

export const GET_ETH_PRICE_CHANGE = 'GET_ETH_PRICE_CHANGE'
export const GET_ETH_PRICE_CHANGE_SUCCEED = 'GET_ETH_PRICE_CHANGE_SUCCEED'
export const GET_ETH_PRICE_CHANGE_FAILED = 'GET_ETH_PRICE_CHANGE_FAILED'

export const GET_USDT_PRICE_CHANGE = 'GET_USDT_PRICE_CHANGE'
export const GET_USDT_PRICE_CHANGE_SUCCEED = 'GET_USDT_PRICE_CHANGE_SUCCEED'
export const GET_USDT_PRICE_CHANGE_FAILED = 'GET_USDT_PRICE_CHANGE_FAILED'

export const GET_BANNER_INFO = 'GET_BANNER_INFO'
export const GET_BANNER_INFO_SUCCEED = 'GET_BANNER_INFO_SUCCEED'
export const GET_BANNER_INFO_FAILED = 'GET_BANNER_INFO_FAILED'

export const TRANSFER_VOUCHER = 'TRANSFER_VOUCHER'
export const TRANSFER_VOUCHER_SUCCEED = 'TRANSFER_VOUCHER_SUCCEED'
export const TRANSFER_VOUCHER_FAILED = 'TRANSFER_VOUCHER_FAILED'
export const CLEAR_TRANSFER_VOUCHER = 'CLEAR_TRANSFER_VOUCHER'

export const GET_COMMISSION_AND_NFT = 'GET_COMMISSION_AND_NFT'
export const GET_COMMISSION_AND_NFT_SUCCEED = 'GET_COMMISSION_AND_NFT_SUCCEED'
export const GET_COMMISSION_AND_NFT_FAILED = 'GET_COMMISSION_AND_NFT_FAILED'

export const CLAIM_NFT = 'CLAIM_NFT'
export const CLAIM_NFT_SUCCEED = 'CLAIM_NFT_SUCCEED'
export const CLAIM_NFT_FAILED = 'CLAIM_NFT_FAILED'
export const CLEAR_CLAIM_NFT = 'CLEAR_CLAIM_NFT'

export const GET_NFT_CLAIMED = 'GET_NFT_CLAIMED'
export const GET_NFT_CLAIMED_SUCCEED = 'GET_NFT_CLAIMED_SUCCEED'
export const GET_NFT_CLAIMED_FAILED = 'GET_NFT_CLAIMED_FAILED'

export const GET_NFT_ASSET = 'GET_NFT_ASSET'
export const GET_NFT_ASSET_SUCCEED = 'GET_NFT_ASSET_SUCCEED'
export const GET_NFT_ASSET_FAILED = 'GET_NFT_ASSET_FAILED'

export const GET_COUNTDOWN = 'GET_COUNTDOWN'
export const GET_COUNTDOWN_SUCCEED = 'GET_COUNTDOWN_SUCCEED'
export const GET_COUNTDOWN_FAILED = 'GET_COUNTDOWN_FAILED'
export const CLEAR_COUNTDOWN = 'CLEAR_COUNTDOWN'

export const GET_LATEST_ARTICLE = 'GET_LATEST_ARTICLE'
export const GET_LATEST_ARTICLE_SUCCEED = 'GET_LATEST_ARTICLE_SUCCEED'
export const GET_LATEST_ARTICLE_FAILED = 'GET_LATEST_ARTICLE_FAILED'

export const GET_OAUTH_TOKEN = 'GET_OAUTH_TOKEN'
export const GET_OAUTH_TOKEN_SUCCEED = 'GET_OAUTH_TOKEN_SUCCEED'
export const GET_OAUTH_TOKEN_FAILED = 'GET_OAUTH_TOKEN_FAILED'

export const CHECK_ADDR_VALIDITY = 'CHECK_ADDR_VALIDITY'
export const CHECK_ADDR_VALIDITY_SUCCEED = 'CHECK_ADDR_VALIDITY_SUCCEED'
export const CHECK_ADDR_VALIDITY_FAILED = 'CHECK_ADDR_VALIDITY_FAILED'

export const CHECK_VERIFICATION_DETAIL = 'CHECK_VERIFICATION_DETAIL'
export const CHECK_VERIFICATION_DETAIL_SUCCEED = 'CHECK_VERIFICATION_DETAIL_SUCCEED'
export const CHECK_VERIFICATION_DETAIL_FAILED = 'CHECK_VERIFICATION_DETAIL_FAILED'
