import {withTranslation} from "react-i18next";
import {Button, makeStyles, Modal, Typography} from "@material-ui/core";
import React, {useEffect, useState} from "react";
import {useDispatch, useSelector} from "react-redux";
import x from "../../../images/closePopIcon.png";
import './LogoutModal.css'
import {motion} from "framer-motion"
import arrorTo from "../../../images/arrorTo.png"
import newMessage from "../../../images/newMessage.png";
import { formDateString } from '../../../utils/ConvertDate'

const AnnocementModal = (props) => {
    const {
        onCancel: handleClose,
        modalVisible,
        t,
        phone,
        width,
    } = props;
    const useStyles = makeStyles((theme) => ({
        modal: {
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            textAlign: 'center',
        },
        contactDiv: {
            height: phone ? 40 : 66,
            width: '80%',
            maxWidth: 425,
            borderRadius: 12,
            backgroundColor: 'white',
            margin: "21px auto 0px",
            position: 'relative',
            border: "1px solid #EDEDED"
        },
        font: {
            color: '#1C2237',
            fontFamily: 'Avenir',
            fontSize: 16,
            fontWeight: 500,
            letterSpacing: 0,
            textAlign: 'center',
        },
        selected: {
            color: '#3254FA !important',
            borderBottom: "2px solid #3254FA"
        },

        popTitle: {
            fontSize: 14, fontWeight: '500', fontFamily: 'Avenir', whiteSpace: 'nowrap', maxWidth: 200, textOverflow: 'ellipsis', overflow: 'hidden'
        },
        popSubTitle: {
            fontSize: 12, fontWeight: '500', color: "#8B96A7", fontFamily: 'Avenir', textAlign: 'left', whiteSpace: 'nowrap', maxWidth: 200, textOverflow: 'ellipsis', overflow: 'hidden'
        }
    }));

    const [showEmptyView, setShowEmptyView] = useState(true)
    const classes = useStyles();
    const { articleList } = useSelector(state => state.auth)

    useEffect(() => {
        setShowEmptyView(articleList.length === 0)
    }, [])


    const cellComponent = (title, content, url, date, index) => {
        return (
            <div onClick={() => window.open(url,'_blank')} key={index} style={{ display: 'flex', alignItems: 'center', flexDirection: 'column', marginBottom: 16 }}>
                <div style={{ width: "91%", display: 'flex', flexDirection: 'row', alignItems: 'center' }}>

                    <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-start', overflow: 'hidden', width: '100%' }}>
                        <div style={{ flexDirection: 'row', display: 'flex', alignItems: 'center' }}>
                            <img src={newMessage} style={{ width: 20, marginRight: 12 }}></img>

                            <Typography className={classes.popTitle}>
                                {title}
                            </Typography>
                        </div>
                        <Typography className={classes.popSubTitle} style={{ marginTop: 0, fontSize: 10 }}>
                            {formDateString(date)}
                        </Typography>
                    </div>
                    <img src={arrorTo} style={{ height: 16, marginLeft: 12 }}></img>

                </div>
                {/* <div style={{ width: '91%', height: 1, backgroundColor: '#EAEAEA', marginTop: 8, marginBottom: 8 }}></div> */}
            </div>
        )
    }
    const ContactUsBody = (
        <motion.div
            initial={{ opacity: 0, scale: 0 }}
            animate={{ opacity: 1, scale: 1 }}
        >
            <div style={{
                backgroundColor: 'white', borderRadius: 20, width: " 89vw", overflow: 'auto', outline: 'none'
            }}>
                <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', marginLeft: 16, marginRight: 16,marginTop:16}}>
                    <Typography className={classes.popTitle} style={{  fontSize: 20, color: '#8B96A7' }}>
                        {t("announcement")}
                    </Typography>

                    <Button
                        style={{
                            padding: 0,
                            minHeight: 0,
                            minWidth: 0,
                        }}
                        onClick={handleClose}
                    >
                        <img alt='' src={x} style={{ height: 24, width: 24 }} />
                    </Button>


                </div>

                <div style={{ marginTop: 15,  height: 1,  marginLeft: 16, marginRight: 16 ,backgroundColor:'#F2F2F2',marginBottom:16}} />






                {
                    showEmptyView ?
                        <div style={{ height: 60, width: '100%', display: 'flex', alignItems: 'center', justifyContent: 'center',marginBottom:16 }}>
                            <Typography style={{ fontSize: 14, fontWeight: '500', color: "#8B96A7", fontFamily: 'Avenir' }}>
                                {t("noAnnocement")}
                            </Typography>
                        </div>
                        :
                        articleList.length < 5 ?
                            articleList.map((item, index) =>
                                cellComponent(item.title, item.content, item.html_url, item.updated_at, index)
                            ) :
                            articleList.slice(articleList.length - 5, ).map((item, index) =>
                                cellComponent(item.title, item.content, item.html_url, item.updated_at, index)
                            )
                }





            </div >
        </motion.div>
    );

    return (
        <Modal
            open={modalVisible}

            onClose={() => {
                handleClose()
            }}
            className={classes.modal}
            aria-labelledby="simple-modal-title"
            aria-describedby="simple-modal-description"
            BackdropProps={{
                className:
                    'modalBackground'
            }}
        >
            {ContactUsBody}
        </Modal>
    )
}


export default withTranslation()(AnnocementModal);
