import React from 'react'

// import './AboutUs.css'
import Checkbox from '@material-ui/core/Checkbox';
import FormGroup from '@material-ui/core/FormGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import TextField from '@material-ui/core/TextField';
import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import emailjs from 'emailjs-com';
import { withTranslation } from 'react-i18next';

import { toast } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'
import aboutUs from '../../images/aboutUs.png';
import wWeDo from '../../images/wWeDo.png';


import yesbitComLogo from '../../images/home/yesbitComLogo.png';

import yesbitLogoWithBG from '../../images/yesbitLogoWithBG.png';
import tradingAnimation from '../../images/tradingAnimation.json';
import codingAnimation from '../../images/codingAnimation.json';

import Lottie from 'lottie-react-web'

import { Typography } from '@material-ui/core';

toast.configure()
function AboutUs({ width,t }) {



    return (
        <>
            {width < 1200 ?
                <div className=""
                    style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center' }}
                >
                    <div style={{ height: "40vh", width: "100%", marginTop: 0, display: 'flex', backgroundColor: '#f8f8f8', alignItems: "center", justifyContent: 'center', marginBottom: "6vh" }}>

                        <div style={{ width: '85%', display: 'flex', alignItems: 'left', justifyContent: 'center', flexDirection: 'column' }}>
                            <Typography style={{ fontSize: 24, fontWeight: 'bold', fontFamily: 'Avenir', textTransform: 'none', }}>{t('about_Title')}</Typography>
                            <Typography style={{ fontSize: 12, fontWeight: '500', color: '#8B96A7', fontFamily: 'Avenir', textTransform: 'none', }}>{t('about_SubTitle')}</Typography>
                            <img style={{ width: 120, marginTop: 12 }} src={yesbitLogoWithBG}></img>

                        </div>


                    </div>

                    <div style={{ width: "100%", display: 'flex', marginTop: 0, flexDirection: 'column', alignItems: 'center' }}>

                        <Typography style={{ fontSize: 10, fontWeight: 'bold', fontFamily: 'Avenir', textTransform: 'none', marginBottom: 16 }}>WHAT WE DO</Typography>


                        <div style={{ width: '95%', display: 'flex', alignItems: 'center', justifyContent: 'center', flexDirection: 'column' }}>
                            <div style={{ width: "91%", display: 'flex', alignItems: 'center', justifyContent: 'center', border: "1px solid #000000", borderRadius: 24 }}>
                                <Lottie

                                    // direction={success ? 1 : -1}
                                    options={{
                                        animationData: codingAnimation,
                                        loop: true,
                                    }}
                                />
                            </div>
                            <div style={{ width: '100%', display: 'flex', alignItems: 'left', justifyContent: 'center', flexDirection: 'column', marginTop: 16 }}>
                                <Typography style={{ marginLeft: 16, marginRight: 16, fontSize: 20, fontWeight: 'bold', fontFamily: 'Avenir', textTransform: 'none', }}>{t('about_SoftwareDevelop')}</Typography>
                                <Typography style={{ marginLeft: 16, marginRight: 16, fontSize: 12, fontWeight: '500', color: '#8B96A7', fontFamily: 'Avenir', textTransform: 'none', }}>{t('about_SoftwareDevelopSubtitle')}</Typography>

                            </div>
                        </div>

                    </div>

                    <div style={{ width: "100%", display: 'flex', flexDirection: 'column', alignItems: 'center', marginTop: 40 }}>

                        <div style={{ width: '95%', display: 'flex', alignItems: 'center', justifyContent: 'center', flexDirection: 'column' }}>
                            <div style={{ width: "91%", display: 'flex', alignItems: 'center', justifyContent: 'center', border: "1px solid #000000", borderRadius: 24 }}>
                                <Lottie

                                    options={{
                                        animationData: tradingAnimation,
                                        loop: true,
                                    }}
                                />
                            </div>

                            <div style={{ width: '100%', display: 'flex', alignItems: 'left', justifyContent: 'center', flexDirection: 'column', marginTop: 16 }}>
                                <Typography style={{ marginLeft: 16, marginRight: 16, fontSize: 20, fontWeight: 'bold', fontFamily: 'Avenir', textTransform: 'none', }}>{t('about_OTC')}</Typography>
                                <Typography style={{ marginLeft: 16, marginRight: 16, fontSize: 12, fontWeight: '500', color: '#8B96A7', fontFamily: 'Avenir', textTransform: 'none', }}>{t('about_OTCSubtitle')}</Typography>

                            </div>
                        </div>
                    </div>

                    <div style={{ height: 200, backgroundColor: '#f8f8f8', marginTop: 80, display: 'flex', borderRadius: 24, flexDirection: 'column', alignItems: "center", justifyContent: 'center' }}>

                        <Typography style={{ fontSize: 20, fontWeight: 'bold', fontFamily: 'Avenir', textTransform: 'none', marginLeft: 16, marginRight: 16, }}>{t('about_JoinUs')}</Typography>
                        <Typography style={{ fontSize: 12, fontWeight: '500', color: '#8B96A7', fontFamily: 'Avenir', textTransform: 'none', marginLeft: 16, marginRight: 16, textAlign: 'center', marginTop: 12 }}>{t('about_JoinUsTitle')}</Typography>
                        <img style={{ width: 60, marginTop: 12 }} src={yesbitComLogo}></img>




                    </div>
                </div>
                :
                <div className=""
                    style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center' }}
                >
                    <div style={{ height: "40vh", width: 1100, marginTop: "8vh", display: 'flex', backgroundColor: '#f8f8f8', alignItems: "center", justifyContent: 'center', borderRadius: 24, marginBottom: "6vh" }}>

                        <div style={{ width: '85%', display: 'flex', alignItems: 'left', justifyContent: 'center', flexDirection: 'column' }}>
                            <Typography style={{ fontSize: 32, fontWeight: 'bold', fontFamily: 'Avenir', textTransform: 'none', }}>{t('about_Title')}</Typography>
                            <Typography style={{ fontSize: 16, fontWeight: '500', color: '#8B96A7', fontFamily: 'Avenir', textTransform: 'none', }}>{t('about_SubTitle')}</Typography>
                            <img style={{ width: 200, marginTop: 12 }} src={yesbitLogoWithBG}></img>

                        </div>


                    </div>

                    <div style={{ height: 320, width: 1100, display: 'flex', marginTop: 60 }}>

                        <div style={{ width: '5%', display: 'flex', alignItems: 'center' }}>
                            <img style={{ width: 12 }} src={wWeDo}></img>
                        </div>
                        <div style={{ width: '95%', display: 'flex', alignItems: 'left', justifyContent: 'center', flexDirection: 'row' }}>
                            <div style={{ width: 550, height: "100%", display: 'flex', alignItems: 'center', justifyContent: 'center', border: "1px solid #000000", borderRadius: 24 }}>
                                <Lottie

                                    // direction={success ? 1 : -1}
                                    options={{
                                        animationData: codingAnimation,
                                        loop: true,
                                    }}
                                />
                            </div>
                            <div style={{ width: '50%', display: 'flex', alignItems: 'left', justifyContent: 'center', flexDirection: 'column' }}>
                                <Typography style={{ marginLeft: 24, fontSize: 26, fontWeight: 'bold', fontFamily: 'Avenir', textTransform: 'none', }}>{t('about_SoftwareDevelop')}</Typography>
                                <Typography style={{ marginLeft: 24, fontSize: 16, fontWeight: '500', color: '#8B96A7', fontFamily: 'Avenir', textTransform: 'none', }}>{t('about_SoftwareDevelopSubtitle')}</Typography>

                            </div>
                        </div>

                    </div>

                    <div style={{ height: 320, width: 1100, display: 'flex', marginTop: "16vh" }}>
                        <div style={{ width: '5%', display: 'flex', alignItems: 'center' }}>
                        </div>
                        <div style={{ width: '95%', display: 'flex', alignItems: 'left', justifyContent: 'center', flexDirection: 'row' }}>
                            <div style={{ width: '50%', display: 'flex', alignItems: 'left', justifyContent: 'center', flexDirection: 'column' }}>
                                <Typography style={{ marginRight: 24, fontSize: 26, fontWeight: 'bold', fontFamily: 'Avenir', textTransform: 'none', }}>{t('about_OTC')}</Typography>
                                <Typography style={{ marginRight: 24, fontSize: 16, fontWeight: '500', color: '#8B96A7', fontFamily: 'Avenir', textTransform: 'none', }}>{t('about_OTCSubtitle')}</Typography>

                            </div>
                            <div style={{ width: 550, height: "100%", display: 'flex', alignItems: 'center', justifyContent: 'center', border: "1px solid #000000", borderRadius: 24 }}>
                                <Lottie

                                    // direction={success ? 1 : -1}
                                    options={{
                                        animationData: tradingAnimation,
                                        loop: true,
                                    }}
                                />
                            </div>
                        </div>
                    </div>

                    <div style={{ height: 280, backgroundColor: '#f8f8f8', width: 800, marginTop: "16vh", display: 'flex', borderRadius: 24, flexDirection: 'column', alignItems: "center", justifyContent: 'center', marginBottom: "16vh" }}>

                        <Typography style={{ fontSize: 26, fontWeight: 'bold', fontFamily: 'Avenir', textTransform: 'none', }}>{t('about_JoinUs')}</Typography>
                        <Typography style={{ fontSize: 16, fontWeight: '500', color: '#8B96A7', fontFamily: 'Avenir', textTransform: 'none', marginLeft: 80, marginRight: 80, textAlign: 'center', marginTop: 12 }}>{t('about_JoinUsTitle')}</Typography>
                        <img style={{ width: 120, marginTop: 12 }} src={yesbitComLogo}></img>




                    </div>
                </div>
            }




        </>


    )
}

export default withTranslation()(AboutUs)
