import React, { useEffect, useState } from 'react'
import './ProcessSection.css'
import { withTranslation } from 'react-i18next';
import { useDispatch, useSelector } from "react-redux";
import { tradeActions } from "../../redux/actions/tradeActions";
import { fundsActions } from "../../redux/actions/fundsActions";

import {
    Button,
    Typography,
    TextField,
    makeStyles,
    MenuItem,
    Avatar,
    Hidden
} from '@material-ui/core';

import { FormatNumber, getIcons, roundingDown } from "../../utils/Common";

function TodayMarket({ t, width, height, phone }) {
    const useStyles = makeStyles({
        fontStyle: {
            fontFamily: "Avenir", fontWeight: '500'
        }
    })
    const { ethPriceChange, btcPriceChange, usdtPriceChange } = useSelector(state => state.trade)
    const { userCapitals, priceQuote, ethQuote, btcQuote } = useSelector(state => state.funds);

    const classes = useStyles();
    const dispatch = useDispatch();

    const [priceInfo, setPriceInfo] = useState([
        // {
        //     token1: 'USDT', priceChange: '0.001',
        //     priceChangePercent: '0.00', quote: ethQuote.price
        // },
        {
            token1: 'ETH', priceChange: '0.001',
            priceChangePercent: '0.00', quote: priceQuote.price
        },
        {
            token1: 'BTC', priceChange: '0.001',
            priceChangePercent: '0.00', quote: btcQuote.price
        },

    ])

    const [quote, setQuote] = useState({
        ETH: ethQuote,
        BTC: btcQuote,
        USDT: priceQuote
    })

    useEffect(() => {
        setQuote({
            ETH: ethQuote,
            BTC: btcQuote,
            USDT: priceQuote
        })
    }, [priceQuote, ethQuote, btcQuote])
    useEffect(() => {
        dispatch(tradeActions.getPriceChange({
            token1: 'ETH',
            token2: 'USDT'
        }))

        dispatch(tradeActions.getPriceChange({
            token1: 'BTC',
            token2: 'USDT'
        }))

        dispatch(tradeActions.getPriceChange({
            token1: 'USDT',
            token2: 'CAD'
        }))

        dispatch(tradeActions.getBannerInfo())

    }, [])


    useEffect(() => {
        dispatch(fundsActions.priceQuoteNoAuth({
            "ticker": "USDT-CAD",
            "deliverQuantity": "1",
            "tradeSide": "buy"
        }))
        dispatch(fundsActions.priceETHNoAuth({
            "ticker": "ETH-CAD",
            "deliverQuantity": "1",
            "tradeSide": "buy"
        }))
        dispatch(fundsActions.priceBTCNoAuth({
            "ticker": "BTC-CAD",
            "deliverQuantity": "1",
            "tradeSide": "buy"
        }))

    }, [])

    useEffect(() => {
        arrangePriceInfo(ethPriceChange)
    }, [ethPriceChange])

    useEffect(() => {
        arrangePriceInfo(btcPriceChange)
    }, [btcPriceChange])

    useEffect(() => {
        arrangePriceInfo(usdtPriceChange)
    }, [usdtPriceChange])

    const arrangePriceInfo = (priceChange) => {
        let temInfo = priceInfo
        let index = temInfo.findIndex(item => item.token1 === priceChange.token1)
        if (temInfo !== undefined) {
            temInfo[index] = priceChange
            setPriceInfo(temInfo)
        }
    }
    const renderSelections = (item) => {

        return (
            <div style={{ backgroundColor: '#F9F9F9', width: '100%', marginTop: 16, flexDirection: 'row', display: 'flex', justifyContent: 'space-between', height: 74, alignItems: 'center', borderRadius: 20 }}>
                <div style={{ display: 'flex', alignItems: 'center', marginLeft: 40, width: "20%" }}>
                    <div style={{ height: 52, width: 52, backgroundColor: 'white', borderRadius: 16, display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                        <img style={{ height: 32, borderRadius: 0, alignSelf: 'center' }} src={getIcons(item.token1)} />
                    </div>
                    <Typography style={{ marginLeft: 16, fontSize: 20, fontWeight: 'bold' }} className={classes.fontStyle}>{item.token1}</Typography>
                </div>
                <Typography style={{ fontSize: 20, color: item.priceChange > 0 ? "#39C55E" : "#D9595C", width: "20%", textAlign: 'center' }} className={classes.fontStyle}>{item.priceChange > 0 ? "+" : ""}{roundingDown(item.priceChange,4)}</Typography>
                <Typography style={{ fontSize: 20, color: item.priceChangePercent > 0 ? "#39C55E" : "#D9595C", width: "20%", textAlign: 'center' }} className={classes.fontStyle}>{(item.priceChangePercent) > 0 ? "+" : ""}{roundingDown(item.priceChangePercent , 4)}%</Typography>
                <Typography style={{ fontSize: 20, marginRight: 40, width: "20%", textAlign: 'right' }} className={classes.fontStyle}>{`$${roundingDown(quote[item.token1].price, 4)}`}</Typography>

            </div>
        )
    }

    const renderSelectionsPhone = (item) => {
        return (
            <div style={{ backgroundColor: '#F9F9F9', width: '100%', marginTop: 16, flexDirection: 'row', display: 'flex', justifyContent: 'space-between', height: 60, alignItems: 'center', borderRadius: 16 }}>
                <div style={{ display: 'flex', alignItems: 'center', marginLeft: 12 }}>
                    <div style={{ height: 40, width: 40, backgroundColor: 'white', borderRadius: 12, display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                        <img style={{ height: 32, alignSelf: 'center' }} src={getIcons(item.token1)} />
                    </div>
                    <Typography style={{ marginLeft: 8, fontSize: 14, fontWeight: 'bold' }} className={classes.fontStyle}>{item.token1}</Typography>
                </div>
                <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-end', marginRight: 20 }}>
                    <Typography style={{ fontSize: 16, textAlign: 'right' }} className={classes.fontStyle}>{`$${roundingDown(quote[item.token1].price, 4)}`}</Typography>
                    <Typography style={{ fontSize: 12, color: item.in24 > 0 ? "#39C55E" : "#D9595C", }} className={classes.fontStyle}>{item.priceChange > 0 ? "+" : ""}{roundingDown(item.priceChange,4)}</Typography>
                </div>
                {/* <Typography style={{ fontSize: 20, color: item.in24 > 0 ? "#39C55E" : "#D9595C", width: "20%", textAlign: 'center' }} className={classes.fontStyle}>{item.in24 > 0 ? "+" : ""}{item.in24}</Typography>
                <Typography style={{ fontSize: 20, color: item.in7D > 0 ? "#39C55E" : "#D9595C", width: 200, width: "20%", textAlign: 'center' }} className={classes.fontStyle}>{item.in7D > 0 ? "+" : ""}{item.in7D}</Typography>
                <Typography style={{ fontSize: 20, marginRight: 40, width: "20%", textAlign: 'right' }} className={classes.fontStyle}>{item.currentPrice}</Typography> */}

            </div>
        )
    }

    return (

        <div style={{ width: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center', marginTop: phone ? 40 : 60, flexDirection: 'column' }}>

            <Typography style={{ fontSize: phone ? 20 : 30, fontWeight: '500' }} className={classes.fontStyle}>{t('home_todayTrade')}</Typography>

            <Typography style={{ fontSize: phone ? 12 : 16, fontWeight: '500', color: '#8B96A7', marginTop: 0, marginBottom: phone ? 12 : 48 }} className={classes.fontStyle}>{t('home_todayTradeSubtitle')}</Typography>
            <div style={{ width: phone ?"91%":'81%', display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                {phone ? null : <div style={{ width: '94%', flexDirection: 'row', display: 'flex', justifyContent: 'space-between', marginBottom: 0, alignItems: 'center' }}>
                    <Typography className={classes.fontStyle} style={{ fontSize: 14, color: '#8B96A7', width: '25%', textAlign: 'left' }}>{t('home_crypto')}</Typography>
                    <Typography className={classes.fontStyle} style={{ fontSize: 14, color: '#8B96A7', width: '25%', textAlign: 'center' }}>{t('home_24')}</Typography>
                    <Typography className={classes.fontStyle} style={{ fontSize: 14, color: '#8B96A7', width: '25%', textAlign: 'center' }}>{t('home_7d')}</Typography>
                    <Typography className={classes.fontStyle} style={{ fontSize: 14, color: '#8B96A7', width: '25%', textAlign: 'right' }}>{t('home_newestPrice')}</Typography>

                </div>}
                {priceInfo.map(item => (

                    phone ?
                        renderSelectionsPhone(item)

                        :
                        renderSelections(item)
                )
                )}
            </div>
        </div>
    )
}
export default withTranslation()(TodayMarket)
