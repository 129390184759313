import {
    INIT_KYC_LINK, INIT_KYC_LINK_FAILED, INIT_KYC_LINK_SUCCEED, GET_KYC_DETAILS, GET_KYC_DETAILS_FAILED, GET_KYC_DETAILS_SUCCEED,
    SYNC_KYC_STATUS, SYNC_KYC_STATUS_FAILED, SYNC_KYC_STATUS_SUCCEED, UPDATE_KYC_STATUS, UPDATE_KYC_STATUS_FAILED, UPDATE_KYC_STATUS_SUCCEED,
    UPDATE_VERIFICATION_PHONE, UPDATE_VERIFICATION_PHONE_SUCCEED, UPDATE_VERIFICATION_PHONE_FAILED,
    GET_VERIFICATION_CODE, GET_VERIFICATION_CODE_SUCCEED, GET_VERIFICATION_CODE_FAILED,
    VALIDATE_VERIFICATION_CODE, VALIDATE_VERIFICATION_CODE_SUCCEED, VALIDATE_VERIFICATION_CODE_FAILED,
    CLEAR_PHONE_VERIFY, INIT_ADDRESS_VERIFICATION, INIT_ADDRESS_VERIFICATION_FAILED, INIT_ADDRESS_VERIFICATION_SUCCEED,
    GET_DOCUMENT_DETAILS_SUCCEED, GET_DOCUMENT_DETAILS_FAILED, GET_DOCUMENT_DETAILS, SYNC_ADDRESS_STATUS_SUCCEED, SYNC_ADDRESS_STATUS_FAILED,
    SYNC_ADDRESS_STATUS
} from '../constants';

export function kyc (state = {
    kycDetails: {
        remark: '',
        status: '',
        idExpiry: ''
    },
    getKycDetailsMsg: '',
    syncKycStatusMsg: '',
    updateKycStatusMsg: '',
    inited: false,
    initKycMsg: '',
    fetched: false,
    updated: false,
    verificationPhone: '',
    updateVerificationPhoneMsg: '',
    phoneUpdated: false,
    verificationMobileCode: '',
    verificationCodeValid: false,
    phoneValidated: false,
    validatePhoneMsg: '',
    getVerificationCodeMsg: '',
    phoneCodeSent: false,
    failedReason: '',

    initAddressVerificationMsg: '',
    addressVerificationInited: false,
    getDocumentDetailsMsg: '',
    documentDetails: {
        remark: '',
        clientRedirectUrl: '',
        status: ''
    },
    documentFetched: false,
    addressVerificationUrl: '',
    syncAddressStatusMsg: ''
}, action) {
    switch (action.type) {
        case INIT_KYC_LINK:
            return {
                ...state,
                loggedIn: true,
                loading: true
            }
        case INIT_KYC_LINK_FAILED:
            return {
                ...state,
                initKycMsg:action.message,
                loading: false,
                inited: false
            }
        case INIT_KYC_LINK_SUCCEED:
            return {
                ...state,
                loading: false,
                inited: true
            }

        case GET_KYC_DETAILS:
            return {
                ...state,
                loading: true
            }
        case GET_KYC_DETAILS_SUCCEED:
            return {
                ...state,
                loading: false,
                kycDetails: action.data,
                fetched: false,
                getKycDetailsMsg: ''
            }
        case GET_KYC_DETAILS_FAILED:
            return {
                ...state,
                loading: false,
                getKycDetailsMsg: action.message,
                kycDetails: {}
            }
        case SYNC_KYC_STATUS:
            return {
                ...state,
                loading: true
            };
        case SYNC_KYC_STATUS_SUCCEED:
            return {
                ...state,
                loading: false,
                fetched: true,
                syncKycStatusMsg: ''
            };
        case SYNC_KYC_STATUS_FAILED:
            return {
                ...state,
                loading: false,
                fetched: false,
                syncKycStatusMsg: action.message,
            };
        case UPDATE_KYC_STATUS:
            return{
                ...state,
                loading: true,
                updated: false
            }
        case UPDATE_KYC_STATUS_SUCCEED:
            return {
                ...state,
                loading:false,
                updateKycStatusMsg: '',
                updated: true
            }
        case UPDATE_KYC_STATUS_FAILED:
            return {
                ...state,
                loading:false,
                updateKycStatusMsg: action.message,
                updated: true
            }
        case UPDATE_VERIFICATION_PHONE:
            return {
                ...state,
                loading:false,
                updateVerificationPhoneMsg: '',
                phoneUpdated: false
            }
        case UPDATE_VERIFICATION_PHONE_SUCCEED:
            return {
                ...state,
                loading:false,
                updateVerificationPhoneMsg: action.data,
                phoneUpdated: true
            }
        case UPDATE_VERIFICATION_PHONE_FAILED:
            return {
                ...state,
                loading:false,
                updateVerificationPhoneMsg: action.message,
                phoneUpdated: false
            }
        case GET_VERIFICATION_CODE:
            return {
                ...state,
                loading:false,
                getVerificationCodeMsg: '',
                failedReason: ''
            }
        case GET_VERIFICATION_CODE_SUCCEED:
            return {
                ...state,
                loading:false,
                getVerificationCodeMsg: action.data,
                failedReason: '',
                phoneCodeSent: true
            }
        case GET_VERIFICATION_CODE_FAILED:
            return {
                ...state,
                loading:false,
                getVerificationCodeMsg: action.message.message,
                failedReason: action.message.data,
                phoneCodeSent: true
            }
        case VALIDATE_VERIFICATION_CODE:
            return {
                ...state,
                loading:false,
                validatePhoneMsg: ''
            }
        case VALIDATE_VERIFICATION_CODE_SUCCEED:
            return {
                ...state,
                loading:false,
                phoneValidated: true,
                validatePhoneMsg: action.data
            }
        case VALIDATE_VERIFICATION_CODE_FAILED:
            return {
                ...state,
                loading:false,
                phoneValidated: false,
                validatePhoneMsg: action.message
            }
        case CLEAR_PHONE_VERIFY:
            return {
                ...state,
                validatePhoneMsg: '',
                getVerificationCodeMsg: '',
                updateVerificationPhoneMsg: '',
                phoneValidated: false,
                failedReason: '',
                phoneCodeSent: false,
                phoneUpdated: false
        }
        case INIT_ADDRESS_VERIFICATION:
            return {
                ...state,
                loggedIn: true,
                loading: true
            }
        case INIT_ADDRESS_VERIFICATION_FAILED:
            return {
                ...state,
                initAddressVerificationMsg:action.message,
                loading: false,
                addressVerificationInited: false
            }
        case INIT_ADDRESS_VERIFICATION_SUCCEED:
            return {
                ...state,
                loading: false,
                addressVerificationInited: true,
                addressVerificationUrl: action.data.clientRedirectUrl
            }
        case GET_DOCUMENT_DETAILS:
            return {
                ...state,
                loading: true
            }
        case GET_DOCUMENT_DETAILS_SUCCEED:
            if (action.data === null) {
                action.data = {
                }
            }
            return {
                ...state,
                loading: false,
                documentDetails: action.data,
                documentFetched: false,
                getDocumentDetailsMsg: ''
            }
        case GET_DOCUMENT_DETAILS_FAILED:
            return {
                ...state,
                loading: false,
                getDocumentDetailsMsg: action.message,
                documentDetails: {
                    status: ''
                }
            }
        case SYNC_ADDRESS_STATUS:
            return {
                ...state,
                loading: true
            };
        case SYNC_ADDRESS_STATUS_SUCCEED:
            return {
                ...state,
                loading: false,
                documentFetched: true,
                syncAddressStatusMsg: ''
            };
        case SYNC_ADDRESS_STATUS_FAILED:
            return {
                ...state,
                loading: false,
                documentFetched: false,
                syncAddressStatusMsg: action.message,
            };
        default:
            return state;
    }
};
