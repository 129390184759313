import React, { useEffect, useState } from 'react'
import { withTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { fundsActions } from "../../redux/actions/fundsActions";
import { AppBar, Box, Button, makeStyles, Tab, Tabs, Typography } from '@material-ui/core';
import PropTypes from 'prop-types';
import "react-datepicker/dist/react-datepicker.css";
import { useHistory } from "react-router-dom";
import FundHistory from "./FundHistory";
import TradeHistory from "./TradeHistory";
import TradeHistoryDetail from "./TradeHistoryDetail";
import { tradeActions } from "../../redux/actions/tradeActions";
import { Helmet } from "react-helmet";

function TabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box p={3}>
                    <Typography>{children}</Typography>
                </Box>
            )}
        </div>
    );
}

TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.any.isRequired,
    value: PropTypes.any.isRequired,
};

function a11yProps(index) {
    return {
        id: `simple-tab-${index}`,
        'aria-controls': `simple-tabpanel-${index}`,
    };
}

function HistoryWrapper(props) {
    const { t, width, height } = props
    const dispatch = useDispatch();
    const { token, userInfo, updateInfoSuccess } = useSelector(state => state.auth)
    const { userCapitals, fundsTransactions, priceQuote } = useSelector(state => state.funds)
    const [phone, setPhone] = useState(width <= 745);
    const [loaded, setLoaded] = useState(false)
    const [value, setValue] = React.useState(0);
    const [tradeDetail, setTradeDetail] = useState({});
    const [showTradeDetail, setShowTradeDetail] = useState(false);
    const [showCrypto, setShowCrypto] = useState(false)

    const history = useHistory()

    const handleChange = (event, newValue) => {
        console.log('value: ', newValue)
        setValue(newValue);
    };


    const useStyles = makeStyles({
        root: {
            width: '100%',
            minHeight: '100vh'
        },
        tabBarPhone: {
            backgroundColor: '#F1F0F0',
            padding: 4,
            // top: 20,
            borderRadius: 12,
            boxShadow: 'none',

            display: 'flex',
            position: 'unset',
            zIndex: 0,

            '& .MuiTabs-flexContainer': {
                justifyContent: phone ? 'space-evenly' : 'unset'
            },
        },
        tabsPhone: {
            minHeight: "24px",
            height: "32px",
            "& .MuiTabs-indicator": {
                display: "none",
            },
            "& .Mui-selected": {
                backgroundColor: '#162E83',
                color: 'white',
                borderRadius: 12,

            },
            "& span": {
                fontWeight: '500',
                fontSize: 12
            },

        },
        tabBar: {
            backgroundColor: '#F1F0F0',
            padding: 8,
            top: 20,
            borderRadius: 12,
            boxShadow: 'none',
            width: phone ? '100%' : 350,
            display: 'flex',
            position: 'unset',
            zIndex: 0,

            '& .MuiTabs-flexContainer': {
                justifyContent: phone ? 'space-evenly' : 'unset'
            },
        },
        tabs: {
            "& .MuiTabs-indicator": {
                display: "none",
            },
            "& .Mui-selected": {
                backgroundColor: '#162E83',
                color: 'white',
                borderRadius: 10,

            },
            "& span": {
                fontWeight: 'bold',

            }
        },
        tabPanels: {
            width: "100%",
            justifyContent: 'center',
            alignItems: 'center',
            display: 'flex',
            backgroundColor: 'transparent'
        },
        button: {
            width: '100%',
            height: 20,
            borderRadius: 20,
            fontFamily: 'Avenir',
            fontSize: 18,
            fontWeight: 900,
            letterSpacing: 0,
            lineHeight: '25px',
            textTransform: 'none',
            backgroundColor: '#1890FF',
            color: 'white'
        },
        buttonPhone: {
            width: '100%',
            height: 40,
            borderRadius: 12,
            fontFamily: 'Avenir',
            fontSize: 12,
            fontWeight: 900,
            letterSpacing: 0,
            lineHeight: '16px',
            textTransform: 'none',
            backgroundColor: '#1890FF',
            color: 'white'
        },
        tabActive: {
            overFlow: 'hidden',
            width: 65,
            height: 25,
            borderRadius: 6,
            backgroundColor: '#0173F9',
            color: '#FFFFFF',
            fontFamily: 'Avenir',
            fontSize: 12,
            fontWeight: 900,
            letterSpacing: 0,
            textAlign: 'center',

            '&:hover': {
                backgroundColor: '#0173F9',
                boxShadow: 'none',
            },
            '& .MuiButton-label': {
                display: 'contents'
            }
        },
        tabNonActive: {
            overFlow: 'hidden',
            width: 65,
            height: 25,
            borderRadius: 6,
            backgroundColor: '#BAC3CE',
            color: '#FFFFFF',
            fontFamily: 'Avenir',
            fontSize: 12,
            fontWeight: 900,
            letterSpacing: 0,
            textAlign: 'center',

            '&:hover': {
                backgroundColor: '#BAC3CE',
                boxShadow: 'none',
            },
            '& .MuiButton-label': {
                display: 'contents'
            }
        },
    });
    const classes = useStyles();

    useEffect(() => {
        setPhone(width <= 745)
        return () => {
        }
    }, [width]);

    useEffect(() => {
        dispatch(fundsActions.getFundTransactionByCoinType({ types: ['cryptoDeposit', 'cryptoWithdraw'], depositWithdrawType: 'crypto', currentPage: 0, pageSize: 10 }))
        dispatch(fundsActions.getFundTransactionByCoinType({ types: ['deposit', 'withdraw'], depositWithdrawType: 'fiat', currentPage: 0, pageSize: 10 }))
        dispatch(tradeActions.getTradeTransactionByCoinType({ types: ['buy', 'sell'], depositWithdrawType: '', currentPage: 0, pageSize: 10 }))
        setLoaded(true)

        console.log('history.location.state: ', history.location.state)
        if (history.location.state) {
            if (history.location.state.tab) {
                setValue(history.location.state.tab)
            }
        }

        return () => {
        }
    }, []);

    const exportToCsv = () => {
        let filename = `trade_${userInfo.username}.csv`
        var processRow = function (row) {
            var finalVal = '';
            for (var j = 0; j < row.length; j++) {
                var innerValue = row[j] === null ? '' : row[j].toString();
                if (row[j] instanceof Date) {
                    innerValue = row[j].toLocaleString();
                };
                var result = innerValue.replace(/"/g, '""');
                if (result.search(/("|,|\n)/g) >= 0)
                    result = '"' + result + '"';
                if (j > 0)
                    finalVal += ',';
                finalVal += result;
            }
            return finalVal + '\n';
        };

        var csvFile = '';
        for (var i = 0; i < fundsTransactions.length; i++) {
            csvFile += processRow(fundsTransactions[i]);
        }

        var blob = new Blob([csvFile], { type: 'text/csv;charset=utf-8;' });
        if (navigator.msSaveBlob) { // IE 10+
            navigator.msSaveBlob(blob, filename);
        } else {
            var link = document.createElement("a");
            if (link.download !== undefined) { // feature detection
                // Browsers that support HTML5 download attribute
                var url = URL.createObjectURL(blob);
                link.setAttribute("href", url);
                link.setAttribute("download", filename);
                link.style.visibility = 'hidden';
                document.body.appendChild(link);
                link.click();
                document.body.removeChild(link);
            }
        }
    }

    const selectType = (type) => {
        if (type === 'crypto') {
            setShowCrypto(true)
        } else {
            setShowCrypto(false)
        }
    }

    const renderTabs = () => {
        return (
            <div className={classes.root}>

                {/* <div style={{  alignItems: 'flex-start', display: 'flex', marginTop: phone ? 24:32 }}>

                    <Typography style={{ fontSize:phone ? 20: 32 , fontWeight:500 ,marginBottom:8}}>
                        {t('History')}
                    </Typography>
                </div> */}

                <div style={{ marginTop: phone ? 24 : 0, pointEvents: 'none', width: phone ? 280 : 0.85 * width, height: phone ? 32 : 75, display: 'flex', flexDirection: phone ? 'column' : 'row', alignItems: 'center', justifyContent: 'space-between' }}>
                    {showTradeDetail ? null
                        :
                        <>
                            <AppBar className={phone ? classes.tabBarPhone : classes.tabBar}>
                                <Tabs
                                    className={phone ? classes.tabsPhone : classes.tabs}
                                    value={value}
                                    onChange={handleChange}
                                    indicatorColor="primary"
                                    textColor="primary"
                                    aria-label="scrollable auto tabs example"

                                >
                                    <Tab className={phone ? classes.tabsPhone : classes.tabs} label={t('tradeHistory')} {...a11yProps(0)} />
                                    <Tab className={phone ? classes.tabsPhone : classes.tabs} label={t('fundHistory')} {...a11yProps(1)} />
                                </Tabs>
                            </AppBar>
                            {
                                value === 0 ? null :
                                    <div style={{ alignSelf: phone ? 'flex-start' : null, marginTop: phone ? 25 : null, display: 'flex', justifyContent: 'flex-end', alignItems: 'center' }}>
                                        <Button iconSizeMedium onClick={() => selectType('fiat')} style={{ marginRight: 16, textTransform: 'unset' }} className={showCrypto ? classes.tabNonActive : classes.tabActive}>{t('fiat')}</Button>
                                        <Button iconSizeMedium onClick={() => selectType('crypto')} style={{ textTransform: 'unset' }} className={showCrypto ? classes.tabActive : classes.tabNonActive}>{t('crypto')}</Button>
                                    </div>
                            }
                        </>
                    }


                    {/*<div style={{ justifyContent: 'flex-end', alignSelf: 'center', alignItems: 'center', display: 'flex' }}>*/}
                    {/*    <Button*/}
                    {/*        className={phone?`${classes.buttonPhone}`:`${classes.button}`}*/}
                    {/*        style={{margin:phone ? '8px 0':'15px 0', opacity: !loaded  ? 0.3 : 1}}*/}
                    {/*        disabled={!loaded}*/}
                    {/*        onClick={()=> {exportToCsv()}}*/}
                    {/*    >*/}
                    {/*        <Avatar style={{ width: 24, height: 24, borderRadius: 5, alignSelf: 'center' }} src={exportIcon} variant="square" />*/}
                    {/*        {t('exportCSV')}*/}
                    {/*    </Button>*/}
                    {/*</div>*/}
                </div>

                <div style={{ height: 1, marginTop: 20, marginBottom: 12, backgroundColor: '#EFEFEF' }} />


                <TabPanel className={classes.tabPanels} value={value} index={0}>
                    {showTradeDetail ?
                        <TradeHistoryDetail showCrypto={showCrypto} width={width} height={height} phone={phone} tradeDetail={tradeDetail} setShowTradeDetail={setShowTradeDetail} />
                        : <TradeHistory showCrypto={showCrypto} width={width} height={height} phone={phone} showPage={true} setShowTradeDetail={setShowTradeDetail} setTradeDetail={setTradeDetail} />
                    }
                </TabPanel>
                <TabPanel className={classes.tabPanels} value={value} index={1}>
                    <FundHistory showCrypto={showCrypto} width={width} height={height} rowCount={10} showPage={true} />
                </TabPanel>

            </div>
        )
    }

    return (

        <div style={{ width: '100%', display: 'flex', alignItems: 'center', flexDirection: 'column' }}>
            <Helmet>
            <title>{t('titleHistory')}</title>
                <meta name="keywords" content="Toronto, OTC, bitcoin, exchange, cryptocurrency, 多伦多, 场外交易平台, 比特币, 以太坊, 加元, 换汇，加密货币, 人民币,多倫多, 場外交易平台, 比特幣, 以太坊, 加元, 換匯, 人民幣， Yesbit, Fiat, CAD, BTC, ETH, USD, history, 交易历史" />
            </Helmet>
            <div style={{ width: '90%', alignItems: 'flex-start', textAlign: 'center' }}>
                {renderTabs()}
            </div>
        </div>
    )
}
export default withTranslation()(HistoryWrapper)
