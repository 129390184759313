import React, {useEffect, useState} from 'react'
import {withTranslation} from "react-i18next";
import {useDispatch, useSelector} from "react-redux";
import {
    makeStyles,
    Button, Typography
} from '@material-ui/core';
import "react-datepicker/dist/react-datepicker.css";
import otcLogo from "../../images/nav/navLogo.png";
import {formDateString} from "../../utils/ConvertDate";
import ContactUsModal from "../Nav/components/ContactUsModal";


function TradeHistoryDetail({ t, width, height,phone, tradeDetail, setShowTradeDetail }) {
    const [contactUsVisible, setContactUsVisible] = useState(false);

    const statusColor = {
        'pending': '#0173F9',
        'canceled': '#9097A1',
        'declined': '#9097A1',
        'succeed': '#4D8C7B',
        'expired': '#9097A1'
    }
    const detailStatusColor = {
        'pending': '#0173F9',
        'canceled': '#9097A1',
        'declined': '#9097A1',
        'succeed': '#0173F9',
        'expired': '#9097A1'
    }
    const statusLocales = {
        'pending': 'trade_pending',
        'canceled': 'trade_canceled',
        'declined': 'trade_declined',
        'succeed': 'trade_succeed',
        'expired': 'trade_expired'
    }

    const useStyles = makeStyles({
        font:{
            color: '#1C2237',
            fontFamily: 'Avenir',
            fontSize: phone ? 14:24,
            fontWeight: 900,
            letterSpacing: 0,
            textAlign: 'left',
            textTransform:'none'
        },
        button:{
            width:'100%',
            height:phone?38:70,
            borderRadius: 12,
            backgroundColor: '#162E83',
            textTransform:'none',
            marginTop:phone?35:60,
        },
    })
    const classes = useStyles();

    const hashExplorer = (network, hash) => {
        const ETHLink = "https://etherscan.io/tx/";
        const TRXLink = "https://tronscan.org/#/transaction/";
        if (network === "ETH"){
            return ETHLink + hash
        }else if(network === "TRX")
            return TRXLink + hash
        return null
    };

    return (
        <div style={{
            width: 0.85 * width,
            maxWidth:543,
            height: phone ? "95%" : '80%',
            alignItems: phone ? "flex-start" : 'center',
            justifyContent: 'center',
            display: 'flex',
            flexDirection:'column',
            marginTop: 15,
            padding: 0
        }}>
            <div style={{width:'100%', display:'flex', justifyContent:'space-between'}}>
                <Typography className={classes.font} style={{ color: statusColor[tradeDetail.status] }} >{t(statusLocales[tradeDetail.status])}</Typography>
                <Typography className={classes.font} style={{fontSize:phone?8:16}}> {t('problem')} <Typography className={classes.font} style={{color:'#0173F9', fontSize:phone?8:16, cursor:'pointer', display:'inline'}} onClick={()=>{setContactUsVisible(true)}}>{t('contactUs')}</Typography></Typography>
            </div>
            <div style={{width:'100%', marginTop:30, borderRadius: phone?12:20, backgroundColor: '#F9F9F9'}}>
                <div style={{margin:phone? '21px 17px':'35px 28px'}}>
                    <div style={{display:'flex', justifyContent:'space-between', alignItems:'center'}}>
                        <Typography className={classes.font} style={{color:'#BAC3CE', fontWeight:300}}>{t('trade_orderDetails')}</Typography>
                        <img alt='' src={otcLogo} style={phone ? {width:49, height:10} :{width:79, height:16}}></img>
                    </div>

                    <div style={{marginTop:phone?8:13, boxSizing:'border-box', height: 1, border: '1px solid #DDDDDD'}}></div>

                    <div className={classes.font} style={{marginTop:phone?15:21, display:'flex', justifyContent:'space-between'}}>
                        <Typography className={classes.font} style={{fontSize:phone?8:16, color:'#858E9D'}}>{t('trade_orderNumber')}</Typography>
                        <Typography className={classes.font} style={{fontSize:phone?8:16, color:'#1C2237'}}>{tradeDetail.reference}</Typography>
                    </div>
                    {
                        tradeDetail.status === 'succeed' || tradeDetail.status === 'pending'?
                            <div style={{marginTop:phone?13:18, display:'flex', justifyContent:'space-between'}}>
                                <Typography className={classes.font} style={{fontSize:phone?8:16, color:'#858E9D'}}>{t('trade_txId')}</Typography>
                                <Typography noWrap className={classes.font} style={{maxWidth:'70%', fontSize:phone?8:16, color: detailStatusColor[tradeDetail.status] }} >
                                    {tradeDetail.status === 'succeed'?
                                        <a style={{color: detailStatusColor[tradeDetail.status]}} href= {hashExplorer(tradeDetail.network , tradeDetail.txsHash)} target="_blank" rel="noreferrer noopener">{tradeDetail.txsHash}</a>
                                        :t(statusLocales[tradeDetail.status])}
                                </Typography>
                            </div>
                            :null
                    }

                    <div style={{marginTop:phone?13:18, display:'flex', justifyContent:'space-between'}}>
                        <Typography className={classes.font} style={{fontSize:phone?8:16, color:'#858E9D'}}>{t('trade_orderBuyCrypto')}</Typography>
                        <Typography className={classes.font} style={{fontSize:phone?8:16, color:'#1C2237'}}>{tradeDetail.cryptoAmount} {tradeDetail.cryptoType}</Typography>
                    </div>
                    <div style={{marginTop:phone?13:18, display:'flex', justifyContent:'space-between'}}>
                        <Typography className={classes.font} style={{fontSize:phone?8:16, color:'#858E9D'}}>{t('trade_totalPayment')}</Typography>
                        <Typography className={classes.font} style={{fontSize:phone?8:16, color:'#1C2237'}}>{tradeDetail.fiatAmount} {tradeDetail.fiatType}</Typography>
                    </div>
                    <div style={{marginTop:phone?13:18, display:'flex', justifyContent:'space-between'}}>
                        <Typography className={classes.font} style={{fontSize:phone?8:16, color:'#858E9D'}}>{t('trade_walletAddress')}</Typography>
                        <Typography noWrap className={classes.font} style={{maxWidth:'70%', fontSize:phone?8:16, color:'#1C2237'}}>{tradeDetail.walletAddress}</Typography>
                    </div>
                    <div style={{marginTop:phone?13:18, display:'flex', justifyContent:'space-between'}}>
                        <Typography className={classes.font} style={{fontSize:phone?8:16, color:'#858E9D'}}>{t('trade_netWork')}</Typography>
                        <Typography className={classes.font} style={{fontSize:phone?8:16, color:'#1C2237'}}>{tradeDetail.network}</Typography>
                    </div>
                    <div style={{marginTop:phone?13:18, display:'flex', justifyContent:'space-between'}}>
                        <Typography className={classes.font} style={{fontSize:phone?8:16, color:'#858E9D'}}>{t('trade_orderCreated')}</Typography>
                        <Typography className={classes.font} style={{fontSize:phone?8:16, color:'#1C2237'}}>{formDateString(tradeDetail.createdAt)} (UTC)</Typography>
                    </div>
                    {
                        tradeDetail.status === 'succeed' || tradeDetail.status === 'pending'?
                            null
                            :<div style={{marginTop:phone?13:18}}>
                                <Typography className={classes.font} style={{fontSize:phone?8:16, color:'#858E9D'}}>{t('trade_remark')}</Typography>
                                <Typography noWrap className={classes.font} style={{fontSize:phone?8:16, color:'#DC3055' }} >
                                    {tradeDetail.remark}
                                </Typography>
                            </div>
                    }
                </div>

            </div>

            <Button className={`${classes.button} ${classes.font}`} style={{color:'white'}} onClick={()=>{setShowTradeDetail(false)}}>Back</Button>

            <ContactUsModal
                onCancel={() => {
                    setContactUsVisible(false);
                }}
                modalVisible={contactUsVisible}

                t
                phone={phone}
                width={width}
            />
        </div>
    )
}
export default withTranslation()(TradeHistoryDetail)
