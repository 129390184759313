import { withTranslation } from "react-i18next";
import React, { useEffect, useState } from "react";
import { Button, InputBase, InputAdornment, IconButton, Typography, makeStyles } from '@material-ui/core';
import { VisibilityOff, Visibility } from '@material-ui/icons';
import { Link, useHistory } from 'react-router-dom'
import leftPage from "../../images/leftPage.png";
import pageLogo from "../../images/pageLogo.png";
import i18n from '../../i18n';
import { authActions } from "../../redux/actions/authActions";
import { useDispatch, useSelector } from "react-redux";
import { checkEmail, isValidPassword } from "../../utils/Checker";
import VerificationInput from "react-verification-input";

function ForgotPassword({ t, width, height }) {
    const useStyles = makeStyles({
        inputLabel: {
            marginBottom: 6, textAlign: 'initial', fontFamily: 'Avenir', fontSize: 16, fontWeight: 500, letterSpacing: 0, lineHeight: '22px'
        },
        inputLabelPhone: {
            marginBottom: 5, textAlign: 'initial', fontFamily: 'Avenir', fontSize: 10, fontWeight: 500, letterSpacing: 0, lineHeight: '14px'
        },
        input: {
            borderRadius: 16, backgroundColor: 'white', width: '100%', height: '55px', fontSize: 16, padding: 20, border: "1px solid #EDEDED"
        },
        inputPhone: {
            borderRadius: 12, backgroundColor: 'white', width: '100%', height: '38.5px', fontSize: 10, padding: 14, border: "1px solid #EDEDED",
        },
        button: {
            width: '100%',
            height: '65px',
            borderRadius: 20,
            color: 'white !important',
            backgroundColor: '#081647',
            fontFamily: 'Avenir',
            fontSize: 18,
            fontWeight: 500,
            letterSpacing: 0,
            lineHeight: '25px',
            textTransform: 'none',
            "&:hover": {
                backgroundColor: '#081647',
                // Reset on touch devices, it doesn't add specificity
                "@media (hover: none)": {
                    backgroundColor: '#081647',
                    "&:active": {
                        backgroundColor: '#081647'
                    }
                },
                '&$disabled': {
                    backgroundColor: '#081647',
                },
            },
        },
        buttonPhone: {
            width: '100%',
            height: '48.3px',
            borderRadius: 12,
            color: 'white !important',
            backgroundColor: '#081647',
            fontFamily: 'Avenir',
            fontSize: 12,
            fontWeight: 500,
            letterSpacing: 0,
            lineHeight: '16px',
            textTransform: 'none',
            "&:hover": {
                backgroundColor: '#081647',
                // Reset on touch devices, it doesn't add specificity
                "@media (hover: none)": {
                    backgroundColor: '#081647',
                    "&:active": {
                        backgroundColor: '#081647'
                    }
                },
                '&$disabled': {
                    backgroundColor: '#081647',
                },
            },
        }
    });
    const classes = useStyles();
    const [values, setValues] = useState({
        username: '',
        password: '',
        confirmPassword: '',
        showNewPassword: false,
        showConfirmPassword: false,
    });
    const { checkingUser, checkUserSuccess, checkUserFailed, checkUserMsg, resetPasswordMsg, resettingPassword, resetPasswordSuccess, resetPasswordFailed } = useSelector(state => state.auth)
    const [phone, setPhone] = useState(width <= 745);
    const [validInputWarning, setValidInputWarning] = useState({ username: false, password: false });
    const [step1, setStep1] = useState(true);
    const [step2, setStep2] = useState(false);
    const [step3, setStep3] = useState(false);
    const [verificationCode, setVerificationCode] = useState('');
    const [timeLeft, setTimeLeft] = useState(0);
    const [userRegisteredMessage, setUserRegisteredMessage] = useState(false);
    const [resetPasswordMessage, setResetPasswordMessage] = useState(false);
    const dispatch = useDispatch();
    const history = useHistory();
    let timeout;

    const handleChange = (prop) => (event) => {
        setValues({ ...values, [prop]: event.target.value });
        if (prop === 'username') {
            setValidInputWarning({ ...validInputWarning, [prop]: !checkEmail(event.target.value) })
        }
        if (prop === 'password') {
            setValidInputWarning({ ...validInputWarning, [prop]: !isValidPassword(event.target.value) })
        }
    };

    const handleClickShowPassword = (prop) => {
        setValues({ ...values, [prop]: !values[prop] });
    };

    const handleMouseDownPassword = (event) => {
        event.preventDefault();
    };

    const sendVerificationDisable = () => {
        return !checkEmail(values.username)
    }

    const verifyCodeDisable = () => {
        return verificationCode.length != 6
    }

    const confirmPasswordDisable = () => {
        return values.password != values.confirmPassword || !isValidPassword(values.password)
    }

    const handleNextStep = () => {
        dispatch(authActions.checkEmail(values.username));
    };

    const handleNextStep2 = () => {
        dispatch(authActions.sendVerificationCode(values.username));
        setStep2(false);
        setStep3(true);
        clearTimeout(timeout);
        setTimeLeft(60);
    }

    const handleSendVerificationCode = () => {
        dispatch(authActions.sendVerificationCode(values.username));
        setTimeLeft(60);
    };

    const handleResetPassword = () => {
        let payload = {
            username: values.username,
            password: values.password,
            emailCode: verificationCode
        }
        dispatch(authActions.resetPassword(payload));
    }

    useEffect(() => {
        setPhone(width <= 745)
    }, [width]);

    useEffect(() => {
        timeLeft > 0 && (timeout = setTimeout(() => setTimeLeft(timeLeft - 1), 1000));
    }, [timeLeft]);

    useEffect(() => {
        if (checkUserSuccess) {
            setStep1(false);
            setStep2(true);
            dispatch(authActions.clearCheckEmailState())
        }
        if (checkUserFailed) {
            setUserRegisteredMessage(true)
            dispatch(authActions.clearCheckEmailState())
        }
    }, [checkingUser])

    useEffect(() => {
        if (resetPasswordSuccess) {
            dispatch(authActions.clearResetPasswordState())
            history.push("/login?reset=true")
        }
        if (resetPasswordFailed) {
            setResetPasswordMessage(true)
            dispatch(authActions.clearResetPasswordState())

        }
    }, [resettingPassword])


    const renderSendVerification = () => {
        return (
            <>
                <Typography
                    style={
                        phone ? { fontFamily: 'Avenir', fontSize: 18, fontWeight: 500, letterSpacing: 0, lineHeight: '25px', textAlign: 'initial' }
                            : { fontFamily: 'Avenir', fontSize: 24, fontWeight: 500, letterSpacing: 0, lineHeight: '35px', textAlign: 'left' }
                    }
                >
                    {t('resetPassword')}
                </Typography>
                <Typography
                    style={
                        phone ? { marginTop: 3, fontFamily: 'Avenir', color: '#9097A1', fontSize: 10, fontWeight: 500, letterSpacing: 0, lineHeight: '14px', textAlign: 'initial' }
                            : { marginTop: 4, fontFamily: 'Avenir', color: '#9097A1', fontSize: 14, fontWeight: 500, letterSpacing: 0, lineHeight: '19px', textAlign: 'left' }
                    }
                >
                    {t('forgotPasswordSubtitle')}
                </Typography>
                <div style={{ marginTop: phone ? 29.4 : 60 }}>
                    <Typography
                        className={phone ? classes.inputLabelPhone : classes.inputLabel}
                    >
                        {t('email')}
                    </Typography>
                    <InputBase
                        className={phone ? classes.inputPhone : classes.input}
                        value={values.username}
                        placeholder={t('placeHolderLoginEmail')}

                        onChange={handleChange('username')}
                    />
                </div>

                {
                    userRegisteredMessage
                        ? <Typography style={{ marginTop: phone ? 4 : 8, fontFamily: "Avenir", color: "#DC3055", fontSize: phone ? 8 : 12, fontWeight: 500, lineHeight: phone ? "11px" : '16px', textAlign: "left" }}>
                            {t(checkUserMsg)}
                        </Typography>
                        : null

                }

                <Button
                    className={phone ? classes.buttonPhone : classes.button}
                    style={{ marginTop: phone ? '23px' : '60px', opacity: sendVerificationDisable() ? 0.3 : null }

                    }
                    disabled={sendVerificationDisable()}
                    onClick={handleNextStep}
                >
                    {t('nextStep')}
                </Button>
                <div style={{ margin: phone ? '23px 0px' : '30px 0px', display: 'flex', alignItems: 'center' }}>
                    <div style={{ backgroundColor: '#979797', height: 1, width: '100%' }}></div>
                    <Typography
                        style={
                            phone ? { margin: '0px 5.8px', fontFamily: 'Avenir', color: '#BAC3CE', fontSize: 12.6, fontWeight: 500, letterSpacing: 0, lineHeight: '18px' }
                                : { margin: '0px 14.5px', fontFamily: 'Avenir', color: '#BAC3CE', fontSize: 18, fontWeight: 500, letterSpacing: 0, lineHeight: '25px' }
                        }
                    >
                        {t('or')}
                    </Typography>
                    <div style={{ backgroundColor: '#979797', height: 1, width: '100%' }}></div>
                </div>


                <Link to='/login' style={{ textDecoration: 'none' }}>
                    <Button
                        className={phone ? classes.buttonPhone : classes.button}
                    >
                        {t('login')}
                    </Button>
                </Link>
            </>
        )
    }

    const renderVerification = () => {
        return (
            <>
                <Typography
                    style={
                        phone ? { fontFamily: 'Avenir', fontSize: 18, fontWeight: 500, letterSpacing: 0, lineHeight: '25px', textAlign: 'initial' }
                            : { fontFamily: 'Avenir', fontSize: 24, fontWeight: 500, letterSpacing: 0, lineHeight: '35px', textAlign: 'left' }
                    }
                >
                    {t('resetPassword')}
                </Typography>
                <Typography
                    style={
                        phone ? { marginTop: 3, fontFamily: 'Avenir', color: '#9097A1', fontSize: 10, fontWeight: 500, letterSpacing: 0, lineHeight: '14px', textAlign: 'initial' }
                            : { marginTop: 4, fontFamily: 'Avenir', color: '#9097A1', fontSize: 14, fontWeight: 500, letterSpacing: 0, lineHeight: '19px', textAlign: 'left' }
                    }
                >
                    {t('resetPasswordVerifySubtitle') + " " + values.username}
                </Typography>

                <VerificationInput
                    removeDefaultStyles
                    classNames={{
                        container: width > 960 ? "verification_container" : "verification_container_phone",
                        character: width > 960 ? "verification_character" : "verification_character_phone",
                        characterInactive: "verification_character--inactive",
                        characterSelected: "verification_character--selected",
                    }}
                    value={verificationCode}
                    onChange={(e) => { setVerificationCode(e) }}
                    placeholder={"*"}
                />
                {
                    resetPasswordMessage ?
                        <Typography style={{ marginTop: phone ? 6.37 : 12.84, fontFamily: "Avenir", color: "#DC3055", fontSize: phone ? 8 : 12, fontWeight: 500, lineHeight: phone ? "11px" : '16px', textAlign: "left" }}>
                            {t(resetPasswordMsg)}
                        </Typography>
                        : null

                }

                <div style={{ marginTop: phone ? 41.4 : 60 }}>
                    <Button
                        style={
                            phone ? { textTransform: 'none', fontFamily: 'Avenir', fontSize: 10, fontWeight: 500, letterSpacing: 0, lineHeight: '14px' }
                                : { textTransform: 'none', fontFamily: 'Avenir', fontSize: 14, fontWeight: 500, letterSpacing: 0, lineHeight: '20px' }
                        }
                        onClick={handleSendVerificationCode}
                        disabled={timeLeft > 0}
                    >
                        {t('resendCode')}{timeLeft > 0 ? '(' + timeLeft + 's)' : null}
                    </Button>
                </div>

                <Button
                    className={phone ? classes.buttonPhone : classes.button}
                    style={{ marginTop: phone ? '100px' : '60px', color: "white", opacity: verifyCodeDisable() ? 0.3 : null }}
                    onClick={handleResetPassword}
                    disabled={verifyCodeDisable()}
                >
                    {t('confirm')}
                </Button>
            </>
        )
    }

    const renderResetPassword = () => {
        return (
            <>
                <Typography
                    style={
                        phone ? { fontFamily: 'Avenir', fontSize: 18, fontWeight: 500, letterSpacing: 0, lineHeight: '25px', textAlign: 'initial' }
                            : { fontFamily: 'Avenir', fontSize: 24, fontWeight: 500, letterSpacing: 0, lineHeight: '35px', textAlign: 'left' }
                    }
                >
                    {t('resetPassword')}
                </Typography>
                <Typography
                    style={
                        phone ? { marginTop: 3, fontFamily: 'Avenir', color: '#9097A1', fontSize: 10, fontWeight: 500, letterSpacing: 0, lineHeight: '14px', textAlign: 'initial' }
                            : { marginTop: 4, fontFamily: 'Avenir', color: '#9097A1', fontSize: 14, fontWeight: 500, letterSpacing: 0, lineHeight: '19px', textAlign: 'left' }
                    }
                >
                    {t('resetPasswordSubtitle')}
                </Typography>


                <div style={{ marginTop: phone ? 30 : 60 }}>
                    <Typography
                        style={
                            phone ? { marginBottom: 5, textAlign: 'initial', fontFamily: 'Avenir', fontSize: 10, fontWeight: 500, letterSpacing: 0, lineHeight: '14px' }
                                : { marginBottom: 6, textAlign: 'initial', fontFamily: 'Avenir', fontSize: 16, fontWeight: 500, letterSpacing: 0, lineHeight: '22px' }
                        }
                    >
                        {t('newPassword')}
                    </Typography>
                    <InputBase
                        placeholder={t('placeHolderLoginCreatePassword')}
                        type={values.showNewPassword ? 'text' : 'password'}
                        style={
                            phone ? { borderRadius: 12, backgroundColor: '#F1F0F0', width: '100%', height: '38.5px', fontSize: 10, padding: 14 }
                                : { borderRadius: 16, backgroundColor: '#F1F0F0', width: '100%', height: '55px', fontSize: 16, padding: 20 }
                        }
                        value={values.password}
                        onChange={handleChange('password')}
                        endAdornment={
                            <InputAdornment position="end">
                                <IconButton
                                    aria-label="toggle password visibility"
                                    onClick={() => { handleClickShowPassword('showNewPassword') }}
                                    onMouseDown={handleMouseDownPassword}
                                    edge="end"
                                >
                                    {values.showNewPassword ? <Visibility /> : <VisibilityOff />}
                                </IconButton>
                            </InputAdornment>
                        }
                    />
                </div>
                {
                    validInputWarning.password ?
                        <Typography style={{ marginTop: phone ? 6.37 : 12.84, fontFamily: "Avenir", color: "#DC3055", fontSize: phone ? 8 : 12, fontWeight: 500, lineHeight: phone ? "11px" : '16px', textAlign: "left" }}>
                            {t('passwordWarning')}
                        </Typography>
                        : null

                }

                <div style={{ marginTop: phone ? 23.4 : 30 }}>
                    <Typography
                        style={
                            phone ? { marginBottom: 5, textAlign: 'initial', fontFamily: 'Avenir', fontSize: 10, fontWeight: 500, letterSpacing: 0, lineHeight: '14px' }
                                : { marginBottom: 6, textAlign: 'initial', fontFamily: 'Avenir', fontSize: 16, fontWeight: 500, letterSpacing: 0, lineHeight: '22px' }
                        }
                    >
                        {t('confirmPassword')}
                    </Typography>
                    <InputBase
                        type={values.showConfirmPassword ? 'text' : 'password'}
                        placeholder={t('placeHolderLoginRepeatPassword')}

                        style={
                            phone ? { borderRadius: 12, backgroundColor: '#F1F0F0', width: '100%', height: '38.5px', fontSize: 10, padding: 14 }
                                : { borderRadius: 16, backgroundColor: '#F1F0F0', width: '100%', height: '55px', fontSize: 16, padding: 20 }
                        }

                        value={values.confirmPassword}
                        onChange={handleChange('confirmPassword')}
                        endAdornment={
                            <InputAdornment position="end">
                                <IconButton
                                    aria-label="toggle password visibility"
                                    onClick={() => { handleClickShowPassword('showConfirmPassword') }}
                                    onMouseDown={handleMouseDownPassword}
                                    edge="end"
                                >
                                    {values.showConfirmPassword ? <Visibility /> : <VisibilityOff />}
                                </IconButton>
                            </InputAdornment>
                        }
                    />
                </div>
                {
                    values.password != values.confirmPassword ?
                        <Typography style={{ marginTop: phone ? 6.37 : 12.84, fontFamily: "Avenir", color: "#DC3055", fontSize: phone ? 8 : 12, fontWeight: 500, lineHeight: phone ? "11px" : '16px', textAlign: "left" }}>
                            {t('confirmPasswordWarning')}
                        </Typography>
                        : null

                }

                <Button
                    className={phone ? classes.buttonPhone : classes.button}
                    style={{ marginTop: phone ? '100px' : '60px', color: "white", opacity: confirmPasswordDisable() ? 0.3 : null }}
                    onClick={handleNextStep2}
                    disabled={confirmPasswordDisable()}
                >
                    {t('nextStep')}
                </Button>
            </>
        )
    }



    return (
        <div style={phone ? { display: 'block' } : { display: 'flex' }}>

            <div
                style={
                    phone ? { width: '100%', height: '142px', backgroundImage: `url(${leftPage})`, backgroundSize: `min(${width * 0.45}px,613px) min(${width * 0.6}px,817px)`, backgroundRepeat: 'repeat', overflow: 'hidden' }
                        : { position: 'fixed', width: '50%', height: height, backgroundImage: `url(${leftPage})`, backgroundSize: `min(${width * 0.45}px,613px) min(${width * 0.6}px,817px)`, backgroundRepeat: 'repeat', borderRadius: '0px 16px 16px 0px', overflow: 'hidden' }
                }
            >
                <img
                    alt=''
                    src={pageLogo}
                    style={
                        phone ? { width: 90, height: 90, position: 'relative', transform: 'translate(-50%,-50%)', top: '50%', left: '50%', opacity: 0.39 }
                            : { width: 0.3 * width, height: 0.3 * width, position: 'relative', transform: 'translate(-50%,-50%)', top: '50%', left: '50%' }
                    }
                />

            </div>
            <div style={{ marginLeft: phone ? null : '50%', width: phone ? '100%' : '50%', height: '100%', textAlign: 'center' }}>
                <div style={{ margin: phone ? '5% auto' : '100px auto', maxWidth: 544, padding: '0px 29px' }}>
                    {
                        step1 ?
                            renderSendVerification()
                            : step2 ?
                                renderResetPassword()
                                : step3 ?
                                    renderVerification()
                                    : null
                    }
                </div>
            </div>
        </div>

    )
}

export default withTranslation()(ForgotPassword)