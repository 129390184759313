import { withTranslation } from 'react-i18next';
import React, { useEffect, useState } from "react";
import referralWebBanner from '../../images/referral/referralWebBanner.png'
import referralPhoneBanner from '../../images/referral/referralPhoneBanner.png'
import referralBottom from '../../images/referralBottom.png'
import check from '../../images/tick.png'
import { Link } from 'react-router-dom'


import openNewTab from '../../images/referral/openNewTab.png'
import {
    Button,
    Typography,
    makeStyles,
    withStyles,
    TableCell
} from '@material-ui/core';
import { convertTimeString, roundingDown } from "../../utils/Common";
import { authActions } from "../../redux/actions/authActions";
import { referralActions } from "../../redux/actions/referralActions";
import { useDispatch, useSelector } from "react-redux";
import i18n from "../../i18n";
import empty from "../../images/empty.png";
function ReferralFromHome({ t, width, height }) {
    const { token, userInfo } = useSelector(state => state.auth)
    const { referrals } = useSelector(state => state.referral)
    const [phone, setPhone] = useState(width <= 745);
    const [copied, setCopied] = useState(false)
    const [page, setPage] = React.useState(0);
    const [rowsPerPage, setRowsPerPage] = React.useState(10);

    const dispatch = useDispatch();
    const useStyles = makeStyles({
        table: {
            borderCollapse: 'separate',
            borderSpacing: phone ? "0" : '0px 15px'
        },
        fontTitle: {

            fontSize: phone ? 16 : 24,
            fontWeight: "500",
            letterSpacing: 0
        },
        fontContent: {

            fontSize: phone ? 12 : 16,
            fontWeight: "500",
            letterSpacing: 0
        },
        paginationSelect: {
            margin: 'auto',
            fontSize: 10
        }
    });
    const classes = useStyles();

    const StyledTableCell = withStyles((theme) => ({
        root: {
            borderBottom: 'none',

            padding: 0,
            "&:first-child": {
                paddingLeft: 32,
                borderTopLeftRadius: "25px !important",
                borderBottomLeftRadius: "25px !important"
            },
            "&:last-child": {
                paddingRight: 32,
                borderTopRightRadius: "25px !important",
                borderBottomRightRadius: "25px !important"
            }
        },
        head: {
            // backgroundColor: 'red',
            color: '#9097A1',
            fontSize: 14,
            fontWeight: 400,
            paddingLeft: 80,

        },
        body: {
            color: '#1C2237',

            padding: '24px 0px',
            fontWeight: 900,
            paddingLeft: 58,
            backgroundColor: '#F9F9F9',
        }
    }))(TableCell);

    // useEffect(() => {
    //     dispatch(authActions.getUserInfo(token))
    //     dispatch(referralActions.getReferrals({ currentPage: page, pageSize: rowsPerPage }, token))
    // }, []);

    useEffect(() => {
        setPhone(width <= 745)
    }, [width]);



    return (
        <div style={{ display: 'flex', justifyContent: 'center', paddingBottom: 80 }}>
            <div style={{ width: '90%' }}>
                {phone ?
                    <div style={{ display: 'flex', flexDirection:'column',alignItems: 'flex-start', marginTop: phone ? 12 : 30, width: '100%' }}>
                        <img style={{position:'absolute',opacity:0.2}} src={referralPhoneBanner}></img>
                        <div style={{ marginBottom:24,marginTop:40 }}>
                            <Typography style={{ fontSize: phone ? 16 : 36, lineHeight: '27px', fontWeight: '500' }}>
                                {t('referralTitle1')}
                                <Typography className={classes.font} style={{ fontSize: phone ? 28 : 42, display: 'inline', fontWeight: '500', marginLeft: 8, marginRight: 8, color: '#0173F9' }}>
                                    45%
                                </Typography>
                                {t('referralTitle2')}
                            </Typography>


                            <Button onClick={() => { window.open(i18n.language === 'en' ? "https://otcdocs.yesbit.io/v/english/faq/referrals" : "https://otcdocs.yesbit.io/faq/yao-qing-ji-zhi") }} variant="contained" style={{ backgroundColor: '#0173F9', height: phone ? 32 : 40, borderRadius: phone ? 6 : 10 }}>
                                <Typography style={{ fontSize: phone ? 12 : 24, fontWeight: '500', textTransform: 'none', color: 'white' }}>
                                    {t('learnMore')}
                                </Typography>

                            </Button>
                        </div>

                    </div>
                    :
                    <div style={{ display: 'flex', alignItems: 'center', marginTop: phone ? 12 : 30, width: '100%', height: phone ? 210 : "22.19vw", backgroundImage: `url(${phone ? referralPhoneBanner : referralWebBanner})`, backgroundSize: '100%' }}>

                        <div style={{ marginLeft: phone ? 12 : 28 }}>
                            <Typography style={{ fontSize: phone ? 16 : 36, lineHeight: '27px', fontWeight: '500' }}>
                                {t('referralTitle1')}
                                <Typography className={classes.font} style={{ fontSize: phone ? 28 : 42, display: 'inline', fontWeight: '500', marginLeft: 8, marginRight: 8, color: '#0173F9' }}>
                                    45%
                                </Typography>
                                {t('referralTitle2')}
                            </Typography>


                            <Button onClick={() => { window.open(i18n.language === 'en' ? "https://otcdocs.yesbit.io/v/english/faq/referrals" : "https://otcdocs.yesbit.io/faq/yao-qing-ji-zhi") }} variant="contained" style={{ backgroundColor: '#0173F9', height: phone ? 32 : 40, borderRadius: phone ? 6 : 10 }}>
                                <Typography style={{ fontSize: phone ? 12 : 24, fontWeight: '500', textTransform: 'none', color: 'white' }}>
                                    {t('learnMore')}
                                </Typography>

                            </Button>
                        </div>

                    </div>

                }


                <div style={{ marginLeft: phone ? 12 : 28, marginRight: phone ? 12 : 28, marginTop: 8 }}>
                    <Typography className={classes.font}
                        style={{

                            fontSize: phone ? 16 : 32,
                            fontWeight: 500,
                            letterSpacing: 0,
                            color: 'black'
                        }}>
                        {t('referralContent1')}
                    </Typography>
                    <div style={{ marginTop: 24 }}>
                        <Typography className={classes.fontTitle}>
                            {t('referralContent2')}
                        </Typography>
                        <div style={{ height: 8 }} />
                        <Typography className={classes.fontContent}>
                            {t('referralContent3')}
                        </Typography>
                    </div>
                    <div style={{ marginTop: 24 }}>
                        <Typography className={classes.fontTitle}>
                            {t('referralContent4')}
                        </Typography>
                        <div style={{ height: 8 }} />
                        <Typography className={classes.fontContent}>
                            {t('referralContent5')}
                        </Typography>
                    </div>
                    <div style={{ marginTop: 24 }}>
                        <Typography className={classes.fontTitle}>
                            {t('referralContent6')}
                        </Typography>
                        <div style={{ height: 8 }} />
                        <Typography className={classes.fontContent}>
                            {t('referralContent7')}
                        </Typography>
                    </div>
                </div>
                <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', marginTop: phone ? 16 : 32 }}>
                    <Link to='/referral' style={{ textDecoration: 'none' }}>
                        <Button variant="contained" style={{ backgroundColor: '#0173F9', width: phone ? 320 : 400, height: 60, borderRadius: 12 }}>
                            <Typography style={{ fontSize: 16, fontWeight: '500', textTransform: 'none', color: 'white' }}>

                                {t('referralLinkButton')}
                            </Typography>

                        </Button>
                    </Link>
                </div>
                <div style={{ display: 'flex', alignItems: 'center', marginTop: 0, width: '100%', height: phone ? "14vw" : "14vw", backgroundImage: `url(${phone ? referralBottom : referralBottom})`, backgroundSize: '100%' }} />

            </div>
        </div>
    )
}

export default withTranslation()(ReferralFromHome);