import {tradeService} from "../services/tradeServices";
import {
    CLEAR_TRADE,
    CLEAR_TRADE_QUOTE,
    GET_AQUA_BALANCE,
    GET_AQUA_BALANCE_FAILED,
    GET_AQUA_BALANCE_SUCCEED,
    GET_LIVE_PRICE,
    GET_LIVE_PRICE_FAILED,
    GET_LIVE_PRICE_SUCCEED,
    GET_TRADE_TRANSACTIONS,
    GET_TRADE_TRANSACTIONS_FAILED,
    GET_TRADE_TRANSACTIONS_SUCCEED,
    TRADE,
    TRADE_FAILED,
    TRADE_PRICE_QUOTE,
    TRADE_PRICE_QUOTE_FAILED,
    TRADE_PRICE_QUOTE_SUCCEED,
    TRADE_SUCCEED,
    EXPIRE_AQUA_QUOTE,
    EXPIRE_AQUA_QUOTE_FAILED,
    EXPIRE_AQUA_QUOTE_SUCCEED,
    GET_BTC_PRICE_CHANGE,
    GET_BTC_PRICE_CHANGE_FAILED,
    GET_BTC_PRICE_CHANGE_SUCCEED,
    GET_ETH_PRICE_CHANGE,
    GET_ETH_PRICE_CHANGE_FAILED,
    GET_ETH_PRICE_CHANGE_SUCCEED,
    GET_USDT_PRICE_CHANGE,
    GET_USDT_PRICE_CHANGE_FAILED,
    GET_USDT_PRICE_CHANGE_SUCCEED,
    GET_BANNER_INFO, GET_BANNER_INFO_SUCCEED,
    GET_BANNER_INFO_FAILED
} from '../constants';


export const tradeActions = {
    tradePriceQuote,
    getLivePrice,
    clearTradeQuote,
    trade,
    clearTrade,
    getTradeTransactionByCoinType,
    getAquaBalance,
    expireAuaQuote,
    getPriceChange,
    getBannerInfo
}

function tradePriceQuote(tickerPayload, payload,token) {
    return dispatch => {
        dispatch(request());
        tradeService.getTicker(tickerPayload, token)
            .then(
                res=>{
                    payload['ticker'] = res.data.ticker
                    tradeService.tradePriceQuote(payload,token)
                        .then(
                            res => {
                                if (res) {
                                    dispatch(success(res.data));
                                }
                            },
                            error => {
                                dispatch(failure(error.message));
                            }
                        );
                },
                error =>{
                    dispatch(failure(error.message));
                }
            )

    };

    function request() { return { type: TRADE_PRICE_QUOTE } }
    function success(data) { return { type: TRADE_PRICE_QUOTE_SUCCEED, data } }
    function failure(message) { return { type: TRADE_PRICE_QUOTE_FAILED, message } }
}

function getLivePrice(tickerPayload, payload, homepage, token) {
    return dispatch => {
        dispatch(request());
        tradeService.getTicker(tickerPayload, token)
            .then(
                res => {
                    payload['ticker'] = res.data.ticker
                    if (homepage){
                        tradeService.tradeLivePrice(payload)
                            .then(
                                res => {
                                    if (res) {
                                        dispatch(success(res.data));
                                    }
                                },
                                error => {
                                    dispatch(failure(error.message));
                                }
                            );
                    }else{
                        tradeService.tradePriceQuote(payload,token)
                            .then(
                                res => {
                                    if (res) {
                                        dispatch(success(res.data));
                                    }
                                },
                                error => {
                                    dispatch(failure(error.message));
                                }
                            );
                    }
                },
                error => {
                    dispatch(failure(error.message));
                }
            )
    };

    function request() { return { type: GET_LIVE_PRICE } }
    function success(data) { return { type: GET_LIVE_PRICE_SUCCEED, data } }
    function failure(message) { return { type: GET_LIVE_PRICE_FAILED, message } }
}

function clearTradeQuote(){
    return dispatch =>{
        dispatch(request());
    };
    function request(){return {type: CLEAR_TRADE_QUOTE}}
}

function trade(payload, token) {
    return dispatch => {
        dispatch(request());
        tradeService.getTicker({token1:payload.deliverCurrency, token2:payload.receiveCurrency}, token)
            .then(
                res=>{
                    payload['ticker'] = res.data.ticker
                    tradeService.trade(payload, token)
                        .then(
                            res => {
                                if (res) {
                                    dispatch(success(res.data));
                                }
                            },
                            error => {
                                if (error.message === 'Insufficient fund.') {
                                    dispatch(failure('insufficientFund'));
                                } else if (error.message === 'Cannot create transaction, kyc did not passed.') {
                                    dispatch(failure('KycNotPassed'))
                                } else if (error.message === 'Price changed significantly, swap failed.') {
                                    dispatch(failure('tradePriceChangedError'))
                                } else if (error.message === 'Aqua: Insufficient funds'){
                                    dispatch(failure('tradeAquaBalanceError'))
                                } else {
                                    dispatch(failure('tradeNetworkError'));
                                }
                            }
                        );
                },
                error=>{
                    dispatch(failure('tradeNetworkError'));
                }
            )

    };

    function request() { return { type: TRADE } }
    function success(data) { return { type: TRADE_SUCCEED, data } }
    function failure(message) { return { type: TRADE_FAILED, message } }
}

function clearTrade(){
    return dispatch =>{
        dispatch(request());
    };
    function request(){return {type: CLEAR_TRADE}}
}

function getTradeTransactionByCoinType(payload) {
    // console.log('get trade history payload: ', payload)
    return dispatch => {
        const token = localStorage.getItem("otcuser")
        if(token && token != ""){
            dispatch(request(token));
            tradeService.getTradeTransactionByCoinType(token, payload)
                .then(
                    res => {
                        if (res) {
                            dispatch(success(res.data));
                        }
                    },
                    error => {
                        console.log('err: ', error)
                        dispatch(failure(error.message))
                    }
                );
        } else {
            dispatch(failure({message: 'tokenExpired'}));
        }
    };

    function request(token) { return { type: GET_TRADE_TRANSACTIONS, token, payload } }
    function success(data) { return { type: GET_TRADE_TRANSACTIONS_SUCCEED, data } }
    function failure(message) { return { type: GET_TRADE_TRANSACTIONS_FAILED, message } }
}

function getAquaBalance(payload,token) {
    return dispatch => {
        dispatch(request(token));
        tradeService.getAquaBalance(token, payload)
            .then(
                res => {
                    if (res) {
                        dispatch(success(res.data));
                    }
                },
                error => {
                    console.log('err: ', error)
                    dispatch(failure(error.message))
                }
            );
    };

    function request() { return { type: GET_AQUA_BALANCE } }
    function success(data) { return { type: GET_AQUA_BALANCE_SUCCEED, data } }
    function failure(message) { return { type: GET_AQUA_BALANCE_FAILED, message } }
}

function expireAuaQuote(payload,token) {
    return dispatch => {
        dispatch(request());
        tradeService.expireAuaQuote(token, payload)
            .then(
                res => {
                    if (res) {
                        dispatch(success(res.data));
                    }
                },
                error => {
                    console.log('err: ', error)
                    dispatch(failure(error.message))
                }
            );
    };

    function request() { return { type: EXPIRE_AQUA_QUOTE } }
    function success(data) { return { type: EXPIRE_AQUA_QUOTE_SUCCEED, data } }
    function failure(message) { return { type: EXPIRE_AQUA_QUOTE_FAILED, message } }
}

function getPriceChange(payload,token) {
    if (payload.token1 === 'BTC') {
        return dispatch => {
            dispatch(requestBTC());
            tradeService.getPriceChange(payload, token)
                .then(
                    res => {
                        dispatch(successBTC(res.data));
                    },
                    error => {
                        console.log('err: ', error)
                        dispatch(failureBTC(error.message))
                    }
                );
        }
    } else if (payload.token1 === 'ETH') {
        return dispatch => {
            dispatch(requestETH());
            tradeService.getPriceChange(payload, token)
                .then(
                    res => {
                        dispatch(successETH(res.data));
                    },
                    error => {
                        console.log('err: ', error)
                        dispatch(failureETH(error.message))
                    }
                );
        }
    } else if (payload.token1 === 'USDT') {
        return dispatch => {
            dispatch(requestUSDT());
            tradeService.getPriceChange(payload, token)
                .then(
                    res => {
                        dispatch(successUSDT(res.data));
                    },
                    error => {
                        console.log('err: ', error)
                        dispatch(failureUSDT(error.message))
                    }
                );
        }
    };

    function requestBTC() { return { type: GET_BTC_PRICE_CHANGE } }
    function successBTC(data) { return { type: GET_BTC_PRICE_CHANGE_SUCCEED, data } }
    function failureBTC(message) { return { type: GET_BTC_PRICE_CHANGE_FAILED, message } }

    function requestETH() { return { type: GET_ETH_PRICE_CHANGE } }
    function successETH(data) { return { type: GET_ETH_PRICE_CHANGE_SUCCEED, data } }
    function failureETH(message) { return { type: GET_ETH_PRICE_CHANGE_FAILED, message } }

    function requestUSDT() { return { type: GET_USDT_PRICE_CHANGE } }
    function successUSDT(data) { return { type: GET_USDT_PRICE_CHANGE_SUCCEED, data } }
    function failureUSDT(message) { return { type: GET_USDT_PRICE_CHANGE_FAILED, message } }
}

function getBannerInfo(token) {
    return dispatch => {
        dispatch(request());
        tradeService.getBannerInfo(token)
            .then(
                res => {
                    if (res) {
                        dispatch(success(res.data));
                    }
                },
                error => {
                    console.log('err: ', error)
                    dispatch(failure(error.message))
                }
            );
    };

    function request() { return { type: GET_BANNER_INFO } }
    function success(data) { return { type: GET_BANNER_INFO_SUCCEED, data } }
    function failure(message) { return { type: GET_BANNER_INFO_FAILED, message } }
}
