import {withTranslation} from "react-i18next";
import {Button, InputBase, makeStyles, MenuItem, Modal, Select, Typography} from "@material-ui/core";
import React, {useEffect, useState} from "react";
import {useDispatch, useSelector} from "react-redux";
import {kycActions} from "../../redux/actions/kycActions";
import {isEnglishKeyboard} from "../../utils/Checker";
import ReactDatePicker from "react-datepicker";
import backIcon from "../../images/backIcon.png"
import {authActions} from "../../redux/actions/authActions";
import {convertDateString} from "../../utils/Common";
import CAD from '../../images/funds/cad.png'
import USA from '../../images/funds/usa.png'

import secureIcon from '../../images/secure.png'
import dropdownIcon from "../../images/dropdownIcon.png";
import {motion} from "framer-motion"
import x from "../../images/closePopIcon.png";
import enterArrow from "../../images/enterArrow.png";

const KycModal = (props) => {
    const {
        onCancel: handleClose,
        modalVisible,
        t,
        phone,
        width,
        userInfo,
        fromRegi
    } = props;
    const [step, setStep] = useState(1)
    const [personalInfo, setPersonalInfo] = useState({
        firstName: '',
        lastName: '',
        dateOfBirth: '',
        occupation: '',
        employerName: userInfo.employerName,
        language: userInfo.language
    });


    const useStyles = makeStyles((theme) => ({
        indCircalActive: {
            height: phone ? 24 : 40, width: phone ? 24 : 40, backgroundColor: 'black', borderRadius: 20, display: 'flex', alignItems: 'center', justifyContent: 'center'
        },
        indCircalTextActive: {
            fontSize: phone ? 12 : 16, fontWeight: "500", color: 'white', fontFamily: 'Avenir'
        },
        indCircalInactive: {
            height: phone ? 24 : 40, width: phone ? 24 : 40, backgroundColor: '#D8D8D8', borderRadius: 20, display: 'flex', alignItems: 'center', justifyContent: 'center'
        },
        indCircalTextInactive: {
            fontSize: phone ? 12 : 16, fontWeight: "500", color: 'white', fontFamily: 'Avenir'
        },
        modal: {
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            textAlign: 'center',
        },
        font: {
            fontFamily: "Avenir",
            fontWeight: 500,
            letterSpacing: 0,
            textAlign: 'left'
        },
        inputLabel: {
            color: '#9097A1', marginBottom: 6, textAlign: 'initial', fontFamily: 'Avenir', fontSize: 16, fontWeight: 500, letterSpacing: 0, lineHeight: '22px'
        },
        inputLabelPhone: {
            color: '#9097A1', marginBottom: 5, textAlign: 'initial', fontFamily: 'Avenir', fontSize: 12, fontWeight: 500, letterSpacing: 0, lineHeight: '14px'
        },
        input: {
            borderRadius: 16, backgroundColor: 'white', width: '100%', height: '55px', fontSize: 16, padding: 20, border: "1px solid #EDEDED"
        },
        inputPhone: {
            borderRadius: 12, backgroundColor: 'white', width: '100%', height: '38.5px', fontSize: 14, padding: 14, border: "1px solid #EDEDED"
        },
        datePicker: {
            borderRadius: 16, backgroundColor: 'white', width: '-webkit-fill-available', height: '55px', fontSize: 16, padding: '0px 20px', fontWeight: 500, border: "1px solid #EDEDED"
        },
        datePickerPhone: {
            borderRadius: 12, backgroundColor: 'white', width: '-webkit-fill-available', height: '38.5px', fontSize: 14, padding: '0px 14px', fontWeight: 500, border: "1px solid #EDEDED"
        },
        button: {
            // width: '100%',
            height: '65px',
            borderRadius: 20,
            color: 'white !important',
            backgroundColor: '#0173F9',
            fontFamily: 'Avenir',
            fontSize: 18,
            fontWeight: 900,
            letterSpacing: 0,
            lineHeight: '25px',
            textTransform: 'none',
            "&:hover": {
                backgroundColor: '#0173F9',
                // Reset on touch devices, it doesn't add specificity
                "@media (hover: none)": {
                    backgroundColor: '#0173F9',
                    "&:active": {
                        backgroundColor: '#0173F9'
                    }
                },
                '&$disabled': {
                    backgroundColor: '#0173F9',
                },
            },
        },
        buttonPhone: {
            height: '48.3px',
            borderRadius: 12,
            color: 'white !important',
            backgroundColor: '#0173F9',
            fontFamily: 'Avenir',
            fontSize: 12,
            fontWeight: 900,
            letterSpacing: 0,
            lineHeight: '16px',
            textTransform: 'none',
            "&:hover": {
                backgroundColor: '#0173F9',
                // Reset on touch devices, it doesn't add specificity
                "@media (hover: none)": {
                    backgroundColor: '#0173F9',
                    "&:active": {
                        backgroundColor: '#0173F9'
                    }
                },
                '&$disabled': {
                    backgroundColor: '#0173F9',
                },
            },
        },
        select: {
            fontFamily: 'Avenir',
            fontSize: phone ? 12 : 16,
            // padding: phone ? 0 : 8,
            paddingTop: 12,
            paddingBottom: 12,
            paddingLeft: 24,
            // paddingRight:24,
            borderBottom: null,
            backgroundColor: '#F9F9F9',
            marginTop: 0,
            borderRadius: 16,
            marginBottom: 24,
            width: '100%',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            '&:before': {
                borderColor: 'transparent',
            },
            '&:after': {
                borderColor: 'transparent',
            },
            '& .MuiSelect-selectMenu': {
                display: 'flex',
                fontWeight: '500',
                justifyContent: 'flex-start',
                alignItems: 'center'
            },
            '& .MuiSelect-select': {
                fontSize: 12
            }
        },
    }));
    const classes = useStyles();
    const dispatch = useDispatch();

    const [checked, setChecked] = useState(false)
    const [canRedirect, setCanRedirect] = useState(false)
    const { token, updateUserInfoMsg } = useSelector(state => state.auth)
    const { kycDetails, inited, addressVerificationInited, addressVerificationUrl, documentDetails } = useSelector(state => state.kyc)
    const [failedReason, setFailedReason] = useState('')
    const [verifyingAddress, setVerifyingAddress] = useState(false)
    const [addressDocument, setAddressDocument] = useState('BS')

    const handlePersonalInfoChange = (prop) => (event) => {
        if (isEnglishKeyboard(event.target.value)) {
            setPersonalInfo({ ...personalInfo, [prop]: event.target.value });
        }
    };

    const handleUpdate = () => {
        let payload = {}
        for (let input in personalInfo) {
            if (personalInfo[input]) {
                payload[input] = personalInfo[input]
            }
        }
        console.log('updating user info : ', payload)
        dispatch(authActions.updateUserInfo(payload, token))
    }
    const DatepickerInput = ({ ...props }) => (
        <input type="text" {...props} readOnly />
    );

    const handleStart = () => {
        handleUpdate()
        if (kycDetails.status === undefined || kycDetails.status === 'expired' || kycDetails.status === 'failed') {
            dispatch(kycActions.initKycLink(token))
        } else if (kycDetails.status === 'idPassed') {
            dispatch(kycActions.initAddressVerificationLink(token, { docType: addressDocument }))
        }
        setChecked(true)
    }

    const handleDocumentChange = (e) => {
        setAddressDocument(e.target.value)
    }

    useEffect(() => {
        if (kycDetails.status === 'idPassed') {
            setVerifyingAddress(true)
        } else {
            setVerifyingAddress(false)
        }
    }, [kycDetails])

    useEffect(() => {
        if (kycDetails.remark !== "") {
            if (kycDetails.remark === "birthday does not match" || kycDetails.remark === "name does not match") {
                setFailedReason(t('misMatchedUserInfo'))
            } else if (kycDetails.remark === 'verification failed' || kycDetails.remark === "document unreadable") {
                setFailedReason(t('invalidInfo'))
            } else {
                setFailedReason(t('invalidInfo'))
            }
        }
        if (kycDetails.redirectUrl !== undefined && kycDetails !== null) {
            setCanRedirect(true)
        }
        return () => {
        }
    }, [kycDetails])

    useEffect(() => {
        dispatch(kycActions.getKycDetails(token))
        return () => {
        }
    }, [inited])

    useEffect(() => {
        dispatch(kycActions.getDocumentDetails(token))
        return () => {
        }
    }, [addressVerificationInited])

    useEffect(() => {
        if (canRedirect && (kycDetails.status === 'inited' || inited) && checked) {
            window.location.replace(kycDetails.redirectUrl)
        } else if (canRedirect && (documentDetails.status === 'inited' || addressVerificationInited) && checked) {
            if (addressVerificationUrl !== '' && addressVerificationUrl !== undefined) {
                window.location.replace(addressVerificationUrl)
            } else {
                window.location.replace(documentDetails.clientRedirectUrl)
            }
        }
    }, [checked, canRedirect, kycDetails, documentDetails])

    useEffect(() => {
        console.log('msg here: ', updateUserInfoMsg)
    }, [updateUserInfoMsg])

    const stepIndicatorView = (step) => {
        return (
            <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                <div className={classes.indCircalActive}>
                    <Typography className={classes.indCircalTextActive}>
                        1
                    </Typography>
                </div>
                <div style={{ height: 1, width: 40, backgroundColor: step == 1 || step == 2 ? 'black' : "#D8D8D8" }} />

                <div className={step == 2 || step == 3 ? classes.indCircalActive : classes.indCircalInactive}>
                    <Typography className={step == 2 || step == 3 ? classes.indCircalTextActive : classes.indCircalTextInactive}>
                        2
                    </Typography>
                </div>

                <div style={{ height: 1, width: 40, backgroundColor: step == 2 ? 'black' : "#D8D8D8" }} />

                <div className={step == 3 ? classes.indCircalActive : classes.indCircalInactive}>
                    <Typography className={step == 3 ? classes.indCircalTextActive : classes.indCircalTextInactive}>
                        3
                    </Typography>
                </div>
            </div>
        )
    }
    const stepIndicator2View = (step) => {
        return (
            <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                <div className={classes.indCircalActive}>
                    <Typography className={classes.indCircalTextActive}>
                        1
                    </Typography>
                </div>
                <div style={{ height: 1, width: 40, backgroundColor: step == 1 || step == 2 ? 'black' : "#D8D8D8" }} />

                <div className={step == 2 || step == 3 ? classes.indCircalActive : classes.indCircalInactive}>
                    <Typography className={step == 2 || step == 3 ? classes.indCircalTextActive : classes.indCircalTextInactive}>
                        2
                    </Typography>
                </div>


            </div>
        )
    }

    const KycModalBody1 = (
        <div style={{
            backgroundColor: 'white', borderRadius: 20, width: phone ? " 89vw" : "606px", overflow: 'hidden', outline: 0, display: 'flex', alignItems: 'center', flexDirection: 'column', height: phone ? 500 : 600
        }}>
            <div style={{ width: "100%", }}>
                <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', marginTop: phone ? 24 : 40, marginLeft: phone ? 16 : 32, marginRight: phone ? 16 : 32 }}>

                    {/* {stepIndicatorView(1)} */}
                    <Button
                        style={{
                            padding: 0,
                            minHeight: 0,
                            minWidth: 0,
                        }}
                        onClick={handleClose}
                    >
                        <img alt='' src={x} style={phone ? { height: 24, width: 24 } : { height: 32, width: 32 }} />
                    </Button>
                </div>
            </div>
            <div style={{ marginTop: phone ? 15 : 15, marginBottom: 15, boxSizing: 'border-box', height: 1, border: '1px solid #DDDDDD', width: '91%', marginLeft: 32, marginRight: 32 }} />


            <div style={phone ? { marginLeft: "4vw", height: '100%', marginRight: '4vw', marginTop: 0, justifyContent: 'space-between', display: 'flex', flexDirection: 'column' } : { width: '91%', marginTop: 8, height: '100%', justifyContent: 'space-between', display: 'flex', flexDirection: 'column' }}>
                <div style={{}}>

                    <Typography style={{ fontSize: phone ? 16 : 24, fontWeight: "500", color: '#1C2237', fontFamily: 'Avenir', textAlign: 'left' }}>
                        {t('idVerification')}
                    </Typography>
                    <Typography
                        className={classes.font}
                        style={{ color: kycDetails.remark === "birthday does not match" || kycDetails.remark === "name does not match" ? '#DC3055' : "#9097A1", fontSize: phone ? 12 : 14, fontWeight: 500, marginTop: 8, marginBottom: 24 }}
                    >
                        {
                            kycDetails.remark === undefined || kycDetails.remark === '' ?
                                t("step1SubTitle") :
                                kycDetails.remark === "birthday does not match" || kycDetails.remark === "name does not match" ?
                                    failedReason : t("step1SubTitle")
                        }
                    </Typography>

                    <div style={{ marginTop: phone ? 18 : 24, justifyContent: 'space-between', display: 'flex' }}>
                        <div style={{ width: '45%' }}>
                            <Typography
                                className={phone ? classes.inputLabelPhone : classes.inputLabel}
                                style={fromRegi ? { color: "#9097A1" } : { color: kycDetails.idFirstName !== undefined && kycDetails.idFirstName != userInfo.firstName ? '#DC3055' : "#9097A1" }}
                            >
                                {t('firstName')}
                            </Typography>

                            <InputBase
                                className={phone ? classes.inputPhone : classes.input}
                                placeholder={userInfo.firstName ? userInfo.firstName : ""}
                                value={personalInfo.firstName}
                                style={{ color: 'black' }}
                                onChange={handlePersonalInfoChange('firstName')}

                            // onFocus={() => { setFocuses({ ...focuses, ['firstName']: true }) }}
                            // onBlur={() => { setFocuses({ ...focuses, ['firstName']: false }) }}

                            />
                        </div>

                        <div style={{ width: '45%' }}>
                            <Typography
                                className={phone ? classes.inputLabelPhone : classes.inputLabel}
                                style={fromRegi ? { color: "#9097A1" } : { color: kycDetails.idLastName !== undefined && kycDetails.idLastName != userInfo.lastName ? '#DC3055' : "#9097A1" }}

                            >
                                {t('lastName')}
                            </Typography>

                            <InputBase
                                className={phone ? classes.inputPhone : classes.input}
                                placeholder={userInfo.lastName ? userInfo.lastName : ""}
                                value={personalInfo.lastName}
                                style={{ color: 'black' }}
                                onChange={handlePersonalInfoChange('lastName')}
                            // endAdornment={
                            //     noEdit ? null : <InputAdornment position="end">
                            //         <img alt='' src={focuses.lastName ? BlackEditIcon : EditIcon} style={phone ? { height: 13, width: 13 } : { height: 17, width: 17 }} />
                            //     </InputAdornment>
                            // }
                            // disabled={noEdit}
                            // onFocus={() => { setFocuses({ ...focuses, ['lastName']: true }) }}
                            // onBlur={() => { setFocuses({ ...focuses, ['lastName']: false }) }}
                            />
                        </div>
                    </div>

                    <div style={{ marginTop: phone ? 20 : 32 }}>
                        <Typography
                            className={phone ? classes.inputLabelPhone : classes.inputLabel}
                            style={fromRegi ? { color: "#9097A1" } : { color: kycDetails.idDob !== undefined && kycDetails.idDob != userInfo.dateOfBirth ? '#DC3055' : "#9097A1" }}
                        >
                            {t("birthday")}
                        </Typography>

                        <ReactDatePicker
                            className={phone ? classes.datePickerPhone : classes.datePicker}
                            selected={personalInfo.dateOfBirth}
                            onChange={(date) => setPersonalInfo({ ...personalInfo, ['dateOfBirth']: date })}
                            peekNextMonth
                            dateFormat={'yyyy-MM-dd'}
                            showMonthDropdown
                            showYearDropdown
                            dropdownMode="select"
                            placeholderText={fromRegi ? convertDateString(userInfo.dateOfBirth) : userInfo.dateOfBirth}
                            strictParsing
                            customInput={<DatepickerInput />}

                        // disabled={noEdit}
                        // onFocus={()=>{setFocuses({...focuses,['dateOfBirth']:true})}}
                        // onBlur = {()=>{setFocuses({...focuses,['dateOfBirth']:false})}}
                        />
                    </div>

                </div>

                <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'flex-end', width: '100%', height: 100, marginBottom: 16 }}>
                    {/*<Button*/}
                    {/*    className={phone ? classes.buttonPhone : classes.button}*/}
                    {/*    style={{ marginTop: phone ? 40 : 60, marginBottom: phone ? 40 : 60 }}*/}

                    {/*    onClick={() => { setStep(2) }}*/}
                    {/*>*/}
                    {/*    <Typography className={classes.font} style={{ fontSize: phone ? 14 : 18, color: 'white', fontWeight: 500, marginLeft: 12, marginRight: 12, lineHeight: phone ? '11px' : '25px' }}>{t('nextStep')}</Typography>*/}
                    {/*    <img style={{ height: phone ? 16 : 20 }} src={enterArrow}></img>*/}
                    {/*</Button>*/}

                    <Button
                        className={phone ? classes.buttonPhone : classes.button}
                        style={{ marginTop: phone ? 40 : 60, marginBottom: phone ? 40 : 60 }}

                        onClick={handleStart}
                    >
                        <Typography className={classes.font} style={{ fontSize: phone ? 14 : 18, color: 'white', fontWeight: 500, marginLeft: 12, marginRight: 12, lineHeight: phone ? '11px' : '25px' }}> {t('start')}</Typography>
                        <img style={{ height: phone ? 16 : 20 }} src={enterArrow}></img>
                    </Button>
                </div>

            </div>
        </div >
    );

    const KycModalBody2 = (
        <div style={{
            backgroundColor: 'white', borderRadius: 20, width: phone ? " 89vw" : "606px", overflow: 'hidden', outline: 0, display: 'flex', alignItems: 'center', flexDirection: 'column', height: phone ? 500 : 600
        }}>
            <div style={{ width: "100%", }}>
                <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', marginTop: phone ? 24 : 40, marginLeft: phone ? 16 : 32, marginRight: phone ? 16 : 32 }}>

                    {stepIndicatorView(2)}
                    <Button
                        style={{
                            padding: 0,
                            minHeight: 0,
                            minWidth: 0,
                        }}
                        onClick={handleClose}
                    >
                        <img alt='' src={x} style={phone ? { height: 24, width: 24 } : { height: 32, width: 32 }} />
                    </Button>
                </div>
            </div>
            <div style={{ marginTop: phone ? 15 : 15, marginBottom: 15, boxSizing: 'border-box', height: 1, border: '1px solid #DDDDDD', width: '91%', marginLeft: 32, marginRight: 32 }} />

            {/* <div style={{ height: '60', marginTop: 24, display: 'flex', flexDirection: 'row', justifyContent: 'end', marginLeft: phone ? 0 : 24, marginRight: phone ? 0 : 24 }}>
                <Button
                    onClick={() => {
                        setStep(1)
                        handleClose()

                    }}
                >
                    <img src={closeIcon} style={phone ? { height: 24, width: 24 } : { height: 40, width: 40 }} />
                </Button>
            </div> */}

            <div style={phone ? { marginLeft: "4vw", height: '100%', marginRight: '4vw', marginTop: 0, justifyContent: 'space-between', display: 'flex', flexDirection: 'column' } : { width: '91%', marginTop: 8, height: '100%', justifyContent: 'space-between', display: 'flex', flexDirection: 'column' }}>
                <div style={{}}>
                    <Typography
                        className={classes.font}
                        style={{ fontSize: phone ? 14 : 24, }}
                    >
                        {t('supportedIdTitle')}
                    </Typography>
                    <Typography
                        className={classes.font}
                        style={{ color: kycDetails.remark === 'verification failed' || kycDetails.remark === "document unreadable" ? '#DC3055' : "#9097A1", fontSize: phone ? 12 : 14, marginTop: 12, marginBottom: 24 }}
                    >
                        {
                            kycDetails.remark === 'verification failed' || kycDetails.remark === "document unreadable" ?
                                t(failedReason) : t('supportedIdText')
                        }
                    </Typography>

                    <div style={{ marginTop: phone ? 20 : 30, display: 'flex', flexDirection: 'row', justifyContent: 'start', alignItems: 'center', border: "1px solid #f9f9f9", borderRadius: 12, }}>
                        <img style={{ width: phone ? 28 : 32, height: phone ? 28 : 32, borderRadius: phone ? 8 : 12, alignSelf: 'center', backgroundColor: 'transparent', marginLeft: 12, marginRight: 8, marginTop: 12, marginBottom: 12 }} src={CAD} />
                        <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-start' }}>
                            <Typography style={{ color: '#1C2237', fontSize: phone ? 14 : 16, alignSelf: 'start', fontWeight: 500 }}>
                                {t("canada")}
                            </Typography>
                            <Typography style={{ color: "#1C2237", fontSize: phone ? 12 : 14, fontWeight: 300, textTransform: "none" }}>{t('supportedIds')}</Typography>
                        </div>
                    </div>

                    <div style={{ marginTop: phone ? 20 : 30, display: 'flex', flexDirection: 'row', justifyContent: 'start', alignItems: 'center', border: "1px solid #f9f9f9", borderRadius: 12, }}>
                        <img style={{ width: phone ? 28 : 32, height: phone ? 28 : 32, borderRadius: phone ? 8 : 12, alignSelf: 'center', backgroundColor: 'transparent', marginLeft: 12, marginRight: 8, marginTop: 12, marginBottom: 12 }} src={USA} />
                        <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-start' }}>
                            <Typography style={{ color: '#1C2237', fontSize: phone ? 14 : 16, alignSelf: 'start', fontWeight: 500 }}>
                                {t("usa")}
                            </Typography>
                            <Typography style={{ color: "#1C2237", fontSize: phone ? 12 : 14, fontWeight: 300, textTransform: "none" }}>{t('supportedIdsUSA')}</Typography>
                        </div>
                    </div>

                    <div style={{ marginTop: phone ? 20 : 20, display: 'flex' }}>
                        <img alt="" src={secureIcon} style={{ height: phone ? 16 : 21, marginRight: 8 }} />

                        <div>
                            <Typography
                                className={classes.font}
                                style={{ color: '#BAC3CE', fontSize: phone ? 12 : 14 }}
                            >
                                {t('secureNote')}
                            </Typography>
                        </div>
                    </div>
                </div>
                <div style={{ marginTop: phone ? 20 : 20, display: 'flex' }}>
                    {
                        !!updateUserInfoMsg && updateUserInfoMsg !== '' ?
                                <Typography
                                    className={classes.font}
                                    style={{ color: 'red', fontSize: phone ? 12 : 14 }}
                                >
                                    {updateUserInfoMsg}
                                </Typography>
                                 : null

                    }
                </div>

                <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', alignItems: 'center', height: 100, marginBottom: 16 }}>
                    <Button
                        onClick={() => {

                            setStep(1)
                        }}
                    >
                        <img src={backIcon} style={phone ? { height: 24, width: 24 } : { height: 40, width: 40 }} />
                    </Button>

                    <Button
                        className={phone ? classes.buttonPhone : classes.button}
                        style={{ marginTop: phone ? 40 : 60, marginBottom: phone ? 40 : 60 }}

                        onClick={handleStart}
                    >
                        <Typography className={classes.font} style={{ fontSize: phone ? 14 : 18, color: 'white', fontWeight: 500, marginLeft: 12, marginRight: 12, lineHeight: phone ? '11px' : '25px' }}> {t('start')}</Typography>
                        <img style={{ height: phone ? 16 : 20 }} src={enterArrow}></img>
                    </Button>
                </div>

            </div>
        </div >
    );

    const AddressVerificationBody = (
        <div style={{
            backgroundColor: 'white', borderRadius: 20, width: phone ? " 89vw" : "606px", overflow: 'hidden', outline: 0, display: 'flex', alignItems: 'center', flexDirection: 'column', height: phone ? 500 : 600
        }}>

            <div style={{ width: "100%", }}>
                <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', marginTop: phone ? 24 : 40, marginLeft: phone ? 16 : 32, marginRight: phone ? 16 : 32 }}>

                    {stepIndicator2View(1)}
                    <Button
                        style={{
                            padding: 0,
                            minHeight: 0,
                            minWidth: 0,
                        }}
                        onClick={handleClose}
                    >
                        <img alt='' src={x} style={phone ? { height: 24, width: 24 } : { height: 32, width: 32 }} />
                    </Button>
                </div>
            </div>
            <div style={{ marginTop: phone ? 15 : 15, marginBottom: 15, boxSizing: 'border-box', height: 1, border: '1px solid #DDDDDD', width: '91%', marginLeft: 32, marginRight: 32 }} />

            {/* <div style={{ height: '60', marginTop: 24, display: 'flex', flexDirection: 'row', justifyContent: 'end', marginLeft: phone ? 0 : 24, marginRight: phone ? 0 : 24 }}>
                <Button
                    onClick={() => {
                        setStep(1)
                        handleClose()

                    }}
                >
                    <img src={closeIcon} style={phone ? { height: 24, width: 24 } : { height: 40, width: 40 }} />
                </Button>
            </div> */}

            <div style={phone ? { marginLeft: "4vw", height: '100%', marginRight: '4vw', marginTop: 0, justifyContent: 'space-between', display: 'flex', flexDirection: 'column' } : { width: '91%', marginTop: 8, height: '100%', justifyContent: 'space-between', display: 'flex', flexDirection: 'column' }}>

                <div style={{}}>
                    <Typography style={{ fontSize: phone ? 16 : 24, fontWeight: "500", color: '#1C2237', fontFamily: 'Avenir', textAlign: 'left' }}>
                        {t('addressVerificationTitle')}
                    </Typography>
                    <Typography
                        className={classes.font}
                        style={{ marginTop: 3, color: documentDetails.status === 'failed' || documentDetails.status === 'expired' ? "#DC3055" : "#9097A1", fontSize: phone ? 12 : 14, marginBottom: 24 }}
                    >
                        {
                            documentDetails.status === 'failed' || documentDetails.status === 'expired' ?
                                t('addressFailedContent') :
                                t('addressVerificationContent')
                        }
                    </Typography>

                    <div style={{ width: '100%', marginTop: phone ? 0 : 24 }}>
                        <Typography
                            className={phone ? classes.inputLabelPhone : classes.inputLabel}
                        >
                            {t('docType')}
                        </Typography>
                        <Select
                            value={addressDocument}
                            onChange={(e) => handleDocumentChange(e)}
                            displayEmpty
                            disableUnderline
                            className={classes.select}
                            inputProps={{ 'aria-label': 'Without label', icon: classes.icon }}
                            IconComponent={(props) =>
                                <div style={{ marginLeft: -40, display: 'flex', alignItems: 'center', justifyContent: 'space-between', height: 30 }}>
                                    <img style={{ width: 16, height: 16, marginRight: 12 }} src={dropdownIcon} />
                                </div>} >
                            <MenuItem value={'BS'}>
                                <div style={{ display: 'flex', alignItems: 'flex-start' }}>
                                    <Typography style={{ fontSize: phone ? 14 : 16, color: '#1C2237', fontWeight: '500', fontFamily: 'Avenir', }}>{t('bankStatement')}</Typography>
                                </div>
                            </MenuItem>
                            <MenuItem value={'UB'}>
                                <div style={{ display: 'flex', alignItems: 'flex-start' }}>
                                    <Typography style={{ fontSize: phone ? 14 : 16, color: '#1C2237', fontWeight: '500', fontFamily: 'Avenir', }}>{t('utilityBill')}</Typography>
                                </div>
                            </MenuItem>
                            <MenuItem value={'CSS'}>
                                <div style={{ display: 'flex', alignItems: 'flex-start' }}>
                                    <Typography style={{ fontSize: phone ? 14 : 16, color: '#1C2237', fontWeight: '500', fontFamily: 'Avenir', }}>{t('creditCardStatement')}</Typography>
                                </div>
                            </MenuItem>
                        </Select>
                    </div>

                    <div style={{ marginTop: phone ? 20 : 40, display: 'flex' }}>

                        <img alt="" src={secureIcon} style={{ height: phone ? 11 : 21, width: phone ? 16 : 19 }} />

                        <div>
                            <Typography
                                className={classes.font}
                                style={{ color: '#BAC3CE', fontSize: phone ? 12 : 14, marginLeft: 8 }}
                            >
                                {t('secureNote')}
                            </Typography>
                        </div>
                    </div>
                </div>
                <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'flex-end', width: '100%', height: 100, marginBottom: 16 }}>
                    <Button
                        className={phone ? classes.buttonPhone : classes.button}
                        style={{ marginTop: phone ? 40 : 60, marginBottom: phone ? 40 : 60 }}

                        onClick={handleStart}
                    >
                        <Typography className={classes.font} style={{ fontSize: phone ? 14 : 18, color: 'white', fontWeight: 500, marginLeft: 12, marginRight: 12, lineHeight: phone ? '11px' : '25px' }}>                        {t('start')} </Typography>
                        <img style={{ height: phone ? 16 : 20 }} src={enterArrow}></img>
                    </Button>
                </div>

            </div>
        </div >
    )

    return (
        <Modal
            open={modalVisible}
            // open={true}
            onClose={() => {
                setStep(1)
                handleClose()
            }}
            className={classes.modal}
            aria-labelledby="simple-modal-title"
            aria-describedby="simple-modal-description"
            BackdropProps={{
                className:
                    'modalBackground'
            }}
        >
            <motion.div
                initial={{ opacity: 0, scale: 0 }}
                animate={{ opacity: 1, scale: 1 }}
            >
                {

                    verifyingAddress ?
                        AddressVerificationBody :

                        step === 1 ?
                            KycModalBody1
                            :
                            KycModalBody2

                }
            </motion.div>
        </Modal>
    )
}


export default withTranslation()(KycModal);
