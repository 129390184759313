import React, { useEffect, useState, useRef } from 'react';
import { Link, NavLink } from 'react-router-dom';

import { withTranslation } from 'react-i18next';
import {
    Button,
    Typography,
    TextField,
    makeStyles,
    MenuItem,
    Avatar,
    Hidden
} from '@material-ui/core';
import CustomTextFieldHalfSize from "../../../utils/CustomTextFieldHalfSize";
import CustomTextField from "../../../utils/CustomTextField";

import cad from "../../../images/coins/cad.png";
import usdt from "../../../images/coins/usdt.png";
import { useDispatch, useSelector } from "react-redux";
import { tradeActions } from "../../../redux/actions/tradeActions";
import { roundingDown } from "../../../utils/RoundingDown";
import { fundsActions } from "../../../redux/actions/fundsActions";
import { getIcons } from "../../../utils/Common";
import buyIcon from "../../../images/buyIcon.png"
import sellIcon from "../../../images/sellIcon.png"
import buyIconW from "../../../images/buyIconW.png"
import sellIconW from "../../../images/sellIconW.png"
import transferToIcon from "../../../images/transferToIcon.png"
import transferToIconPhone from "../../../images/transferToIconPhone.png"
import { useHistory } from 'react-router-dom';

import caFlag from "../../../images/caFlag.png"
import arrowWhite from "../../../images/arrowWhite.png"

import questionIcon from "../../../images/questionIcon.png"
import i18n from '../../../i18n';

function QuickTradeModal({ t, phone, homePage, next, payValue, setPayValue, receiveValue, setReceiveValue, coin1Selected, setCoin1Selected, coin2Selected, setCoin2Selected, selected, setSelected }) {
    const { tradePriceQuoteLoading, aquaBalance, livePrice, getLivePriceLoading, getAquaBalanceLoading } = useSelector(state => state.trade)
    const { userCapitals, tokensInfo } = useSelector(state => state.funds)
    const { kycDetails } = useSelector(state => state.kyc)
    const { token } = useSelector(state => state.auth);
    const [initial, setInitial] = useState(true);
    const history = useHistory();

    const [inputFocused1, setInputFocused1] = useState(false)
    const [inputFocused2, setInputFocused2] = useState(false)
    const amount1All = useRef(false);
    const [submitDisable, setSubmitDisable] = useState(true);
    const coinFree1 = userCapitals.find(item => item.symbol === coin1Selected);
    const [amount1Warning, setAmount1Warning] = useState(' ');
    const [selectList1, setSelectList1] = useState([]);
    const [selectList2, setSelectList2] = useState([]);
    const [localTokensInfo, setLocalTokensInfo] = useState([]);
    const useStyles = makeStyles({
        paper: {
            borderRadius: phone ? 11 : 20,
            backgroundColor: '#F9F9F9',
            padding: '30px 24px',
            maxWidth: 544
        },
        div: {
            height: phone ? 35 : 65,
            borderRadius: phone ? 8 : 12,
            backgroundColor: '#F1F0F0',
            display: 'flex'
        },

        fontStyle: {
            fontFamily: "Avenir", fontWeight: '500'
        },
        font: {
            color: submitDisable ? "#8B96A7" : 'white',
            fontFamily: 'Avenir',
            fontSize: phone ? 12 : 24,
            fontWeight: 500,
            letterSpacing: 0,
            textAlign: phone ? 'center' : 'left',
            textTransform: 'none'
        },
        button: {
            width: "100%",
            height: phone ? 49 : 70,
            borderRadius: phone ? 16 : 20,
            backgroundColor: '#162E83',
            textTransform: 'none',
            marginTop: phone ? 40 : 60
        },
        label: {
            color: '#8B96A7',
            fontFamily: 'Avenir',
            fontSize: phone ? 12 : 16,
            fontWeight: "500",
            letterSpacing: 0,
            textAlign: 'left',
            textTransform: 'none'
        },
        selected: {
            // borderBottom: '4px solid #162E83',
            // borderRadius: "20px !important",
            margin: 0,
            padding: 0,
            minWidth: 0,
            minHeight: 0,

            height: phone ? 24 : 52,



        },
        unselected: {
            // borderBottom: '1px solid #D8D8D8',
            // borderRadius: "0px !important",
            // borderRadius: "20px !important",
            minWidth: 0,
            minHeight: 0,
            margin: 0,
            padding: 0,



            height: phone ? 24 : 52,

        },
        selectedPhone: {
            // color: 'white',
            // backgroundColor: '#162E83 !important'
            // borderBottom: '4px solid #162E83',
            // borderRadius: "0px !important",
            borderRadius: "14px !important",
            backgroundColor: '#003FFF !important',
            height: 40
        },
        unselectedPhone: {
            // borderBottom: '1px solid #D8D8D8',
            // borderRadius: "0px !important",
            // backgroundColor:'yellow'

            borderRadius: "14px !important",

            backgroundColor: '#F4F4F4 ',
            height: 40

        },
        selector: {
            height: phone ? 30 : 36,
            minWidth: phone ? 24 : 110,
            // borderRadius: phone ? 5 : 16,
            // backgroundColor: '#F1F0F0',
            paddingRight: 8,
            color: 'black',
            borderRight: '1px solid #D8D8D8',






        },
        selectorIcon: {
            paddingRight: phone ? '18px !important' : null
        },
        warningStyle: {
            fontFamily: "Avenir",
            color: "#DC3055 !important",
            fontSize: phone ? 12 : 14,
            fontWeight: 500,
            lineHeight: phone ? "11px" : '16px',
            textAlign: "left",
            marginTop: 8
        },
        selectPaper: {
            backgroundColor: '#F1F0F0',
            borderRadius: 12
        },
        selectIcon: {
            color: 'black'
        }
    })
    const classes = useStyles();
    const dispatch = useDispatch();
    const returnLivePriceQuantity = () => {
        if (coin1Selected === 'ETH' || coin1Selected === 'BTC') {
            return 0.00001
        }
        else {
            return 0.01
        }
    }
    const initCoins = () => {
        dispatch(tradeActions.getLivePrice(
            {
                "token1": coin1Selected,
                "token2": coin2Selected
            },
            {
                "deliverQuantity": returnLivePriceQuantity(),
                "tradeSide": selected
            }, homePage, token))
        if (!homePage) {
            dispatch(tradeActions.getAquaBalance({
                coin: coin1Selected
            }, token))
        }
    }
    const payValueLimit = () => {
        if (coin1Selected === 'ETH') {
            return 0.01
        } else if (coin1Selected === 'BTC') {
            return 0.001
        } else {
            return 100
        }
    }

    useEffect(() => {
        setInitial(false)
        dispatch(fundsActions.getTokenInfo(token))
        initCoins()
        return () => {
        }
    }, []);

    useEffect(() => {
        initCoins()
        return () => {
        }
    }, [coin1Selected, coin2Selected]);

    useEffect(() => {
        if (!initial) {
            initCoins()
        }
        let allCoinList = []
        localTokensInfo.forEach(item => {
            if (selected === 'buy') {
                if (item.buyIsOn === true) {
                    if (!allCoinList.includes(item.token)) {
                        allCoinList.push(item.token)
                    }
                    setSelectList1(['CAD'])
                    setSelectList2(allCoinList)
                }
            } else if (selected === 'sell') {
                if (item.sellIsOn === true) {
                    if (!allCoinList.includes(item.token)) {
                        allCoinList.push(item.token)
                    }
                    setSelectList1(allCoinList)
                    setSelectList2(['CAD'])
                }
            }
        })
        return () => {
        }
    }, [selected, localTokensInfo]);


    useEffect(() => {

        if (payValue != 0) {
            setSubmitDisable(false)
        } else {
            setSubmitDisable(true)
        }
        if (!homePage) {
            if (payValue < payValueLimit() && payValue != "") {
                setAmount1Warning(t('trade-minLimit') + payValueLimit())
            } else if ((!coinFree1 && payValue != "" || coinFree1 && payValue > coinFree1.amount)) {
                setAmount1Warning(t('insufficient_fund'))
            } else if (payValue != "" && (coin1Selected != aquaBalance.symbol || payValue > aquaBalance.availableBalance)) {
                setAmount1Warning(t('aquaBalanceWarning') + roundingDown(aquaBalance.availableBalance, 8) + " " + aquaBalance.symbol)
            } else {
                setAmount1Warning("")
            }
        }
        if (amount1All.current) {
            amount1All.current = false;

            if (selected === 'buy') {
                setReceiveValue(payValue / livePrice)
            } else if (selected === 'sell') {
                setReceiveValue(payValue * livePrice)
            }

        }
        return () => {
        }
    }, [payValue, receiveValue, t, userCapitals, tradePriceQuoteLoading])

    useEffect(() => {
        setLocalTokensInfo(tokensInfo.filter(item => item.token !== 'USDT'))
    }, [tokensInfo])

    const renderSelections = (item) => {
        const result = []
        item.forEach(element => {
            result.push(
                <MenuItem key={element} value={element} >

                    <div style={{ display: 'flex', alignItems: 'center' }}>
                        <Avatar variant="square" alt="Coin Icon" style={{ marginRight: 5, width: 40, height: 40, alignSelf: 'center', marginBottom: 2, marginLeft: 12 }} src={getIcons(element)} />
                        <Typography className={classes.fontStyle} style={{ fontSize: phone ? 12 : 24, color: '#1C2237', fontWeight: '500', marginLeft: 8, marginRight: 12 }}>{element}</Typography>
                    </div>


                </MenuItem >)
        })
        return result
    }

    return (
        <div style={{
            // boxShadow: '0px 2px 16px #E4E4E4',

            // margin: 20,

            width: '100%'
        }}>


            <div style={{ width: '100%', display: 'flex', justifyContent: 'space-between', alignItems: 'center', marginBottom: 24, marginTop: 24 }}>

                <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>
                    <Button
                        className={selected === 'buy' ? classes.selected : classes.unselected}
                        style={{ textTransform: 'none', borderRadius: 16 }}

                        onClick={() => { setSelected('buy'); setCoin1Selected("CAD"); setCoin2Selected('BTC'); setPayValue(''); setReceiveValue(''); }}
                    >
                        <Typography className={classes.fontStyle} style={{ fontSize: phone ? 16 : 24, fontWeight: selected == "buy" ? "bold" : '500', color: selected == 'buy' ? "#3254FA" : '#9A9A9A', fontFamily: 'Avenir' }}>  {t('buyC')}</Typography>


                    </Button>

                    <div style={{ width: 40 }}></div>
                    <Button
                        className={selected === 'sell' ? classes.selected : classes.unselected}
                        style={{ textTransform: 'none' }}
                        onClick={() => { setSelected('sell');; setCoin1Selected("BTC"); setCoin2Selected('CAD'); setPayValue(''); setReceiveValue(''); }}
                    >

                        <Typography className={classes.fontStyle} style={{ fontSize: phone ? 16 : 24, fontWeight: selected == "sell" ? "bold" : '500', color: selected == 'sell' ? "#3254FA" : '#9A9A9A' }}>  {t('sellC')}</Typography>
                    </Button>
                </div>

                <Button style={{
                    display: 'flex', alignItems: 'center', flexDirection: 'column',
                    padding: 0,
                    minHeight: 0,
                    minWidth: 0,
                }}
                    onClick={() => { window.open(i18n.language === 'en' ? "https://otcdocs.yesbit.io/user-guide/quick-trade" : "https://otcdocs.yesbit.io/v/jian-ti-zhong-wen/user-guide/quick-trade") }}

                >
                    <img src={questionIcon} style={{ height: phone ? 14 : 18, marginRight: 4 }} />
                    <Typography style={{ fontSize: phone ? 12 : 14, color: '#8B96A7', fontWeight: '500', fontFamily: 'Avenir' }}>{t('tradeHelpButton')}</Typography>

                </Button>
            </div>

            <div style={{ width: '100%', height: 1, backgroundColor: '#D9D9D9', marginBottom: phone ? 12 : 24 }}></div>



            {

                phone ?
                    <div style={{ display: 'flex', alignItems: 'center', flexDirection: 'column' }}>
                        <CustomTextField
                            placeholder={t('0.00')}
                            id="amount1"
                            name="amount1"
                            phone={phone ? 1 : 0}
                            style={{ width: '100%', textTransform: 'none' }}
                            onChange={(e) => {
                                setPayValue(e.target.value);
                                if (inputFocused1) {
                                    if (selected === 'buy') {
                                        setReceiveValue(e.target.value / livePrice)
                                    } else if (selected === 'sell') {
                                        setReceiveValue(e.target.value * livePrice)
                                    }

                                }
                            }}
                            value={payValue}
                            customtype="number"

                            disabled={getLivePriceLoading || getAquaBalanceLoading}
                            onFocus={() => {
                                setInputFocused1(true)
                            }}
                            onBlur={() => {
                                setInputFocused1(false)
                            }}

                            rightbuttonlabel={homePage ? null : t('all')}
                            rightbuttondisable={getLivePriceLoading || getAquaBalanceLoading}
                            rightbuttonclick={() => {
                                amount1All.current = true
                                if (coinFree1) {
                                    if (roundingDown(coinFree1.amount, 8) != payValue) {
                                        setPayValue(roundingDown(coinFree1.amount, 8))
                                    } else {
                                        amount1All.current = false
                                    }
                                } else {
                                    setPayValue('0')
                                }
                            }}
                            coinselect={
                                <TextField
                                    id="outlined-select-currency"
                                    className={classes.selector}
                                    select
                                    InputProps={{ style: { color: 'black', height: '100%', alignItems: 'center' }, disableUnderline: true }}
                                    value={coin1Selected}
                                    onChange={event => { setCoin1Selected(event.target.value); setPayValue(''); setReceiveValue('') }}
                                    SelectProps={{
                                        classes: { select: classes.selectorIcon, icon: classes.selectIcon },
                                        MenuProps: { classes: { paper: classes.selectPaper } }
                                    }}
                                >
                                    {renderSelections(selectList1)}
                                </TextField>
                            }
                        >
                        </CustomTextField>

                        <img src={transferToIconPhone} style={{ height: phone ? 20 : 48, marginLeft: 24, marginRight: 24 }} />

                        <CustomTextField
                            id="amount2"
                            name="amount2"
                            placeholder={t('0.00')}
                            phone={phone ? 1 : 0}
                            style={{ width: '100%', textTransform: 'none' }}
                            value={receiveValue}

                            customtype="number"
                            onChange={(e) => {
                                setReceiveValue(e.target.value);
                                if (inputFocused2) {
                                    if (selected === 'buy') {
                                        setPayValue(e.target.value * livePrice)
                                    } else if (selected === 'sell') {
                                        setPayValue(e.target.value / livePrice)
                                    }

                                }
                            }}
                            onFocus={() => { setInputFocused2(true) }}
                            onBlur={() => { setInputFocused2(false) }}
                            disabled={getLivePriceLoading || getAquaBalanceLoading}
                            coinselect={
                                <TextField
                                    id="outlined-select-currency"
                                    className={classes.selector}
                                    select
                                    InputProps={{ style: { color: 'white', height: '100%', alignItems: 'center' }, disableUnderline: true }}
                                    value={coin2Selected}
                                    onChange={event => { setCoin2Selected(event.target.value); setPayValue(''); setReceiveValue('') }}
                                    SelectProps={{
                                        classes: { select: classes.selectorIcon, icon: classes.selectIcon },
                                        MenuProps: { classes: { paper: classes.selectPaper } }
                                    }}
                                >
                                    {renderSelections(selectList2)}
                                </TextField>
                            }
                        >
                        </CustomTextField>
                    </div>
                    :

                    <div style={{ display: 'flex', alignItems: 'center' }}>
                        <CustomTextFieldHalfSize
                            placeholder={t('0.00')}
                            id="amount1"
                            name="amount1"
                            phone={phone ? 1 : 0}
                            style={{ width: '100%', textTransform: 'none' }}
                            onChange={(e) => {
                                setPayValue(e.target.value);
                                if (inputFocused1) {
                                    if (selected === 'buy') {
                                        setReceiveValue(e.target.value / livePrice)
                                    } else if (selected === 'sell') {
                                        setReceiveValue(e.target.value * livePrice)
                                    }

                                }
                            }}
                            value={payValue}
                            customtype="number"

                            disabled={getLivePriceLoading || getAquaBalanceLoading}
                            onFocus={() => {
                                setInputFocused1(true)
                            }}
                            onBlur={() => {
                                setInputFocused1(false)
                            }}

                            rightbuttonlabel={homePage ? null : t('all')}
                            rightbuttondisable={getLivePriceLoading || getAquaBalanceLoading}
                            rightbuttonclick={() => {
                                amount1All.current = true
                                if (coinFree1) {
                                    if (roundingDown(coinFree1.amount, 8) != payValue) {
                                        setPayValue(roundingDown(coinFree1.amount, 8))
                                    } else {
                                        amount1All.current = false
                                    }
                                } else {
                                    setPayValue('0')
                                }
                            }}
                            coinselect={
                                <TextField
                                    id="outlined-select-currency"
                                    className={classes.selector}
                                    select
                                    InputProps={{ style: { color: 'black', height: '100%', alignItems: 'center' }, disableUnderline: true }}
                                    value={coin1Selected}
                                    onChange={event => { setCoin1Selected(event.target.value); setPayValue(''); setReceiveValue('') }}
                                    SelectProps={{
                                        classes: { select: classes.selectorIcon, icon: classes.selectIcon },
                                        MenuProps: { classes: { paper: classes.selectPaper } }
                                    }}
                                >
                                    {renderSelections(selectList1)}
                                </TextField>
                            }
                        >
                        </CustomTextFieldHalfSize>

                        <img src={transferToIcon} style={{ height: phone ? 14 : 40, marginLeft: 24, marginRight: 24 }} />

                        <CustomTextFieldHalfSize
                            id="amount2"
                            name="amount2"
                            placeholder={t('0.00')}
                            phone={phone ? 1 : 0}
                            style={{ width: '100%', textTransform: 'none' }}
                            value={receiveValue}

                            customtype="number"
                            onChange={(e) => {
                                setReceiveValue(e.target.value);
                                if (inputFocused2) {
                                    if (selected === 'buy') {
                                        setPayValue(e.target.value * livePrice)
                                    } else if (selected === 'sell') {
                                        setPayValue(e.target.value / livePrice)
                                    }

                                }
                            }}
                            onFocus={() => { setInputFocused2(true) }}
                            onBlur={() => { setInputFocused2(false) }}
                            disabled={getLivePriceLoading || getAquaBalanceLoading}
                            coinselect={
                                <TextField
                                    id="outlined-select-currency"
                                    className={classes.selector}
                                    select
                                    InputProps={{ style: { color: 'white', height: '100%', alignItems: 'center' }, disableUnderline: true }}
                                    value={coin2Selected}
                                    onChange={event => { setCoin2Selected(event.target.value); setPayValue(''); setReceiveValue('') }}
                                    SelectProps={{
                                        classes: { select: classes.selectorIcon, icon: classes.selectIcon },
                                        MenuProps: { classes: { paper: classes.selectPaper } }
                                    }}
                                >
                                    {renderSelections(selectList2)}
                                </TextField>
                            }
                        >
                        </CustomTextFieldHalfSize>
                    </div>}

            <div style={{ display: 'flex', justifyContent: 'space-between', marginTop: 24, marginBottom: 24 }}>
                <div onClick={() => { 
                    // window.open("https://www10.fintrac-canafe.gc.ca/msb-esm/public/detailed-information/msb-details/7b226d73624f72674e756d626572223a3136373031342c227072696d617279536561726368223a7b226f72674e616d65223a22687062222c2273656172636854797065223a337d7d/") 
                    }} style={{ display: 'flex', alignItems: 'center' }}>
                    <img src={caFlag} style={{ height: phone ? 16 : 32, overflow: 'hidden' }} />

                    <Typography style={{ color: '#8B96A7', fontSize: phone ? 12 : 16, fontWeight: '500', marginLeft: 4 }} className={classes.fontStyle} > {t("MSB")} FINTRAC #M22187471</Typography>
                </div>

                <Button style={{ backgroundColor: submitDisable ? '#E1E1E1' : "#3254FA", textTransform: 'none', height: phone ? 48 : 64, borderRadius: 20 }} disabled={submitDisable} onClick={next}>
                    <Typography style={{ fontSize: phone ? 14 : 18, color: 'white', fontWeight: '500', marginLeft: phone ? 8 : 16 ,marginRight:phone ? 8:0}} className={classes.fontStyle} >
                        {t('quote')}
                    </Typography>
                    {
                        phone ?
                            null
                            :
                            <img src={arrowWhite} style={{ height: phone ? 14 : 24, overflow: 'hidden', marginLeft: 12, marginRight: 12 }} />

                    }

                </Button>
            </div>
            {/* <Typography className={classes.font} style={{ marginTop: phone ? 15 : 30, fontSize: phone ? 12 : 14, textAlign: 'center', color: '#8B96a7' }}>
                {t('livePrice')} 1 {selected === 'buy' ? coin2Selected : coin1Selected} = {livePrice === 0 ? 0 : roundingDown(livePrice, 4)} {selected === 'buy' ? coin1Selected : coin2Selected}
            </Typography> */}
        </div >
    );
}

export default withTranslation()(QuickTradeModal);
