import React, {useEffect, useState} from 'react';
import {withTranslation} from 'react-i18next';
import {Button, Typography} from '@material-ui/core';
import './LiveChat.css'
import Zendesk from "react-zendesk";
const ZENDESK_KEY = "50a0d29e-2380-423d-8c96-9be94cc67f75";

function LiveChat({t,width, height}) {
    const [phone, setPhone] = useState(width<=745);

    const setting = {
        color: {
            theme: "#000"
        },
        launcher: {
            chatLabel: {
                "en-US": "Need Help"
            }
        },
        contactForm: {
            fields: [
                { id: "description", prefill: { "*": "My pre-filled description" } }
            ]
        }
    };

    useEffect(() => {
        setPhone(width<=745)
    }, [width]);

    const startChat = () => {
        console.log('starting chat !')
    }

    return (
        <div className="chatContainer" >
            {/*<Button style={{backgroundColor: '#000C75', borderRadius: 15, height: 50 }} onClick={startChat} >*/}
            {/*    <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'center', marginLeft: 16, marginRight: 16 }}>*/}
            {/*        <Typography style={{ fontSize: phone ? 10: 12, fontWeight: 600 ,color:'white' }}>{t('startChat')}</Typography>*/}
            {/*    </div>*/}
            {/*</Button>*/}
            {/*<Zendesk defer zendeskKey={ZENDESK_KEY} {...setting} onLoaded={() => console.log('is loaded')} />*/}
        </div>
    );
}

export default withTranslation()(LiveChat);
