import * as Url from '../../config/Url'
import { authHeader } from '../../utils/AuthHeader';
import { handleResponse } from '../../utils/HandleResponse'

export const fundsService = {
    getUserCapital,
    getFundsTransaction,
    fiatDeposit,
    priceQuote,
    priceQuoteNoAuth,
    fiatWithdraw,
    getFundTransactionByCoinType,
    cryptoWithdraw,
    getFeeWithActionAndAmount,
    getAddressByChain,
    getTokenInfo,
    checkAddrValidity,
    checkVerificationDetails
};

async function getUserCapital(token) {
    const requestOptions = {
        method: 'GET',
        headers: authHeader(token),
    };

    return fetch(Url.GET_USER_CAPITAL, requestOptions).then(handleResponse);
}

async function getFundsTransaction(token) {
    const requestOptions = {
        method: 'GET',
        headers: authHeader(token),
    };

    return fetch(Url.GET_FUNDS_TRANSACTIONS, requestOptions).then(handleResponse);
}

async function fiatDeposit(token, payload) {
    const requestOptions = {
        method: 'POST',
        headers: authHeader(token),
        body: JSON.stringify(payload)
    };

    return fetch(Url.FIAT_DEPOSIT, requestOptions).then(handleResponse);
}

async function priceQuote(payload, token) {
    const requestOptions = {
        method: 'POST',
        headers: authHeader(token),
        body: JSON.stringify(payload)
    };

    return fetch(Url.PRICE_QUOTE, requestOptions).then(handleResponse);
}
async function priceQuoteNoAuth(payload, token) {
    const requestOptions = {
        method: 'POST',
        headers: authHeader(token),
        body: JSON.stringify(payload)
    };

    return fetch(Url.PRICE_QUOTE_NO_AUTH, requestOptions).then(handleResponse);
}

async function fiatWithdraw(token, payload) {
    const requestOptions = {
        method: 'POST',
        headers: authHeader(token),
        body: JSON.stringify(payload)
    };

    return fetch(Url.FIAT_WITHDRAW, requestOptions).then(handleResponse);
}

async function getFundTransactionByCoinType(token, payload) {
    const requestOptions = {
        method: 'POST',
        headers: authHeader(token),
        body: JSON.stringify(payload)
    };

    return fetch(Url.GET_TRANSACTION_BY_TYPE, requestOptions).then(handleResponse);
}

async function cryptoWithdraw(token, payload) {
    const requestOptions = {
        method: 'POST',
        headers: authHeader(token),
        body: JSON.stringify(payload)
    };

    return fetch(Url.WITHDRAW_CRYPTO, requestOptions).then(handleResponse);
}

async function getFeeWithActionAndAmount(token, payload) {
    const requestOptions = {
        method: 'GET',
        headers: authHeader(token)
    };

    return fetch(`${Url.GET_FEE}${payload.withdrawKey}/${payload.amount}`, requestOptions).then(handleResponse);
}

async function getAddressByChain(token, payload) {
    const requestOptions = {
        method: 'GET',
        headers: authHeader(token)
    };
    console.log('addr: ', Url.GET_ADDRESS_BY_CHAIN + payload.chain)

    return fetch(Url.GET_ADDRESS_BY_CHAIN + payload.chain, requestOptions).then(handleResponse);
}

async function getTokenInfo(token, payload) {
    const requestOptions = {
        method: 'GET',
        headers: authHeader(token)
    };

    return fetch(Url.GET_TOKEN_INFO, requestOptions).then(handleResponse);
}

/** archived as amlbot service plan is on hold -- 240924 Richard */
async function checkAddrValidity(token, payload) {
    const requestOptions = {
        method: 'GET',
        headers: authHeader(token)
    };

    return fetch(`${Url.CHECK_ADDR_VALIDITY}address=${payload.address}&chain=${payload.chain}`, requestOptions).then(handleResponse);
}

async function checkVerificationDetails(token, payload) {
    const requestOptions = {
        method: 'GET',
        headers: authHeader(token)
    };

    return fetch(`${Url.CHECK_VERIFICATION_DETAIL}${payload.address}`, requestOptions).then(handleResponse);
}
