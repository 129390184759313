import { referralService } from "../services/referralServices";
import {
    GET_REFERRALS,
    GET_REFERRALS_FAILED,
    GET_REFERRALS_SUCCEED,
    GET_USERNAME_BY_REFERRAL_CODE,
    GET_USERNAME_BY_REFERRAL_CODE_FAILED,
    GET_USERNAME_BY_REFERRAL_CODE_SUCCEED,
    CLEAR_REFERRER_USERNAME,
    SEND_EMAIL_SUBSCRIBE,
    SEND_EMAIL_SUBSCRIBE_SUCCEED,
    SEND_EMAIL_SUBSCRIBE_FAILED
} from '../constants';
import { toast } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'

toast.configure()
export const referralActions = {
    getReferrals,
    getUsernameByReferralCode,
    clearReferrerUsername,
    sendSubscribe
}

function getReferrals(payload, token) {
    return dispatch => {
        dispatch(request());
        referralService.getReferrals(payload, token)
            .then(
                res => {
                    dispatch(success(res.data));
                },
                error => {
                    dispatch(failure(error.message));
                }
            )

    };

    function request() { return { type: GET_REFERRALS } }
    function success(data) { return { type: GET_REFERRALS_SUCCEED, data } }
    function failure(message) { return { type: GET_REFERRALS_FAILED, message } }
}

function getUsernameByReferralCode(payload) {
    return dispatch => {
        dispatch(request());
        referralService.getUsernameByReferralCode(payload)
            .then(
                res => {
                    dispatch(success(res.data));
                },
                error => {
                    dispatch(failure(error.message));
                }
            )

    };

    function request() { return { type: GET_USERNAME_BY_REFERRAL_CODE } }
    function success(data) { return { type: GET_USERNAME_BY_REFERRAL_CODE_SUCCEED, data } }
    function failure(message) { return { type: GET_USERNAME_BY_REFERRAL_CODE_FAILED, message } }
}

function clearReferrerUsername() {
    return dispatch => {
        dispatch(request());
    };

    function request() { return { type: CLEAR_REFERRER_USERNAME } }
}

function sendSubscribe(t, payload) {
    return dispatch => {
        dispatch(request());
        referralService.sendSubscribe(payload)
            .then(
                res => {
                    toast.success(t("subscribeSuccess"))

                    dispatch(success(res.data));
                },
                error => {
                    toast.success(t("subscribeSuccess"))
                }
            )
    }
    function request() { return { type: SEND_EMAIL_SUBSCRIBE } }
    function success(data) { return { type: SEND_EMAIL_SUBSCRIBE_SUCCEED, data } }
    function failure(message) { return { type: SEND_EMAIL_SUBSCRIBE_FAILED, message } }
}