import { withTranslation } from "react-i18next";
import React, { useEffect, useState } from "react";
import {Button, InputBase, makeStyles, Typography, Select, MenuItem, Avatar} from '@material-ui/core';
import { Link } from 'react-router-dom'
import './Verification.css';
import pageLogo from "../../images/pageLogo.png";
import leftPage from "../../images/leftPage.png";
import { isEnglishKeyboard } from "../../utils/Checker"
import { useDispatch, useSelector } from "react-redux";
import { authActions } from "../../redux/actions/authActions";
import ReactDatePicker from "react-datepicker";
import { CountryDropdown, RegionDropdown } from 'react-country-region-selector';
import success from "../../images/success.png";
import arrow from "../../images/arrow.png";
import KycModal from "../Kyc/KycModal";
import englishIcon from "../../images/englishIcon.png";
import chineseIcon from "../../images/chineseIcon.png";

function Verification({ t, width, height }) {
    const useStyles = makeStyles({
        inputLabel: {
            marginBottom: 6, textAlign: 'initial', fontFamily: 'Avenir', fontSize: 16, fontWeight: 500, letterSpacing: 0, lineHeight: '22px'
        },
        inputLabelPhone: {
            marginBottom: 5, textAlign: 'initial', fontFamily: 'Avenir', fontSize: 10, fontWeight: 500, letterSpacing: 0, lineHeight: '14px'
        },
        input: {
            borderRadius: 16, backgroundColor: 'white', width: '100%', height: '55px', fontSize: 16, padding: 20, border: "1px solid #EDEDED"
        },
        inputPhone: {
            borderRadius: 12, backgroundColor: 'white', width: '100%', height: '38.5px', fontSize: 12, padding: 14, border: "1px solid #EDEDED"
        },
        datePicker: {
            borderRadius: 16, backgroundColor: 'white', width: '-webkit-fill-available', height: '55px', fontSize: 16, padding: '0px 20px', border: "1px solid #EDEDED"
        },
        datePickerPhone: {
            borderRadius: 12, backgroundColor: 'white', width: '-webkit-fill-available', height: '38.5px', fontSize: 12, padding: '0px 14px', border: "1px solid #EDEDED"
        },
        button: {
            width: '100%',
            height: '65px',
            borderRadius: 20,
            color: 'white !important',
            backgroundColor: '#081647',
            fontFamily: 'Avenir',
            fontSize: 18,
            fontWeight: 500,
            letterSpacing: 0,
            lineHeight: '25px',
            textTransform: 'none',
            "&:hover": {
                backgroundColor: '#081647',
                // Reset on touch devices, it doesn't add specificity
                "@media (hover: none)": {
                    backgroundColor: '#081647',
                    "&:active": {
                        backgroundColor: '#081647'
                    }
                },
                '&$disabled': {
                    backgroundColor: '#081647',
                },
            },
        },
        buttonPhone: {
            width: '100%',
            height: '48.3px',
            borderRadius: 12,
            color: 'white !important',
            backgroundColor: '#081647',
            fontFamily: 'Avenir',
            fontSize: 12,
            fontWeight: 500,
            letterSpacing: 0,
            lineHeight: '16px',
            textTransform: 'none',
            "&:hover": {
                backgroundColor: '#081647',
                // Reset on touch devices, it doesn't add specificity
                "@media (hover: none)": {
                    backgroundColor: '#081647',
                    "&:active": {
                        backgroundColor: '#081647'
                    }
                },
                '&$disabled': {
                    backgroundColor: '#081647',
                },
            },
        },
        selectIcon:{
            color:'black',
            marginRight:'5%'
        },
        selectInput:{
            display:"flex"
        }
    });
    const classes = useStyles();

    const { token, userInfo, updateInfoSuccess } = useSelector(state => state.auth)

    const [personalInfo, setPersonalInfo] = useState({
        firstName: '',
        lastName: '',
        dateOfBirth: '',
        occupation: '',
        employerName: '',
    });
    const [contactInfo, setContactInfo] = useState({
        phone: '',
        address: '',
        country: '',
        province: '',
        city: '',
        postalCode: '',
        language:''
    });
    const [phone, setPhone] = useState(width <= 745);
    const [kycVisible, setKycVisible] = useState(false);
    const [step1, setStep1] = useState(true);
    const [step2, setStep2] = useState(false);
    const [step3, setStep3] = useState(false);

    const dispatch = useDispatch();

    useEffect(() => {
        setPhone(width <= 745)
    }, [width]);

    useEffect(() => {
        if (updateInfoSuccess) {
            setStep2(false);
            setStep3(true);
            dispatch(authActions.clearUpdateUserInfoState())
        }
    }, [updateInfoSuccess]);

    const handlePersonalInfoChange = (prop) => (event) => {
        if (isEnglishKeyboard(event.target.value)) {
            let input = event.target.value
            if (prop === 'firstName' || prop === 'lastName') {
                input = input.replace(/[^a-zA-Z]/g, '');
            }
            setPersonalInfo({ ...personalInfo, [prop]: input });
        }
    };

    const handleContactInfoChange = (prop) => (event) => {
        if (prop === 'phone') {
            const format = /^[0-9\d+]+$/;
            if (event.target.value != '' && !format.test(event.target.value)) {
                return
            }
        }
        if (isEnglishKeyboard(event.target.value)) {
            setContactInfo({ ...contactInfo, [prop]: event.target.value });
        }
    };

    const nextStepDisable = () => {
        return !personalInfo.firstName || !personalInfo.lastName || !personalInfo.dateOfBirth || !personalInfo.occupation || !personalInfo.employerName
    };
    const DatepickerInput = ({ ...props }) => (
        <input type="text" {...props} readOnly />
    );
    const confirmDisable = () => {
        return !contactInfo.phone || !contactInfo.address || !contactInfo.country || !contactInfo.province || !contactInfo.city || !contactInfo.postalCode || !contactInfo.language
    };

    const handleUpdate = () => {
        let payload = {
            ...personalInfo, ...contactInfo
        }
        dispatch(authActions.updateUserInfo(payload, token))
    }

    const renderPersonalInformation = () => {
        return (
            <div>
                {/* <div style={{display:'flex',alignItems:'center',justifyContent:phone ? 'left':'center'}}>
                    <div
                        style={
                            phone ? {height:28.8, width:28.8, backgroundColor:'#081647', borderRadius:14.4,textAlign: 'center'}
                                :{height:48, width:48, backgroundColor:'#081647', borderRadius:24,textAlign: 'center'}
                        }
                    >
                        <Typography
                            style={{fontFamily:'Avenir', color:'white', fontSize:phone?10.8:18, fontWeight:500, margin:phone ? '6px 0px':'12px 0px'}}
                        >
                            1
                        </Typography>
                    </div>
                    <div style={{ boxSizing: 'border-box', border: '1px solid #1C2237', height:'1px', width:phone?'22px':'55px'}}></div>
                    <div
                        style={
                            phone ? {height:28.8, width:28.8, backgroundColor:'#BAC3CE', borderRadius:14.4,textAlign: 'center'}
                                :{height:48, width:48, backgroundColor:'#BAC3CE', borderRadius:24,textAlign: 'center'}
                        }
                    >
                        <Typography
                            style={{fontFamily:'Avenir', color:'white', fontSize:phone? 10.8:18, fontWeight:500, margin:phone ? '6px 0px':'12px 0px'}}
                        >
                            2
                        </Typography>
                    </div>
                </div> */}
                <div style={{ display: 'flex', alignItems: 'flex-start', flexDirection: 'column' }}>
                    <Typography
                        style={
                            phone ? { marginTop: 24, fontFamily: 'Avenir', fontSize: 18, fontWeight: 500, letterSpacing: 0, lineHeight: '25px', textAlign: 'initial' }
                                : { marginTop: 30, fontFamily: 'Avenir', fontSize: 24, fontWeight: 500, letterSpacing: 0, lineHeight: '35px' }
                        }
                    >
                        1/2   {t('personalInfo')}
                    </Typography>
                    <Typography
                        style={
                            phone ? { marginTop: 3, fontFamily: 'Avenir', color: '#9097A1', fontSize: 10, fontWeight: 500, letterSpacing: 0, lineHeight: '14px', textAlign: 'initial' }
                                : { marginTop: 4, fontFamily: 'Avenir', color: '#9097A1', fontSize: 14, fontWeight: 500, letterSpacing: 0, lineHeight: '19px' }
                        }
                    >
                        {t('personalInfoSubtitle')}
                    </Typography>
                </div>

                <div style={{ marginTop: phone ? 29.4 : 60, justifyContent: 'space-between', display: 'flex' }}>
                    <div style={{ width: '45%' }}>
                        <Typography
                            className={phone ? classes.inputLabelPhone : classes.inputLabel}
                        >
                            {t('firstName')}
                        </Typography>

                        <InputBase
                            className={phone ? classes.inputPhone : classes.input}
                            placeholder={t('firstNamePlaceholder')}
                            value={personalInfo.firstName}
                            onChange={handlePersonalInfoChange('firstName')}
                            type="text"

                        />
                    </div>

                    <div style={{ width: '45%' }}>
                        <Typography
                            className={phone ? classes.inputLabelPhone : classes.inputLabel}
                        >
                            {t('lastName')}
                        </Typography>

                        <InputBase
                            className={phone ? classes.inputPhone : classes.input}
                            placeholder={t('namePlaceholder')}
                            value={personalInfo.lastName}
                            onChange={handlePersonalInfoChange('lastName')}
                            type="text"
                        />
                    </div>
                </div>

                <div style={{ marginTop: phone ? 23 : 30 }}>
                    <Typography className={phone ? classes.inputLabelPhone : classes.inputLabel}>
                        {t("birthday")}
                    </Typography>

                    <ReactDatePicker
                        className={phone ? classes.datePickerPhone : classes.datePicker}
                        selected={personalInfo.dateOfBirth}
                        onChange={(date) => setPersonalInfo({ ...personalInfo, ['dateOfBirth']: date })}
                        peekNextMonth
                        showMonthDropdown
                        showYearDropdown
                        dropdownMode="select"
                        placeholderText={t('birthPlaceholder')}
                        strictParsing

                        customInput={<DatepickerInput />}


                    />


                </div>

                <div style={{ marginTop: phone ? 23 : 30 }}>
                    <Typography
                        className={phone ? classes.inputLabelPhone : classes.inputLabel}
                    >
                        {t('occupation')}
                    </Typography>

                    <InputBase
                        className={phone ? classes.inputPhone : classes.input}
                        placeholder={t('occupationPlaceholder')}
                        value={personalInfo.occupation}
                        onChange={handlePersonalInfoChange('occupation')}
                    />
                </div>

                <div style={{ marginTop: phone ? 23 : 30 }}>
                    <Typography
                        className={phone ? classes.inputLabelPhone : classes.inputLabel}
                    >
                        {t('employerName')}
                    </Typography>

                    <InputBase
                        className={phone ? classes.inputPhone : classes.input}
                        placeholder={t('employerPlaceholder')}
                        value={personalInfo.employerName}
                        onChange={handlePersonalInfoChange('employerName')}
                    />
                </div>

                <Button
                    className={phone ? classes.buttonPhone : classes.button}
                    style={{ marginTop: phone ? '52px' : '60px', opacity: nextStepDisable() ? 0.3 : null }}
                    onClick={() => { setStep1(false); setStep2(true); }}
                    disabled={nextStepDisable()}
                >
                    {t('nextStep')}
                </Button>

            </div>
        )
    }

    const renderContactInformation = () => {
        return (
            <div>
                {/* <div style={{display:'flex',alignItems:'center',justifyContent:phone ? 'left':'center'}}>
                    <div
                        style={
                            phone ? {height:28.8, width:28.8, backgroundColor:'#081647', borderRadius:14.4,textAlign: 'center'}
                                :{height:48, width:48, backgroundColor:'#081647', borderRadius:24,textAlign: 'center'}
                        }
                    >
                        <Typography
                            style={{fontFamily:'Avenir', color:'white', fontSize:phone?10.8:18, fontWeight:500, margin:phone ? '6px 0px':'12px 0px'}}
                        >
                            1
                        </Typography>
                    </div>
                    <div style={{ boxSizing: 'border-box', border: '1px solid #1C2237', height:'1px', width:phone?'22px':'55px'}}></div>
                    <div
                        style={
                            phone ? {height:28.8, width:28.8, backgroundColor:'#081647', borderRadius:14.4,textAlign: 'center'}
                                :{height:48, width:48, backgroundColor:'#081647', borderRadius:24,textAlign: 'center'}
                        }
                    >
                        <Typography
                            style={{fontFamily:'Avenir', color:'white', fontSize:phone? 10.8:18, fontWeight:500, margin:phone ? '6px 0px':'12px 0px'}}
                        >
                            2
                        </Typography>
                    </div>
                </div> */}
                <div style={{ display: 'flex', alignItems: 'flex-start', flexDirection: 'column' }}>

                    <Typography
                        style={
                            phone ? { marginTop: 24, fontFamily: 'Avenir', fontSize: 18, fontWeight: 500, letterSpacing: 0, lineHeight: '25px', textAlign: 'initial' }
                                : { marginTop: 30, fontFamily: 'Avenir', fontSize: 24, fontWeight: 500, letterSpacing: 0, lineHeight: '35px' }
                        }
                    >
                        2/2   {t('contactInfo')}
                    </Typography>
                    <Typography
                        style={
                            phone ? { marginTop: 3, fontFamily: 'Avenir', color: '#9097A1', fontSize: 10, fontWeight: 500, letterSpacing: 0, lineHeight: '14px', textAlign: 'initial' }
                                : { marginTop: 4, fontFamily: 'Avenir', color: '#9097A1', fontSize: 14, fontWeight: 500, letterSpacing: 0, lineHeight: '19px' }
                        }
                    >
                        {t('contactInfoSubtitle')}
                    </Typography>
                </div>

                <div style={{ marginTop: phone ? 30 : 60 }}>
                    <Typography
                        className={phone ? classes.inputLabelPhone : classes.inputLabel}
                    >
                        {t('homeAddress')}
                    </Typography>

                    <InputBase
                        className={phone ? classes.inputPhone : classes.input}
                        placeholder={t('addressPlaceholder')}
                        value={contactInfo.address}
                        onChange={handleContactInfoChange('address')}
                    />
                </div>

                <div style={{ marginTop: phone ? 23 : 30, justifyContent: 'space-between', display: 'flex' }}>
                    <div style={{ width: '45%' }}>
                        <Typography
                            className={phone ? classes.inputLabelPhone : classes.inputLabel}
                        >
                            {t('country')}
                        </Typography>

                        <CountryDropdown
                            className={phone ? classes.inputPhone : classes.input}
                            style={{ padding: "0px 30px 0px 20px" }}
                            value={contactInfo.country}
                            onChange={(value) => setContactInfo({ ...contactInfo, ['country']: value })}
                        />
                    </div>

                    <div style={{ width: '45%' }}>
                        <Typography
                            className={phone ? classes.inputLabelPhone : classes.inputLabel}
                        >
                            {t('province')}
                        </Typography>

                        <RegionDropdown
                            className={phone ? classes.inputPhone : classes.input}
                            style={{ padding: "0px 30px 0px 20px" }}
                            country={contactInfo.country}
                            value={contactInfo.province}
                            onChange={(value) => setContactInfo({ ...contactInfo, ['province']: value })}
                        />
                    </div>
                </div>

                <div style={{ marginTop: phone ? 23 : 30, justifyContent: 'space-between', display: 'flex' }}>
                    <div style={{ width: '45%' }}>
                        <Typography
                            className={phone ? classes.inputLabelPhone : classes.inputLabel}
                        >
                            {t('city')}
                        </Typography>

                        <InputBase
                            className={phone ? classes.inputPhone : classes.input}
                            value={contactInfo.city}
                            placeholder={t('cityPlaceholder')}
                            onChange={handleContactInfoChange('city')}
                        />
                    </div>
                    <div style={{ width: '45%' }}>
                        <Typography
                            className={phone ? classes.inputLabelPhone : classes.inputLabel}
                        >
                            {t('postalCode')}
                        </Typography>

                        <InputBase
                            className={phone ? classes.inputPhone : classes.input}
                            value={contactInfo.postalCode}
                            placeholder={t('postalCodePlaceholder')}
                            onChange={handleContactInfoChange('postalCode')}
                        />
                    </div>

                </div>

                <div style={{ marginTop: phone ? 23 : 30, justifyContent: 'space-between', display: 'flex' }}>
                    <div style={{ width: '45%' }}>
                        <Typography
                            className={phone ? classes.inputLabelPhone : classes.inputLabel}
                        >
                            {t('phone')}
                        </Typography>

                        <InputBase
                            className={phone ? classes.inputPhone : classes.input}
                            value={contactInfo.phone}
                            placeholder={t('phonePlaceholder')}
                            onChange={handleContactInfoChange('phone')}
                        />
                    </div>
                    <div style={{width:'45%'}}>
                        <Typography
                            className={phone ? classes.inputLabelPhone : classes.inputLabel}
                        >
                            {t('preferredLanguage')}
                        </Typography>
                        <Select
                            className={phone ? classes.inputPhone : classes.input}
                            labelId="demo-select-small"
                            id="demo-select-small"
                            disableUnderline
                            inputProps={{
                                classes: {
                                    icon: classes.selectIcon,
                                    select:classes.selectInput
                                },
                            }}
                            displayEmpty
                            value={contactInfo.language}
                            onChange={handleContactInfoChange('language')}
                        >
                            <MenuItem disabled value=''>
                                {t('pleaseSelect')}
                            </MenuItem>
                            <MenuItem value="EN">
                                <img style={{ marginRight: phone?12:16, width: phone?18:24, height: phone?13:18, alignSelf: 'center', marginBottom: 2 }} src={englishIcon}/>
                                English
                            </MenuItem>
                            <MenuItem value="CN">
                                <img style={{ marginRight: phone?12:16, width: phone?18:24, height: phone?13:18, alignSelf: 'center', marginBottom: 2 }} src={chineseIcon}/>
                                中文
                            </MenuItem>
                        </Select>
                    </div>
                </div>

                <Button
                    className={phone ? classes.buttonPhone : classes.button}
                    style={{ marginTop: phone ? '52px' : '60px', opacity: confirmDisable() ? 0.3 : null }}
                    onClick={handleUpdate}
                    disabled={confirmDisable()}
                >
                    {t('confirm')}
                </Button>

            </div>
        )
    }

    const renderRegisterSuccess = () => {
        return (
            <>

                <div style={{ height: "30%", width: '100%' }}>
                    <img alt='' src={success} style={{ width: phone ? 40 : 97, height: phone ? 40 : 97, marginTop: phone ? 70 : 50 }}></img>
                </div>
                <Typography
                    style={
                        phone ? { marginTop: 21, fontFamily: 'Avenir', fontSize: 18, fontWeight: 500, letterSpacing: 0, lineHeight: '25px' }
                            : { marginTop: 60, fontFamily: 'Avenir', fontSize: 24, fontWeight: 500, letterSpacing: 0, lineHeight: '35px' }
                    }
                >
                    {t('successTitle')}
                </Typography>
                <Typography
                    style={
                        phone ? { marginTop: 3, fontFamily: 'Avenir', color: '#9097A1', fontSize: 12, fontWeight: 500, letterSpacing: 0, lineHeight: '14px' }
                            : { marginTop: 4, fontFamily: 'Avenir', color: '#9097A1', fontSize: 14, fontWeight: 500, letterSpacing: 0, lineHeight: '19px' }
                    }
                >
                    {t('successSubtitle')}
                </Typography>

                <Button
                    style={
                        phone ? {
                            marginTop: '64px',
                            width: '243px',
                            height: '48.3px',
                            borderRadius: 12,
                            backgroundColor: '#081647',
                            fontFamily: 'Avenir',
                            color: 'white',
                            fontSize: 12,
                            fontWeight: 500,
                            letterSpacing: 0,
                            lineHeight: '16px',
                            textTransform: 'none'
                        }
                            : {
                                marginTop: '60px',
                                width: '295px',
                                height: '65px',
                                borderRadius: 20,
                                backgroundColor: '#081647',
                                fontFamily: 'Avenir',
                                color: 'white',
                                fontSize: 18,
                                fontWeight: 500,
                                letterSpacing: 0,
                                lineHeight: '25px',
                                textTransform: 'none'
                            }
                    }
                    onClick={() => { setKycVisible(true) }}
                >
                    {t('verifyMyIdentity')}
                </Button>

                <div style={{ marginTop: phone ? 20 : 30 }}>
                    <Link

                        to='/trade'
                        style={
                            phone ? { textDecoration: 'none', fontFamily: 'Avenir', color: '#1C2237', fontSize: 12, fontWeight: 500, letterSpacing: 0, lineHeight: '14px' }
                                : { textDecoration: 'none', fontFamily: 'Avenir', color: '#1C2237', fontSize: 18, fontWeight: 500, letterSpacing: 0, lineHeight: '25px' }
                        }
                    >
                        {t('verifyLater')} <img alt='' src={arrow} style={{ width: phone ? 9.79 * 0.7 : 9.79, height: phone ? 16.59 * 0.7 : 16.59 }} />
                    </Link>
                </div>

                <Typography
                    style={
                        phone ? { marginTop: 77, fontFamily: 'Avenir', color: '#9097A1', fontSize: 12, fontWeight: 500, letterSpacing: 0, lineHeight: '12px' }
                            : { marginTop: 100, fontFamily: 'Avenir', color: '#9097A1', fontSize: 16, fontWeight: 500, letterSpacing: 0, lineHeight: '19px' }
                    }
                >
                    {t('gotQuestion')}
                </Typography>
                <div
                    style={
                        phone ? { margin: '12px auto', border: '0.72px solid #9097A1', borderRadius: 12, width: 180, display: 'flex', height: 24, alignItems: 'center', justifyContent: 'center', padding: "6px 23.7px" }
                            : { margin: '15px auto', border: '1.2px solid #9097A1', borderRadius: 20, width: 219, padding: "10px 0px" }
                    }
                >
                    <Typography
                        style={
                            phone ? { fontFamily: 'Avenir', color: '#9097A1', fontSize: 12, fontWeight: 500, letterSpacing: 0, lineHeight: '12px' }
                                : { fontFamily: 'Avenir', color: '#9097A1', fontSize: 14, fontWeight: 500, letterSpacing: 0, lineHeight: '19px' }
                        }
                    >
                        {t('whatsapp')}
                    </Typography>
                </div>
                <div
                    style={
                        phone ? { margin: '12px auto', border: '0.72px solid #9097A1', borderRadius: 12, width: 180, display: 'flex', height: 24, alignItems: 'center', justifyContent: 'center', padding: "6px 23.7px" }
                            : { margin: '15px auto', border: '1.2px solid #9097A1', borderRadius: 20, width: 219, padding: "10px 0px" }
                    }
                >
                    <Typography
                        style={
                            phone ? { fontFamily: 'Avenir', color: '#9097A1', fontSize: 12, fontWeight: 500, letterSpacing: 0, lineHeight: '12px' }
                                : { fontFamily: 'Avenir', color: '#9097A1', fontSize: 14, fontWeight: 500, letterSpacing: 0, lineHeight: '19px' }
                        }
                    >
                        {t('otcEmail')}
                    </Typography>
                </div>
                <div
                    style={
                        phone ? { margin: '12px auto 70px auto', border: '0.72px solid #9097A1', borderRadius: 12, width: 180, display: 'flex', height: 24, alignItems: 'center', justifyContent: 'center', padding: "6px 23.7px" }
                            : { margin: '15px auto', border: '1.2px solid #9097A1', borderRadius: 20, width: 219, padding: "10px 0px" }
                    }
                >
                    <Typography
                        style={
                            phone ? { fontFamily: 'Avenir', color: '#9097A1', fontSize: 12, fontWeight: 500, letterSpacing: 0, lineHeight: '12px' }
                                : { fontFamily: 'Avenir', color: '#9097A1', fontSize: 14, fontWeight: 500, letterSpacing: 0, lineHeight: '19px' }
                        }
                    >
                        {t('weChat')}
                    </Typography>
                </div>
            </>
        )
    }

    return (
        <div style={{ display: phone ? 'block' : 'flex' }}>

            <meta
                name="viewport"
                content="width=device-width, initial-scale=1, maximum-scale=1"
            />
            <div
                style={
                    phone ? { width: '100%', height: '142px', backgroundImage: `url(${leftPage})`, backgroundSize: `min(${width * 0.45}px,613px) min(${width * 0.6}px,817px)`, backgroundRepeat: 'repeat', overflow: 'hidden' }
                        : { position: 'fixed', width: '50%', height: height, backgroundImage: `url(${leftPage})`, backgroundSize: `min(${width * 0.45}px,613px) min(${width * 0.6}px,817px)`, backgroundRepeat: 'repeat', borderRadius: '0px 16px 16px 0px', overflow: 'hidden' }
                }
            >
                <img
                    alt=''
                    src={pageLogo}
                    style={
                        phone ? { width: 90, height: 90, position: 'relative', transform: 'translate(-50%,-50%)', top: '50%', left: '50%', opacity: 0.39 }
                            : { width: 0.3 * width, height: 0.3 * width, position: 'relative', transform: 'translate(-50%,-50%)', top: '50%', left: '50%' }
                    }
                />
            </div>
            <div style={{ marginLeft: phone ? null : '50%', width: phone ? '100%' : '50%', height: '100%', textAlign: 'center' }}>
                <div style={{ margin: phone ? '5% auto' : '100px auto', maxWidth: 544, padding: '0 29px' }}>
                    {step1 ?
                        renderPersonalInformation()
                        : step2 ?
                            renderContactInformation()
                            : step3 ?
                                renderRegisterSuccess()
                                : null
                    }



                </div>
            </div>
            {/*KYC弹窗*/}
            <KycModal
                onCancel={() => {
                    setKycVisible(false);
                }}
                modalVisible={kycVisible}
                t
                phone={phone}
                width={width}
                userInfo={personalInfo}
                fromRegi={true}
            />

        </div>

    )
}

export default withTranslation()(Verification)
