import React, { useEffect } from 'react'
import { withTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import {
    makeStyles,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableFooter,
    TableHead,
    TablePagination,
    TableRow,
    Typography,
    withStyles
} from '@material-ui/core';
import "react-datepicker/dist/react-datepicker.css";
import { useHistory } from "react-router-dom";
import { convertTimeString, roundingDown } from "../../utils/Common";
import { tradeActions } from "../../redux/actions/tradeActions";
import empty from "../../images/empty.png"


function FundHistory({ t, width, height, phone, setShowTradeDetail, setTradeDetail }) {
    const dispatch = useDispatch();
    const { tradeTransactions } = useSelector(state => state.trade)
    const [page, setPage] = React.useState(0);
    const [rowsPerPage, setRowsPerPage] = React.useState(10);
    const statusColor = {
        'pending': '#0173F9',
        'canceled': '#9097A1',
        'declined': '#9097A1',
        'succeed': '#4D8C7B',
        'expired': '#9097A1'
    }
    const statusLocales = {
        'pending': 'trade_pending',
        'canceled': 'trade_canceled',
        'declined': 'trade_declined',
        'succeed': 'trade_succeed',
        'expired': 'trade_expired'
    }

    const StyledTableCell = withStyles((theme) => ({
        root: {
            borderBottom: 'none',

            padding: 0,
            "&:first-child": {
                paddingLeft: 32,
                borderTopLeftRadius: "25px !important",
                borderBottomLeftRadius: "25px !important"
            },
            "&:last-child": {
                paddingRight: 32,
                borderTopRightRadius: "25px !important",
                borderBottomRightRadius: "25px !important"
            }
        },
        head: {
            // backgroundColor: 'red',
            color: '#9097A1',
            fontSize: 14,
            fontWeight: 400,
            paddingLeft: 80,

        },
        body: {
            color: '#1C2237',

            padding: '24px 0px',
            fontWeight: 900,
            paddingLeft: 58,
            backgroundColor: '#F9F9F9',
        }
    }))(TableCell);

    const useStyles = makeStyles({
        table: {
            borderCollapse: 'separate',
            borderSpacing: phone ? "0" : '0px 15px'
        },
        font: {
            fontFamily: 'Avenir',
            fontSize: 12,
            fontWeight: 500,
            color: "#1C2237"
        },
        pagination: {
            // flexDirection: 'column',
            // margin: 'auto',
        },
        paginationSelect: {
            margin: 'auto',
            fontSize: 10
        }
    });
    const classes = useStyles();
    const history = useHistory();

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
        dispatch(tradeActions.getTradeTransactionByCoinType({ types: ['buy', 'sell'], depositWithdrawType: '', currentPage: newPage, pageSize: rowsPerPage }))
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
        dispatch(tradeActions.getTradeTransactionByCoinType({ types: ['buy', 'sell'], depositWithdrawType: '', currentPage: 0, pageSize: event.target.value }))
    };

    useEffect(() => {
        dispatch(tradeActions.getTradeTransactionByCoinType({ types: ['buy', 'sell'], depositWithdrawType: '', currentPage: 0, pageSize: 10 }))
    }, [])

    useEffect(() => {
        // console.log('tradeTransactions: ', tradeTransactions)
    }, [tradeTransactions])

    const renderPhoneRow = (row) => {

        return (
            <div style={{ display: 'flex',alignItems:'flex-start', width: '100%', backgroundColor: 'transparent', marginBottom: 15, flexDirection: 'column' }}>
                <div style={{ paddingTop: 3, paddingBottom: 3, paddingLeft: 6, paddingRight: 6, backgroundColor: "#f0f0f0", borderRadius: 5 ,display:'flex',marginBottom:8}}>
                    <Typography style={{ fontSize: 12, fontWeight: 500, color: row.type == "buy" ? "#0173F9" : '#DC3055' }} >{t(row.type)}</Typography>
                </div>
                <div style={{ width: '100%', margin: '0px 0px', justifyContent: 'space-between', display: 'flex', flexDirection: 'row', alignItems: 'center' }}>

                    <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>

                        <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'flex-start', alignItems: 'flex-start' }}>
                            <Typography style={{ fontSize: 12, fontWeight: 500, color: "#1C2237" }}>{row.reference}</Typography>
                            <Typography style={{ fontSize: 12, fontWeight: 500, color: "#8B96A7", marginTop: 4 }}>{convertTimeString(row.createdAt)} (UTC)</Typography>
                        </div>
                    </div>

                    <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-end' }}>
                        <div style={{ display: 'flex', alignItems: 'flex-end' }}>
                            <Typography style={{ fontSize: 12, fontWeight: 500, color: "#8B96A7", marginRight: 4 }}>{t('tradeHistory_receivedAmount1')}</Typography>

                            <Typography style={{ fontSize: 12, fontWeight: 500, color: "#0173F9" }}>{`${roundingDown(row.receiveQuantity, 8)}`} {row.receiveCurrency}</Typography>
                        </div>
                        <div style={{ display: 'flex', alignItems: 'flex-end', marginTop: 4 }}>
                            <Typography style={{ fontSize: 12, fontWeight: 500, color: "#8B96A7", marginRight: 4 }}>{t('pay')}</Typography>
                            <Typography style={{ fontSize: 12, fontWeight: 500, color: "#1C2237" }}>{t(`${roundingDown(row.deliverQuantity, 8)}`)} {row.deliverCurrency}</Typography>

                        </div>

                    </div>

                </div>
                <div style={{ height: 1, width: '100%', backgroundColor: '#F2F2F2', marginTop: 12 }}></div>
            </div>
        )

    }
    return (
        <div style={{
            width: 0.90 * width,
            alignItems: phone ? "flex-start" : 'center',
            justifyContent: 'center',
            display: 'flex',
            padding: 0
        }}>
            <TableContainer
                style={{
                    background: 'transparent',
                    alignItems: 'center',
                    justifyContent: 'center',

                    border: 'none',
                    boxShadow: 'none'
                }}>
                <Table className={classes.table} aria-label="customized table">
                    {
                        phone ?
                            tradeTransactions.list.map((row, index) => (renderPhoneRow(row)))
                            :
                            <>
                                <TableHead >
                                    <TableRow>
                                        <StyledTableCell align="left">{t('tradeHistory_orderNumber')}</StyledTableCell>
                                        <StyledTableCell align="right" >{t('type')}</StyledTableCell>
                                        <StyledTableCell align="right">{t('tradeHistory_orderCreated')}</StyledTableCell>
                                        <StyledTableCell align="right">{t('tradeHistory_paidAmount')}</StyledTableCell>
                                        <StyledTableCell align="right">{t('tradeHistory_receivedAmount')}</StyledTableCell>
                                    </TableRow>
                                </TableHead>

                                <TableBody>
                                    {
                                        tradeTransactions.list.map((row, index) => (
                                            <TableRow style={{ marginTop: 10 }} key={index}>
                                                <StyledTableCell align="left"><Typography style={{ color: '#101010', fontWeight: 'bold', textAlign: 'left', fontSize: 14 }}>{row.reference}</Typography></StyledTableCell>
                                                <StyledTableCell align="right"> <Typography style={{ color: row.type == 'buy' ? '#0173F9' : '#DC3055', fontSize: 14, fontWeight: 'bold' }}>{t(row.type)}</Typography></StyledTableCell>
                                                <StyledTableCell align="right"><Typography style={{ color: '#101010', fontSize: 14, fontWeight: 'bold' }}>{convertTimeString(row.createdAt)} (UTC) </Typography></StyledTableCell>
                                                <StyledTableCell align="right"><Typography style={{ color: '#101010', fontSize: 14, fontWeight: 'bold' }}>{`${roundingDown(row.deliverQuantity, 8)}`} {row.deliverCurrency}</Typography></StyledTableCell>
                                                <StyledTableCell align="right"><Typography style={{ color: '#101010', fontWeight: 'bold', fontSize: 16 }}>{t(`${roundingDown(row.receiveQuantity, 8)}`)} {row.receiveCurrency}</Typography></StyledTableCell>
                                            </TableRow>
                                        ))
                                    }
                                </TableBody>
                            </>
                    }

                    {tradeTransactions.list.length === 0 ?
                        null
                        :
                        <TableFooter>
                            <TableRow >
                                <TablePagination
                                    style={{ backgroundColor: '#f1f0f0' }}
                                    rowsPerPageOptions={[5, 10, 25]}
                                    count={tradeTransactions.total}
                                    rowsPerPage={rowsPerPage}
                                    page={page}
                                    SelectProps={{
                                        inputProps: { 'aria-label': 'rows per page' },
                                        native: true,
                                    }}
                                    labelRowsPerPage={t('rowsPerPage')}
                                    labelDisplayedRows={(arg) => {
                                        return `${arg.from}-${arg.to} ${t('paginationOf')} ${arg.count}`
                                    }}
                                    onPageChange={handleChangePage}
                                    onRowsPerPageChange={handleChangeRowsPerPage}
                                    classes={phone ? {
                                        toolbar: classes.pagination,
                                        actions: classes.pagination,
                                        caption: classes.paginationSelect
                                    } : null}
                                />
                            </TableRow>
                        </TableFooter>
                    }
                </Table>
                {tradeTransactions.list.length === 0 ?
                    <>
                        <div style={{ display: 'flex', justifyContent: "center" }}>
                            <img alt="" src={empty} style={{ width: 190, height: 190 }} />
                        </div>
                        <Typography className={classes.font} style={{ marginTop: 25, fontSize: 14, color: "#333333", fontWeight: 900 }}>{t('historyEmpty')}</Typography>
                    </>
                    : null}
            </TableContainer>
        </div>
    )
}
export default withTranslation()(FundHistory)
