import * as Url from '../../config/Url'
import { authHeader } from '../../utils/AuthHeader';
import { handleResponse } from '../../utils/HandleResponse'

export const kycService = {
    initKycLink,
    getKycDetails,
    syncKycStatus,
    updateKycStatus,
    updateVerificationPhone,
    getVerificationCode,
    validateVerificationCode,
    initAddressVerificationLink,
    getDocumentDetails,
    syncAddressStatus
};

function initKycLink(token) {
    const requestOptions = {
        method: 'GET',
        headers: authHeader(token),
    };

    return fetch(Url.INIT_KYC_LINK, requestOptions).then(handleResponse);
}

function getKycDetails(token) {
    const requestOptions = {
        method: 'GET',
        headers: authHeader(token),
    };

    return fetch(Url.GET_KYC_DETAILS, requestOptions).then(handleResponse);
}

function syncKycStatus(token) {
    const requestOptions = {
        method: 'GET',
        headers: authHeader(token),
    };

    return fetch(Url.SYNC_KYC_STATUS, requestOptions).then(handleResponse);
}

function updateKycStatus(token, payload) {
    const requestOptions = {
        method: 'PUT',
        headers: authHeader(token),
        body: JSON.stringify(payload)
    };

    return fetch(Url.UPDATE_KYC_STATUS, requestOptions).then(handleResponse);
}

function updateVerificationPhone(token, payload) {
    const requestOptions = {
        method: 'PUT',
        headers: authHeader(token),
        body: JSON.stringify(payload)
    };

    return fetch(Url.UPDATE_VERIFICATION_PHONE + payload.newPhone, requestOptions).then(handleResponse);
}

function getVerificationCode(token) {
    const requestOptions = {
        method: 'GET',
        headers: authHeader(token),
    };

    return fetch(Url.GET_MOBILE_VERIFICATION_CODE, requestOptions).then(handleResponse);
}

function validateVerificationCode(token, payload) {
    const requestOptions = {
        method: 'POST',
        headers: authHeader(token),
        body: JSON.stringify(payload)
    };

    return fetch(Url.VALIDATE_VERIFICATION_CODE + payload.code, requestOptions).then(handleResponse);
}

function initAddressVerificationLink(token, payload) {
    const requestOptions = {
        method: 'GET',
        headers: authHeader(token)
    };

    return fetch(Url.INIT_ADDRESS_VERIFICATION + payload.docType, requestOptions).then(handleResponse);
}

function getDocumentDetails(token) {
    const requestOptions = {
        method: 'GET',
        headers: authHeader(token),
    };

    return fetch(Url.GET_DOCUMENT_DETAILS, requestOptions).then(handleResponse);
}

function syncAddressStatus(token) {
    const requestOptions = {
        method: 'GET',
        headers: authHeader(token),
    };

    return fetch(Url.SYNC_ADDRESS_STATUS, requestOptions).then(handleResponse);
}
