import * as Url from '../../config/Url'
import { authHeader } from '../../utils/AuthHeader';
import { handleResponse } from '../../utils/HandleResponse'

export const referralService = {
    getReferrals,
    getUsernameByReferralCode,
    sendSubscribe
};

async function getReferrals(payload, token) {
    const requestOptions = {
        method: 'GET',
        headers: authHeader(token),
    };
    return fetch(Url.GET_REFERRALS+`currentPage=${payload.currentPage}&pageSize=${payload.pageSize}`, requestOptions).then(handleResponse);
}

async function getUsernameByReferralCode(payload) {
    const requestOptions = {
        method: 'GET'
    };
    return fetch(Url.GET_USERNAME_BY_REFERRAL_CODE+payload, requestOptions).then(handleResponse);
}

function sendSubscribe(payload,token) {
    const requestOptions = {
        method: 'POST',
        headers: authHeader(token),

    };


    return fetch(Url.SUBSCRIBE + 'email=' + payload.email + '&' + 'language=' + payload.lung, requestOptions).then(handleResponse);
}