export const checkEmail = email => {
    const reg = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w+)+$/;
    if (reg.test(email)) {
        return true;
    } else {
        return false;
    }
}

export const isValidPassword = (psw) => {
    const format = /^[A-Za-z0-9\d@$!%*#?&]+$/;
    // let letterNumber = /^(?=.*?\d)(?=.*?[a-zA-Z])[a-zA-Z\d]+$/
    return format.test(psw) && psw.length >= 8 && psw.length <= 30
}

export const isEnglishKeyboard = (str) => {
    if (str === ''){
        return true
    }
    const reg = /^[ -~]+$/
    // let letterNumber = /^(?=.*?\d)(?=.*?[a-zA-Z])[a-zA-Z\d]+$/
    return reg.test(str)
}