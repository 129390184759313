import * as Url from '../../config/Url'
import { authHeader } from '../../utils/AuthHeader';
import { handleResponse } from '../../utils/HandleResponse'

export const nftService = {
    transferVoucher,
    getCommissionAndNft,
    claimNft,
    getNftClaimed,
    getNftAsset,
    getCountdown
};

async function transferVoucher(payload, token) {
    const requestOptions = {
        method: 'POST',
        headers: authHeader(token),
    };
    return fetch(Url.TRANSFER_VOUCHER+`${payload.email}&amount=${payload.amount}&emailCode=${payload.emailCode}&smsCode=${payload.phoneCode}`, requestOptions).then(handleResponse);
}

async function getCommissionAndNft( token) {
    const requestOptions = {
        method: 'GET',
        headers: authHeader(token),
    };
    return fetch(Url.GET_COMMISSION_AND_NFT, requestOptions).then(handleResponse);
}

async function claimNft(payload, token) {
    const requestOptions = {
        method: 'POST',
        headers: authHeader(token),
    };
    return fetch(Url.CLAIM_NFT+`${payload}`, requestOptions).then(handleResponse);
}

async function getNftClaimed( token) {
    const requestOptions = {
        method: 'GET',
        headers: authHeader(token),
    };
    return fetch(Url.GET_NFT_CLAIMED, requestOptions).then(handleResponse);
}

async function getNftAsset( token) {
    const requestOptions = {
        method: 'GET',
        headers: authHeader(token),
    };
    return fetch(Url.GET_NFT_ASSET, requestOptions).then(handleResponse);
}

async function getCountdown( token) {
    const requestOptions = {
        method: 'GET',
        headers: authHeader(token),
    };
    return fetch(Url.GET_COUNTDOWN, requestOptions).then(handleResponse);
}