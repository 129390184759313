import {
    TRADE_PRICE_QUOTE,
    TRADE_PRICE_QUOTE_SUCCEED,
    TRADE_PRICE_QUOTE_FAILED,
    GET_LIVE_PRICE,
    GET_LIVE_PRICE_SUCCEED,
    GET_LIVE_PRICE_FAILED,
    CLEAR_TRADE_QUOTE,
    TRADE,
    TRADE_SUCCEED,
    TRADE_FAILED,
    CLEAR_TRADE,
    GET_TRADE_TRANSACTIONS,
    GET_TRADE_TRANSACTIONS_SUCCEED,
    GET_TRADE_TRANSACTIONS_FAILED,
    GET_AQUA_BALANCE,
    GET_AQUA_BALANCE_SUCCEED,
    GET_AQUA_BALANCE_FAILED,
    EXPIRE_AQUA_QUOTE,
    EXPIRE_AQUA_QUOTE_SUCCEED,
    EXPIRE_AQUA_QUOTE_FAILED,
    GET_BTC_PRICE_CHANGE,
    GET_BTC_PRICE_CHANGE_FAILED,
    GET_BTC_PRICE_CHANGE_SUCCEED,
    GET_ETH_PRICE_CHANGE,
    GET_ETH_PRICE_CHANGE_FAILED,
    GET_ETH_PRICE_CHANGE_SUCCEED,
    GET_USDT_PRICE_CHANGE,
    GET_USDT_PRICE_CHANGE_FAILED,
    GET_USDT_PRICE_CHANGE_SUCCEED,
    GET_BANNER_INFO, GET_BANNER_INFO_FAILED, GET_BANNER_INFO_SUCCEED
} from '../constants';
import {roundingDown} from "../../utils/RoundingDown";

export function trade (state = {
    tradePriceQuote:{},
    livePrice:0,
    tradeTransactions:{
        list:[],
        page:0,
        size:10,
        isEnd:true,
        total:0
    },
    aquaBalance:{
        pendingTransfer: 0,
        totalBalance: 0,
        availableBalance: 0,
        symbol: ""
    },
    btcPriceChange: {},
    ethPriceChange: {},
    usdtPriceChange: {},
    tradeLoading: false,
    tradeBannerInfo: {}
}, action) {
    switch (action.type) {
        case TRADE_PRICE_QUOTE:
            return {
                ...state,
                tradePriceQuoteLoading: true
            }
        case TRADE_PRICE_QUOTE_SUCCEED:
            return {
                ...state,
                tradePriceQuote: action.data.payload,
                tradePriceQuoteLoading: false
            }
        case TRADE_PRICE_QUOTE_FAILED:
            return {
                ...state,
                tradePriceQuoteMsg: action.message,
                tradePriceQuoteLoading: false
            }

        case GET_LIVE_PRICE:
            return {
                ...state,
                getLivePriceLoading: true
            }
        case GET_LIVE_PRICE_SUCCEED:
            return {
                ...state,
                livePrice: action.data.payload.price,
                getLivePriceLoading: false
            }
        case GET_LIVE_PRICE_FAILED:
            return {
                ...state,
                livePrice:0,
                livePriceQuoteMsg: action.message,
                getLivePriceLoading: false
            }

        case CLEAR_TRADE_QUOTE:
            return{
                ...state,
                tradePriceQuote:{}
            }

        case TRADE:
            return {
                ...state,
                tradeLoading: true,
                tradeSucceed:false,
                tradeFailed:false
            }
        case TRADE_SUCCEED:
            return {
                ...state,
                tradeSucceed:true,
                tradeLoading: false,
                tradeData:action.data
            }
        case TRADE_FAILED:
            return {
                ...state,
                tradeMsg: action.message,
                tradeFailed:true,
                tradeLoading: false
            }
        case CLEAR_TRADE:
            return {
                ...state,
                tradeFailed:false,
                tradeSucceed:false
            }

        case GET_TRADE_TRANSACTIONS:
            return{
                ...state,
                tradeTransactions:{
                    list:[],
                    page:1,
                    size:10,
                    isEnd:true,
                    total:0
                },
                loading:true,
            }
        case GET_TRADE_TRANSACTIONS_SUCCEED:
            return{
                ...state,
                tradeTransactions:action.data,
                loading:false
            }
        case GET_TRADE_TRANSACTIONS_FAILED:
            return{
                ...state,
                tradeTransactionsMsg:action.message,
                loading:false
            }

        case GET_AQUA_BALANCE:
            return{
                ...state,
                getAquaBalanceLoading:true,
            }
        case GET_AQUA_BALANCE_SUCCEED:
            return{
                ...state,
                aquaBalance:action.data,
                getAquaBalanceLoading:false
            }
        case GET_AQUA_BALANCE_FAILED:
            return{
                ...state,
                aquaBalance:{
                    pendingTransfer: 0,
                    totalBalance: 0,
                    availableBalance: 0,
                    symbol: ""},
                getAquaBalanceLoading:false
            }

        case EXPIRE_AQUA_QUOTE:
            return{
                ...state,
                expireAquaQuoteLoading:true,
            }
        case EXPIRE_AQUA_QUOTE_SUCCEED:
            return{
                ...state,
                expireAquaQuoteLoading:false
            }
        case EXPIRE_AQUA_QUOTE_FAILED:
            return{
                ...state,
                expireAquaQuoteLoading:false
            }
        case GET_BTC_PRICE_CHANGE:
            return{
                ...state,
                tradeLoading: true,
            }
        case GET_BTC_PRICE_CHANGE_SUCCEED:
            return{
                ...state,
                btcPriceChange:action.data,
                tradeLoading: false
            }
        case GET_BTC_PRICE_CHANGE_FAILED:
            return{
                ...state,
                tradeLoading: false
            }

        case GET_ETH_PRICE_CHANGE:
            return{
                ...state,
                tradeLoading: true,
            }
        case GET_ETH_PRICE_CHANGE_SUCCEED:
            return{
                ...state,
                ethPriceChange:action.data,
                tradeLoading: false
            }
        case GET_ETH_PRICE_CHANGE_FAILED:
            return{
                ...state,
                tradeLoading: false
            }

        case GET_USDT_PRICE_CHANGE:
            return{
                ...state,
                tradeLoading: true,
            }
        case GET_USDT_PRICE_CHANGE_SUCCEED:
            return{
                ...state,
                usdtPriceChange: action.data,
                tradeLoading: false
            }
        case GET_USDT_PRICE_CHANGE_FAILED:
            return{
                ...state,
                tradeLoading: false
            }

        case GET_BANNER_INFO:
            return{
                ...state,
                tradeLoading: true,
            }
        case GET_BANNER_INFO_SUCCEED:
            return{
                ...state,
                tradeBannerInfo: action.data,
                tradeLoading: false
            }
        case GET_BANNER_INFO_FAILED:
            return{
                ...state,
                tradeLoading: false
            }

        default:
            return state;
    }
};
