import * as Url from '../../config/Url'
import { authHeader } from '../../utils/AuthHeader';
import { handleResponse } from '../../utils/HandleResponse'
import * as credentials from '../../config/credentials.json'

export const authService = {
    logIn,
    logOut,
    signUp,
    getUserInfo,
    updateUserInfo,
    sendVerificationCode,
    checkUser,
    resetPassword,
    updateLoginPassword,
    getLatestArticles,
    getOAUTHToken
};

async function signUp(payload) {
    const requestOptions = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(payload)
    };

    return fetch(Url.SIGN_UP, requestOptions).then(handleResponse);

}

function logIn(payload) {
    const requestOptions = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(payload)
    };

    return fetch(Url.LOGIN, requestOptions).then(handleResponse);
}

function logOut() {
    // remove user from local storage to log user out
    localStorage.removeItem('otcuser');
}

function getUserInfo(token) {
    const requestOptions = {
        method: 'GET',
        headers: authHeader(token),
    };

    return fetch(Url.GET_USERINFO, requestOptions).then(handleResponse);
}

function updateUserInfo(payload,token) {
    console.log('updating: ', payload)
    const requestOptions = {
        method: 'POST',
        headers: authHeader(token),
        body: JSON.stringify(payload)
    };

    return fetch(Url.UPDATE_USERINFO, requestOptions).then(handleResponse);
}

function sendVerificationCode(payload) {
    const requestOptions = {
        method: 'GET',
    };

    return fetch(Url.GET_VERIFICATION_CODE+'username='+payload, requestOptions).then(handleResponse);
}

function checkUser(payload) {
    const requestOptions = {
        method: 'GET',
    };

    return fetch(Url.CHECK_USER+'username='+payload, requestOptions).then(handleResponse);
}

function resetPassword(payload) {
    const requestOptions = {
        method: 'PUT',
        headers:{'Content-Type': 'application/json'},
        body: JSON.stringify(payload)
    };

    return fetch(Url.RESET_PASSWORD, requestOptions).then(handleResponse);
}

function updateLoginPassword(payload,token) {
    const requestOptions = {
        method: 'POST',
        headers:authHeader(token),
        body: JSON.stringify(payload)
    };

    return fetch(Url.UPDATE_LOGIN_PASSWORD, requestOptions).then(handleResponse);
}

function getLatestArticles(token) {
    const requestOptions = {
        method: 'GET',
        headers:new Headers({
            'Authorization': `Bearer ${token}`,
            'Content-Type': 'application/json',
        }),
    };

    return fetch(Url.GET_ZENDESK_ARTICLES, requestOptions).then(handleResponse);
}

function getOAUTHToken() {
    let payload = {
        grant_type: "password",
        client_id: credentials.default.uid,
        client_secret: credentials.default.secret,
        scope: "read",
        username: credentials.default.username,
        password: credentials.default.password
    }

    console.log('payload: ', payload)

    const requestOptions = {
        method: 'POST',
        headers:new Headers({
            'Content-Type': 'application/json',
        }),
        body: JSON.stringify(payload),
        // mode: 'no-cors'
    };

    return fetch(Url.GET_OAUTH_TOKEN, requestOptions).then(handleResponse);
}
