import React, { useEffect, useState,useRef } from 'react'

import './Wealth.css'
import Checkbox from '@material-ui/core/Checkbox';
import FormGroup from '@material-ui/core/FormGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import TextField from '@material-ui/core/TextField';
import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import emailjs from 'emailjs-com';
import { withTranslation } from 'react-i18next';
import WealthBanner from '../../images/WealthBanner.png'
import WealthBanner2 from '../../images/WealthBanner2.png'
import BitcoinBlack from '../../images/BitcoinBlack.png'
import enterButtonIcon from '../../images/enterButtonIcon.png'
import USDBlack from '../../images/USDBlack.png'
import CADBlack from '../../images/CADBlack.png'
import { useHistory } from 'react-router-dom'

import yesbitComWhiteLogo from '../../images/home/yesbitComWhiteLogo.png';

import USDTBlack from '../../images/USDTBlack.png'
import ETHBlack from '../../images/ETHBlack.png'
import {
    Button,
    List,
    ListItem,
    ListItemAvatar,
    ListItemSecondaryAction,
    makeStyles,
    Modal,
    Typography
} from '@material-ui/core';
import { toast } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'

toast.configure()
function Wealth({ t, width }) {
    const useStyles = makeStyles({

        buttonSelect: {
            width: '50%', backgroundColor: 'black', height: 6, borderRadius: 3
        },
        buttonUnselect: {
            width: '50%', backgroundColor: 'black', height: 6, borderRadius: 3, opacity: 0
        },

        submitEnable: {
            backgroundColor: '#0173F9',
            borderRadius: 8,
            width: width < 900 ? "100%" : 240,
            height: 60,
            opacity: 1
        },
        submitDisable: {
            backgroundColor: '#0173F9',
            borderRadius: 8,
            width: width < 900 ? "100%" : 240,
            height: 60,
            opacity: 0.4

        }


    });
    const classes = useStyles();
    const history = useHistory();
    const [phone, setPhone] = useState(width <= 745);
    const [isValidEmailAddress, setIsValidEmailAddress] = React.useState(true)
    const handleSetIsValidEmailAddress = (isValid) => {
        setIsValidEmailAddress(isValid)
    }

    useEffect(() => {
        setPhone(width <= 745)
    }, [width]);
    const [buttonStatus, setButtonStatus] = React.useState(1)
    const signUp= useRef(null)
    const signUpPhone= useRef(null)

    const [firstNameState, setFirstNameState] = React.useState('')

    const handleFirstNameInput = (event) => {
        setFirstNameState(event.target.value)
    }


    const [lastNameState, setLastNameState] = React.useState('')

    const handleLastNameInput = (event) => {
        setLastNameState(event.target.value)
    }

    const [emailState, setEmailState] = React.useState('')

    const handleEmailInput = (event) => {
        checkIsValidEmailAddress(event.target.value)
        setEmailState(event.target.value)
    }

    function checkIsValidEmailAddress(address) {
        if (address !== '') {
            if (address.match(/.+@.+/)) {
                handleSetIsValidEmailAddress(true)
            } else {
                handleSetIsValidEmailAddress(false)
            }
        } else {
            handleSetIsValidEmailAddress(true)
        }

    }

    const [phoneState, setPhoneState] = React.useState('')

    const handlePhoneInput = (event) => {
        setPhoneState(event.target.value)
    }


    const handleSendEmail = (event) => {

        event.preventDefault();

        const templateParams = {
            firstName: firstNameState,
            lastName: lastNameState,
            email: emailState,
            telephone: phoneState,
            transaction: transaction,
            BTC: coinState.BTC,
            ETH: coinState.ETH,
            USDT: coinState.USDT,
            Others: coinState.Others,
            BUY: buyOrSellState.BUY,
            SELL: buyOrSellState.SELL
        }
        resetAllInput()


        emailjs.send('OTC_service', 'OTC_Template', templateParams, 'user_5SNLv4jbZDYixNY2cyL5I')
            .then((result) => {
                toast.success(t('toast_Success'))
            }, (error) => {
                toast.error(t('toast_Error'))

                console.log(error.text);
            });

    }



    function resetAllInput() {
        toast.info(t('toast_Sending'))

        setCoinState({
            BTC: false,
            ETH: false,
            USDT: false,
            Others: false
        })
        setBuyOrSellState({
            BUY: false,
            SELL: false,
        })
        setFirstNameState('')
        setLastNameState('')
        setEmailState('')
        setPhoneState('')
        setTransaction('')
    }




    const [transaction, setTransaction] = React.useState('');
    const handleSelectChange = (event) => {
        setTransaction(event.target.value);
    };


    const [coinState, setCoinState] = React.useState({
        BTC: false,
        ETH: false,
        USDT: false,
        Others: false
    });
    const [buyOrSellState, setBuyOrSellState] = React.useState({
        BUY: false,
        SELL: false,

    });

    const handleCheckBoxChange = (event) => {
        setCoinState({ ...coinState, [event.target.name]: event.target.checked });

    };

    const handleBuyOrSellCheckBoxChange = (event) => {
        setBuyOrSellState({ ...buyOrSellState, [event.target.name]: event.target.checked });
    };
    return (
        <>
            {phone ?
                <div style={{}}>
                    <div
                        style={{ display: 'flex', flexDirection: 'column', width: "100%" }}
                    >
                        <div style={{
                            width: '100%',
                            backgroundImage: `url(${WealthBanner})`,
                            backgroundPosition: 'center',
                            backgroundSize: 'cover',
                            backgroundRepeat: 'no-repeat',
                            display: 'flex', alignItems: 'center',
                        }}>

                            <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                                <Typography style={{ fontSize: 24, color: "white", fontWeight: 'bold', fontFamily: 'Avenir', textAlign: 'left', marginTop: 40, marginLeft: 24, marginRight: 24 }}>
                                    {t('wealth_title')}
                                </Typography>

                                <Typography style={{ fontSize: 12, marginTop: 8, color: 'white', fontWeight: '400', fontFamily: 'Avenir', textAlign: 'left', marginLeft: 24, marginRight: 24 }}>
                                    {t('wealth_subtitle')}
                                </Typography>
                                <div style={{ display: 'flex', marginTop: 60, height: 52, marginBottom: 40, flexDirection: 'column', width: '89%' }}>
                                    <Button onClick={() => { window.scrollTo({behavior:'smooth',top:signUpPhone.current.offsetTop}) }}  style={{ backgroundColor: '#202129', borderRadius: 0, paddingLeft: 40, paddingRight: 40 }}>
                                        <Typography style={{ fontSize: 12, color: 'white', fontWeight: 'bold', fontFamily: 'Avenir', marginTop: 12, marginBottom: 12 }}>
                                            {t('wealth_startButton')}
                                        </Typography>
                                        <img src={enterButtonIcon} style={{ height: 12, marginLeft: 6 }}></img>

                                    </Button>

                                    <Typography style={{ fontSize: 12, color: 'white', fontWeight: '400', fontFamily: 'Avenir', marginBottom: 60, marginTop: 12, textAlign: 'center' }}>
                                        {t('wealth_emailButton')} otc@yesbit.ca
                                    </Typography>



                                </div>
                                {/* <p className={'contact__subtitle'}>
                        {t("quote_Subtitle")}
                    </p> */}
                            </div>


                        </div>
                        <div style={{
                            width: '100%', backgroundImage: `url(${WealthBanner2})`,
                            backgroundPosition: 'center',
                            backgroundSize: 'cover',
                            backgroundRepeat: 'no-repeat',
                            display: 'flex', alignItems: 'center',
                            flexDirection: 'column'
                        }}>

                            <div style={{ width: '100%', display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center' }}>
                                <Typography style={{ fontSize: 20, color: 'white', fontWeight: 'bold', fontFamily: 'Avenir', textAlign: 'center', marginTop: 24 }}>
                                    {t('wealth_sec1')}
                                </Typography>
                                <Typography style={{ fontSize: 12, marginTop: 24, color: 'white', fontWeight: '400', fontFamily: 'Avenir', marginLeft: 16, marginRight: 16 }}>
                                    {t('wealth_sec1Subtitle')}
                                </Typography>


                            </div>
                            <div style={{ display: 'flex', }}>
                                <img style={{ height: 24, marginTop: 16, marginBottom: 16 }} src={yesbitComWhiteLogo}></img>


                            </div>

                        </div>

                        <div style={{ width: '100%', display: 'flex', alignItems: 'center', justifyContent: 'center', marginTop: 24 }}>
                            <div style={{ width: '91%' }}>
                                <Button style={{ width: '50%', textTransform: 'none', height: 40 }} onClick={() => setButtonStatus(1)}>
                                    <Typography style={{ fontSize: 12, color: buttonStatus === 1 ? "black" : '#D8d8d8', fontWeight: '400', fontFamily: 'Avenir', }}>
                                        {t('wealth_cryptocurrency')}
                                    </Typography>
                                </Button>

                                <Button style={{ width: '50%', textTransform: 'none', height: 40 }} onClick={() => setButtonStatus(2)}>
                                    <Typography style={{ fontSize: 12, color: buttonStatus === 2 ? "black" : '#D8d8d8', fontWeight: '400', fontFamily: 'Avenir', }}>
                                        {t('wealth_fiat')}
                                    </Typography>
                                </Button>


                                <div style={{ height: 6, borderRadius: 3, width: "100%", backgroundColor: '#d8d8d8', marginTop: -6, display: 'flex' }}>
                                    <div className={buttonStatus === 1 ? classes.buttonSelect : classes.buttonUnselect}></div>

                                    <div className={buttonStatus === 2 ? classes.buttonSelect : classes.buttonUnselect}></div>
                                </div>
                            </div>
                        </div>
                        {
                            buttonStatus === 1 ?
                                <div style={{ width: '100%', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                                    <div style={{ width: '91%', marginTop: 24, display: 'flex', justifyContent: 'space-between' }}>
                                        <div style={{ width: "31%", backgroundColor: '#efefef', height: 60, borderRadius: 12, display: 'flex', alignItems: 'center' }}>
                                            <img src={BitcoinBlack} style={{ height: 30, marginLeft: 12 }}></img>
                                            <div style={{ marginLeft: 8 }}>
                                                <Typography style={{ fontSize: 10, color: '#858e9d', fontWeight: '400', fontFamily: 'Avenir', }}>{t("bitCoin")}</Typography>
                                                <Typography style={{ fontSize: 14, color: 'black', fontWeight: 'Bold', fontFamily: 'Avenir', }}>BTC</Typography>
                                            </div>

                                        </div>

                                        <div style={{ width: "31%", backgroundColor: '#efefef', height: 60, borderRadius: 12, display: 'flex', alignItems: 'center' }}>
                                            <img src={USDTBlack} style={{ height: 30, marginLeft: 12 }}></img>
                                            <div style={{ marginLeft: 8 }}>
                                                <Typography style={{ fontSize: 10, color: '#858e9d', fontWeight: '400', fontFamily: 'Avenir', }}>{t("Tether")}</Typography>
                                                <Typography style={{ fontSize: 14, color: 'black', fontWeight: 'Bold', fontFamily: 'Avenir', }}>USDT</Typography>
                                            </div>

                                        </div>

                                        <div style={{ width: "31%", backgroundColor: '#efefef', height: 60, borderRadius: 12, display: 'flex', alignItems: 'center' }}>
                                            <img src={ETHBlack} style={{ height: 30, marginLeft: 12 }}></img>
                                            <div style={{ marginLeft: 8 }}>
                                                <Typography style={{ fontSize: 10, color: '#858e9d', fontWeight: '400', fontFamily: 'Avenir', }}>{t("Ethereum")}</Typography>
                                                <Typography style={{ fontSize: 14, color: 'black', fontWeight: 'Bold', fontFamily: 'Avenir', }}>ETH</Typography>
                                            </div>

                                        </div>

                                    </div>
                                </div>
                                :
                                <div style={{ width: '100%', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                                    <div style={{ width: '91%', marginTop: 24, display: 'flex', justifyContent: 'space-between' }}>
                                        <div style={{ width: "49%", backgroundColor: '#efefef', height: 60, borderRadius: 12, display: 'flex', alignItems: 'center' }}>
                                            <img src={CADBlack} style={{ height: 30, marginLeft: 12 }}></img>
                                            <div style={{ marginLeft: 16 }}>
                                                <Typography style={{ fontSize: 10, color: '#858e9d', fontWeight: '400', fontFamily: 'Avenir', }}>{t('cad')}</Typography>
                                                <Typography style={{ fontSize: 14, color: 'black', fontWeight: 'Bold', fontFamily: 'Avenir', }}>CAD</Typography>
                                            </div>

                                        </div>

                                        <div style={{ width: "49%", backgroundColor: '#efefef', height: 60, borderRadius: 12, display: 'flex', alignItems: 'center' }}>
                                            <img src={USDBlack} style={{ height: 30, marginLeft: 12 }}></img>
                                            <div style={{ marginLeft: 16 }}>
                                                <Typography style={{ fontSize: 10, color: '#858e9d', fontWeight: '400', fontFamily: 'Avenir', }}>{t('usd')}</Typography>
                                                <Typography style={{ fontSize: 14, color: 'black', fontWeight: 'Bold', fontFamily: 'Avenir', }}>USD</Typography>
                                            </div>

                                        </div>


                                    </div>
                                </div>
                        }
                        <div style={{ widht: "100%", height: 1, backgroundColor: '#efefef', marginTop: 24, marginBottom: 0 }}></div>
                        <div ref={signUpPhone} style={{
                            backgroundColor: 'white', width: "100%", display: 'flex', alignItems: 'center', marginBottom: 60, flexDirection: 'column'
                        }} >
                            <div style={{ display: 'flex', width: '100%' }}>
                                <Typography style={{ marginTop: 40, fontSize: 24, marginLeft: 16, color: 'black', fontWeight: 'bold', fontFamily: 'Avenir', }}>{t('wealth_SignUp')}</Typography>
                            </div>
                            <div style={{ width: "91%" }} >
                                <div className='contact__textInputAreaWrapper'>
                                    <Typography style={{ marginTop: 24, fontSize: 20, color: '#8B96A7', fontWeight: '400', marginBottom: 12, fontFamily: 'Avenir', }}>{t('quote_ContactInfo')}</Typography>
                                    <TextField value={firstNameState} id="outlined-basic" fullWidth label={t('quote_firstName')} variant="outlined" type="text" name="firstName" margin='normal' onChange={handleFirstNameInput} />
                                    <TextField value={lastNameState} id="outlined-basic" fullWidth label={t('quote_lastName')} variant="outlined" type="text" name="lastName" margin='normal' onChange={handleLastNameInput} />
                                    <TextField fullWidth error={!isValidEmailAddress} helperText={isValidEmailAddress ? null : t('email_error')} value={emailState} id="outlined-basic" label={t('quote_email')} variant="outlined" type="email" name="email" margin='normal' fullWidth onChange={handleEmailInput} />
                                    <TextField fullWidth value={phoneState} id="outlined-basic" label={t('quote_telephone')} variant="outlined" type="text" name="telephone" margin='normal' onChange={handlePhoneInput} />

                                </div>
                                <div style={{ width: '100%', height: 1, backgroundColor: '#EFEFEF', marginTop: 24, marginBottom: 24 }}></div>
                                <div className='contact__textInputAreaWrapper' style={{ marginTop: 24 }}>
                                    <Typography style={{ marginTop: 24, fontSize: 20, color: '#8B96A7', fontWeight: '400', marginBottom: 12, fontFamily: 'Avenir', }}>{t('quote_detail')}</Typography>
                                    <FormControl variant="outlined" className="contact__pickerWrapper" margin='normal'>
                                        <InputLabel >{t('quote_transaction')}</InputLabel>

                                        <Select
                                            labelId="transaction"
                                            value={transaction}
                                            onChange={handleSelectChange}
                                            label={t('quote_transaction')}
                                            name="transaction"
                                        >
                                            <MenuItem value="None">
                                                <em>{t('quote_transaction_notSure')}</em>
                                            </MenuItem>
                                            <MenuItem value={"10k-100k"}> {t('quote_transaction_10k')}</MenuItem>
                                            <MenuItem value={"100k-500k"}>{t('quote_transaction_100k')}</MenuItem>
                                            <MenuItem value={"over 500k"}>{t('quote_transaction_500k')}</MenuItem>
                                        </Select>
                                    </FormControl>

                                </div>




                                <div className="contact__selectCoinContainer">
                                    <p className="contact__subtitle-dark">{t('quote_coinType')}</p>
                                    <FormGroup row className="contact__SelectionRow" m="12rem">

                                        <FormControlLabel
                                            control={
                                                <Checkbox
                                                    checked={coinState.BTC}
                                                    onChange={handleCheckBoxChange}
                                                    name="BTC"
                                                    value={coinState.BTC}

                                                />
                                            }
                                            label="BTC"
                                        />

                                        <FormControlLabel
                                            control={
                                                <Checkbox
                                                    checked={coinState.ETH}
                                                    onChange={handleCheckBoxChange}
                                                    name="ETH"
                                                    value={coinState.ETH}

                                                // color="primary"
                                                />
                                            }
                                            label="ETH"
                                        />

                                        <FormControlLabel
                                            control={
                                                <Checkbox
                                                    checked={coinState.USDT}
                                                    onChange={handleCheckBoxChange}
                                                    name="USDT"
                                                    value={coinState.USDT}
                                                />
                                            }
                                            label="USDT"
                                        />


                                        <FormControlLabel
                                            control={
                                                <Checkbox
                                                    checked={coinState.Others}
                                                    onChange={handleCheckBoxChange}
                                                    name="Others"
                                                    value={coinState.Others}
                                                />
                                            }
                                            label="Others"
                                        />
                                    </FormGroup>
                                </div>

                                <div className="contact__selectCoinContainer">
                                    <p className="contact__subtitle-dark">{t('wealth_tradeType')}</p>
                                    <FormGroup row className="contact__SelectionRow" m="12rem">

                                        <FormControlLabel
                                            control={
                                                <Checkbox
                                                    checked={buyOrSellState.BUY}
                                                    onChange={handleBuyOrSellCheckBoxChange}
                                                    name="BUY"
                                                    value={buyOrSellState.BUY}

                                                />
                                            }
                                            label={t('buy')}
                                        />

                                        <FormControlLabel
                                            control={
                                                <Checkbox
                                                    checked={buyOrSellState.SELL}
                                                    onChange={handleBuyOrSellCheckBoxChange}
                                                    name="SELL"
                                                    value={buyOrSellState.ETH}

                                                // color="primary"
                                                />
                                            }
                                            label={t('sell')}
                                        />


                                    </FormGroup>
                                </div>
                                <div style={{ width: '100%', marginTop: 64, display: 'flex', justifyContent: 'center', marginBottom: 40 }}>
                                    <Button onClick={handleSendEmail} disabled={firstNameState !== "" && lastNameState !== "" && phoneState !== "" && emailState !== "" && isValidEmailAddress ? false : true} className={firstNameState !== "" && lastNameState !== "" && phoneState !== "" && emailState !== "" && isValidEmailAddress ? classes.submitEnable : classes.submitDisable}>
                                        {/* {t("quote_confirm")} */}


                                        <Typography style={{ fontSize: 20, color: 'white', fontWeight: '400', fontFamily: 'Avenir', }}>Get Started</Typography>
                                        <img src={enterButtonIcon} style={{ height: 16, marginLeft: 12 }}></img>
                                    </Button>
                                </div>


                            </div>
                        </div>

                    </div>

                </div>
                :
                <div className="contact__row"
                    style={{ display: 'flex', flexDirection: 'column' }}
                >
                    <div style={{
                        width: '100%', height: 686, backgroundImage: `url(${WealthBanner})`,
                        backgroundPosition: 'center',
                        backgroundSize: 'cover',
                        backgroundRepeat: 'no-repeat',
                        display: 'flex', alignItems: 'center'
                    }}>

                        <div style={{ marginLeft: '12%' }}>
                            <Typography style={{ fontSize: 40, color: "white", fontWeight: 'bold', fontFamily: 'Avenir', width: 600 }}>
                                {t('wealth_title')}
                            </Typography>
                            <Typography style={{width:'70%', fontSize: 20, marginTop: 24, color: '#b1b2bb', fontWeight: '400', fontFamily: 'Avenir', }}>
                                {t('wealth_subtitle')}
                            </Typography>
                            <div style={{ display: 'flex', marginTop: 60, height: 80 }}>
                                <Button onClick={() => { window.scrollTo({behavior:'smooth',top:signUp.current.offsetTop}) }} style={{ backgroundColor: '#202129', borderRadius: 0, paddingLeft: 24, paddingRight: 24 }}>
                                    <Typography style={{ fontSize: 20, color: 'white', fontWeight: 'bold', fontFamily: 'Avenir' }}>
                                        {t('wealth_startButton')}
                                    </Typography>
                                    <img src={enterButtonIcon} style={{ height: 16, marginLeft: 12 }}></img>

                                </Button>

                                <div style={{ border: "1px solid #202129", justifyContent: 'center', alignItems: 'flex-start', display: 'flex', flexDirection: 'column', paddingLeft: 24, paddingRight: 24 }}>
                                    <Typography style={{ fontSize: 20, color: '#8B96A7', fontWeight: '400', fontFamily: 'Avenir' }}>
                                        {t('wealth_emailButton')}
                                    </Typography>
                                    <Typography style={{ fontSize: 20, color: '#8B96A7', fontWeight: '400', fontFamily: 'Avenir' }}>
                                        otc@yesbit.ca
                                    </Typography>

                                </div>
                            </div>
                            {/* <p className={'contact__subtitle'}>
                            {t("quote_Subtitle")}
                        </p> */}
                        </div>


                    </div>
                    <div style={{
                        width: '100%', height: 300, backgroundImage: `url(${WealthBanner2})`,
                        backgroundPosition: 'center',
                        backgroundSize: 'cover',
                        backgroundRepeat: 'no-repeat',
                        display: 'flex', alignItems: 'center'
                    }}>

                        <div style={{ marginLeft: '12%', width: '55%' }}>
                            <Typography style={{ fontSize: 40, color: 'white', fontWeight: 'bold', fontFamily: 'Avenir', width: 600 }}>
                                {t('wealth_sec1')}
                            </Typography>
                            <Typography style={{ fontSize: 20, marginTop: 24, color: 'white', fontWeight: '400', fontFamily: 'Avenir', }}>
                                {t('wealth_sec1Subtitle')}
                            </Typography>


                        </div>
                        <div style={{ marginRight: '12%', marginLeft: '4%', display: 'flex', alignItems: 'flex-end', justifyContent: 'flex-end' }}>
                            <img style={{ height: 80 }} src={yesbitComWhiteLogo}></img>


                        </div>

                    </div>

                    <div style={{ width: '60%', marginTop: 24 }}>
                        <Button style={{ width: '50%', textTransform: 'none', height: 80 }} onClick={() => setButtonStatus(1)}>
                            <Typography style={{ fontSize: 20, color: buttonStatus === 1 ? "black" : '#D8d8d8', fontWeight: '400', fontFamily: 'Avenir', }}>
                                {t('wealth_cryptocurrency')}
                            </Typography>
                        </Button>

                        <Button style={{ width: '50%', textTransform: 'none', height: 80 }} onClick={() => setButtonStatus(2)}>
                            <Typography style={{ fontSize: 20, color: buttonStatus === 2 ? "black" : '#D8d8d8', fontWeight: '400', fontFamily: 'Avenir', }}>
                                {t('wealth_fiat')}
                            </Typography>
                        </Button>


                        <div style={{ height: 6, borderRadius: 3, width: "100%", backgroundColor: '#d8d8d8', marginTop: -6, display: 'flex' }}>
                            <div className={buttonStatus === 1 ? classes.buttonSelect : classes.buttonUnselect}></div>

                            <div className={buttonStatus === 2 ? classes.buttonSelect : classes.buttonUnselect}></div>
                        </div>

                    </div>
                    {
                        buttonStatus === 1 ?
                            <div style={{ width: '60%', marginTop: 24, display: 'flex', justifyContent: 'space-between' }}>
                                <div style={{ width: "31%", backgroundColor: '#efefef', height: 100, borderRadius: 16, display: 'flex', alignItems: 'center' }}>
                                    <img src={BitcoinBlack} style={{ height: 60, marginLeft: 20 }}></img>
                                    <div style={{ marginLeft: 16 }}>
                                        <Typography style={{ fontSize: 16, color: '#858e9d', fontWeight: '400', fontFamily: 'Avenir', }}>{t("bitCoin")}</Typography>
                                        <Typography style={{ fontSize: 24, color: 'black', fontWeight: 'Bold', fontFamily: 'Avenir', }}>BTC</Typography>
                                    </div>

                                </div>

                                <div style={{ width: "31%", backgroundColor: '#efefef', height: 100, borderRadius: 16, display: 'flex', alignItems: 'center' }}>
                                    <img src={USDTBlack} style={{ height: 60, marginLeft: 20 }}></img>
                                    <div style={{ marginLeft: 16 }}>
                                        <Typography style={{ fontSize: 16, color: '#858e9d', fontWeight: '400', fontFamily: 'Avenir', }}>{t("Tether")}</Typography>
                                        <Typography style={{ fontSize: 24, color: 'black', fontWeight: 'Bold', fontFamily: 'Avenir', }}>USDT</Typography>
                                    </div>

                                </div>

                                <div style={{ width: "31%", backgroundColor: '#efefef', height: 100, borderRadius: 16, display: 'flex', alignItems: 'center' }}>
                                    <img src={ETHBlack} style={{ height: 60, marginLeft: 20 }}></img>
                                    <div style={{ marginLeft: 16 }}>
                                        <Typography style={{ fontSize: 16, color: '#858e9d', fontWeight: '400', fontFamily: 'Avenir', }}>{t("Ethereum")}</Typography>
                                        <Typography style={{ fontSize: 24, color: 'black', fontWeight: 'Bold', fontFamily: 'Avenir', }}>ETH</Typography>
                                    </div>

                                </div>

                            </div>
                            :

                            <div style={{ width: '60%', marginTop: 24, display: 'flex', justifyContent: 'space-between' }}>
                                <div style={{ width: "49%", backgroundColor: '#efefef', height: 100, borderRadius: 16, display: 'flex', alignItems: 'center' }}>
                                    <img src={CADBlack} style={{ height: 60, marginLeft: 20 }}></img>
                                    <div style={{ marginLeft: 16 }}>
                                        <Typography style={{ fontSize: 16, color: '#858e9d', fontWeight: '400', fontFamily: 'Avenir', }}>{t('cad')}</Typography>
                                        <Typography style={{ fontSize: 24, color: 'black', fontWeight: 'Bold', fontFamily: 'Avenir', }}>CAD</Typography>
                                    </div>

                                </div>

                                <div style={{ width: "49%", backgroundColor: '#efefef', height: 100, borderRadius: 16, display: 'flex', alignItems: 'center' }}>
                                    <img src={USDBlack} style={{ height: 40, marginLeft: 20 }}></img>
                                    <div style={{ marginLeft: 16 }}>
                                        <Typography style={{ fontSize: 16, color: '#858e9d', fontWeight: '400', fontFamily: 'Avenir', }}>{t('usd')}</Typography>
                                        <Typography style={{ fontSize: 24, color: 'black', fontWeight: 'Bold', fontFamily: 'Avenir', }}>USD</Typography>
                                    </div>

                                </div>


                            </div>
                    }

                    <div ref={signUp} style={{
                        backgroundColor: 'white', width: "60%", marginTop: 80, borderRadius: 16, display: 'flex', alignItems: 'center', marginBottom: 100, flexDirection: 'column', boxShadow: "0 2px 20px 0 rgba(200,200,200,0.5)"
                    }} >
                        <Typography style={{ marginTop: 40, fontSize: 24, color: 'black', fontWeight: 'bold', fontFamily: 'Avenir', }}>{t('wealth_SignUp')}</Typography>
                        <div style={{ width: "91%" }} >
                            <div className='contact__textInputAreaWrapper'>
                                <Typography style={{ marginTop: 24, fontSize: 20, color: '#8B96A7', fontWeight: '400', marginBottom: 12, fontFamily: 'Avenir', }}>{t('quote_ContactInfo')}</Typography>
                                <div style={{ width: "100%", display: 'flex', justifyContent: 'space-between' }}>
                                    <TextField style={{ width: "48%" }} value={firstNameState} id="outlined-basic" label={t('quote_firstName')} variant="outlined" type="text" name="firstName" margin='normal' onChange={handleFirstNameInput} />
                                    <TextField style={{ width: "48%" }} value={lastNameState} id="outlined-basic" label={t('quote_lastName')} variant="outlined" type="text" name="lastName" margin='normal' onChange={handleLastNameInput} />
                                </div>

                                <div style={{ width: "100%", display: 'flex', justifyContent: 'space-between', marginTop: 24 }}>
                                    <TextField style={{ width: "48%" }} error={!isValidEmailAddress} helperText={isValidEmailAddress ? null : t('email_error')} value={emailState} id="outlined-basic" label={t('quote_email')} variant="outlined" type="email" name="email" margin='normal' fullWidth onChange={handleEmailInput} />
                                    <TextField style={{ width: "48%" }} value={phoneState} id="outlined-basic" label={t('quote_telephone')} variant="outlined" type="text" name="telephone" margin='normal' fullWidth onChange={handlePhoneInput} />
                                </div>
                            </div>
                            <div style={{ width: '100%', height: 1, backgroundColor: '#EFEFEF', marginTop: 80, marginBottom: 40 }}></div>
                            <div className='contact__textInputAreaWrapper' style={{ marginTop: 40 }}>
                                <Typography style={{ marginTop: 24, fontSize: 20, color: '#8B96A7', fontWeight: '400', marginBottom: 12, fontFamily: 'Avenir', }}>{t('quote_detail')}</Typography>
                                <FormControl variant="outlined" className="contact__pickerWrapper" margin='normal'>
                                    <InputLabel >{t('quote_transaction')}</InputLabel>

                                    <Select
                                        labelId="transaction"
                                        value={transaction}
                                        onChange={handleSelectChange}
                                        label={t('quote_transaction')}
                                        name="transaction"
                                    >
                                        <MenuItem value="None">
                                            <em>{t('quote_transaction_notSure')}</em>
                                        </MenuItem>
                                        <MenuItem value={"10k-100k"}> {t('quote_transaction_10k')}</MenuItem>
                                        <MenuItem value={"100k-500k"}>{t('quote_transaction_100k')}</MenuItem>
                                        <MenuItem value={"over 500k"}>{t('quote_transaction_500k')}</MenuItem>
                                    </Select>
                                </FormControl>

                            </div>




                            <div className="contact__selectCoinContainer">
                                <p className="contact__subtitle-dark">{t('quote_coinType')}</p>
                                <FormGroup row className="contact__SelectionRow" m="12rem">

                                    <FormControlLabel
                                        control={
                                            <Checkbox
                                                checked={coinState.BTC}
                                                onChange={handleCheckBoxChange}
                                                name="BTC"
                                                value={coinState.BTC}

                                            />
                                        }
                                        label="BTC"
                                    />

                                    <FormControlLabel
                                        control={
                                            <Checkbox
                                                checked={coinState.ETH}
                                                onChange={handleCheckBoxChange}
                                                name="ETH"
                                                value={coinState.ETH}

                                            // color="primary"
                                            />
                                        }
                                        label="ETH"
                                    />

                                    <FormControlLabel
                                        control={
                                            <Checkbox
                                                checked={coinState.USDT}
                                                onChange={handleCheckBoxChange}
                                                name="USDT"
                                                value={coinState.USDT}
                                            />
                                        }
                                        label="USDT"
                                    />


                                    <FormControlLabel
                                        control={
                                            <Checkbox
                                                checked={coinState.Others}
                                                onChange={handleCheckBoxChange}
                                                name="Others"
                                                value={coinState.Others}
                                            />
                                        }
                                        label="Others"
                                    />
                                </FormGroup>
                            </div>

                            <div className="contact__selectCoinContainer">
                                <p className="contact__subtitle-dark">{t('wealth_tradeType')}</p>
                                <FormGroup row className="contact__SelectionRow" m="12rem">

                                    <FormControlLabel
                                        control={
                                            <Checkbox
                                                checked={buyOrSellState.BUY}
                                                onChange={handleBuyOrSellCheckBoxChange}
                                                name="BUY"
                                                value={buyOrSellState.BUY}

                                            />
                                        }
                                        label={t('buy')}
                                    />

                                    <FormControlLabel
                                        control={
                                            <Checkbox
                                                checked={buyOrSellState.SELL}
                                                onChange={handleBuyOrSellCheckBoxChange}
                                                name="SELL"
                                                value={buyOrSellState.ETH}

                                            // color="primary"
                                            />
                                        }
                                        label={t('sell')}
                                    />


                                </FormGroup>
                            </div>
                            <div style={{ width: '100%', marginTop: 64, display: 'flex', justifyContent: 'flex-end', marginBottom: 40 }}>
                                <Button onClick={handleSendEmail} disabled={firstNameState !== "" && lastNameState !== "" && phoneState !== "" && emailState !== "" && isValidEmailAddress ? false : true} className={firstNameState !== "" && lastNameState !== "" && phoneState !== "" && emailState !== "" && isValidEmailAddress ? classes.submitEnable : classes.submitDisable}>
                                    {/* {t("quote_confirm")} */}


                                    <Typography style={{ fontSize: 20, color: 'white', fontWeight: '400', fontFamily: 'Avenir', }}>{t('wealth_startButton')}</Typography>
                                    <img src={enterButtonIcon} style={{ height: 16, marginLeft: 12 }}></img>
                                </Button>
                            </div>


                        </div>
                    </div>

                </div>
            }




        </>


    )
}

export default withTranslation()(Wealth)
