import { withTranslation } from 'react-i18next';
import React, {useEffect, useState, useRef} from "react";
import { useHistory } from 'react-router-dom';
import {Helmet} from "react-helmet";
import {
    Button,
    makeStyles,
    Typography,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    withStyles,
    styled,
    Grid,
    Box
} from "@material-ui/core";
import otcYesbit from '../../images/nft/Group 3.png'
import arrow from '../../images/nft/Group 4.png'
import arrowPhone from '../../images/nft/Group 4 Phone.png'
import nft1 from '../../images/nft/nft1.png'
import nft2 from '../../images/nft/nft2.png'
import nft3 from '../../images/nft/nft3.png'
import fire from '../../images/nft/fire.png'
import infoIcon from '../../images/nft/Bitmap.png'
import vouchersWhite from '../../images/nft/voucherWhite.png'
import vouchersBlack from '../../images/nft/voucherBlack.png'
import vouchersRefer from '../../images/nft/Group 16.png'
import vouchersTransfer from '../../images/nft/Group 20.png'
import inviteIcon from '../../images/invIcon.png'
import './Nft.css'
import NftDetailsModal from "./components/NftDetailsModal";
import i18n from "../../i18n";
import {useDispatch, useSelector} from "react-redux";
import {authActions} from "../../redux/actions/authActions";
import {referralActions} from "../../redux/actions/referralActions";
import {kycActions} from "../../redux/actions/kycActions";
import VoucherTransferModal from "./components/VoucherTransferModal";
import VoucherReceiveModal from "./components/VoucherReceiveModal";
import TwoFARequired from "./components/TwoFARequired";
import ClaimNFTModal from "./components/ClaimNFTModal";
import {nft} from "../../redux/reducers/nft";
import {nftActions} from "../../redux/actions/nftActions";
import {roundingDown} from "../../utils/Common";
let timeout;
function Nft({ t, width }) {
    const [phone, setPhone] = useState(width <= 745);
    const { kycDetails, fetched } = useSelector(state => state.kyc);
    const { token, userInfo } = useSelector(state => state.auth)
    const { commissionAndNft, nftClaimed, nftAsset, countdown } = useSelector(state => state.nft)
    const [tabSelected, setTabSelected] = useState("nft");
    const [nftDetailsVisible, setNftDetailsVisible] = useState(false);
    const [nftDetails, setNftDetails] = useState('nft1');
    const [voucherTransferVisible, setVoucherTransferVisible] = useState(false);
    const [voucherReceiveVisible, setVoucherReceiveVisible] = useState(false);
    const [twoFARequiredVisible, setTwoFARequiredVisible] = useState(false);
    const [claimNFTVisible, setClaimNFTVisible] = useState(false);
    const [countDown, setCountDown] = useState({});
    const [remainingNft, setRemainingNft] = useState(0);
    const [loadingNftAsset, setLoadingNftAsset] = useState(false);

    const scrollToVouchers = useRef(null);
    const history = useHistory();
    const dispatch = useDispatch();
    const StyledTableCell = withStyles((theme) => ({
        head: {
            color: '#1C2237',
            fontWeight: phone ? 900 : 400,
            fontFamily: 'Avenir',
            fontSize: phone? 12 : 16,
        },
        body: {
            color: '#1C2237',
            fontWeight: phone ? 500 : 900,
            fontFamily: 'Avenir',
            fontSize: phone ? 12 : 24,
            paddingTop:phone? null:33,
            paddingBottom:phone? null:33
        },
    }))(TableCell);

    const StyledTableRow = styled(TableRow)(({ theme }) => ({
        // hide last border
        '&:last-child td, &:last-child th': {
            border: 0,
        },
    }));
    const useStyles = makeStyles({
        font: {
            color: '#1C2237',
            fontFamily: 'Avenir',
            fontSize: phone?14:16,
            letterSpacing: 0,
            textAlign: phone?"left":'center',
        },
        tabSelected:{
            color: "#1C2237",
            fontFamily: "Avenir",
            fontSize: phone?18:28,
            fontWeight: 900,
            textTransform:"none"
        },
        tab:{
            color: "#8B96A7",
            fontFamily: "Avenir",
            fontSize: phone?14:20,
            fontWeight: 500,
            textTransform:"none"
        }
    })
    const classes = useStyles();
    function createData(name, tradable, fee, liquidity, commissions,) {
        return { name, tradable, fee, liquidity, commissions };
    }
    function createPhoneData(name, nft, regular, rare) {
        return { name, nft, regular, rare };
    }
    const rows = phone ?
        [
            createPhoneData(t('nftTradable'), t('nftNo'), t('nftYes'), t('nftYes')),
            createPhoneData(t('nftWithdrawFee'), t('nftRegularFee'), t('nftRegularFee'), t('nftFree')),
            createPhoneData(t('nftLiquiditySpread'), "1%", "1%", "0.67%"),
            createPhoneData(t('nftCommission'), "30%", "30%", "50%"),
        ]
        :
        [
            createData(t('nftNoNft'), t('nftNo'), t('nftRegularFee'), "1%", "30%"),
            createData(t('nftRegular'), t('nftYes'), t('nftRegularFee'), "1%", "30%"),
            createData(t('nftRare'), t('nftYes'), t('nftFree'), "0.67%", "50%"),
        ];

    const loadings =()=>{
        dispatch(authActions.getUserInfo(token))
        dispatch(nftActions.getCommissionAndNft(token))
        dispatch(nftActions.getNftClaimed(token))
        dispatch(nftActions.getNftAsset(token))
        setLoadingNftAsset(true)
    }
    useEffect(() => {
        loadings()
        dispatch(kycActions.syncKycStatus(token))
        dispatch(nftActions.getCountdown(token))
        return () => {
            clearInterval(timeout);
            dispatch(nftActions.clearCountdown())
        }
    }, []);

    useEffect(() => {
        if (fetched) {
            dispatch(kycActions.getKycDetails(token))
        }
    }, [fetched])

    useEffect(() => {
        if (loadingNftAsset && nftAsset.length > 0) {
            setTabSelected("myNft")
            setLoadingNftAsset(false);
        }
    }, [ nftAsset])

    useEffect(() => {
        var countDownDate = new Date(countdown.mintStartTime).getTime();
        var now = new Date(countdown.systemTime).getTime();
        var distance = countDownDate - now;
        if(distance>0){
            setCountDown(
                {
                    distance,
                    days:Math.floor(distance / (1000 * 60 * 60 * 24)),
                    hours:Math.floor((distance % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60)),
                    minutes:Math.floor((distance % (1000 * 60 * 60)) / (1000 * 60)),
                }
            );
            timeout = setInterval(function() {
                distance -= 1000
                setCountDown(
                    {
                        distance,
                        days:Math.floor(distance / (1000 * 60 * 60 * 24)),
                        hours:Math.floor((distance % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60)),
                        minutes:Math.floor((distance % (1000 * 60 * 60)) / (1000 * 60)),
                    }
                );

                if (distance <= 0) {
                    clearInterval(timeout);
                }
            },1000)
        }
    }, [countdown])

    useEffect(() => {
        setPhone(width <= 745)
        if (width <= 745){
            if (Math.floor(width*0.9/170) - nftAsset.length%Math.floor(width*0.9/170) === Math.floor(width*0.9/170)){
                setRemainingNft(0)
            }else{
                setRemainingNft( Math.floor(width*0.9/170) - nftAsset.length%Math.floor(width*0.9/170))
            }
        }else{
            if (Math.floor(width*0.8/322) - nftAsset.length%Math.floor(width*0.8/322) === Math.floor(width*0.8/322)){
                setRemainingNft(0)
            }else{
                setRemainingNft( Math.floor(width*0.8/322) - nftAsset.length%Math.floor(width*0.8/322))
            }
        }

    }, [width, nftAsset]);

    function renderRestNft() {
        let result = []
        for(let i = 0; i < remainingNft; i++){
            result.push(<div style={{width:phone?170:322}}></div>)
        }
        return result
    }

    function handleScrollClick() {
        setTabSelected("vouchers");
        scrollToVouchers.current.scrollIntoView()
    }
    const renderHeader = () => {
        return(
            <div style={{display: 'contents', alignItems: 'center', justifyContent: 'center'}}>
                <div style={{display: phone ?"block":"flex", position:phone?null:"relative"}}>
                    <Typography className={classes.font} style={{fontSize:phone?28:54, fontWeight:900}}>
                        {t('nftVirtualHead')}
                    </Typography>
                    <div style={{position:phone?null:"absolute",display:"flex", width:"100%", marginLeft:phone?0:i18n.language === 'en' ? 340 :230, bottom:phone?"none":16, textOverflow:"clip", alignItems:"center", justifyContent:phone?"center":null}}>
                        <Typography noWrap={true} className={classes.font} style={{ display:"contents", fontWeight:900}}>
                            NFT by
                        </Typography>
                        <img src={otcYesbit} style={{marginLeft:5, height:phone?13:14, width:phone?68:73}}/>
                    </div>

                </div>
                {
                    countDown.distance > 0 ?
                        <>
                            <Typography className={classes.font} style={{marginTop:phone?24:10, fontSize:14}}>
                                {t('nftCountdownTitle')}
                            </Typography>
                            <div style={{display:"flex", marginTop:10}}>
                                <div style={{
                                    height: phone?61:101,
                                    width: phone?59:99,
                                    borderRadius: phone?10:16,
                                    background: "linear-gradient(227.05deg, #FF0069 0%, #2B00FF 100%)",
                                    display:"flex",
                                    flexDirection:"column",
                                    justifyContent:"center",
                                    alignItems:"center"
                                }}>
                                    <Typography className={classes.font} style={{fontSize:phone?26:43, color:"white", fontWeight:900,lineHeight: phone?'25px':'40px'}}>
                                        {countDown.days}
                                    </Typography>
                                    <Typography className={classes.font} style={{fontSize:phone?6:10, color:"white"}}>
                                        {t('nftDays')}
                                    </Typography>
                                </div>

                                <div style={{
                                    height: phone?61:101,
                                    width: phone?59:99,
                                    borderRadius: phone?10:16,
                                    backgroundColor: "#F1F1F1",
                                    marginLeft:phone?8:12,
                                    display:"flex",
                                    flexDirection:"column",
                                    justifyContent:"center",
                                    alignItems:"center"
                                }}>
                                    <Typography className={classes.font} style={{fontSize:phone?26:43, fontWeight:900,lineHeight: phone?'25px':'40px'}}>
                                        {countDown.hours}
                                    </Typography>
                                    <Typography className={classes.font} style={{fontSize:phone?6:10}}>
                                        {t('nftHours')}
                                    </Typography>
                                </div>

                                <div style={{
                                    height: phone?61:101,
                                    width: phone?59:99,
                                    borderRadius: phone?10:16,
                                    backgroundColor: "#F1F1F1",
                                    marginLeft:phone?8:12,
                                    display:"flex",
                                    flexDirection:"column",
                                    justifyContent:"center",
                                    alignItems:"center"
                                }}>
                                    <Typography className={classes.font} style={{fontSize:phone?26:43, fontWeight:900,lineHeight: phone?'25px':'40px'}}>
                                        {countDown.minutes}
                                    </Typography>
                                    <Typography className={classes.font} style={{fontSize:phone?6:10}}>
                                        {t('nftMinutes')}
                                    </Typography>
                                </div>
                            </div>
                        </>

                        :
                        <>
                            <Typography className={classes.font} style={{marginTop:phone?24:0, color:"#858E9D", fontFamily:"PingFang SC", textAlign:"center"}}>
                                {t('nftHomeSubTitle')}
                            </Typography>
                            <Button
                                style={{marginTop:phone?13:28,height:phone?30:40, width:phone?114:157, borderRadius: phone?17:24, background:"linear-gradient(227.05deg, #FF0069 0%, #2B00FF 100%)"}}
                                onClick={()=>{i18n.language === 'en' ? window.open("https://otcdocs.yesbit.io/faq/virtual-head-nft") : window.open("https://otcdocs.yesbit.io/v/jian-ti-zhong-wen/faq/yi-neng-he-zi-nft") }}
                            >
                                <Typography className={classes.font} style={{color:"white", textTransform:"none", fontSize:phone?14:20, fontWeight:500}}>
                                    {t('nftLearnMore')}
                                </Typography>
                                <img src={arrow} style={{marginLeft:5, height:phone?14:21, width:phone?14:21}}/>
                            </Button>
                        </>

                }

            </div>
        );
    }

    const renderNftDisplay = () => {
        return(
            <div style={{display: 'flex',width:"100%",marginTop:90, alignItems: 'flex-end', justifyContent: 'space-between'}}>
                <div style={{height: 432, width: 328, borderRadius: 26, boxShadow: "0 2px 20px 0 rgba(200,200,200,0.5)"}}>
                    <div style={{margin:24}}>
                        <img src={nft1} style={{height:280, width:280, cursor: "pointer"}} onClick={()=>{setNftDetailsVisible(true); setNftDetails("nft1")}}/>
                        <div style={{display:"flex", justifyContent:"space-between", alignItems:"center", marginTop:13}}>
                            <Typography className={classes.font} style={{fontSize:24, fontWeight:900}}>
                                {t('nftRegular')}
                            </Typography>
                            <Typography className={classes.font} style={{color:"#8B96A7"}}>
                                {t('nftClaim')}
                                <Typography className={classes.font} style={{display:"inline"}}> {nftClaimed.regularNftClaimed ? nftClaimed.regularNftClaimed : 0} </Typography>
                            </Typography>
                        </div>
                        <div style={{display:"flex", justifyContent:"space-between", alignItems:"center", marginTop:13}}>
                            <Button
                                style={{height:48, width:60, minWidth:48, borderRadius:14, backgroundColor:"#F2F2F2"}}
                                onClick={()=>{setNftDetailsVisible(true); setNftDetails("nft1")}}
                            >
                                <img src={infoIcon} style={{width:22, height:22}}/>
                            </Button>
                            <Button
                                style={{height:48, width:"75%", borderRadius:14, backgroundColor:"#003FFF", opacity:countDown.distance > 0 ? 0.4:1}}
                                onClick={()=>{setClaimNFTVisible(true);setNftDetails("nft1")}}
                                disabled={countDown.distance > 0}
                            >
                                <img src={vouchersWhite} style={{height:20, width:20, marginRight:8}}/>
                                <Typography className={classes.font} style={{color:"white", fontSize:20, textTransform:"none"}}>
                                    1 {t('nftVoucher')}
                                </Typography>
                            </Button>
                        </div>
                    </div>
                </div>
                <div style={{height: 462, width: 328, borderRadius: 26, boxShadow: "0 2px 20px 0 rgba(200,200,200,0.5)"}}>
                    <div style={{margin:24}}>
                        <div style={{position:"relative"}}>
                            <img src={nft2} style={{height:280, width:280, cursor: "pointer"}} onClick={()=>{setNftDetailsVisible(true); setNftDetails("nft2")}}/>
                            <img src={fire} style={{position:"absolute" , width:42, height:42, bottom:-20, right:20}}/>
                        </div>
                        <div style={{display:"flex", justifyContent:"space-between", alignItems:"center", marginTop:32}}>
                            <Typography className={classes.font} style={{fontSize:30, fontWeight:900}}>
                                {t('nftRare')}
                            </Typography>
                            <Typography className={classes.font} style={{color:"#8B96A7"}}>
                                {t('nftClaim')}
                                <Typography className={classes.font} style={{display:"inline"}}> {nftClaimed ? nftClaimed.rareNftClaimed+'/'+nftClaimed.rareNftTotal : 0} </Typography>
                            </Typography>
                        </div>
                        <div style={{display:"flex", justifyContent:"space-between", alignItems:"center", marginTop:13}}>
                            <Button
                                style={{height:48, width:60, minWidth:48, borderRadius:14, backgroundColor:"#F2F2F2"}}
                                onClick={()=>{setNftDetailsVisible(true); setNftDetails("nft2")}}
                            >
                                <img src={infoIcon} style={{width:22, height:22}}/>
                            </Button>
                            <Button
                                style={{height:48, width:"75%", borderRadius:14, backgroundColor:"#003FFF", opacity:countDown.distance > 0 ? 0.4:1}}
                                onClick={()=>{setClaimNFTVisible(true);setNftDetails("nft2")}}
                                disabled={countDown.distance > 0}
                            >
                                <img src={vouchersWhite} style={{height:20, width:20, marginRight:8}}/>
                                <Typography className={classes.font} style={{color:"white", fontSize:20, textTransform:"none"}}>
                                    10 {t('nftVouchers')}
                                </Typography>
                            </Button>
                        </div>
                    </div>
                </div>
                <div style={{height: 432, width: 328, borderRadius: 26, boxShadow: "0 2px 20px 0 rgba(200,200,200,0.5)", fontWeight:500}}>
                    <div style={{margin:24}}>
                        <img src={nft3} style={{height:280, width:280}}/>
                        <Typography className={classes.font} style={{color:"#8B96A7", fontSize:18, marginTop:52}}>
                            {t('nft3Sub')}
                        </Typography>
                    </div>
                </div>
            </div>
        );
    }

    const renderNftDisplayPhone = () =>{
        return (
            <div style={{width:"100%", display:"flex", flexDirection:"column"}}>
                <div style={{marginTop:52, alignItems: "center", height: 130, width: "100%", borderRadius: 16, boxShadow: "0 1px 6px 0 rgba(200,200,200,0.5)"}}>
                    <div style={{display:"flex",margin:20}}>
                        <img src={nft1} style={{height:90, width:90, cursor: "pointer"}} onClick={()=>{setNftDetailsVisible(true); setNftDetails("nft1")}}/>
                        <div style={{display:"flex", flexDirection:"column", justifyContent: "space-between", alignItems:"center", width:"100%", marginLeft:12}}>
                            <div style={{display:"flex", justifyContent:"space-between", width:"100%"}}>
                                <div>
                                    <Typography className={classes.font} style={{fontSize:20, fontWeight:900, lineHeight:"27px"}}>
                                        {t('nftRegular')}
                                    </Typography>
                                    <Typography className={classes.font} style={{fontSize:12, color:"#8B96A7"}}>
                                        {t('nftClaim')}
                                        <Typography className={classes.font} style={{fontSize:12, display:"inline"}}> {nftClaimed.regularNftClaimed ? nftClaimed.regularNftClaimed : 0} </Typography>
                                    </Typography>
                                </div>

                                <img src={infoIcon} style={{width:20, height:20, cursor: "pointer"}} onClick={()=>{setNftDetailsVisible(true); setNftDetails("nft1")}}/>
                            </div>

                            <Button
                                style={{height:35, width:"100%", borderRadius:8, backgroundColor:"#003FFF", opacity:countDown.distance > 0 ? 0.4:1}}
                                onClick={()=>{setClaimNFTVisible(true);setNftDetails("nft1")}}
                                disabled={countDown.distance > 0}
                            >
                                <img src={vouchersWhite} style={{height:16, width:16, marginRight:8}}/>
                                <Typography className={classes.font} style={{color:"white", fontSize:16, textTransform:"none"}}>
                                    1 {t('nftVoucher')}
                                </Typography>
                            </Button>
                        </div>
                    </div>
                </div>
                <div style={{marginTop:52, alignItems: "center", height: 130, width: "100%", borderRadius: 16, boxShadow: "0 1px 6px 0 rgba(200,200,200,0.5)"}}>
                    <div style={{display:"flex", margin:20}}>
                        <div style={{position:"relative", height:90, width:90, cursor: "pointer"}} onClick={()=>{setNftDetailsVisible(true);setNftDetails("nft2")}}>
                            <img src={nft2} style={{height:90, width:90}}/>
                            <img src={fire} style={{position:"absolute" , width:27, height:27, bottom:-13, right:5}}/>
                        </div>
                        <div style={{display:"flex", flexDirection:"column", justifyContent: "space-between", alignItems:"center", flexGrow:1, marginLeft:12}}>
                            <div style={{display:"flex", justifyContent:"space-between", width:"100%"}}>
                                <div>
                                    <Typography className={classes.font} style={{fontSize:20, fontWeight:900, lineHeight:"27px"}}>
                                        {t('nftRare')}
                                    </Typography>
                                    <Typography className={classes.font} style={{fontSize:12, color:"#8B96A7"}}>
                                        {t('nftClaim')}
                                        <Typography className={classes.font} style={{fontSize:12, display:"inline"}}> {nftClaimed ? nftClaimed.rareNftClaimed+'/'+nftClaimed.rareNftTotal : 0} </Typography>
                                    </Typography>
                                </div>

                                <img src={infoIcon} style={{width:20, height:20, cursor: "pointer"}} onClick={()=>{setNftDetailsVisible(true);setNftDetails("nft2")}}/>
                            </div>

                            <Button
                                style={{height:35, width:"100%", borderRadius:8, backgroundColor:"#003FFF", opacity:countDown.distance > 0 ? 0.4:1}}
                                onClick={()=>{setClaimNFTVisible(true);setNftDetails("nft2")}}
                                disabled={countDown.distance > 0}
                            >
                                <img src={vouchersWhite} style={{height:16, width:16, marginRight:8}}/>
                                <Typography className={classes.font} style={{color:"white", fontSize:16, textTransform:"none"}}>
                                    10 {t('nftVouchers')}
                                </Typography>
                            </Button>
                        </div>
                    </div>
                </div>
                <div style={{marginTop:52, alignItems: "center", height: 130, width: "100%", borderRadius: 16, boxShadow: "0 1px 6px 0 rgba(200,200,200,0.5)"}}>
                    <div style={{display:"flex", margin:20}}>
                        <img src={nft3} style={{height:90, width:90}}/>
                        <Typography className={classes.font} style={{color:"#8B96A7", fontSize:18, width:"100%", margin:"auto",textAlign:"center"}}>
                            {t('nft3Sub')}
                        </Typography>
                    </div>
                </div>
            </div>

        )
    }
    const renderAccountDetails = () => {
        return(
            <div style={{width:"100%",marginTop:90, boxSizing: "border-box", border:" 1px solid #BAC3CE", borderRadius: 24}}>
                <div style={{margin:"35px 45px"}}>
                    <div style={{display:"flex", justifyContent:"space-between", alignItems:"baseline"}}>
                        <div>
                            <Typography className={classes.font} style={{fontWeight:900,textAlign:"Left"}}>
                                {t('nftMyAccount')}
                            </Typography>
                            <div style={{display:"flex", alignItems:"baseline"}}>
                                <img src={vouchersBlack} style={{marginLeft:5, height:36, width:36, marginRight:8}}/>
                                <Typography className={classes.font} style={{fontSize:40, fontWeight:900, marginRight:8}}>
                                    {userInfo.voucher}
                                </Typography>
                                <Typography className={classes.font} style={{fontSize:20, fontWeight:900}}>
                                    {t('nftVouchers')}
                                </Typography>
                            </div>
                        </div>
                        <Button
                            style={{height:35, width:159, borderRadius:17.5, backgroundColor:"#003FFF"}}
                            onClick={handleScrollClick}
                        >
                            <Typography className={classes.font} style={{color:"white", textTransform:"none"}}>
                                {t('nftMoreVouchers')}
                            </Typography>
                            <img src={arrow} style={{marginLeft:5, height:18, width:18}}/>
                        </Button>
                    </div>
                    <div style={{display:"flex", justifyContent:"space-between", alignItems:"center"}}>
                        <div>
                            <Typography className={classes.font} style={{color:"#8B96A7", textAlign:"Left"}}>
                                {t('nftHighestLevel')}
                            </Typography>
                            <Typography className={classes.font} style={{fontSize:24, fontWeight:900, textAlign:"Left"}}>
                                {commissionAndNft.highestNftGrade ? commissionAndNft.highestNftGrade === 'Regular' ? t('nftRegular'): t('nftRare'):t('nftNoNft')}
                            </Typography>
                        </div>

                        <div>
                            <Typography className={classes.font} style={{color:"#8B96A7"}}>
                                {t('nftTradable')}
                            </Typography>
                            <Typography className={classes.font} style={{fontSize:24, fontWeight:900}}>
                                {commissionAndNft.tradableNft ? commissionAndNft.tradableNft : 0}
                            </Typography>
                        </div>

                        <div>
                            <Typography className={classes.font} style={{color:"#8B96A7"}}>
                                {t('nftWithdrawFee')}
                            </Typography>
                            <Typography className={classes.font} style={{fontSize:24, fontWeight:900}}>
                                {commissionAndNft.highestNftGrade === 'Rare' ? t('nftFree') : t('nftRegularFee')}
                            </Typography>
                        </div>

                        <div>
                            <Typography className={classes.font} style={{color:"#8B96A7"}}>
                                {t('nftLiquiditySpread')}
                            </Typography>
                            <Typography className={classes.font} style={{fontSize:24, fontWeight:900}}>
                                {commissionAndNft.highestNftGrade ? commissionAndNft.highestNftGrade === 'Rare' ? 0.67: 1:1}%
                            </Typography>
                        </div>

                        <div>
                            <Typography className={classes.font} style={{color:"#8B96A7", textAlign:"Right"}}>
                                {t('nftCommission')}
                            </Typography>
                            <Typography className={classes.font} style={{fontSize:24, fontWeight:900, textAlign:"Right"}}>
                                {roundingDown(commissionAndNft.referralBonusRate*100,2) == 20
                                    ? 30
                                    :roundingDown(commissionAndNft.referralBonusRate*100,2) == 33.33?
                                        50
                                        :null
                                }%
                            </Typography>
                        </div>
                    </div>
                </div>
            </div>
        )
    }

    const renderAccountDetailsPhone = () => {
        return(
            <div style={{width:"100%",marginTop:40, boxSizing: "border-box", border:" 1px solid #BAC3CE", borderRadius: 24}}>
                <div style={{margin:30}}>
                    <div style={{display:"flex", justifyContent:"space-between", alignItems:"center"}}>
                        <Typography className={classes.font} style={{fontSize:20, fontWeight:900,textAlign:"Left"}}>
                            {t('nftMyAccount')}
                        </Typography>
                        <div
                            style={{display:"flex", alignItems:"center", cursor: "pointer"}}
                            onClick={handleScrollClick}
                        >
                            <Typography className={classes.font} style={{color:"#003FFF", textTransform:"none"}}>
                                {t('nftMoreVouchers')}
                            </Typography>
                            <img src={arrowPhone} style={{marginLeft:4, height:14, width:14}}/>
                        </div>
                    </div>

                    <div style={{display:"flex", justifyContent:"space-between", alignItems:"center", marginTop:20}}>
                        <div>
                            <Typography className={classes.font} style={{fontSize:12, color:"#8B96A7"}}>
                                {t('nftMyVouchers')}
                            </Typography>
                            <Typography className={classes.font} style={{fontSize:16, fontWeight:900}}>
                                {userInfo.voucher}
                            </Typography>
                        </div>
                        <div>
                            <Typography className={classes.font} style={{fontSize:12, color:"#8B96A7", textAlign:"right"}}>
                                {t('nftHighestLevel')}
                            </Typography>
                            <Typography className={classes.font} style={{fontSize:16, fontWeight:900, textAlign:"right"}}>
                                {commissionAndNft.highestNftGrade ? commissionAndNft.highestNftGrade === 'Regular' ? t('nftRegular'): t('nftRare'):t('nftNoNft')}
                            </Typography>
                        </div>
                    </div>

                    <div style={{display:"flex", justifyContent:"space-between", alignItems:"center", marginTop:12}}>
                        <div>
                            <Typography className={classes.font} style={{fontSize:12, color:"#8B96A7"}}>
                                {t('nftTradable')}
                            </Typography>
                            <Typography className={classes.font} style={{fontSize:16, fontWeight:900}}>
                                {commissionAndNft.tradableNft ? commissionAndNft.tradableNft : 0}
                            </Typography>
                        </div>
                        <div>
                            <Typography className={classes.font} style={{fontSize:12, color:"#8B96A7", textAlign:"right"}}>
                                {t('nftWithdrawFee')}
                            </Typography>
                            <Typography className={classes.font} style={{fontSize:16, fontWeight:900, textAlign:"right"}}>
                                {commissionAndNft.highestNftGrade === 'Rare' ? t('nftFree') : t('nftRegularFee')}
                            </Typography>
                        </div>
                    </div>

                    <div style={{display:"flex", justifyContent:"space-between", alignItems:"center", marginTop:12}}>
                        <div>
                            <Typography className={classes.font} style={{fontSize:12, color:"#8B96A7"}}>
                                {t('nftLiquiditySpread')}
                            </Typography>
                            <Typography className={classes.font} style={{fontSize:16, fontWeight:900}}>
                                {commissionAndNft.highestNftGrade ? commissionAndNft.highestNftGrade === 'Rare' ? 0.67: 1:1}%
                            </Typography>
                        </div>
                        <div>
                            <Typography className={classes.font} style={{fontSize:12, color:"#8B96A7", textAlign:"right"}}>
                                {t('nftCommission')}
                            </Typography>
                            <Typography className={classes.font} style={{fontSize:16, fontWeight:900, textAlign:"right"}}>
                                {roundingDown(commissionAndNft.referralBonusRate*100,2) == 20
                                    ? 30
                                    :roundingDown(commissionAndNft.referralBonusRate*100,2) == 33.33?
                                        50
                                        :null
                                }%
                            </Typography>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
    const renderTabs = () => {
        return(
            <div style={{width:"100%", marginTop:93}}>
                <div style={{display:"flex"}}>
                    {nftAsset.length > 0 ? <Button
                        onClick={()=>{setTabSelected("myNft")}}
                        style={{marginRight:phone?null:150}}
                    >
                        <Typography className={tabSelected === "myNft" ? classes.tabSelected :classes.tab}>
                            {t('myNft')}
                        </Typography>
                    </Button>:null}
                    <Button
                        style={{marginRight:phone?null:150}}
                        onClick={()=>{setTabSelected("nft")}}>
                        <Typography className={tabSelected === "nft" ? classes.tabSelected :classes.tab}>
                            {t('nftBenefits')}
                        </Typography>
                    </Button>
                    <Button
                        ref={scrollToVouchers}
                        id={"getVouchers"}
                        style={{marginRight:phone?null:150}}
                        onClick={()=>{setTabSelected("vouchers")}}>
                        <Typography className={tabSelected === "vouchers" ? classes.tabSelected :classes.tab}>
                            {t('nftGetVouchers')}
                        </Typography>
                    </Button>
                </div>
                <div style={{borderBottom: "1px solid #BAC3CE"}}/>
                {
                    tabSelected === "myNft" ?
                            <div style={{display:"flex",justifyContent:"space-around", flexWrap:"wrap"}}>
                                {nftAsset.map(item => (
                                    <div style={{ display:"flex", width: phone?170:322, borderRadius: phone?14:26, boxShadow: "0 2px 20px 0 rgba(200,200,200,0.5)", backgroundColor: 'white', marginTop:phone?16:50}}>
                                        <div style={{margin:phone?11:21}}>
                                            <img src={item.imageUrl} style={{height:phone?147:280, width:phone?147:280, borderRadius:phone?14:26}}/>
                                            <div style={{display:'flex', justifyContent:"space-between", alignItems:"baseline", marginTop:phone?10:20}}>
                                                <Typography className={classes.font} style={{fontSize:phone?14:24}}>
                                                    #{item.id}
                                                </Typography>
                                                <Typography className={classes.font} style={{fontWeight:900, fontSize:phone?12:16}}>
                                                    {item.grade === "Rare" ?t('nftRare') :t('nftRegular')}
                                                </Typography>
                                            </div>

                                        </div>
                                    </div>
                                ))}
                                {renderRestNft()}
                            </div>
                    :tabSelected === "nft" ?
                        <div style={{marginTop:phone?20:50}}>
                            <Typography className={classes.font} style={{fontSize:phone ? 10:null ,textAlign:"Left"}}>
                                {t('nftBenefitsOfAll')}
                            </Typography>
                            <div style={{marginTop:phone?7:18, backgroundColor:"#F7F7F7", borderRadius: 24, padding:phone?null:"18px 26px"}}>
                                <TableContainer >
                                    <Table aria-label="simple table" >
                                        <TableHead >
                                            <TableRow>
                                                <StyledTableCell> </StyledTableCell>
                                                {
                                                    phone ?
                                                        <>
                                                            <StyledTableCell align="left">{t('nftNoNft')}</StyledTableCell>
                                                            <StyledTableCell align="left">{t('nftRegular')}</StyledTableCell>
                                                            <StyledTableCell className={"gradient-text-phone"} align="right">{t('nftRare')}</StyledTableCell>
                                                        </>
                                                        :
                                                        <>
                                                            <StyledTableCell align="left">{t('nftTradable')}</StyledTableCell>
                                                            <StyledTableCell align="center">{t('nftWithdrawFee')}</StyledTableCell>
                                                            <StyledTableCell align="center">{t('nftLiquiditySpread')}</StyledTableCell>
                                                            <StyledTableCell align="right">{t('nftCommission')}</StyledTableCell>
                                                        </>
                                                }
                                            </TableRow>
                                        </TableHead>
                                        <TableBody>
                                            {rows.map((row) => (
                                                <StyledTableRow
                                                    key={row.name}
                                                    className={phone ? null: "gradient-text"}
                                                >
                                                    {
                                                        phone?
                                                            <>
                                                                <StyledTableCell component="th" scope="row">
                                                                    {row.name}
                                                                </StyledTableCell>
                                                                <StyledTableCell align="left">{row.nft}</StyledTableCell>
                                                                <StyledTableCell align="left">{row.regular}</StyledTableCell>
                                                                <StyledTableCell align="right">{row.rare}</StyledTableCell>
                                                            </>
                                                            :
                                                            <>
                                                                <StyledTableCell component="th" scope="row">
                                                                    {row.name}
                                                                </StyledTableCell>
                                                                <StyledTableCell align="left">{row.tradable}</StyledTableCell>
                                                                <StyledTableCell align="center">{row.fee}</StyledTableCell>
                                                                <StyledTableCell align="center">{row.liquidity}</StyledTableCell>
                                                                <StyledTableCell align="right">{row.commissions}</StyledTableCell>
                                                            </>
                                                    }

                                                </StyledTableRow>
                                            ))}
                                        </TableBody>
                                    </Table>
                                </TableContainer>
                            </div>

                            <Typography className={classes.font} style={{marginTop:phone?16:36, fontSize:phone?10:null, color:"#8B96A7", textAlign:"left", whiteSpace:'pre-wrap'}}>
                                {t('nftBenefitsSubTitle')}
                            </Typography>
                        </div>
                        :tabSelected === "vouchers" ?
                        <div style={{display:phone?null:"flex", marginTop:phone?20:68, justifyContent:"space-between"}}>
                            <div style={{ boxSizing: "border-box", width: phone?"100%":509, border: "1px solid #979797", borderRadius: phone?14:24}}>
                                <div style={{display:"flex", flexDirection:"column", alignItems:"center", margin:"0px 45px"}}>
                                    <img src={vouchersRefer} style={{width:phone?230:416, height:phone?140:246}}/>
                                    <Typography className={classes.font} style={{width:"100%", marginTop:21, fontSize:phone?16:28, fontWeight:900, textAlign:"left"}} >
                                        {t('nftReferFriend')}
                                    </Typography>
                                    <Typography className={classes.font} style={{width:"100%", marginTop:14, textAlign:"left", whiteSpace:'pre-wrap'}} >
                                        {t('nftReferFriendSubTitle')}
                                    </Typography>
                                    <Button
                                        className={classes.font}
                                        style={{marginTop:30, marginBottom: 33, height:phone?45:79, width:"100%", fontSize:phone?14:18, fontWeight:900, color:"white", borderRadius:phone?14:20, backgroundColor:"#003FFF", textTransform:"none"}}
                                        onClick={()=>{history.push('/referral')}}
                                    >
                                        <img src={inviteIcon} style={{width:phone?15:21, height:phone?15:21, marginRight:8}}/>
                                        {t('nftInviteFriend')}
                                    </Button>
                                </div>
                            </div>
                            <div style={{ marginTop:phone?33:null,  boxSizing: "border-box", width: phone?"100%":509, border: "1px solid #979797", borderRadius: phone?14:24}}>
                                <div style={{display:"flex", flexDirection:"column", alignItems:"center", margin:"0px 45px"}}>
                                    <img src={vouchersTransfer} style={{width:phone?230:416, height:phone? 140:246}}/>
                                    <Typography className={classes.font} style={{width:"100%", marginTop:21, fontSize:phone?16:28, fontWeight:900, textAlign:"left"}} >
                                        {t('nftTransferVouchers')}
                                    </Typography>
                                    <Typography className={classes.font} style={{width:"100%", marginTop:14, textAlign:"left", whiteSpace:'pre-wrap'}} >
                                        {t('nftTransferSubTitle')}
                                    </Typography>
                                    <div style={{marginTop:30, marginBottom: 33, width:"100%", display:"flex", justifyContent:"space-between"}}>
                                        <Button
                                            className={classes.font}
                                            style={{height:phone?45:79, width:"47%", fontSize:phone?14:18, fontWeight:900, color:"white", borderRadius:phone?14:20, backgroundColor:"#003FFF", textTransform:"none"}}
                                            onClick={()=>{setVoucherReceiveVisible(true);}}
                                        >
                                            {t('nftReceive')}
                                        </Button>
                                        <Button
                                            className={classes.font}
                                            style={{height:phone?45:79, width:"47%", fontSize:phone?14:18, fontWeight:900, color:"white", borderRadius:phone?14:20, backgroundColor:"#003FFF", textTransform:"none", opacity:kycDetails.status != "passed" ? 0.5 :1}}
                                            disabled={kycDetails.status != "passed"}
                                            onClick={()=>{
                                                if (userInfo.phoneValid){
                                                    setVoucherTransferVisible(true);
                                                }else{
                                                    setTwoFARequiredVisible(true);
                                                }

                                                dispatch(authActions.getUserInfo(token))
                                            }}
                                        >
                                            {t('nftTransfer')}
                                        </Button>
                                    </div>
                                </div>
                            </div>
                        </div>
                        :null
                }
            </div>
        )
    }
    return(
        <div style={{ width: '100%', display: 'flex', alignItems: 'center', flexDirection: 'column', marginTop:50, marginBottom: phone ? 40 : 120 }}>
            <div style={{ width: phone?"90%":"80%", display: 'flex',flexDirection: 'column', alignItems: 'center', justifyContent: 'center' }}>
                <Helmet>
                    <title>{t('titleNft')}</title>

                    <meta name="keywords" content="Toronto, OTC, bitcoin, exchange, cryptocurrency, 多伦多, 场外交易平台, 比特币, 以太坊, 加元, 换汇，加密货币, 人民币,多倫多, 場外交易平台, 比特幣, 以太坊, 加元, 換匯, 人民幣， Yesbit, Fiat, CAD, BTC, ETH, USD, balance" />
                </Helmet>
                {renderHeader()}
                {phone? renderNftDisplayPhone() : renderNftDisplay()}
                {phone ? renderAccountDetailsPhone() : renderAccountDetails()}
                {renderTabs()}
            </div>
            {/*更新用户信息弹框*/}
            <NftDetailsModal
                onCancel={() => {
                    setNftDetailsVisible(false);
                }}
                modalVisible={nftDetailsVisible}
                t
                phone={phone}
                width={width}
                nft={nftDetails}
                setClaimNFTVisible={(visible)=>{
                    setClaimNFTVisible(visible)
                }}
                countdownDistants={countDown.distance}
            />
            {/*转让劵转账*/}
            <VoucherTransferModal
                onCancel={() => {
                    setVoucherTransferVisible(false);
                }}
                modalVisible={voucherTransferVisible}
                t
                phone={phone}
                width={width}
                setVoucherTransferVisible = {(visible)=>{
                    setVoucherTransferVisible(visible)
                }}
            />
            {/*接收劵转账*/}
            <VoucherReceiveModal
                onCancel={() => {
                    setVoucherReceiveVisible(false);
                }}
                modalVisible={voucherReceiveVisible}
                t
                phone={phone}
                width={width}
            />
            {/*双重验证*/}
            <TwoFARequired
                onCancel={() => {
                    setTwoFARequiredVisible(false);
                }}
                modalVisible={twoFARequiredVisible}
                t
                phone={phone}
                width={width}
            />
            {/*兑换NFT*/}
            <ClaimNFTModal
                onCancel={() => {
                    setClaimNFTVisible(false);
                }}
                modalVisible={claimNFTVisible}
                t
                phone={phone}
                width={width}
                nft={nftDetails}
                setClaimNftVisible={(visible)=>{setClaimNFTVisible(visible)}}
                refresh={loadings}
                scrollToVouchers={handleScrollClick}

            />
        </div>
    );
}
export default withTranslation()(Nft);