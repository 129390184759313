import React, { useEffect, useState } from 'react'
import './ProcessSection.css'
import bottomImage from '../../images/home/bottomImage.svg'
import { Typography, makeStyles, Button } from '@material-ui/core'
import supportIcon from '../../images/home/24supportIcon.png'
import annoucementIcon from '../../images/home/annoucementIcon.png'
import tutorialIcon from '../../images/home/tutorialIcon.png'
import { withTranslation } from 'react-i18next';
import { Link, useHistory } from 'react-router-dom'

import transferToIcon from '../../images/transferToIcon.png'

function LastContainer({ t, phone }) {

    const history = useHistory();

    const useStyles = makeStyles({
        fontStyle: {
            fontFamily: "Avenir", fontWeight: '500'
        }
    })
    const classes = useStyles();
    const cell = (title, image, subtitle, action) => {
        return (


            <div style={{ height: 260, display: 'flex', flexDirection: 'column', backgroundColor: '#F9F9F9', width: '22%', borderRadius: 20, justifyContent: 'space-between' }}>
                <div style={{ marginLeft: 24, marginRight: 24, marginTop: 24 }}>
                    <Typography className={classes.fontStyle} style={{ fontSize: 20 }}>{title}</Typography>
                    <Typography className={classes.fontStyle} style={{ fontSize: 14, color: '#8B96A7' }}>{subtitle}</Typography>
                </div>
                <Button onClick={action} style={{ marginLeft: 16, marginRight: 24, marginBottom: 24, display: 'flex', flexDirection: 'row', justifyContent: 'space-between', alignItems: 'center' }}>
                    <img src={image} style={{ height: 105, width: 105 }} />

                    <img src={transferToIcon} style={{ height: 50 }} />

                </Button>
            </div>
        )
    }

    const cellPhone = (title, image, subtitle, action) => {

        return (

            <div style={{ display: 'flex', flexDirection: 'column', backgroundColor: '#F9F9F9', width: '100%', borderRadius: 20, justifyContent: 'space-between', marginTop: 16 }}>
                <div style={{ marginLeft: 20, marginRight: 20, marginTop: 20 }}>
                    <Typography className={classes.fontStyle} style={{ fontSize: 16 }}>{title}</Typography>
                    <Typography className={classes.fontStyle} style={{ fontSize: 12, color: '#8B96A7' }}>{subtitle}</Typography>
                </div>
                <Button onClick={action} style={{ marginLeft: 16, marginTop: 12, marginRight: 12, marginBottom: 12, display: 'flex', flexDirection: 'row', justifyContent: 'space-between', alignItems: 'center' }}>
                    <img src={image} style={{ height: 40, width: 40 }} />

                    <img src={transferToIcon} style={{ height: 28 }} />

                </Button>
            </div>
        )
    }

    const handle24Service = () => {
        onclick = "zE.show();"
    }

    const handleToturial = () => {
        window.open(localStorage.getItem('lng') === 'cn' ? "https://otcdocs.yesbit.io/v/jian-ti-zhong-wen/ " : "https://otcdocs.yesbit.io/")
    }

    const handleAnnoce = () => {
        window.open(localStorage.getItem('lng') === 'cn' ? "https://hpblink.zendesk.com/hc/en-us/sections/7111220790932-Announcements" : "https://hpblink.zendesk.com/hc/en-us/sections/7111220790932-Announcements")
    }

    return phone ?
        (
            <div style={{ width: '100%', display: 'flex', alignItems: 'center', justifyContent: 'center', flexDirection: 'column', marginBottom: 24, marginTop: 40 }}>
                <div style={{ height: 1, width: '91%', backgroundColor: '#F2F2F2', marginBottom: 24 }}></div>

                <div style={{ width: '91%', display: 'flex', alignItems: 'center', flexDirection: 'column' }}>

                    <div style={{ display: 'flex', flexDirection: 'column', width: '95%', justifyContent: 'center' }}>
                        <Typography className={classes.fontStyle} style={{ fontSize: phone ? 16 : 30 }}>{t('home_needHelp')}</Typography>
                        <Typography className={classes.fontStyle} style={{ fontSize: phone ? 12 : 16, color: '#8B96A7' }}>{t('home_needHelpSub')}</Typography>
                    </div>
                    <div style={{ display: 'flex', flexDirection: 'column' }}>
                        {cellPhone(t('home_needHelp1'), supportIcon, t('home_needHelp1Sub'), handle24Service)}

                        {cellPhone(t('home_needHelp2'), tutorialIcon, t('home_needHelp2Sub'), handleToturial)}
                        {cellPhone(t('home_needHelp3'), annoucementIcon, t('home_needHelp3Sub'), handleAnnoce)}
                    </div>
                </div>


            </div >
        ) :
        (
            <div style={{ width: '100%', display: 'flex', alignItems: 'center', justifyContent: 'center', flexDirection: 'column', marginBottom: 64, marginTop: 100 }} >
                <div style={{ height: 1, width: '81%', backgroundColor: '#F2F2F2', marginBottom: 64 }}></div>

                <div style={{ width: '81%', display: 'flex', justifyContent: 'space-between', alignItems: 'center', flexDirection: 'row' }}>

                    <div style={{ height: 260, display: 'flex', flexDirection: 'column', width: '24%', justifyContent: 'center' }}>
                        <Typography className={classes.fontStyle} style={{ fontSize: 30 }}>{t('home_needHelp')}</Typography>
                        <Typography className={classes.fontStyle} style={{ fontSize: 16, color: '#8B96A7' }}>{t('home_needHelpSub')}</Typography>
                    </div>
                    {cell(t('home_needHelp1'), supportIcon, t('home_needHelp1Sub'), handle24Service)}

                    {cell(t('home_needHelp2'), tutorialIcon, t('home_needHelp2Sub'), handleToturial)}
                    {cell(t('home_needHelp3'), annoucementIcon, t('home_needHelp3Sub'), handleAnnoce)}



                </div>


            </div >
        )
}

export default withTranslation()(LastContainer)
