import React from 'react';
import { makeStyles, } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
import CancelIcon from '@material-ui/icons/Cancel';
import NumberFormat from 'react-number-format';
import IconButton from '@material-ui/core/IconButton';

import InputAdornment from '@material-ui/core/InputAdornment';


export default function CustomTextFieldHalfSize(props) {
  const { phone, rightbuttonclick, ...other } = props
  const useStylesInput = makeStyles((theme) => ({
    root: {

      'label + &': {
        marginTop: theme.spacing(3),

      },

      '&:hover': {
        backgroundColor: 'white',
      },
  
      backgroundColor: '#FFFFFF',
      borderRadius: phone ? 16 : 24,
      alignItems: 'center',
      overflow: 'hidden',
      height: phone ? 60 : 72,
      border: "1px solid #EDEDED",

    },

    input: {
      position: 'relative',
      fontSize: phone ? 20 : 32,
      padding: phone ? '10px' : '17px 20px',
      color: '#1C2237',

      fontFamily: [
        'Arial',
      ].join(','),


    },
  


  }));

  // const useHelperTextStyles = makeStyles(() => ({
  //   root: {
  //     color: "blue !important",
  //     fontSize:"24px !important"
  //   }
  // }));

  const classes = useStylesInput();

  return <TextField
    id="filled-full-width"
    margin="normal"
    InputLabelProps={{ className: "textfield__label" }}
    // FormHelperTextProps={{
    //   classes: {
    //     root: helperTextStyles.root
    //   }
    // }}
    variant="filled"
    InputProps={{
      classes,
      disableUnderline: true,
      inputMode: props.customtype === "number" ? 'numeric' : undefined,
      inputComponent: props.customtype === "number" ? props.int ? IntFormatCustom : NumberFormatCustom : undefined,
      startAdornment: props.rightbuttonlabel || props.showcancellbutton || props.coinselect ?
        <InputAdornment position="end" style={{ height: '100%', justifyContent: 'flex-end', margin: 0 }}>
          {props.showcancellbutton ?

            <CancelIcon style={{ color: 'white' }} onClick={props.clear} />
            :
            null
          }

          {props.coinselect ?
            props.coinselect :
            null
          }
        </InputAdornment>
        :
        null,

      endAdornment: props.rightbuttonlabel || props.showcancellbutton || props.coinselect ?
        <InputAdornment position="end" style={{ height: '100%', justifyContent: 'flex-end', margin: 0 }}>

          {!props.rightbuttonlabel ?
            null
            :

            <IconButton position="end"
              style={{ color: '#0173F9', fontSize: phone ? 14 : 16, fontWeight: 'bold' }}
              onClick={props.rightbuttonclick}
              disabled={props.rightbuttondisable}
            >
              {props.rightbuttonlabel}
            </IconButton>

          }


        </InputAdornment>
        :
        null,


    }

    }
    {...other}
    autoCapitalize="off"
    autoComplete="off"
    autoCorrect="off"
  >

  </TextField>
}

function NumberFormatCustom(props) {
  const { inputRef, onChange, ...other } = props;
  return (
    <NumberFormat
      {...other}
      getInputRef={inputRef}
      onValueChange={(values) => {
        onChange({
          target: {
            name: props.name,
            value: values.value,
          },
        });
      }}
      allowNegative={false}
      decimalScale={8}
    />
  );
}

function IntFormatCustom(props) {
  const { inputRef, onChange, ...other } = props;
  return (
    <NumberFormat
      {...other}
      getInputRef={inputRef}
      onValueChange={(values) => {
        onChange({
          target: {
            name: props.name,
            value: values.value,
          },
        });
      }}
      allowNegative={false}
      decimalScale={0}
    />
  );
}


