import { withTranslation } from "react-i18next";
import { Avatar, Button, Fade, IconButton, makeStyles, Modal, Snackbar, Typography, Dialog, duration } from "@material-ui/core";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import x from "../../../images/closePopIcon.png";
import payIcon from "../../../images/payIcon.png"
import receiveIcon from "../../../images/receiveIcon.png"
import { tradeActions } from "../../../redux/actions/tradeActions";
import { getIcons } from "../../../utils/Common";
import enterArrow from "../../../images/enterArrow.png";
import { motion } from "framer-motion"
const ResultModal = (props) => {
    const {
        onCancel: handleClose,
        modalVisible,
        t,
        phone,
        payValue,
        receiveValue,
        coin1Selected,
        coin2Selected,
        selected,
        timeLeft,
        stopTimeLeft
    } = props;
    const useStyles = makeStyles((theme) => ({
        modal: {
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            textAlign: 'center',
        },
        font: {
            color: "#858E9D",
            fontFamily: 'Avenir',
            letterSpacing: 0,
            fontWeight: '500',
            textTransform: 'none'
        },
        button: {
            // width: '100%',
            height: phone ? 40 : 55,
            borderRadius: 16,
            backgroundColor: '#0173F9 !important',
            textTransform: 'none',
            marginTop: phone ? 38 : 30
        },
    }));
    const classes = useStyles();
    const { token } = useSelector(state => state.auth);
    const { tradePriceQuoteLoading, tradeLoading, tradePriceQuote } = useSelector(state => state.trade);
    const dispatch = useDispatch();

    const handleTrade = () => {
        const payload = {
            quoteId: tradePriceQuote.quoteId,
            tradeSide: selected,
            deliverCurrency: coin1Selected,
            deliverQuantity: payValue,
            receiveCurrency: coin2Selected,
            receiveQuantity: tradePriceQuote.receiveQuantity
        }
        dispatch(tradeActions.trade(payload, token))
        stopTimeLeft()
    }

    const confirmBody = (
        <motion.div
            initial={{ opacity: 0, scale: 0 }}
            animate={{ opacity: 1, scale: 1 }}
        >
            <div style={{
                backgroundColor: 'white', borderRadius: 20, width: phone ? " 89vw" : "606px", overflow: 'auto', outline: 'none'
            }}>
                <div style={{ marginTop: 'min(10%,40px)', marginBottom: 'min(10%,40px)', marginLeft: 'min(6%,55px)', marginRight: 'min(6%,55px)' }}>
                    <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                        <Typography className={classes.font} style={{ fontSize: phone ? 16 : 22, fontWeight: 300, lineHeight: phone ? '10px' : '30px', color: 'black' }}>
                            {t('confirmTrading')}
                        </Typography>

                        <Button
                            style={{
                                opacity: tradePriceQuoteLoading || tradeLoading ? 0.4 : 1, display: 'inline-block',
                                padding: 0,
                                minHeight: 0,
                                minWidth: 0,
                            }}
                            onClick={handleClose}
                            disabled={tradePriceQuoteLoading || tradeLoading}
                        >
                            <img alt='' src={x} style={phone ? { height: 24, width: 24 } : { height: 32, width: 32 }} />
                        </Button>

                    </div>
                    <div style={{ marginTop: phone ? 10 : 15, boxSizing: 'border-box', height: 1, width: '100%', border: '1px solid #DDDDDD' }} />

                    <p style={{ textAlign: 'left', marginTop: 32 }}>
                        <p style={{ display: 'inline', color: "#858E9D", fontFamily: 'Avenir', fontSize: phone ? 12 : 16 }}>
                            {t('tradeConfirmText1')}
                        </p>
                        <p style={{ display: 'inline', color: "#0173F9", fontFamily: 'Avenir', fontSize: phone ? 12 : 16, fontWeight: 'bold' }}>
                            {new Date(timeLeft * 1000).toISOString().substr(14, 5)}
                        </p>
                        <p style={{ display: 'inline', color: "#858E9D", fontFamily: 'Avenir', fontSize: phone ? 12 : 16 }}>
                            {t('tradeConfirmText2')}
                        </p>
                    </p>


                    <div style={{ display: 'flex', justifyContent: 'space-between', marginTop: phone ? 24 : 32, alignItems: 'center' }}>
                        <div style={{ display: 'flex', alignItems: 'center' }}>
                            {/* <img src={payIcon} style={{ width: phone ? 22 : 27, height: phone ? 22 : 27 }} /> */}
                            <div style={{ textAlign: 'left' }}>
                                <Typography className={classes.font} style={{ fontSize: phone ? 12 : 18, lineHeight: phone ? '22px' : '25px' }}>
                                    {t('pay')}
                                </Typography>
                                <Typography className={classes.font} style={{ color: '#1C2237', fontSize: phone ? 14 : 16, lineHeight: phone ? '19px' : '22px' }}>
                                    {payValue} {coin1Selected}
                                </Typography>
                            </div>

                        </div>

                        <Avatar variant="square" alt="Coin Icon" style={{ width: phone ? 23 : 29, height: phone ? 23 : 29 }} src={getIcons(coin1Selected)} />
                    </div>

                    <div style={{ display: 'flex', justifyContent: 'space-between', marginTop: 30, alignItems: 'center' }}>
                        <div style={{ display: 'flex', alignItems: 'center' }}>
                            {/* <img src={receiveIcon} style={{ width: phone ? 22 : 27, height: phone ? 22 : 27 }} /> */}
                            <div style={{ textAlign: 'left' }}>
                                <Typography className={classes.font} style={{ fontSize: phone ? 12 : 18, lineHeight: phone ? '22px' : '25px' }}>
                                    {t('receive')}
                                </Typography>
                                <Typography className={classes.font} style={{ color: '#1C2237', fontSize: phone ? 14 : 16, lineHeight: phone ? '19px' : '22px' }}>
                                    {receiveValue} {coin2Selected}
                                </Typography>
                            </div>

                        </div>

                        <Avatar variant="square" alt="Coin Icon" style={{ width: phone ? 23 : 29, height: phone ? 23 : 29 }} src={getIcons(coin2Selected)} />
                    </div>

                    {/* <Typography className={classes.font} style={{ textAlign: 'left', marginTop: 30, fontSize: phone ? 12 : 14, fontWeight: 500 }}>
                    {t('tradeConfirmText')} {new Date(timeLeft * 1000).toISOString().substr(14, 5)}
                </Typography> */}




                    <div style={{ display: 'flex', justifyContent: 'end', }}>
                        <Button
                            className={classes.button}
                            style={{ opacity: tradePriceQuoteLoading || tradeLoading ? 0.4 : 1 }}
                            onClick={() => { handleTrade() }}
                            disabled={tradePriceQuoteLoading || tradeLoading}
                        >
                            <Typography className={classes.font} style={{ fontSize: phone ? 14 : 18, color: 'white', fontWeight: 500, marginLeft: 12, marginRight: 12, lineHeight: phone ? '11px' : '25px' }}>{t("confirm")}</Typography>
                            <img style={{ height: phone ? 16 : 20 }} src={enterArrow}></img>
                        </Button>
                    </div>

                    {/* <Button
                    style={{ marginTop: phone ? 15 : 20, opacity: tradePriceQuoteLoading || tradeLoading ? 0.4 : 1 }}
                    onClick={handleClose}
                    disabled={tradePriceQuoteLoading || tradeLoading}
                >
                    <Typography className={classes.font} style={{ fontSize: phone ? 14 : 18, color: '#1C2237', fontWeight: 900, lineHeight: phone ? '11px' : '25px' }}>{t("cancel")}</Typography>
                </Button> */}

                </div>
            </div >
        </motion.div>
    );

    return (
        <Modal
            open={modalVisible}
            

            onClose={() => {
                handleClose();
            }}
            className={classes.modal}


            aria-labelledby="simple-modal-title"
            aria-describedby="simple-modal-description"
            BackdropProps={{
                class:
                    'modalBackground'
            }}
        >
            {confirmBody}
        </Modal>
    )
}


export default withTranslation()(ResultModal);
