import React, {useEffect, useState} from 'react';

import './ProcessSection.css'
import {makeStyles, Typography} from '@material-ui/core';
import {useDispatch, useSelector} from "react-redux";
import {authActions} from "../../redux/actions/authActions";
import './ScrollBar.css'


function HorizontalScrollBar(phone) {
    const dispatch = useDispatch();
    const { articleList, getArticleMsg, zendeskToken, zendeskTokenMsg } = useSelector(state => state.auth)
    const [localArticles, setLocalArticles] = useState([
        {
            title: '',
            html_url: '',
            updated_at: ''
        }
    ])
    const useStyles = makeStyles({
        fontStyle: {
            fontFamily: "Avenir", fontWeight: '500'
        }
    })

    useEffect(() => {
        dispatch(authActions.getLatestArticles("e352bd2e72bb1d58a6ae2dd0be75fdc4ba812786e515a8405a754f3c0988a45b"))
    }, [])

    useEffect(() => {
        if (articleList.length > 5) {
            let tem = articleList.slice(articleList.length - 4,)
            tem.push(articleList[articleList.length - 4])
            setLocalArticles(tem)
        } else {
            let tem = articleList
            let count = articleList.length
            while (count < 5) {
                count++
                tem.push(articleList[articleList.length - 1])
            }
            setLocalArticles(tem)
        }
    }, [articleList])

    useEffect(() => {
    }, [localArticles])

    const classes = useStyles();

    const goToLink = (url) => {
        window.open(url,'_blank');
    }

    const cellComponent = (title, url, index) => {
        return (
            <li key={index}>
                <Typography onClick={() => goToLink(url)} style={{ cursor: 'pointer', fontSize: 16, color: 'white', marginLeft: 40, marginRight: 40, whiteSpace: 'nowrap', textOverflow: 'ellipsis' }} className={classes.fontStyle}>{title}</Typography>
            </li>
        )
    }
    return (

        <div className="article" style={{ width: '100%', height: phone ? 40 : 80, backgroundColor: '#3254FA', display: 'flex', alignItems: 'center', justifyContent: 'center', flexDirection: 'row' }}>
            <div className="box">
                <ul>
                {
                    localArticles.map((item, index) =>
                        cellComponent(item.title, item.html_url, index)
                    )
                }
                </ul>
            </div>
        </div>
    )
}

export default HorizontalScrollBar
