import { withTranslation } from "react-i18next";
import { Avatar, Button, Fade, IconButton, makeStyles, Modal, Snackbar, Typography, Dialog } from "@material-ui/core";
import React, {useEffect, useRef, useState} from "react";
import nft1 from "../../../images/nft/nft1.png";
import nft2 from "../../../images/nft/nft2.png";
import rareIcon from "../../../images/nft/Group 8.png"
import regularIcon from "../../../images/nft/Group 7.png"
import vouchersWhite from "../../../images/nft/voucherWhite.png";
import close from "../../../images/nft/close.png";
import closePhone from "../../../images/nft/closePhone.png";
import {useSelector} from "react-redux";

const NftDetailsModal = (props) => {
    const {
        onCancel: handleClose,
        modalVisible,
        t,
        phone,
        width,
        nft,
        setClaimNFTVisible,
        countdownDistants,
        homePage
    } = props;
    const useStyles = makeStyles((theme) => ({
        modal: {
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            overflow:'scroll',
        },
        font: {
            color: '#1C2237',
            fontFamily: 'Avenir',
            fontSize: 14,
            letterSpacing: -0.31
        },
    }));
    const classes = useStyles();
    const { nftClaimed } = useSelector(state => state.nft)
    const imgWidth = useRef(null)
    const NftDetailsBody = (
        <div style={{position:"relative", height: phone?null :522, width: phone? 300 : 520, borderRadius: 32, backgroundColor:"white", outline:0}}>
            <img src={phone ?closePhone:close} style={{position:"absolute", width:phone?40:33, height:phone?40:33, right:phone?0:-20, top:phone?-20:-30, cursor: "pointer"}} onClick={()=>{handleClose()}}/>
            <div style={{margin:phone?30:"50px 60px"}}>
                {
                    phone ?
                        <div ref={imgWidth} style={{position:"relative", backgroundImage:nft === "nft1" ? `url(${nft1})` : `url(${nft2})`, backgroundSize:"cover", height:240, width:"100%", borderRadius:20}}>

                            {/*<img src={nft === "nft1" ? nft1 : nft2} style={{height:150, width:150, borderRadius:20}}/>*/}
                            <div style={{position:"absolute", bottom:20, left:20}}>
                                <Typography className={classes.font} style={{color: phone?"white":null, display:"flex", alignItems:nft === "nft1" ?"baseline":"center", fontSize:24, fontWeight:900}}>
                                    {t('nftVirtualHead')}
                                    {
                                        nft === "nft1" ?
                                            <Typography style={{color: "white",marginLeft:8, fontSize:12, fontWeight:900}}>
                                                {t('nftRegular')}
                                            </Typography>
                                            :
                                            <div style={{display:"flex", marginLeft:8,height:21, width:44, borderRadius:6, background: "linear-gradient(90deg, #FC4362 0%, #D00ABD 100%)", alignItems:"center"}}>
                                                <Typography style={{marginLeft:8, fontSize:12, fontWeight:900, color:"white"}}>
                                                    {t('nftRare')}
                                                </Typography>
                                            </div>
                                    }

                                </Typography>
                                {
                                    homePage?null
                                        : <Typography className={classes.font} style={{color:phone?"white":"#8B96A7"}}>
                                            {t('nftClaim')}
                                            <Typography className={classes.font} style={{color: phone?"white":null , display:"inline"}}> {nft === 'nft1' ? (nftClaimed ? nftClaimed.regularNftClaimed : 0) :(nftClaimed ? nftClaimed.rareNftClaimed+'/'+nftClaimed.rareNftTotal : 0)} </Typography>
                                        </Typography>
                                }

                            </div>
                        </div>
                        :
                        <div style={{display:"flex"}}>
                            <img src={nft === "nft1" ? nft1 : nft2} style={{height:150, width:150, borderRadius:20}}/>
                            <div style={{marginLeft:16}}>
                                <Typography className={classes.font} style={{display:"flex", alignItems: nft === "nft1" ?"baseline":"center", fontSize:24, fontWeight:900}}>
                                    {t('nftVirtualHead')}
                                    {
                                        nft === "nft1" ?
                                            <Typography style={{marginLeft:8, fontSize:12, fontWeight:900}}>
                                                {t('nftRegular')}
                                            </Typography>
                                            :
                                            <div style={{display:"flex", marginLeft:8,height:21, width:44, borderRadius:6, background: "linear-gradient(90deg, #FC4362 0%, #D00ABD 100%)", alignItems:"center"}}>
                                                <Typography style={{marginLeft:8, fontSize:12, fontWeight:900, color:"white"}}>
                                                    {t('nftRare')}
                                                </Typography>
                                            </div>
                                    }

                                </Typography>
                                {
                                    homePage?null
                                        :<Typography className={classes.font} style={{color:"#8B96A7"}}>
                                            {t('nftClaim')} &nbsp;
                                            <Typography className={classes.font} style={{display:"inline"}}>
                                                {nft === 'nft1' ? (nftClaimed ? nftClaimed.regularNftClaimed : 0) :(nftClaimed ? nftClaimed.rareNftClaimed+'/'+nftClaimed.rareNftTotal : 0)}
                                            </Typography>
                                        </Typography>
                                }
                                <Typography className={classes.font} style={{color:"#8B96A7", marginTop:16}}>
                                    {
                                        nft === "nft1" ?
                                            t('nft1Detail')
                                            :
                                            t('nft2Detail')
                                    }

                                </Typography>
                            </div>
                        </div>
                }
                <Typography className={classes.font} style={{marginTop:phone?28:35, fontSize:phone?14:16, fontWeight:900}}>
                    {t('nftBenefits')}
                    <img src={nft === "nft1" ? regularIcon : rareIcon} style={{width:phone?23:24, height:phone?23:24, marginLeft:8}}/>
                </Typography>
                <Typography className={classes.font} style={{color:"#8B96A7"}}>
                    {nft === "nft1" ? t('nft1BenefitSub') : t('nft2BenefitSub')}
                </Typography>
                <div style={{display:"flex", justifyContent:"space-between", marginTop:16}}>
                    <Typography className={classes.font} >{t('nftTradable')}</Typography>
                    <Typography className={classes.font} style={{fontWeight:900, textAlign:"right"}}>{t('nftYes')}</Typography>
                </div>
                <div style={{display:"flex", justifyContent:"space-between", marginTop:4}}>
                    <Typography className={classes.font} >{t('nftWithdrawFee')}</Typography>
                    <Typography className={classes.font} style={{fontWeight:900, textAlign:"right"}}>{nft === "nft1" ? t('nftRegularFee') : t('nftFree')}</Typography>
                </div>
                <div style={{display:"flex", justifyContent:"space-between", marginTop:4}}>
                    <Typography className={classes.font} >{t('nftLiquiditySpread')}</Typography>
                    <Typography className={classes.font} style={{fontWeight:900, textAlign:"right"}}>{nft === "nft1" ? "1%" : "0.67%"}</Typography>
                </div>
                <div style={{display:"flex", justifyContent:"space-between", marginTop:4}}>
                    <Typography className={classes.font} >{t('nftCommission')}</Typography>
                    <Typography className={classes.font} style={{fontWeight:900, textAlign:"right"}}>{nft === "nft1" ? "30%" : "50%"}</Typography>
                </div>
                <Button
                    style={{marginTop:phone?23:32, height:phone?45:55, width:"100%", borderRadius:20, backgroundColor:"#003FFF", opacity:countdownDistants > 0?0.4:1}}
                    onClick={()=>{setClaimNFTVisible(true);handleClose()}}
                    disabled={countdownDistants > 0}
                >
                    <img src={vouchersWhite} style={{height:phone?16:20, width:phone?16:20, marginRight:8}}/>
                    <Typography className={classes.font} style={{color:"white", fontSize:phone?16:20, textTransform:"none"}}>
                        {t('nftUse1')} {nft==="nft1" ? 1 : 10 } {nft === "nft1" ? t('nftUseVoucher') :t('nftUse2')}
                    </Typography>
                </Button>
            </div>
        </div>
    );

    return (
        <Modal
            open={modalVisible}

            onClose={() => {
                handleClose()
            }}
            className={classes.modal}
            aria-labelledby="simple-modal-title"
            aria-describedby="simple-modal-description"
            BackdropProps={{
                className:
                    'modalBackground'
            }}
            disableAutoFocus={true}
        >
            {NftDetailsBody}
        </Modal>
    )
}


export default withTranslation()(NftDetailsModal);