import {withTranslation} from "react-i18next";
import React, {useEffect, useRef, useState} from 'react';
import {
    Button,
    ClickAwayListener,
    Grow,
    makeStyles,
    MenuItem,
    MenuList,
    Paper,
    Popper,
    Typography
} from '@material-ui/core';
import announcementIcon from '../../../images/AnnouncementIcon.png';
import announcementWithDotIcon from '../../../images/AnnouncementWithDotIcon.png';

import arrorTo from "../../../images/arrorTo.png"

import {useHistory} from 'react-router-dom';

import newMessage from "../../../images/newMessage.png";
import {useDispatch, useSelector} from "react-redux";
import {authActions} from "../../../redux/actions/authActions";
import { formDateString } from '../../../utils/ConvertDate'

const AnnocementButton = (props) => {
    const dispatch = useDispatch();
    const {t} = props;
    const history = useHistory();
    const [open, setOpen] = useState(false);
    const anchorRef = useRef(null);
    const prevOpen = React.useRef(open);
    const useStyles = makeStyles({
        font: {
            color: '#1C2237',
            fontFamily: 'Avenir',
            fontSize: 16,
            fontWeight: 500,
            letterSpacing: 0,
            textAlign: 'center',
        },
        selected: {
            color: '#3254FA !important',
            borderBottom: "2px solid #3254FA"
        },

        popTitle: {
            fontSize: 16, fontWeight: '500', fontFamily: 'Avenir', whiteSpace: 'nowrap', maxWidth: 350, textOverflow: 'ellipsis', overflow: 'hidden'
        },
        popSubTitle: {
            fontSize: 14, fontWeight: '500', color: "#8B96A7", fontFamily: 'Avenir', whiteSpace: 'nowrap', maxWidth: 200, textOverflow: 'ellipsis', overflow: 'hidden'
        }
    })
    const classes = useStyles();

    const [dummyData, setDummyData] = useState([{ title: "aaa", content: "bbb", url: "ccc", date: "03/03/22" }, { title: "aaa", content: "bbb", url: "ccc", date: "03/03/22" }, { title: "aaa", content: "bbb", url: "ccc", date: "03/03/22" }])
    const [showEmptyView, setShowEmptyView] = useState(false)
    const { articleList, getArticleMsg } = useSelector(state => state.auth)

    useEffect(() => {
        if (prevOpen.current === true && open === false) {
            anchorRef.current.focus();
        }

        prevOpen.current = open;
    }, [open]);
    const handleToggle = () => {
        setOpen((prevOpen) => !prevOpen);
    };

    const handleClose = (event) => {
        if (anchorRef.current && anchorRef.current.contains(event.target)) {
            return;
        }

        setOpen(false);
    };


    const cellComponent = (title, content, url, date, index) => {
        return (
            <MenuItem key={index} onClick={() => { window.open(url,'_blank'); handleToggle() }} style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', width: "100%", marginTop: 8, marginBottom: 8 }}>
                <div style={{ display: 'flex', flexDirection: 'column', overflow: 'hidden', width: '90%' }}>
                    <div style={{ flexDirection: 'row', display: 'flex', alignItems: 'center' }}>
                        <img src={newMessage} style={{ width: 20, marginRight: 12 }}></img>

                        <Typography className={classes.popTitle}>
                            {title}
                        </Typography>
                    </div>
                    <Typography className={classes.popSubTitle} style={{ maxWidth: '80%', marginLeft: 32 }}>
                        {content}
                    </Typography>
                    <Typography className={classes.popSubTitle} style={{ maxWidth: '80%', marginLeft: 32, marginTop: 0, fontSize: 10 }}>
                        {formDateString(date)}
                    </Typography>
                </div>
                <img src={arrorTo} style={{ width: 8, marginRight: 8 }}></img>
            </MenuItem>
        )
    }

    useEffect(() => {
        dispatch(authActions.getLatestArticles("e352bd2e72bb1d58a6ae2dd0be75fdc4ba812786e515a8405a754f3c0988a45b"))
    }, [])

    return (
        <div>
            <Button ref={anchorRef} onClick={handleToggle} style={{ minHeight: '100%' }}>
                <img src={showEmptyView ?announcementIcon : announcementWithDotIcon} style={{ height: 24, width: 24 }}></img>

            </Button>

            <Popper
                style={{ marginTop: 16 }}
                open={open}
                anchorEl={anchorRef.current}
                role={undefined}
                placement="top-end"
                // placement="bottom-end"
                transition
            >
                {({ TransitionProps, placement }) => (
                    <Grow
                        {...TransitionProps}

                        in={open}

                    >
                        <Paper elevation={8} style={{ backgroundColor: 'white', borderRadius: 12, width:showEmptyView?400: 500 }}>
                            <ClickAwayListener onClickAway={handleClose}>
                                <MenuList
                                    autoFocusItem={open}
                                    id="composition-menu"
                                    aria-labelledby="composition-button"

                                >
                                    {
                                        showEmptyView ?
                                            <div style={{ height: 60,  width: '100%',display:'flex',alignItems:'center',justifyContent:'center' }}>
                                                <Typography style={{ fontSize: 14, fontWeight: '500', color: "#8B96A7", fontFamily: 'Avenir' }}>
                                                    {t("noAnnocement")}
                                                </Typography>
                                            </div>
                                            :
                                            articleList.length < 5 ?
                                                articleList.map((item, index) =>
                                                    cellComponent(item.title, item.content, item.html_url, item.updated_at, index)
                                                ) :
                                                articleList.slice(articleList.length - 5, ).map((item, index) =>
                                                    cellComponent(item.title, item.content, item.html_url, item.updated_at, index)
                                                )
                                    }

                                </MenuList>
                            </ClickAwayListener>
                        </Paper>
                    </Grow>
                )}
            </Popper>
        </div>

    )
}


export default withTranslation()(AnnocementButton);
