// export const BASE_API_URL = 'http://52.204.184.252:8090/api/otc';
// export const BASE_API_URL = 'https://testapi.otc.yesbit.io/api/otc';
export const BASE_API_URL = 'https://yesbitotcapi.com:20000/api/otc';
export const GA_TRACKING_ID = 'G-ECTW43DE7H';

export const LOGIN = BASE_API_URL + '/user/login';
export const SIGN_UP = BASE_API_URL + '/user/sign-up';
export const GET_USERINFO = BASE_API_URL + '/user/details';
export const UPDATE_USERINFO = BASE_API_URL + '/user/info/update';
export const GET_VERIFICATION_CODE = BASE_API_URL + '/user/auth/email?';
export const CHECK_USER= BASE_API_URL + '/user/exist?';
export const RESET_PASSWORD= BASE_API_URL + '/user/password';

export const INIT_KYC_LINK = BASE_API_URL + '/kyc/link/init'
// export const INIT_KYC_LINK = BASE_API_URL + '/kyc/link/init?callbackUrl=https://dnaramtest.com/api/otc/kyc/callback&successUrl=https://www.trade.yesbit.io/redirect&errorUrl=https://www.trade.yesbit.io/redirect'

export const SYNC_KYC_STATUS = BASE_API_URL + '/kyc/status/retrieve'
export const GET_KYC_DETAILS = BASE_API_URL + '/kyc/details'
export const UPDATE_KYC_STATUS = BASE_API_URL + '/kyc/status/update'

//Funds API - Richard
export const GET_USER_CAPITAL = BASE_API_URL + '/capital/user'
export const GET_FUNDS_TRANSACTIONS = BASE_API_URL + '/transaction/user/type?type=deposit&current=0&pageSize=10'
export const FIAT_DEPOSIT = BASE_API_URL + '/financial/fiat/deposit'
export const PRICE_QUOTE = BASE_API_URL + '/financial/price/quote'
export const PRICE_QUOTE_NO_AUTH = BASE_API_URL + '/financial/price/quote/no-auth'

export const TRADE_LIVE_PRICE = BASE_API_URL + '/financial/price/quote/no-auth'
export const TRADE_PRICE_QUOTE = BASE_API_URL + '/financial/price/quote'
export const TRADE = BASE_API_URL + '/financial/trade'

export const UPDATE_LOGIN_PASSWORD = BASE_API_URL + '/user/password/update'

export const FIAT_WITHDRAW = BASE_API_URL + '/financial/fiat/withdraw'

export const GET_TRANSACTION_BY_TYPE = BASE_API_URL + '/transaction/user/types'

export const UPDATE_VERIFICATION_PHONE = BASE_API_URL + '/user/verification-phone?phone='
export const GET_MOBILE_VERIFICATION_CODE = BASE_API_URL + '/user/auth/sms/code'
export const VALIDATE_VERIFICATION_CODE = BASE_API_URL + '/user/auth/sms/validate?code='

export const GET_TOKEN_INFO = BASE_API_URL + '/financial/get-all-tokens-ignoring-availability'
export const GET_FEE = BASE_API_URL + '/financial/getFeeWithActionAndAmount/'
export const GET_ADDRESS_BY_CHAIN = BASE_API_URL + '/user/address/generate?chain='
export const WITHDRAW_CRYPTO = BASE_API_URL + '/financial/withdraw'
export const GET_AQUA_BALANCE = BASE_API_URL + '/financial/aquanow/balance?symbol='

export const EXPIRE_AQUA_QUOTE = BASE_API_URL + '/financial/aquanow/rfq/expire?quoteId='
export const GET_TICKER = BASE_API_URL + `/financial/aquanow/ticker?`

export const GET_REFERRALS = BASE_API_URL + `/user/referrals?`
export const GET_USERNAME_BY_REFERRAL_CODE = BASE_API_URL +`/user/find/referral-code?referralCode=`

export const INIT_ADDRESS_VERIFICATION = BASE_API_URL + '/kyc/document/link/init?docType='
// export const INIT_ADDRESS_VERIFICATION = BASE_API_URL + '/kyc/document/link/init?callbackUrl=https://dnaramtest.com/api/otc/kyc/callback&successUrl=https://www.trade.yesbit.io/redirect&errorUrl=https://www.trade.yesbit.io/redirect&docType='

export const GET_DOCUMENT_DETAILS = BASE_API_URL + '/kyc/document/details'
export const SYNC_ADDRESS_STATUS = BASE_API_URL + '/kyc/document/status/retrieve'

export const GET_BANNER_INFO = BASE_API_URL + '/banner/get-banner-uri'

export const TRANSFER_VOUCHER = BASE_API_URL + '/user/voucher/transfer?receiverUsername='
export const GET_COMMISSION_AND_NFT = BASE_API_URL + `/financial/commissions`
export const CLAIM_NFT = BASE_API_URL + `/nft/mint?grade=`
export const GET_NFT_CLAIMED = BASE_API_URL + `/nft/claimed`
export const GET_NFT_ASSET = BASE_API_URL + `/nft/asset`
export const GET_COUNTDOWN = BASE_API_URL + `/nft/mint/date`

export const SUBSCRIBE = BASE_API_URL + `/user/newsletter/subscribe?`

export const GET_ZENDESK_ARTICLES = `https://hpblink.zendesk.com/api/v2/help_center/incremental/articles?start_time=1655839568`
export const GET_OAUTH_TOKEN = 'https://hpblink.zendesk.com/oauth/tokens'

export const CHECK_ADDR_VALIDITY = BASE_API_URL + '/financial/address/deposit-withdraw/is-allow?'
export const CHECK_VERIFICATION_DETAIL = BASE_API_URL + '/financial/address/verification?address='
