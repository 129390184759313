import React, {useEffect, useState} from 'react';
import './App.css';
import Navbar from './components/Navbar'
import {BrowserRouter as Router, Redirect, Route, Switch} from 'react-router-dom'
import Home from './components/HomePage/Home';
import ContactUs from './components/ContactUsPage/ContactUs'
import AboutUs from './components/AboutUsPage/AboutUs'
import Wealth from './components/WealthPage/Wealth'

import Footer from './components/Footer/Footer';
import Login from './components/Login/Login';
import Register from './components/Register/Register'
import useWindowDimensions from "./utils/WindowDimensions";
import UserCenter from "./components/UserCenter/UserCenter";
import UserProfile from "./components/UserCenter/UserProfile";

import PlatformFooter from "./components/Footer/PlatformFooter";
import PlatformNav from "./components/Nav/PlatformNav";
import {useDispatch, useSelector} from "react-redux";
import {authActions} from "./redux/actions/authActions";
import Verification from "./components/Verification/Verification";
import ForgotPassword from "./components/ForgotPassword/ForgotPassword";
import Jump from "./components/Jump/Jump";
import ScrollToTop from "./utils/ScrollToTop";
import Balance from "./components/Balance/Balance";
import Fund from "./components/Fund/Fund";
import History from './components/History/HistoryWrapper'
import QuickTrade from "./components/QuickTrade/QuickTrade";
import Setting from "./components/Setting/Setting";
import Withdraw from "./components/Withdraw/Withdraw";
import NotFound from "./components/NotFound/NotFound";
import Referral from "./components/Referral/Referral";
import ReferralFromHome from "./components/Referral/ReferralFromHome";
import LiveChat from './components/LiveChat/LiveChat'
import Nft from "./components/Nft/Nft";
import NftHomePage from "./components/Nft/NftHomePage";

function App(){
    const { loggedIn, userInfo, loading, token, checkLoading } = useSelector(state => state.auth)
    const { height, width } = useWindowDimensions();
    const dispatch = useDispatch();

    const [callBack, setCallBack] = useState(false)
    const [initialing, setInitialing] = useState(true)
    useEffect(() => {
        setInitialing(false)
        dispatch(authActions.checkUser())
    }, []);


    useEffect(() => {
        console.log('window.location.host: ', window.location.host)
    }, [])

    useEffect(() => {
        if (!loading && !loggedIn) {
            setCallBack(true)
        }
    }, [loading, loggedIn])

    useEffect(()=>{
        if (!initialing) {
            if (loggedIn && !userInfo.lastName && checkPath()){
                window.location.replace (window.location.origin + ('/verification'))
            }
        }
        return () => {
        }
    },[userInfo])

    function checkPath (){
        if (window.location.pathname === "/"){
            return false
        }else if(window.location.pathname === "/verification"){
            return false
        }else if(window.location.pathname === "/otc"){
            return false
        }else if(window.location.pathname === "/referral-info"){
            return false
        }
        else{
            return true
        }
    }

  return(
      !initialing && !checkLoading ?
        <Router>
            <ScrollToTop>
                <Switch>
                    <Route path='/' exact component = {Home} >
                        <Navbar width = {width}/>
                        {
                            window.location.host === 'wealth.yesbit.io' || window.location.host === 'www.wealth.yesbit.io' ?
                                <Wealth /> :
                                loggedIn ?
                                    <Redirect to='/trade'/> :
                                    <Home width = {width} height={height} />
                        }
                        <LiveChat width={width} height={height} />
                        <Footer width={width}/>
                    </Route>
                    <Route path='/otc' component = {ContactUs} >
                        <Navbar width = {width}/>
                        <ContactUs/>
                        <Footer width={width}/>
                    </Route>

                    <Route path='/wealth' component = {ContactUs} >
                        <Navbar width = {width}/>
                        <Wealth width = {width}/>
                        <Footer width={width}/>
                    </Route>
                    <Route path='/about-us' component = {AboutUs} >
                        <Navbar width = {width}/>
                        <AboutUs width = {width}/>
                        <Footer width={width}/>
                    </Route>
                    <Route path='/referral-info' component = {ReferralFromHome}>

                        <Navbar width = {width}/>
                        <ReferralFromHome width = {width}/>
                        <Footer width={width}/>

                    </Route>
                    <Route path='/login' exact>
                        {loggedIn ?
                            <Redirect to='/trade'/>
                            :< Login width = {width} height={height} />
                        }
                    </Route>
                    <Route path='/register' exact>
                        {loggedIn ?
                            <Redirect to='/trade'/>
                            : <Register width={width} height={height}/>
                        }
                    </Route>
                    <Route path='/forgot' exact>
                        {loggedIn ?
                            <Redirect to='/trade'/>
                            : <ForgotPassword width={width} height={height}/>
                        }
                    </Route>

                    <Route path='/verification' exact>
                        {
                            loggedIn && !userInfo.lastName?
                                <Verification width={width} height={height}/>
                                : <Redirect to='/login'/>

                        }
                    </Route>
                    <Route path='/user' exact>
                        {loggedIn ?
                            <>
                                <PlatformNav width = {width}/>
                                <UserCenter width = {width} height={height} />
                                <PlatformFooter width = {width}/>
                            </>
                            : <Redirect to='/login'/>
                        }
                    </Route>
                    <Route path='/userProfile' exact>
                        {loggedIn ?
                            <>
                                <PlatformNav width = {width}/>
                                <UserProfile width = {width} height={height} />
                                <PlatformFooter width = {width}/>
                            </>
                            : <Redirect to='/login'/>
                        }
                    </Route>
                    <Route path='/redirect' exact>
                            <>
                                <PlatformNav width = {width}/>
                                <Jump width={width} height={height}/>
                                <PlatformFooter width = {width}/>
                            </>
                    </Route>
                    <Route path='/balance' exact>
                        {loggedIn ?
                            <>
                                <PlatformNav width = {width}/>
                                <Balance width = {width} height={height} />
                                <PlatformFooter width = {width}/>

                            </>
                            : <Redirect to='/login'/>
                        }
                    </Route>
                    <Route path='/trade' exact>
                        {
                            loggedIn ?
                                <>
                                    <PlatformNav width = {width}/>
                                    <QuickTrade width = {width} height={height} />
                                    <LiveChat width={width} height={height} />
                                    <PlatformFooter width = {width}/>
                                </>
                                : <Redirect to='/login'/>

                        }
                    </Route>
                    <Route path='/fund' exact>
                        {
                            loggedIn ?
                                <>
                                    <PlatformNav width = {width}/>
                                    <Fund width = {width} height={height} />
                                    <PlatformFooter width = {width}/>
                                </>
                                : <Redirect to='/login'/>
                        }
                    </Route>
                    <Route path='/withdraw' exact>
                        {loggedIn ?
                            <>
                                <PlatformNav width = {width}/>
                                <Withdraw width = {width} height={height} />
                                <PlatformFooter width = {width}/>
                            </>
                            : <Redirect to='/login'/>
                        }
                    </Route>
                    <Route path='/history' exact>
                        {loggedIn ?
                            <>
                                <PlatformNav width = {width}/>
                                <History width = {width} height={height} />
                                <PlatformFooter width = {width}/>
                            </>
                            : <Redirect to='/login'/>
                        }
                    </Route>

                    <Route path='/setting' exact>
                        {loggedIn ?
                            <>
                                <PlatformNav width = {width}/>
                                <Setting width = {width} height={height} />
                                <PlatformFooter width = {width}/>
                            </>
                            : <Redirect to='/login'/>
                        }
                    </Route>

                    <Route path='/referral' exact>
                        {loggedIn ?
                            <>
                                <PlatformNav width = {width}/>
                                <Referral width = {width} height={height} />
                                <PlatformFooter width = {width}/>
                            </>
                            : <Redirect to='/login'/>
                        }
                    </Route>

                    <Route path='/nft' exact>
                        {loggedIn ?
                            <>
                                <PlatformNav width = {width}/>
                                <Nft width = {width} height={height} />
                                <PlatformFooter width = {width}/>
                            </>
                            : <Redirect to='/login'/>
                        }
                    </Route>

                    <Route path='/nft-homepage' exact>

                        <Navbar width = {width}/>

                        <NftHomePage width = {width}/>
                        <Footer width={width}/>
                    </Route>


                    <Route>
                        <>
                            <PlatformNav width = {width}/>
                            <NotFound />
                            <PlatformFooter width = {width}/>
                        </>
                    </Route>

                </Switch>
            </ScrollToTop>
        </Router>
      :null
  )
}

export default App
