import React, {useEffect, useState} from 'react'

import HeroSection from './HeroSection';
import ProcessSection from './ProcessSection';
import ProcessSellSection from './ProcessSellSection';
import LastContainer from './LastContainer';
import HorizontalScrollBar from './HorizontalScrollBar';

import TodayMarket from './TodayMarket';
import ProcessSellSection2 from './ProcessSellSection2';

function Home({ width, height }) {

    const [phone, setPhone] = useState(width <= 745);
    useEffect(() => {
        setPhone(width <= 745)


    }, [width]);
    return (
        <div style={{ display: 'flex', flexDirection: 'column', overflow: 'hidden' }}>
            <HorizontalScrollBar phone ={phone}/>

            <HeroSection width={width} height={height} />

            <TodayMarket phone={phone}/>
            <ProcessSection phone={phone}/>
            <ProcessSellSection phone={phone}/>

            <ProcessSellSection2 phone={phone}/>
            {/* hide for now phone download
             <DownloadSection phone={phone}/>
             */}
            <LastContainer phone={phone}/>

        </div>
    )
}

export default Home
