import { withTranslation } from "react-i18next";
import React, { useEffect, useState } from "react";
import { Button, InputBase, InputAdornment, IconButton, Typography } from '@material-ui/core';
import { VisibilityOff, Visibility } from '@material-ui/icons';
import { Link, useLocation } from 'react-router-dom'
import leftPage from "../../images/leftPage.png";
import pageLogo from "../../images/pageLogo.png";
import i18n from '../../i18n';
import { authActions } from "../../redux/actions/authActions";
import { useDispatch, useSelector } from "react-redux";
import GreenSuccess from "../../images/passed.png";
import { Helmet } from "react-helmet";
import {checkRoutes} from "../../utils/CheckRoutes";

function Login({ t, width, height }) {
    let query = new URLSearchParams(useLocation().search);
    let resetPassword = query.get("reset")
    let redirect = query.get('redirect')
    const [values, setValues] = useState({
        username: '',
        password: '',
        showPassword: false,
    });
    const { loggingIn, loginMsg, loginFailed } = useSelector(state => state.auth)
    const [loginMessage, setLoginMessage] = useState(false);
    const [phone, setPhone] = useState(width <= 745);
    const dispatch = useDispatch();

    const handleChange = (prop) => (event) => {
        setValues({ ...values, [prop]: event.target.value });
    };

    const handleClickShowPassword = () => {
        setValues({ ...values, showPassword: !values.showPassword });
    };

    const handleMouseDownPassword = (event) => {
        event.preventDefault();
    };

    const changeLanguage = (e) => {
        let newLang = i18n.language === 'en' ? 'cn' : 'en'
        i18n.changeLanguage(newLang);
        localStorage.setItem('lng', newLang)
    }

    const handleLogin = () => {
        dispatch(authActions.logIn(values))
    }

    const buttonDisable = () => {
        return values.password.length === 0 || values.username.length === 0 || loggingIn
    }

    const resetPasswordSuccess = () => {
        return (
            <Button
                style={
                    phone ? { width: "100%", height: "65px", backgroundColor: "#4D8C7B", borderRadius: 10.8, textTransform: "none", padding: "17.8px 11px", justifyContent: 'left' }
                        : { width: "100%", height: "107.1px", backgroundColor: "#4D8C7B", borderRadius: 18, textTransform: "none", padding: "28px 20px", justifyContent: 'left' }
                }
                disabled
            >
                <img alt='' src={GreenSuccess} style={phone ? { width: 27, height: 27 } : { width: 44, height: 44 }} />
                <div style={phone ? { margin: "0px 9.52px" } : { margin: "0px 17px" }}>
                    <Typography
                        style={
                            phone ? { fontFamily: "Avenir", color: "white", fontSize: 12, fontWeight: 900, letterSpacing: 0, lineHeight: "16px", textAlign: 'initial' }
                                : { fontFamily: "Avenir", color: "white", fontSize: 20, fontWeight: 900, letterSpacing: 0, lineHeight: "30px", textAlign: 'initial' }
                        }
                    >
                        {t('passwordReset')}
                    </Typography>
                    <Typography
                        style={
                            phone ? { fontFamily: "Avenir", color: "white", fontSize: 8, fontWeight: 900, letterSpacing: 0, lineHeight: "11px", textAlign: 'initial' }
                                : { fontFamily: "Avenir", color: "white", fontSize: 14, fontWeight: 900, letterSpacing: 0, lineHeight: "19px", textAlign: 'initial' }
                        }
                    >
                        {t('passwordResetSubtitle')}
                    </Typography>
                </div>
            </Button>
        )
    }

    const redirectSuccess = () => {
        return (
            <Button
                style={
                    phone ? { width: "100%", height: "65px", backgroundColor: "#4D8C7B", borderRadius: 10.8, textTransform: "none", padding: "17.8px 11px", justifyContent: 'left' }
                        : { width: "100%", height: "107.1px", backgroundColor: "#4D8C7B", borderRadius: 18, textTransform: "none", padding: "28px 20px", justifyContent: 'left' }
                }
                disabled
            >
                <img alt='' src={GreenSuccess} style={phone ? { width: 27, height: 27 } : { width: 44, height: 44 }} />
                <div style={phone ? { margin: "0px 9.52px" } : { margin: "0px 17px" }}>
                    <Typography
                        style={
                            phone ? { fontFamily: "Avenir", color: "white", fontSize: 12, fontWeight: 500, letterSpacing: 0, lineHeight: "16px", textAlign: 'initial' }
                                : { fontFamily: "Avenir", color: "white", fontSize: 20, fontWeight: 500, letterSpacing: 0, lineHeight: "30px", textAlign: 'initial' }
                        }
                    >
                        {t('redirect')}
                    </Typography>
                    <Typography
                        style={
                            phone ? { fontFamily: "Avenir", color: "white", fontSize: 8, fontWeight: 500, letterSpacing: 0, lineHeight: "11px", textAlign: 'initial' }
                                : { fontFamily: "Avenir", color: "white", fontSize: 14, fontWeight: 500, letterSpacing: 0, lineHeight: "19px", textAlign: 'initial' }
                        }
                    >
                        {t('redirectSubtitle')}
                    </Typography>
                </div>
            </Button>
        )
    }

    useEffect(() => {
        setPhone(width <= 745)
    }, [width]);

    useEffect(() => {
        if (loginFailed) {
            dispatch(authActions.clearLoginState())
            setLoginMessage(true)
        }
    }, [loggingIn]);

    useEffect(() => {
        checkRoutes()
    }, [])

    return (

        <div style={phone ? { display: 'block' } : { display: 'flex' }}>
            <Helmet>
                <title>{t('titleLogin')}</title>
                <meta name="keywords" content="Yesbit登陆,login Toronto, OTC, bitcoin, exchange, cryptocurrency, 多伦多, 场外交易平台, 比特币, 以太坊, 加元, 换汇,加密货币, 人民币,多倫多, 場外交易平台, 比特幣, 以太坊, 加元, 換匯, 人民幣, Yesbit, Fiat, CAD, BTC, ETH, USD" />
            </Helmet>
            <div
                style={
                    phone ? { width: '100%', height: '142px', backgroundImage: `url(${leftPage})`, backgroundSize: `min(${width * 0.45}px,613px) min(${width * 0.6}px,817px)`, backgroundRepeat: 'repeat', overflow: 'hidden' }
                        : { position: 'fixed', width: '50%', height: height, backgroundImage: `url(${leftPage})`, backgroundSize: `min(${width * 0.45}px,613px) min(${width * 0.6}px,817px)`, backgroundRepeat: 'repeat', borderRadius: '0px 16px 16px 0px', overflow: 'hidden' }
                }
            >
                <img
                    alt=''
                    src={pageLogo}
                    style={
                        phone ? { width: 90, height: 90, position: 'relative', transform: 'translate(-50%,-50%)', top: '50%', left: '50%', opacity: 0.39 }
                            : { width: 0.3 * width, height: 0.3 * width, position: 'relative', transform: 'translate(-50%,-50%)', top: '50%', left: '50%' }
                    }
                />

            </div>
            <div style={{ marginLeft: phone ? null : '50%', width: phone ? '100%' : '50%', height: '100%', textAlign: 'center' }}>
                <div style={{ margin: phone ? '5% auto' : '100px auto', maxWidth: 544, padding: '0px 29px' }}>
                    {resetPassword === 'true'
                        ? resetPasswordSuccess()
                        : redirect === 'true' ?
                            redirectSuccess() :
                            <div style={{ display: 'flex', alignItems: 'flex-start', flexDirection: 'column' }}>

                                <Typography
                                    style={
                                        phone ? { fontFamily: 'Avenir', fontSize: 24, fontWeight: "500", letterSpacing: 0, lineHeight: '25px', textAlign: 'initial' }
                                            : { fontFamily: 'Avenir', fontSize: 40, fontWeight: "500", letterSpacing: 0, lineHeight: '35px' }
                                    }
                                >
                                    {t('login')}
                                </Typography>
                                <Typography
                                    style={
                                        phone ? { marginTop: 8, fontFamily: 'Avenir', color: '#9097A1', fontSize: 12, fontWeight: 500, letterSpacing: 0, lineHeight: '14px', textAlign: 'initial' }
                                            : { marginTop: 8, fontFamily: 'Avenir', color: '#9097A1', fontSize: 14, fontWeight: 500, letterSpacing: 0, lineHeight: '19px', textAlign: 'initial' }
                                    }
                                >
                                    {t('login_subtitle')}
                                </Typography>
                            </div>
                    }

                    <div style={{ marginTop: phone ? 29.4 : 60 }}>
                        <Typography
                            style={
                                phone ? { marginBottom: 5, textAlign: 'initial', fontFamily: 'Avenir', fontSize: 12, fontWeight: 500, letterSpacing: 0, lineHeight: '14px' }
                                    : { marginBottom: 6, textAlign: 'initial', fontFamily: 'Avenir', fontSize: 16, fontWeight: 500, letterSpacing: 0, lineHeight: '22px' }
                            }
                        >
                            {t('email')}
                        </Typography>
                        <InputBase
                            style={
                                phone ? { borderRadius: 12, backgroundColor: 'white', width: '100%', height: '50px', fontSize: 12, padding: 14, border: "1px solid #EDEDED" }
                                    : { borderRadius: 16, backgroundColor: 'white', width: '100%', height: '60px', fontSize: 16, padding: 20, border: "1px solid #EDEDED" }
                            }
                            value={values.username}
                            onChange={handleChange('username')}
                            placeholder={t('placeHolderLoginEmail')}

                        />
                    </div>
                    <div style={{ marginTop: phone ? 23.4 : 30 }}>
                        <Typography
                            style={
                                phone ? { marginBottom: 5, textAlign: 'initial', fontFamily: 'Avenir', fontSize: 12, fontWeight: 500, letterSpacing: 0, lineHeight: '14px' }
                                    : { marginBottom: 6, textAlign: 'initial', fontFamily: 'Avenir', fontSize: 16, fontWeight: 500, letterSpacing: 0, lineHeight: '22px' }
                            }
                        >
                            {t('password')}
                        </Typography>
                        <InputBase
                            id="filled-adornment-password"
                            type={values.showPassword ? 'text' : 'password'}
                            style={
                                phone ? { borderRadius: 12, backgroundColor: 'white', width: '100%', height: '50px', fontSize: 12, padding: 14, border: "1px solid #EDEDED" }
                                    : { borderRadius: 16, backgroundColor: 'white', width: '100%', height: '55px', fontSize: 16, padding: 20, border: "1px solid #EDEDED" }
                            }
                            value={values.password}
                            placeholder={t('placeHolderLoginPassword')}
                            onChange={handleChange('password')}
                            endAdornment={
                                <InputAdornment position="end">
                                    <IconButton
                                        aria-label="toggle password visibility"
                                        onClick={handleClickShowPassword}
                                        onMouseDown={handleMouseDownPassword}
                                        edge="end"
                                    >
                                        {values.showPassword ? <Visibility /> : <VisibilityOff />}
                                    </IconButton>
                                </InputAdornment>
                            }
                        />
                    </div>

                    {
                        loginMessage
                            ? <Typography style={{ marginTop: phone ? 4 : 8, fontFamily: "Avenir", color: "#DC3055", fontSize: phone ? 10 : 12, fontWeight: 500, lineHeight: phone ? "11px" : '16px', textAlign: "left" }}>
                                {t(loginMsg)}
                            </Typography>
                            : null
                    }

                    <div style={{ marginTop: phone ? 20.5 : 30 }}>
                        <Link
                            to='/forgot'
                            style={
                                phone ? { textDecoration: 'none', fontFamily: 'Avenir', color: '#9097A1', fontSize: 12, fontWeight: 500, letterSpacing: 0, lineHeight: '14px' }
                                    : { textDecoration: 'none', fontFamily: 'Avenir', color: '#9097A1', fontSize: 16, fontWeight: 500, letterSpacing: 0, lineHeight: '20px' }
                            }
                        >
                            {t('forgotPassword')}
                        </Link>
                    </div>

                    <Button
                        style={
                            phone ? {
                                marginTop: '23px',
                                width: '100%',
                                height: '50px',
                                borderRadius: 16,
                                backgroundColor: '#081647',
                                fontFamily: 'Avenir',
                                color: 'white',
                                fontSize: 16,
                                fontWeight: 500,
                                letterSpacing: 0,
                                lineHeight: '16px',
                                textTransform: 'none',
                                opacity: buttonDisable() ? 0.3 : null
                            }
                                : {
                                    marginTop: '60px',
                                    width: '100%',
                                    height: '65px',
                                    borderRadius: 20,
                                    backgroundColor: '#081647',
                                    fontFamily: 'Avenir',
                                    color: 'white',
                                    fontSize: 20,
                                    fontWeight: 500,
                                    letterSpacing: 0,
                                    lineHeight: '25px',
                                    textTransform: 'none',
                                    opacity: buttonDisable() ? 0.3 : null
                                }
                        }
                        disabled={buttonDisable()}
                        onClick={() => {
                            handleLogin()
                        }}
                    >
                        {t('login')}
                    </Button>
                    <div style={{ margin: phone ? '23px 0px' : '30px 0px', display: 'flex', alignItems: 'center' }}>
                        <div style={{ backgroundColor: '#BAC3CE', height: 1, width: '100%' }}></div>
                        <Typography
                            style={
                                phone ? { margin: '0px 5.8px', fontFamily: 'Avenir', color: '#BAC3CE', fontSize: 10, fontWeight: 500, letterSpacing: 0, lineHeight: '18px' }
                                    : { margin: '0px 14.5px', fontFamily: 'Avenir', color: '#BAC3CE', fontSize: 18, fontWeight: 500, letterSpacing: 0, lineHeight: '25px' }
                            }
                        >
                            {t('or')}
                        </Typography>
                        <div style={{ backgroundColor: '#BAC3CE', height: 1, width: '100%' }}></div>
                    </div>


                    <Link to='/register' style={{ textDecoration: 'none' }}>
                        <Button
                            style={
                                phone ? {
                                    width: '100%',
                                    height: '50px',
                                    borderRadius: 16,
                                    backgroundColor: '#081647',
                                    fontFamily: 'Avenir',
                                    color: 'white',
                                    fontSize: 16,
                                    fontWeight: 500,
                                    letterSpacing: 0,
                                    lineHeight: '16px',
                                    textTransform: 'none'
                                }
                                    : {
                                        width: '100%',
                                        height: '65px',
                                        borderRadius: 20,
                                        backgroundColor: '#081647',
                                        fontFamily: 'Avenir',
                                        color: 'white',
                                        fontSize: 18,
                                        fontWeight: 500,
                                        letterSpacing: 0,
                                        lineHeight: '25px',
                                        textTransform: 'none'
                                    }
                            }
                        >
                            {t('signUp')}
                        </Button>
                    </Link>
                    <div style={{ marginTop: phone ? 23 : 20 }}>
                        <Link
                            style={{ fontFamily: 'PingFang SC', color: 'black', fontSize: phone ? 10 : 12, fontWeight: 500, lineHeight: '14px', textDecoration: 'none' }}
                            onClick={changeLanguage}
                        >
                            {localStorage.getItem('lng') === 'cn' ? "Switch to English" : "切换至中文"}
                        </Link>
                    </div>
                </div>
            </div>
        </div>

    )
}

export default withTranslation()(Login)
