import { withTranslation } from "react-i18next";
import React, { useState, useEffect } from "react";
import { Button, InputBase, InputAdornment, IconButton, Typography, Checkbox, makeStyles, colors } from '@material-ui/core';
import { VisibilityOff, Visibility } from '@material-ui/icons';
import { Link } from 'react-router-dom'
import Agreement from "./components/Agreement";
import RiskStatement from "./components/RiskStatement";

import VerificationInput from "react-verification-input";
import './Register.css';
import pageLogo from "../../images/pageLogo.png";
import leftPage from "../../images/leftPage.png";
import referralBanner from "../../images/referral/referralRegisterBanner.png"
import { checkEmail, isValidPassword } from "../../utils/Checker"
import { useDispatch, useSelector } from "react-redux";
import { authActions } from "../../redux/actions/authActions";
import { referralActions } from "../../redux/actions/referralActions";
import RedX from "../../images/failed.png";
import {checkRoutes} from "../../utils/CheckRoutes";

function Register({ t, width, height }) {
    const useStyles = makeStyles({
        inputLabel: {
            marginBottom: 6, textAlign: 'initial', fontFamily: 'Avenir', fontSize: 16, fontWeight: 500, letterSpacing: 0, lineHeight: '22px'
        },
        inputLabelPhone: {
            marginBottom: 5, textAlign: 'initial', fontFamily: 'Avenir', fontSize: 12, fontWeight: 500, letterSpacing: 0, lineHeight: '14px'
        },
        input: {
            borderRadius: 16, backgroundColor: 'white', width: '100%', height: '60px', fontSize: 16, padding: 20, border: "1px solid #EDEDED",

        },
        inputPhone: {
            borderRadius: 12, backgroundColor: 'white', width: '100%', height: '50px', fontSize: 12, padding: 14, border: "1px solid #EDEDED"
        },
        button: {
            width: '100%',
            height: '65px',
            borderRadius: 20,
            color: 'white !important',
            backgroundColor: '#081647',
            fontFamily: 'Avenir',
            fontSize: 20,
            fontWeight: 500,
            letterSpacing: 0,
            lineHeight: '25px',
            textTransform: 'none',
            "&:hover": {
                backgroundColor: '#081647',
                // Reset on touch devices, it doesn't add specificity
                "@media (hover: none)": {
                    backgroundColor: '#081647',
                    "&:active": {
                        backgroundColor: '#081647'
                    }
                },
                '&$disabled': {
                    backgroundColor: '#081647',
                },
            },
        },
        buttonPhone: {
            width: '100%',
            height: '50px',
            borderRadius: 16,
            color: 'white !important',
            backgroundColor: '#081647',
            fontFamily: 'Avenir',
            fontSize: 16,
            fontWeight: 500,
            letterSpacing: 0,
            lineHeight: '16px',
            textTransform: 'none',
            "&:hover": {
                backgroundColor: '#081647',
                // Reset on touch devices, it doesn't add specificity
                "@media (hover: none)": {
                    backgroundColor: '#081647',
                    "&:active": {
                        backgroundColor: '#081647'
                    }
                },
                '&$disabled': {
                    backgroundColor: '#081647',
                },
            },
        }
    });
    const classes = useStyles();
    const { signingUp, registerFailed, registerSuccess, signUpMsg } = useSelector(state => state.auth)
    const { referrer } = useSelector(state => state.referral)
    const [values, setValues] = useState({
        username: '',
        password: '',
        agreement: false,
        showPassword: false,
    });
    const [phone, setPhone] = useState(width <= 745);
    useEffect(() => {
        setPhone(width <= 745)
    }, [width]);

    useEffect(() => {
        checkRoutes()
    }, [])

    const [agreementVisible, setAgreementVisible] = useState(false);
    const [riskStatementVisible, setRiskStatementVisible] = useState(false);

    const [step1, setStep1] = useState(true);
    const [step2, setStep2] = useState(false);
    const [verificationCode, setVerificationCode] = useState('');
    const [timeLeft, setTimeLeft] = useState(0);
    const [validInputWarning, setValidInputWarning] = useState({ username: false, password: false });
    const [signUpMessage, setSignUpMessage] = useState(false);
    const [accountUsedMessage, setAccountUsedMessage] = useState(false);
    const dispatch = useDispatch();
    let timeout;
    let urlParams = new URLSearchParams(window.location.search);
    let referralCode = urlParams.has('referralCode') ? urlParams.get('referralCode') : null;

    const handleChange = (prop) => (event) => {
        setValues({ ...values, [prop]: event.target.value });
        if (prop === 'username') {
            setValidInputWarning({ ...validInputWarning, [prop]: !checkEmail(event.target.value) })
        }
        if (prop === 'password') {
            setValidInputWarning({ ...validInputWarning, [prop]: !isValidPassword(event.target.value) })
        }
    };

    const handleClickShowPassword = () => {
        setValues({ ...values, showPassword: !values.showPassword });
    };

    const handleClickAgreement = () => {
        setValues({ ...values, agreement: !values.agreement });
    };

    const handleMouseDownPassword = (event) => {
        event.preventDefault();
    };

    const handleSendVerificationCode = () => {
        dispatch(authActions.sendVerificationCode(values.username));
        setTimeLeft(60);
    };

    const handleCreateAccountStep1 = () => {
        dispatch(authActions.sendVerificationCode(values.username));
        setStep1(false);
        setStep2(true);
        clearTimeout(timeout);
        setTimeLeft(60);
    };
    const handleCreateAccount = () => {
        let payload = {
            username: values.username,
            password: values.password,
            emailCode: verificationCode,
            referralCode: referralCode
        }
        dispatch(authActions.signUp(payload))
    }

    const createAccountStep1Disable = () => {
        return !checkEmail(values.username) || !isValidPassword(values.password) || !values.agreement
    }

    const createAccountDisable = () => {
        return verificationCode.length != 6
    }

    useEffect(() => {
        dispatch(referralActions.getUsernameByReferralCode(referralCode))
        return () => {
            dispatch(referralActions.clearReferrerUsername())
        }
    }, []);

    useEffect(() => {
        timeLeft > 0 && (timeout = setTimeout(() => setTimeLeft(timeLeft - 1), 1000));
    }, [timeLeft]);

    useEffect(() => {
        if (registerSuccess) {
            dispatch(authActions.clearSignUpState())
            dispatch(referralActions.clearReferrerUsername())
        }
        if (registerFailed && signUpMsg === 'emailUsed' || registerFailed && signUpMsg === 'invalidReferralCode') {
            setStep1(true);
            setStep2(false);
            setValues({
                username: values.username,
                password: '',
                agreement: false,
                showPassword: false,
            });
            setTimeLeft(0);
            setVerificationCode('')
            setAccountUsedMessage(true)
            dispatch(authActions.clearSignUpState())
        } else if (registerFailed) {
            setSignUpMessage(true)
            dispatch(authActions.clearSignUpState())
        }
    }, [signingUp]);

    const signUpError = () => {
        return (
            <Button
                style={
                    phone ? { width: "100%", height: "65px", backgroundColor: "#DC3055", borderRadius: 10.8, textTransform: "none", padding: "17.8px 11px", justifyContent: 'left' }
                        : { width: "100%", height: "107.1px", backgroundColor: "#DC3055", borderRadius: 18, textTransform: "none", padding: "28px 20px", justifyContent: 'left' }
                }
                disabled
            >
                <img alt='' src={RedX} style={phone ? { width: 27, height: 27 } : { width: 44, height: 44 }} />
                <div style={phone ? { margin: "0px 9.52px" } : { margin: "0px 17px" }}>
                    <Typography
                        style={
                            phone ? { fontFamily: "Avenir", color: "white", fontSize: 12, fontWeight: 500, letterSpacing: 0, lineHeight: "16px", textAlign: 'initial' }
                                : { fontFamily: "Avenir", color: "white", fontSize: 20, fontWeight: 500, letterSpacing: 0, lineHeight: "30px", textAlign: 'initial' }
                        }
                    >
                        {signUpMsg === 'emailUsed' ? t('emailUsedTitle') : t('signUpFailed')}
                    </Typography>
                    <Typography
                        style={
                            phone ? { fontFamily: "Avenir", color: "white", fontSize: 8, fontWeight: 500, letterSpacing: 0, lineHeight: "11px", textAlign: 'initial' }
                                : { fontFamily: "Avenir", color: "white", fontSize: 14, fontWeight: 500, letterSpacing: 0, lineHeight: "19px", textAlign: 'initial' }
                        }
                    >
                        {t(signUpMsg)}
                    </Typography>
                </div>
            </Button>
        )
    }


    const showStatementBox = (status) => {
        if (status === 1) {
            setAgreementVisible(true)

        } else {
            setRiskStatementVisible(true)

        }
    }

    const registerAccount = () => {
        return (
            <div>
                {
                    accountUsedMessage ?
                        signUpError()
                        :
                        <div style={{ display: 'flex', alignItems: 'flex-start', flexDirection: 'column' }}>
                            {referralCode ? <img src={referralBanner} style={{ width: '100%', height: phone ? width * 0.4 : width * 0.21, maxHeight: 250, marginBottom: 30 }} /> : null}
                            <Typography
                                style={
                                    phone ? { fontFamily: 'Avenir', fontSize: 24, fontWeight: 500, letterSpacing: 0, lineHeight: '25px', textAlign: 'initial' }
                                        : { fontFamily: 'Avenir', fontSize: 40, fontWeight: 500, letterSpacing: 0, lineHeight: '35px', textAlign: 'initial' }
                                }
                            >
                                {referralCode ? referrer + t('registerInvitedBy') : t('signUp')}
                            </Typography>

                            <Typography
                                style={
                                    phone ? { marginTop: 8, fontFamily: 'Avenir', color: '#9097A1', fontSize: 12, fontWeight: 500, letterSpacing: 0, lineHeight: '14px', textAlign: 'initial' }
                                        : { marginTop: 8, fontFamily: 'Avenir', color: '#9097A1', fontSize: 14, fontWeight: 500, letterSpacing: 0, lineHeight: '19px', textAlign: 'initial' }
                                }
                            >
                                {referralCode ? t('registerInvitedBySubtitle') : t('signUp_subtitle')}
                            </Typography>
                        </div>
                }


                <div style={{ marginTop: phone ? 29.4 : 60 }}>
                    <Typography
                        className={phone ? classes.inputLabelPhone : classes.inputLabel}
                    >
                        {t('email')}
                    </Typography>

                    <InputBase
                        className={phone ? classes.inputPhone : classes.input}
                        value={values.username}
                        onChange={handleChange('username')}
                        placeholder={t('placeHolderLoginEmail')}

                    />
                </div>
                {
                    validInputWarning.username
                        ? <Typography style={{ marginTop: phone ? 4 : 8, fontFamily: "Avenir", color: "#DC3055", fontSize: phone ? 12 : 14, fontWeight: 500, lineHeight: phone ? "11px" : '16px', textAlign: "left" }}>
                            {t('emailWarning')}
                        </Typography>
                        : null
                }
                <div style={{ marginTop: phone ? 23.4 : 30 }}>
                    <Typography
                        className={phone ? classes.inputLabelPhone : classes.inputLabel}
                    >
                        {t('createPassword')}
                    </Typography>
                    <InputBase
                        id="filled-adornment-password"
                        type={values.showPassword ? 'text' : 'password'}
                        className={phone ? classes.inputPhone : classes.input}
                        value={values.password}
                        placeholder={t('placeHolderLoginCreatePassword')}
                        onChange={handleChange('password')}
                        endAdornment={
                            <InputAdornment position="end">
                                <IconButton
                                    aria-label="toggle password visibility"
                                    onClick={handleClickShowPassword}
                                    onMouseDown={handleMouseDownPassword}
                                    edge="end"
                                >
                                    {values.showPassword ? <Visibility /> : <VisibilityOff />}
                                </IconButton>
                            </InputAdornment>
                        }
                    />
                </div>
                {
                    validInputWarning.password
                        ? <Typography style={{ marginTop: phone ? 4 : 8, fontFamily: "Avenir", color: "#DC3055", fontSize: phone ? 12 : 14, fontWeight: 500, lineHeight: phone ? "11px" : '16px', textAlign: "left" }}>
                            {t('passwordWarning')}
                        </Typography>
                        : null
                }
                <div style={{ marginTop: phone ? 29.5 : 40, textAlign: "left" }}>
                    <Checkbox
                        checked={values.agreement}
                        style={{ padding: 0 }}
                        onChange={handleClickAgreement}
                        color="default"
                        inputProps={{ 'aria-label': 'checkbox with default color' }}
                    />
                    <Typography
                        style={
                            phone ? { display: 'inline', textDecoration: 'none', fontFamily: 'Avenir', color: '#9097A1', fontSize: 10, fontWeight: 500, letterSpacing: 0, lineHeight: '14px' }
                                : { display: 'inline', textDecoration: 'none', fontFamily: 'Avenir', color: '#9097A1', fontSize: 14, fontWeight: 500, letterSpacing: 0, lineHeight: '20px' }
                        }
                    >
                        {t('read')}
                        <div style={{ display: 'inline', color: 'black', cursor: 'pointer' }} onClick={() => { showStatementBox(1) }}>
                            {" " + t('serviceAgreement') + " "}
                        </div>
                        {t('read1')}
                        <div style={{ display: 'inline', color: 'black', cursor: 'pointer' }} onClick={() => { showStatementBox(2) }}>
                            {" " + t('riskStatement')}
                        </div>
                    </Typography>
                </div>

                <Button
                    className={phone ? classes.buttonPhone : classes.button}
                    style={{ marginTop: phone ? '51px' : '60px', opacity: createAccountStep1Disable() ? 0.3 : null, color: "white" }}
                    onClick={handleCreateAccountStep1}
                    disabled={createAccountStep1Disable()}
                >
                    {t('createAccount')}
                </Button>
                <div style={{ margin: phone ? '30px 0px 23.6px 0px' : '30px 0px', display: 'flex', alignItems: 'center' }}>
                    <div style={{ backgroundColor: '#979797', height: 1, width: '100%' }}></div>
                    <Typography
                        style={
                            phone ? { margin: '0px 5.8px', fontFamily: 'Avenir', color: '#979797', fontSize: 10, fontWeight: 500, letterSpacing: 0, lineHeight: '18px' }
                                : { margin: '0px 14.5px', fontFamily: 'Avenir', color: '#979797', fontSize: 18, fontWeight: 500, letterSpacing: 0, lineHeight: '25px' }
                        }
                    >
                        {t('or')}
                    </Typography>
                    <div style={{ backgroundColor: '#979797', height: 1, width: '100%' }}></div>
                </div>

                <Link to={'/login'} style={{ textDecoration: 'none' }}>
                    <Button
                        className={phone ? classes.buttonPhone : classes.button}
                    >
                        {t('login')}
                    </Button>
                </Link>
            </div>
        )
    };

    const verification = () => {
        return (
            <div>
                <div style={{ display: 'flex', alignItems: 'flex-start', flexDirection: 'column' }}>
                    <Typography
                        style={
                            phone ? { fontFamily: 'Avenir', fontSize: 24, fontWeight: 500, letterSpacing: 0, lineHeight: '25px', textAlign: 'initial' }
                                : { fontFamily: 'Avenir', fontSize: 40, fontWeight: 500, letterSpacing: 0, lineHeight: '35px' }
                        }
                    >
                        {t('signUp')}
                    </Typography>
                    <Typography
                        style={
                            phone ? { marginTop: 3, fontFamily: 'Avenir', color: '#9097A1', fontSize: 12, fontWeight: 500, letterSpacing: 0, lineHeight: '14px', textAlign: 'initial' }
                                : { marginTop: 4, fontFamily: 'Avenir', color: '#9097A1', fontSize: 16, fontWeight: 500, letterSpacing: 0, lineHeight: '19px' }
                        }
                    >
                        {t('verificationSubtitle') + " " + values.username}
                    </Typography>
                </div>
                <VerificationInput
                    removeDefaultStyles
                    classNames={{
                        container: width > 960 ? "verification_container" : "verification_container_phone",
                        character: width > 960 ? "verification_character" : "verification_character_phone",
                        characterInactive: "verification_character--inactive",
                        characterSelected: "verification_character--selected",
                    }}
                    value={verificationCode}
                    onChange={(e) => { setVerificationCode(e) }}
                // placeholder={"*"}
                />
                {
                    signUpMessage ?
                        <Typography style={{ marginTop: phone ? 6.37 : 12.84, fontFamily: "Avenir", color: "#DC3055", fontSize: phone ? 12 : 14, fontWeight: 500, lineHeight: phone ? "11px" : '16px', textAlign: "left" }}>
                            {t(signUpMsg)}
                        </Typography>
                        : null

                }

                <div style={{ marginTop: phone ? 41.4 : 60 }}>
                    <Button
                        style={
                            phone ? { textTransform: 'none', fontFamily: 'Avenir', fontSize: 10, fontWeight: 500, letterSpacing: 0, lineHeight: '14px' }
                                : { textTransform: 'none', fontFamily: 'Avenir', fontSize: 14, fontWeight: 500, letterSpacing: 0, lineHeight: '20px' }
                        }
                        onClick={handleSendVerificationCode}
                        disabled={timeLeft > 0}
                    >
                        {t('resendCode')}{timeLeft > 0 ? '(' + timeLeft + 's)' : null}
                    </Button>
                </div>

                <Button
                    className={phone ? classes.buttonPhone : classes.button}
                    style={{ marginTop: phone ? '100px' : '60px', color: "white", opacity: createAccountDisable() ? 0.3 : null }}
                    onClick={handleCreateAccount}
                    disabled={createAccountDisable()}
                >
                    {t('createAccount')}
                </Button>
                <div style={{ margin: phone ? '23px 0px' : '30px 0px', display: 'flex', alignItems: 'center' }}>
                    <div style={{ backgroundColor: '#979797', height: 1, width: '100%' }}></div>
                    <Typography
                        style={
                            phone ? { margin: '0px 5.8px', fontFamily: 'Avenir', color: '#BAC3CE', fontSize: 12.6, fontWeight: 500, letterSpacing: 0, lineHeight: '18px' }
                                : { margin: '0px 14.5px', fontFamily: 'Avenir', color: '#BAC3CE', fontSize: 18, fontWeight: 500, letterSpacing: 0, lineHeight: '25px' }
                        }
                    >
                        {t('or')}
                    </Typography>
                    <div style={{ backgroundColor: '#979797', height: 1, width: '100%' }}></div>
                </div>

                <Link to={'/login'} style={{ textDecoration: 'none' }}>
                    <Button
                        className={phone ? classes.buttonPhone : classes.button}
                    >
                        {t('login')}
                    </Button>
                </Link>
            </div>
        )
    };

    return (
        <div style={{ display: phone ? 'block' : 'flex' }}>
            <div
                style={
                    phone ? { width: '100%', height: '142px', backgroundImage: `url(${leftPage})`, backgroundSize: `min(${width * 0.45}px,613px) min(${width * 0.6}px,817px)`, backgroundRepeat: 'repeat', overflow: 'hidden' }
                        : { position: 'fixed', width: '50%', height: height, backgroundImage: `url(${leftPage})`, backgroundSize: `min(${width * 0.45}px,613px) min(${width * 0.6}px,817px)`, backgroundRepeat: 'repeat', borderRadius: '0px 16px 16px 0px', overflow: 'hidden' }
                }
            >
                <img
                    alt=''
                    src={pageLogo}
                    style={
                        phone ? { width: 90, height: 90, position: 'relative', transform: 'translate(-50%,-50%)', top: '50%', left: '50%', opacity: 0.39 }
                            : { width: 0.3 * width, height: 0.3 * width, position: 'relative', transform: 'translate(-50%,-50%)', top: '50%', left: '50%' }
                    }
                />
            </div>
            <div style={{ marginLeft: phone ? null : '50%', width: phone ? '100%' : '50%', height: '100%', textAlign: 'center' }}>
                <div style={{ margin: phone ? '5% auto' : referralCode ? '50px auto' : '100px auto', maxWidth: 544, padding: '0 29px' }}>
                    {step1 ?
                        registerAccount()
                        : step2 ?
                            verification()
                            : null
                    }

                </div>
            </div>

            {/*协议*/}
            <Agreement
                onCancel={() => {
                    setAgreementVisible(false);
                }}
                modalVisible={agreementVisible}
                t
                phone={phone}
                width={width}
            />

            <RiskStatement
                onCancel={() => {
                    setRiskStatementVisible(false);
                }}
                modalVisible={riskStatementVisible}
                t
                phone={phone}
                width={width}
            />

        </div>

    )
}

export default withTranslation()(Register)
