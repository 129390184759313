import React, { useEffect, useState } from 'react'
import { withTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { authActions } from "../../redux/actions/authActions";
import { kycActions } from "../../redux/actions/kycActions";
import { fundsActions } from "../../redux/actions/fundsActions";
import {
    Button,
    List,
    ListItem,
    ListItemAvatar,
    ListItemSecondaryAction,
    makeStyles,
    Modal,
    Typography
} from '@material-ui/core';
import passed from '../../images/passed.png';
import "react-datepicker/dist/react-datepicker.css";
import { useHistory } from "react-router-dom";
import { FormatNumber, getIcons, roundingDown } from "../../utils/Common";
import safe from '../../images/2fa.png'
import EliteKycStatus from "../KycStatus/EliteKycStatus";
import depositIcon from "../../images/depositIcon.png"
import withdrawIcon from "../../images/withdrawIcon.png"
import warningicon from "../../images/warningicon.png"
import noBalancePlaceHolder from "../../images/noBalancePlaceHolder.svg"
import { motion } from "framer-motion"
import enterArrow from "../../images/enterArrow.png";
import x from "../../images/closePopIcon.png";
import { Helmet } from "react-helmet";
import historyIcon from '../../images/historyIconBlack.png';

function Balance({ t, width }) {
    const dispatch = useDispatch();
    const { token, userInfo } = useSelector(state => state.auth)
    const { userCapitals, fundsTransactions, priceQuote, ethQuote, btcQuote } = useSelector(state => state.funds)
    const [phone, setPhone] = useState(width <= 745);
    const [loaded, setLoaded] = useState(false)
    const [updateInfoVisible, setUpdateInfoVisible] = useState(false);
    const [kycVisible, setKycVisible] = useState(false);
    const [CADToUSDT, setCADToSUDT] = useState(0)

    const [authModalVisible, setAuthModalVisible] = useState(false)

    const [canWithdraw, setCanWithdraw] = useState(false)

    const [totalBalance, setTotalBalance] = useState(0)

    const goToFund = () => {
        history.push('/fund')
    }

    const goToWithdraw = () => {
        if (canWithdraw) {
            history.push('/withdraw')
        } else {
            setAuthModalVisible(true)
        }
    }


    const useStyles = makeStyles({

        tableCell: {
            backgroundColor: '#F9F9F9',
            marginBottom: 15,
            borderRadius: 12,
            padding: '15px 12px',
            transition: "0.3s",
            "&:hover": {

                backgroundColor: 'white',
            }
        },
        font: {
            color: '#1C2237',
            fontFamily: 'Avenir',
            fontSize: phone ? 14 : 24,
            fontWeight: 500,
            letterSpacing: 0,
            textAlign: 'left',
            textTransform: 'none'
        },
        phoneHeader: {
            backgroundColor: '#F9F9F9',
            borderRadius: 8,
            padding: '15px 20px',
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'flex-start',
            justifyContent: 'center',
            marginTop: 20
        },
        wrapper: {
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center'
        },
        modal: {
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            textAlign: 'center',
        },
        button: {
            alignSelf: 'center',

            height: '65px',
            borderRadius: 20,
            color: 'white',
            backgroundColor: '#0173F9',
            fontFamily: 'Avenir',
            fontSize: 18,
            fontWeight: 500,
            letterSpacing: 0,
            lineHeight: '25px',
            textTransform: 'none',
            "&:hover": {
                backgroundColor: '#081647',
                // Reset on touch devices, it doesn't add specificity
                "@media (hover: none)": {
                    backgroundColor: '#081647',
                    "&:active": {
                        backgroundColor: '#162E83'
                    }
                },
                '&$disabled': {
                    backgroundColor: '#162E83',
                },
            }
        },
        buttonPhone: {
            width: '100%',
            height: '50px',
            borderRadius: 14,
            color: 'white',
            backgroundColor: '#162E83',
            fontFamily: 'Avenir',
            fontSize: 14,
            fontWeight: 500,
            letterSpacing: 0,
            lineHeight: '16px',
            textTransform: 'none',
            "&:hover": {
                backgroundColor: '#081647',
                // Reset on touch devices, it doesn't add specificity
                "@media (hover: none)": {
                    backgroundColor: '#081647',
                    "&:active": {
                        backgroundColor: '#081647'
                    }
                },
                '&$disabled': {
                    backgroundColor: '#162E83',
                },
            }
        },
        listItem: {
            "& span": {
                fontWeight: 'bold',
                color: '#1C2237',
                fontSize: phone ? 12 : 16,

            }
        }
    });
    const classes = useStyles();
    const history = useHistory();

    const { kycDetails, fetched } = useSelector(state => state.kyc)

    const calculateBalance = () => {
        let USDTBalance = userCapitals.find(item => item.symbol === 'USDT')
        let CADBalance = userCapitals.find(item => item.symbol === 'CAD')
        let ETHBalance = userCapitals.find(item => item.symbol === 'ETH')
        let BTCBalance = userCapitals.find(item => item.symbol === 'BTC')

        if (USDTBalance === undefined) {
            USDTBalance = { amount: 0 }
        }
        if (CADBalance === undefined) {
            CADBalance = { amount: 0 }
        }
        if (ETHBalance === undefined) {
            ETHBalance = { amount: 0 }
        }
        if (BTCBalance === undefined) {
            BTCBalance = { amount: 0 }
        }
        let temBalance = CADToUSDT * USDTBalance.amount + CADBalance.amount + ETHBalance.amount * ethQuote.price + BTCBalance.amount * btcQuote.price
        setTotalBalance(temBalance)
    }

    useEffect(() => {
        setPhone(width <= 745)
        return () => {
        }
    }, [width]);

    useEffect(() => {
        dispatch(authActions.getUserInfo(token))
        dispatch(kycActions.syncKycStatus(token))
        dispatch(fundsActions.getUserCapital(token))
        dispatch(fundsActions.priceQuote({
            "ticker": "USDT-CAD",
            "deliverQuantity": "1",
            "tradeSide": "sell"
        }, token))
        dispatch(fundsActions.getETHQuote({
            "ticker": "ETH-CAD",
            "deliverQuantity": "0.01",
            "tradeSide": "sell"
        }, token))
        dispatch(fundsActions.getBTCQuote({
            "ticker": "BTC-CAD",
            "deliverQuantity": "0.01",
            "tradeSide": "sell"
        }, token))
        setLoaded(true)
        return () => {
        }
    }, []);

    useEffect(() => {
        // console.log('userInfo: ', userInfo)
        setCanWithdraw(userInfo.phoneValid)
    }, [userInfo])

    useEffect(() => {
        if (fetched) {
            dispatch(kycActions.getKycDetails(token))
        }
    }, [fetched])

    useEffect(() => {
        if (priceQuote.price !== undefined) {
            setCADToSUDT(priceQuote.price)
        }
        calculateBalance()
    }, [priceQuote, userCapitals, ethQuote, btcQuote])

    const renderBalanceList = () => {
        let noBalance = userCapitals.length <= 0
        return (
            <div style={{ marginTop: 0 }}>
                {
                    phone ?
                        null
                        :
                        <Typography style={{ fontSize: phone ? 12 : 14, color: '#8B96A7', fontWeight: 'bold', fontFamily: 'Avenir', marginBottom: 8, textAlign: 'left', marginTop: 24 }}>  {t("balanceDetails")}</Typography>

                }


                {
                    noBalance || kycDetails.status !== 'passed' ?
                        <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'flex-start', alignItems: 'flex-start', width: '100%', marginBottom: 16 }}>
                            <div style={{ display: 'flex', alignItems: 'flex-start', marginBottom: 8 }}>
                                <img style={{ height: 20 }} src={warningicon}></img>
                                <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-start' }}>
                                    {kycDetails.status !== 'passed' ?
                                        <Typography style={{ fontSize: 14, fontWeight: 'bold', color: '#959FAF', marginLeft: 8, textAlign: 'left' }} >
                                            · {t("notVerifyWarning")}
                                        </Typography>
                                        :
                                        null
                                    }
                                    {
                                        noBalance ?
                                            <Typography style={{ fontSize: 14, fontWeight: 'bold', color: '#959FAF', marginLeft: 8 }} >
                                                · {t("noBalance")}
                                            </Typography>
                                            :
                                            null
                                    }

                                </div>
                            </div>
                        </div>
                        :
                        null
                }
                {


                    noBalance ?
                        <img src={noBalancePlaceHolder}></img>
                        :
                        <div style={{ justifyContent: 'space-between', display: 'flex', backgroundColor: phone ? "white" : '#F9F9F9', borderRadius: 20, padding: phone ? 0 : 16, flexDirection: 'column' }}>

                            {/* est balance */}

                            <div style={{ marginLeft: phone ? -24 : 0, marginRight: phone ? -24 : 0, backgroundColor: '#f9f9f9', }}>
                                {phone ? <div style={{ height: 1, width: '100%', backgroundColor: '#EFEFEF', marginBottom: 8 }} /> : null}

                                <div style={phone ? { display: 'flex', justifyContent: 'space-between', alignItems: 'center', marginTop: 0, paddingLeft: 20, paddingRight: 12, paddingTop: 12, paddingBottom: 12 } : { display: 'flex', justifyContent: 'space-between', alignItems: 'center', marginBottom: phone ? 16 : 24, marginTop: phone ? 8 : 16 }}>
                                    <div style={{ display: 'flex', alignItems: 'center' }}>

                                        <div style={{ marginLeft: 12 }}>
                                            <Typography style={{ fontSize: phone ? 12 : 14, color: '#8B96A7', fontWeight: '500', fontFamily: 'Avenir', textAlign: 'left' }}>{t('estimated')}</Typography>

                                            <div style={{ display: 'flex', alignItems: 'flex-end' }}>
                                                <Typography style={{
                                                    color: 'black',
                                                    fontFamily: 'Avenir',
                                                    fontSize: phone ? 20 : 24,
                                                    fontWeight: 500,
                                                    letterSpacing: 0,
                                                    textAlign: 'left',
                                                    textTransform: 'none'
                                                }}>${FormatNumber(totalBalance)} </Typography>
                                                <Typography style={{ fontSize: phone ? 10 : 14, color: 'black', fontWeight: '500', marginBottom: phone ? 3 : 4, marginLeft: phone ? 4 : 8, fontFamily: 'Avenir', }}>CAD </Typography>
                                            </div>
                                        </div>

                                    </div>





                                    <div>
                                        <Button style={{
                                            backgroundColor: 'white', height: phone ?32:40, width: phone ?32:40, padding: '0',
                                            display: 'inline-block',
                                            minHeight: 0,
                                            minWidth: 0,
                                            borderRadius: 12,
                                            marginRight: 12,
                                            opacity: !loaded || kycDetails.status !== 'passed' ? 0.6 : 1
                                        }} onClick={() => { history.push('/history') }}
                                            disabled={!loaded || kycDetails.status !== 'passed'}
                                        >
                                            <img alt='' src={historyIcon} style={{ height: "100%", }}></img>

                                        </Button>
                                        {/* <Button style={{
                                            backgroundColor: '#D7E2FF', height: 32, width: 32, padding: '0',
                                            display: 'inline-block',
                                            minHeight: 0,
                                            minWidth: 0,
                                            borderRadius: 12,
                                            marginRight: 8,
                                            opacity: !loaded || kycDetails.status !== 'passed' ? 0.6 : 1
                                        }} onClick={() => { history.push('/fund') }}
                                            disabled={!loaded || kycDetails.status !== 'passed'}
                                        >
                                            <img alt='' src={depositIcon} style={{ height: phone ? 20 : 24, marginTop: 4, marginBottom: 4 }}></img>

                                        </Button> */}
                                    </div>
                                </div>


                            </div>
                            <div style={{ height: 1, width: "100%", marginTop: phone ? 24 : 0, marginBottom: phone ? 12 : 0, backgroundColor: phone ? "white" : '#ECECEC' }} />
                            {
                                phone ?


                                    <Typography style={{ fontSize: phone ? 12 : 14, color: '#8B96A7', fontWeight: '500', fontFamily: 'Avenir', marginBottom: 8, textAlign: 'left' }}>  {t("balanceDetails")}</Typography>
                                    : null

                            }

                            <List style={{ width: '100%' }} >
                                {userCapitals.map(item => (
                                    <ListItem key={item.symbol} className={classes.tableCell}
                                    >
                                        <ListItemAvatar style={{ minWidth: 30 }}>
                                            <img style={{ height: phone ? 28 : 32, borderRadius: 0, alignSelf: 'center' }} src={getIcons(item.symbol)} />
                                        </ListItemAvatar>
                                        {/* <ListItemText
                                            primary={item.symbol}
                                            className={classes.listItem}
                                        /> */}

                                        <Typography style={{ fontSize: phone ? 16 : 20, marginLeft: 8, color: 'black', fontWeight: '500', fontFamily: 'Avenir', }}>{item.symbol}</Typography>

                                        <ListItemSecondaryAction>


                                            <div style={{ display: 'flex', alignItems: 'baseline' }}>
                                                <Typography style={{ fontSize: phone ? 16 : 20, color: 'black', fontWeight: '500', fontFamily: 'Avenir', }}> {`${item.symbol === 'CAD' ? roundingDown(item.amount, 2) : roundingDown(item.amount, 8)}`}</Typography>

                                                <Typography style={{ fontSize: phone ? 10 : 12, marginLeft: 4, color: '#8B96A7', fontWeight: 'bold', fontFamily: 'Avenir', }}>{item.symbol}</Typography>

                                            </div>
                                        </ListItemSecondaryAction>
                                    </ListItem>
                                )
                                )}
                            </List>
                        </div>
                }


            </div >
        )
    }

    const renderHeader = () => {
        let hideWarning = kycDetails.status === 'passed'
        calculateBalance()
        return (
            hideWarning ?
                <div className={phone ? classes.phoneHeader : null}>
                    <Typography
                        style={
                            phone ? { color: "#9097A1", fontFamily: "Avenir", fontSize: 12, fontWeight: 500, letterSpacing: 0, lineHeight: '22px', textAlign: 'initial' }
                                : { marginTop: 60, color: "#9097A1", fontFamily: "Avenir", fontSize: 14, fontWeight: 500, letterSpacing: 0, lineHeight: '35px', textAlign: 'initial' }
                        }
                    >
                        {t("totalBalance")}
                    </Typography>
                    <Typography
                        style={
                            phone ? { color: "#1C2237", fontFamily: "Avenir", fontSize: 16, fontWeight: 500, letterSpacing: 0, lineHeight: '22px', textAlign: 'initial' }
                                : { color: "#1C2237", fontFamily: "Avenir", fontSize: 24, fontWeight: 500, letterSpacing: 0, lineHeight: '35px', textAlign: 'initial' }
                        }
                    >
                        {`≈ $${FormatNumber(totalBalance)} CAD`}
                    </Typography>
                </div>
                :
                <EliteKycStatus phone={phone} setKycVisible={setKycVisible} />
        )
    }

    const setAuth = () => {
        history.push('/setting')
    }

    const handleClose = () => {
        setAuthModalVisible(false)
    }

    const ModalBody = (
        <motion.div
            initial={{ opacity: 0, scale: 0 }}
            animate={{ opacity: 1, scale: 1 }}
        >
            <div style={{
                backgroundColor: 'white', borderRadius: 20, width: phone ? " 89vw" : "606px", overflow: 'auto', outline: 'none'
            }}>
                <div style={phone ? { margin: 'min(10%,50px)' } : { marginTop: 40, marginBottom: 40, marginLeft: 32, marginRight: 32 }}>
                    <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                        <Typography style={{ fontSize: phone ? 16 : 24, fontWeight: "500", color: '#1C2237', fontFamily: 'Avenir', }}>
                            {t('2faRequiredTitle')}
                        </Typography>

                        <Button
                            style={{
                                padding: 0,
                                minHeight: 0,
                                minWidth: 0,
                            }}
                            onClick={handleClose}
                        >
                            <img alt='' src={x} style={phone ? { height: 24, width: 24 } : { height: 32, width: 32 }} />
                        </Button>

                    </div>
                    <div style={{ marginTop: phone ? 15 : 15, boxSizing: 'border-box', height: 1, width: '100%', border: '1px solid #DDDDDD' }} />

                    <img src={safe} style={{ width: phone ? 30 : 80, marginTop: phone ? 8 : 40, marginBottom: 20 }} />
                    <Typography
                        className={classes.font}
                        style={{
                            fontSize: phone ? 14 : 24, lineHeight: phone ? '21px' : '33px',
                            color: "black",
                            fontFamily: 'Avenir',
                            fontWeight: 500,
                            letterSpacing: 0,
                            textAlign: 'center',
                        }}
                    >
                        {t('2faRequired')}
                    </Typography>


                    <Typography
                        className={classes.font}
                        style={{
                            marginTop: 3, color: "#9097A1", fontSize: phone ? 12 : 16, lineHeight: phone ? '12px' : '23px',
                            fontFamily: 'Avenir',
                            fontWeight: 500,
                            letterSpacing: 0,
                            textAlign: 'center',
                            marginBottom: 20
                        }}
                    >
                        {t('2faContent')}
                    </Typography>
                    {/* <div className="row">
                        <div className="col" style={{ width: '100%' }}>
                            <Button
                                className={phone ? classes.buttonPhone : classes.button}
                                style={{ marginTop: phone ? 10 : 20 }}
                                onClick={setAuth}
                            >
                                {t('set2FA')}
                            </Button>
                        </div>
                        <div className="col" style={{ width: '100%' }}>
                            <Button
                                className={phone ? classes.buttonPhone : classes.button}
                                style={{ backgroundColor: 'transparent', color: "#1C2237", marginTop: phone ? 10 : 20 }}
                                onClick={handleClose}
                            >
                                <img src={cancel} style={{ alignSelf: 'center', width: 15, paddingRight: 5 }} />
                                {t('cancel')}
                            </Button>
                        </div>
                    </div> */}
                    <div style={{ display: 'flex', justifyContent: 'end', }}>
                        <Button
                            className={phone ? classes.buttonPhone : classes.button}
                            style={{ marginTop: phone ? 40 : 60 }}
                            onClick={setAuth}

                        >
                            <Typography className={classes.font} style={{ fontSize: phone ? 14 : 18, color: 'white', fontWeight: 500, marginLeft: 12, marginRight: 12, lineHeight: phone ? '11px' : '25px' }}> {t('set2FA')}</Typography>
                            <img style={{ height: phone ? 16 : 20 }} src={enterArrow}></img>
                        </Button>
                    </div>
                </div>
            </div >
        </motion.div>
    );

    return (

        <div style={{ width: '100%', display: 'flex', alignItems: 'center', flexDirection: 'column', marginBottom: phone ? 40 : 120 }}>
            <Helmet>
                <title>{t('titleBalance')}</title>

                <meta name="keywords" content="Toronto, OTC, bitcoin, exchange, cryptocurrency, 多伦多, 场外交易平台, 比特币, 以太坊, 加元, 换汇，加密货币, 人民币,多倫多, 場外交易平台, 比特幣, 以太坊, 加元, 換匯, 人民幣， Yesbit, Fiat, CAD, BTC, ETH, USD, balance" />
            </Helmet>
            <div style={{ width: '90%', alignItems: 'center', textAlign: 'center', maxWidth: 543, minWidth: 317 }}>
                {/* {loaded ? renderHeader() : null} */}

                {renderBalanceList()}

                <div className={classes.wrapper}>
                    <Button
                        className={phone ? classes.buttonPhone : classes.button}
                        style={{ color: '#1C2237', width: '48%', backgroundColor: '#FFE4E5', margin: phone ? '42px 0' : '53px 0', opacity: !loaded || kycDetails.status !== 'passed' ? 0.6 : 1 }}
                        disabled={!loaded || kycDetails.status !== 'passed'}
                        onClick={() => { goToWithdraw() }}
                    >
                        <img style={{ height: 24 }} src={withdrawIcon}></img>
                        {t('withdraw')}
                    </Button>

                    <Button
                        className={phone ? classes.buttonPhone : classes.button}
                        style={{ color: '#1C2237', width: '48%', backgroundColor: "#D7E2FF", margin: phone ? '42px 0' : '53px 0', opacity: !loaded || kycDetails.status !== 'passed' ? 0.6 : 1 }}
                        disabled={!loaded || kycDetails.status !== 'passed'}
                        onClick={() => { goToFund() }}
                    >
                        <img style={{ height: 24 }} src={depositIcon}></img>
                        {t('getFund')}
                    </Button>
                </div>

            </div>
            {/*KYC弹窗*/}
            {/* <KycModal
                onCancel={() => {
                    setKycVisible(false);
                }}
                modalVisible={kycVisible}
                t
                phone={phone}
                width={width}
            /> */}

            <Modal
                open={authModalVisible}

                onClose={() => {
                    handleClose()
                }}
                className={classes.modal}
                aria-labelledby="simple-modal-title"
                aria-describedby="simple-modal-description"
                BackdropProps={{
                    className:
                        'modalBackground'
                }}
            >
                {ModalBody}
            </Modal>
        </div>
    )
}
export default withTranslation()(Balance)
